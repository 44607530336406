import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { COLORS } from '../../constants';

class PayAtProperty extends Component {
  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 9,
        }}
      >
        <div style={{ position: 'absolute', width: '100%' }}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                color: 'white',
                // width: '94%',
                width: 'auto',
                margin: 'auto',
                padding: 5,
                backgroundColor: COLORS.primary,
                borderRadius: 10,
                fontSize: 10,
                display: 'flex',
              }}
            >
              <img
                style={{
                  width: 12,
                  marginRight: 10,
                }}
                src="/icon/cottage.png"
                alt="Nusalink"
              />
              {t.payAtVilla}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);
export default translator(PayAtProperty);
