import React from 'react';
import SelectOption from './SelectOption';

export default function SelectGuest({ onChange, defaultValue }) {
  return (
    <SelectOption
      defaultValue={defaultValue}
      onChange={e => {
        localStorage.setItem('GUEST_COUNT', e.target.value);
        onChange(e);
      }}
      label="Guest (s)"
    >
      <option value=""></option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </SelectOption>
  );
}
