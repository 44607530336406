import React, { Component } from "react";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";
import {
  toggleModal,
  showModal,
  setMessage,
  setPages,
  setMessageMId,
  setBoxFilterDetail,
} from "../../redux/actions";
import EventList from "../../components/EventList/EventList.js";
import Container from "../layouts/Container.js";
class Events extends Component {
  // constructor(props) {
  //   super(props);
  // }
  // componentWillMount() {}

  // componentDidMount() {}

  render() {
    const { t } = this.props;
    return (
      <Container>
        <h2 style={{ marginBottom: "30px" }}>{t.upcomingEvents}</h2>
        <EventList all={true} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  showModal,
  toggleModal,
  setMessage,
  setPages,
  setMessageMId,
  setBoxFilterDetail,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Events));
