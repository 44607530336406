import React from 'react';

function NusaMinang3Icon({ width = 42, height = 42 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="8" fill="#FFBA33" />
      <path
        d="M15.7998 12.8003V16.6003"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7998 21.667V31.8003"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0668 12.8003L20.0001 17.8669C20.0001 18.8748 19.5576 19.8413 18.7699 20.5539C17.9823 21.2666 16.914 21.6669 15.8001 21.6669C14.6862 21.6669 13.6179 21.2666 12.8302 20.5539C12.0426 19.8413 11.6001 18.8748 11.6001 17.8669L12.5334 12.8003"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7998 25.4669H22.7998C22.7998 25.4669 24.2998 14.3836 29.7998 12.8003V31.8003"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NusaMinang3Icon;
