import React from 'react';

export function SelectOption({
  label,
  id,
  name,
  onChange,
  children,
  defaultValue
}) {
  return (
    <div className="field">
      <label className="labels">{label}</label>
      <div className="control">
        <div className="select is-fullwidth">
          <select
            style={{ backgroundColor: '#F0F0F0' }}
            id={id}
            name={name}
            onChange={e => {
              onChange(e);
            }}
            defaultValue={defaultValue}
          >
            {children}
          </select>
        </div>
      </div>
    </div>
  );
}

export default SelectOption;
