import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { setBoxFilterDetail } from "./../../redux/actions/AppActions";
import { translatable } from "react-multilingual";
import Slider from "react-slick";
import ApiCall from "../../services/ApiCall";
import { connect } from "react-redux";
import moment from "moment";
import "flatpickr/dist/themes/confetti.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { dateIN, dateEN, sliderSettings as settings } from "./../../constants";

class EventList extends Component {
  state = {
    eventHome: [],
  };

  // constructor(props) {
  //   super(props);
  // }

  gotodetail = (e, hotelid) => {
    this.props.setBoxFilterDetail(false);
    this.props.history.push(`/detail-villas?villas=${hotelid}`);
  };

  gotoAllPropertyWithCity(cityId) {
    this.props.history.push(`/villas?city=${cityId}`);
  }

  componentDidMount() {
    ApiCall.getEventHome(this.props.all || false).then((response) => {
      this.setState({
        eventHome: response.data.data,
      });
    });
  }

  render() {
    return (
      <div
        className="columns is-multiline long"
        onClick={(e) => {
          //this.eventOnCloseGuest(e)
        }}
      >
        {this.state.eventHome.map((items, index) => (
          <div className="column is-3 boxListVillas" key={index.toString()}>
            <div
              className="box boxheight"
              style={{
                padding: 0,
                borderRadius: 4,
                paddingBottom: 10,
              }}
            >
              <div className="columns" style={{ marginBottom: -15 }}>
                <div className="column is-12" style={{ paddingTop: 0 }}>
                  <Slider {...settings}>
                    <div
                      className="slide-item"
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <figure image="true" is-4by3="true">
                        <img
                          alt=""
                          onClick={(e) =>
                            this.gotoAllPropertyWithCity(items.kota.id)
                          }
                          className="images-galery imgVillasList"
                          src={items.image_url || "/img/architecture-beach.jpg"}
                        />
                      </figure>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="columns kolomeventnama">
                <div className="column is-12">
                  <p className="namaevent"> {items.category} </p>
                </div>
              </div>
              <div className="columns kolomkotanama">
                <div className="column is-12">
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: -5 }}
                  >
                    <div className="column is-12 boxKonten180913">
                      <b
                        className="kolomnama"
                        onClick={(e) =>
                          this.gotoAllPropertyWithCity(items.kota.id)
                        }
                      >
                        {" "}
                        {items.nameEvent}{" "}
                      </b>
                      <br />
                      <div
                        onClick={(e) =>
                          this.gotoAllPropertyWithCity(items.kota.id)
                        }
                        className="labelEventName180913"
                      >
                        <img
                          src="/icon/point-location.png"
                          alt="Nusalink"
                          className="iconLocationVilla180913"
                        />
                        <label
                          style={{
                            marginLeft: 5,
                          }}
                          className="labelEventAddress180914"
                        >
                          {items.kota.nama_kota}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns kolomkalender">
                <div className="column is-12 labelEventKalneder180913">
                  <img
                    src="/icon/calander-home.png"
                    className="iconKalender180913"
                    alt=""
                  />

                  {items.schedule.map((jadwal, indexX) => (
                    <label
                      key={indexX}
                      className="labelAddressKalender180914"
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      {moment(jadwal.eventDates).format("D") +
                        " " +
                        (this.props.app.lang === "" ||
                        this.props.app.lang === "id"
                          ? dateIN[moment(jadwal.eventDates).format("M") - 1]
                          : dateEN[moment(jadwal.eventDates).format("M") - 1]) +
                        " " +
                        moment(jadwal.eventDates).format("Y")}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  setBoxFilterDetail,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(withRouter(EventList)));
