import React, { useEffect, useState } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import ShowAll from '../../components/Buttons/ShowAll/ShowAll';
import PrimaryButton from '../../components/form/PrimaryButton';
import Loader from '../../components/Loader/Loader';
import NotFound from '../../components/others/NotFound';
import PageTitle from '../../components/others/PageTitle';
import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';
import Container from '../layouts/Container';

const Promo = ({ match, t, history }) => {
  const [voucher, setVoucher] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [vouchers, setVouchers] = useState([]);
  const [promoId, setPromoId] = useState(null);
  const [others, setOthers] = useState([]);

  useEffect(() => {
    const { promoId } = match.params;
    setPromoId(promoId);
    if (promoId) {
      if (promoId === 'all') {
        ApiCall.getVoucherHomeAll()
          .then(res => {
            console.log(res.data);
            setVouchers(res.data.data);
          })
          .catch(err => {
            console.log('page not found');
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        ApiCall.findVoucherActive(promoId)
          .then(res => {
            // console.log(res.data);
            setVoucher(res.data);
          })
          .catch(err => {
            console.log('page not found');
          })
          .finally(() => {
            setIsLoading(false);
          });
        ApiCall.getOtherVouchers()
          .then(res => {
            if (res.data.status === 'success') {
              setOthers(res.data.data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Container>
      <PageTitle title="Promo" />
      {!isLoading && voucher === null && vouchers.length === 0 && <NotFound />}
      <Loader loading={isLoading} />
      {!isLoading && voucher !== null && (
        <div>
          <div className="has-text-centered">
            <img
              onClick={() => {
                window.open(BASE_URL_ADM + '/images/voucher/' + voucher.images);
              }}
              style={{ maxWidth: 600, cursor: 'pointer' }}
              src={BASE_URL_ADM + '/images/voucher/' + voucher.images}
              alt={'Voucher Promo ' + voucher.code}
            />
            {/* <h3>Code: {voucher.code}</h3> */}
          </div>
          <div className="has-text-centered">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-6">
                <div className="has-text-centered" style={{ marginTop: 20 }}>
                  {/* <h3>{t.term}</h3> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: voucher.term_and_conditions
                    }}
                  />
                  {voucher.btn_link !== null && voucher.btn_text !== null && (
                    <PrimaryButton
                      label={voucher.btn_text}
                      onClick={() => {
                        window.location.href = voucher.btn_link;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="has-text-left">
              <PageTitle title="Promo Menarik Lainnya" />
              <div className="columns is-multiline">
                {others.map((item, index) => (
                  <div key={index} className="column is-3">
                    <img
                      onClick={() => {
                        const link = '/promo/' + item.id;
                        window.location.href = link;
                        // history.push();
                      }}
                      style={{ cursor: 'pointer' }}
                      src={item.image_url_500}
                      alt={item.code}
                      width="500"
                      height="500"
                    />
                  </div>
                ))}
                <div className="column is-12">
                  <ShowAll
                    onClick={() => {
                      // history.push('/promo/all');
                      window.location.href = '/promo/all';
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {promoId === 'all' && (
        <div className="columns is-multiline">
          {vouchers.map((item, index) => (
            <div key={index} className="column is-3">
              <img
                onClick={() => {
                  const link = '/promo/' + item.id;
                  window.location.href = link;
                  // history.push();
                }}
                style={{ cursor: 'pointer' }}
                src={item.image_url}
                alt={item.code}
                width="500"
                height="500"
              />
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(Promo);
