import React from 'react';

function ShowAll({ onClick }) {
  return (
    <div className="showAll">
      <section className="section2Home inner box-list">
        <div className="container">
          <div className="columns">
            <div className="column showAllTab">
              <a onClick={onClick}>
                <span className="read-more-home"> Lihat semua </span>
                &nbsp;<span className="read-more-home"> &gt; </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ShowAll;
