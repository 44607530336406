import React, { useEffect, useState, useRef } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { connect } from 'react-redux';
import PageTitle from '../../components/others/PageTitle';
import ApiCall from '../../services/ApiCall';
import StringHelper from '../../utils/StringHelper';
import Container from '../layouts/Container';

const VoucherRamadhan = ({ match, t, history, app }) => {
  const [promo, setPromo] = useState(null);
  const isLoading = useRef(false);

  useEffect(() => {
    // (async () => {
    //   try {
    //     console.log(app);
    //     const response = await ApiCall.claimPromoEvent();
    //     if (response.data.status === 'success') {
    //       setPromo(response.data.data);
    //     }
    //   } catch (error) {}
    // })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // console.log(app);
        if (!isLoading.current && StringHelper.isFilled(app.user)) {
          const { email, id } = app.user;
          isLoading.current = true;
          // console.log(app.user);
          const response = await ApiCall.claimPromoEvent(id, email);
          if (response.data.status === 'success') {
            setPromo(response.data.data);
          }
        }
      } catch (error) {}
    })();
    // console.log('hehe');
  }, [app.user]);

  if (promo === null) return <div />;

  return (
    <Container>
      <PageTitle title={'Voucher Event '} />
      <div className="has-text-centered">
        <img src={promo.detail.gif_url} alt={promo.voucher_code} />
        <h3>Selamat anda mendapatkan voucher</h3>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};
const connector = connect(mapStateToProps);
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(VoucherRamadhan));
