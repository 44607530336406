import React, { useState } from 'react';
import Slider from 'react-slick';

function Sliders({ children }) {
  const [options] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    initialSlide: 0,
    autoplay: true
  });
  return <Slider {...options}>{children}</Slider>;
}

export default Sliders;
