import React, { Component } from 'react';
import Slider from 'react-slick';
import { BASE_URL_ADM, settings } from '../../constants';
import { translatable } from 'react-multilingual';
import Numeral from 'numeral';
import StartFrom from '../../pages/layouts/StartFrom';
import Star from '../HotelDetail/Star';

class PropertyItem extends Component {
  toTitleCase = str => {
    let _string = str.replace('KOTA', '');
    _string = _string.replace('KABUPATEN', '');
    return _string.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  gotodetail(e, propertyId) {
    this.props.onClick(e, propertyId);
  }
  render() {
    const {
      property,
      t,
      // index,
      per
    } = this.props;
    let perLabel = t.month;
    if (per === 'night') {
      perLabel = t.night;
    } else if (per === 'week') {
      perLabel = t.week;
    } else if (per === 'year') {
      perLabel = t.year;
    }
    // console.log('t', perLabel);

    return (
      <div className="column is-3 boxListVillas">
        <div
          className="box boxheight"
          style={{
            padding: 0,
            borderRadius: 4,
            paddingBottom: 10
          }}
        >
          <div className="columns" style={{ marginBottom: -15 }}>
            <div className="column is-12" style={{ paddingTop: 0 }}>
              <Slider {...settings}>
                {property.images.length > 0 &&
                  property.images.slice(0, 1).map(i => (
                    <div
                      className="slide-item"
                      style={{ marginTop: -15 }}
                      key={i.toString()}
                    >
                      <figure image="true" is-4by3="true">
                        <img
                          className="images-galery imgVillasList"
                          src={`${BASE_URL_ADM}/images/hotel/${i}`}
                          alt=""
                          onClick={e => this.gotodetail(e, property.id)}
                        />
                      </figure>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div
            className="columns kolomkotanama"
            onClick={e => this.gotodetail(e, property.id)}
          >
            <div className="column is-12">
              <div className="columns is-mobile" style={{ marginBottom: -5 }}>
                <div className="column is-12 boxKonten180913">
                  <div className="d-flex justify-content-between align-items-center">
                    <label
                      style={{ width: 'auto' }}
                      onClick={e => this.gotodetail(e, property.id)}
                      className="labelTypeVilla180913"
                    >
                      {property.categoryName}
                    </label>
                    <Star star={property.star} />
                  </div>
                  <label
                    onClick={e => this.gotodetail(e, property.id)}
                    className="labelCityName180913"
                  >
                    <img
                      src="/icon/point-location.png"
                      alt="Nusalink"
                      className="iconLocationVilla180913"
                    />
                    <label className="labelAddress180914">
                      {this.toTitleCase(property.nama_kota) +
                        ', ' +
                        this.toTitleCase(property.nama_provinsi)}
                    </label>
                  </label>
                </div>
              </div>
              <b
                className="kolomnama labelNameVilla180914"
                onClick={e => this.gotodetail(e, property.id)}
              >
                {property.name}
              </b>
            </div>
          </div>
          <div className="columns" style={{ marginLeft: 5, marginRight: 5 }}>
            <div className="column is-12 kolomharga">
              <span style={{ fontSize: 14, color: 'black' }}>
                <p>
                  <StartFrom />
                  <label onClick={e => this.gotodetail(e, property.id)}>
                    {t.currency}{' '}
                    {Numeral(Math.floor(property.startFrom)).format('0,0')}
                    {String(
                      ' / ' +
                        (property.per === 1
                          ? perLabel
                          : (property.per || '') + ' ' + perLabel)
                    ).toLocaleLowerCase()}
                  </label>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(PropertyItem);
