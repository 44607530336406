import React from 'react';

function NusaCafeIcon({ width = 40, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="8" fill="#FFBA33" />
      <path
        d="M12 8.71429V10.5238"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8.71429V10.5238"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8.71429V10.5238"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 22.2857H21"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7889 22.2857C10.6581 21.7889 9.69995 20.9921 9.02833 19.9897C8.3567 18.9873 7.9998 17.8215 8 16.6308V13.2381H21V16.6308C21.0002 17.8216 20.6433 18.9873 19.9717 19.9897C19.3 20.9921 18.3419 21.789 17.211 22.2857"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2865 13.2381C22.0062 13.2381 22.6963 13.4923 23.2052 13.9447C23.7141 14.3972 24 15.0109 24 15.6508V16.2539C24 16.8938 23.7141 17.5075 23.2052 17.96C22.6963 18.4124 22.0062 18.6666 21.2865 18.6666H21"
        stroke="#775719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NusaCafeIcon;
