import React from 'react';
import { IconTopUpEwallet } from '../../assets/images/icons/topups';

export default function EWalletIcon({ width = 40, height = 40 }) {
  return <img src={IconTopUpEwallet} />;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M23.3929 11H7.60714C7.27183 11 7 11.2518 7 11.5625V19.4375C7 19.7482 7.27183 20 7.60714 20H23.3929C23.7282 20 24 19.7482 24 19.4375V11.5625C24 11.2518 23.7282 11 23.3929 11Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 18C16.8807 18 18 16.8807 18 15.5C18 14.1193 16.8807 13 15.5 13C14.1193 13 13 14.1193 13 15.5C13 16.8807 14.1193 18 15.5 18Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11L24 15"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 20L24 16"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11L7 15"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20L7 16"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15"
          y1="5.71429"
          x2="15"
          y2="41.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F77E3C" />
          <stop offset="1" stopColor="#FF9B64" />
        </linearGradient>
      </defs>
    </svg>
  );
}
