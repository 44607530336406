import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translatable } from "react-multilingual";
import { logout, showModal, setUser, toggleModal } from "../../redux/actions";
import { CheckIN, Location } from "./BookingHistory";

class ActiveBookings extends Component {
  render() {
    const { t, data } = this.props;
    console.log("active bookings", data);
    return (
      <div>
        <h3 className="title" style={{ fontSize: 18 }}>
          {t.actbooking}
        </h3>
        <hr style={{ marginTop: -10 }} />
        {data &&
          data.length > 0 &&
          data.map((items, index) => (
            <div>
              {items.status === 1 ||
              items.status === 2 ||
              items.status === 7 ||
              items.status === 0 ? (
                <Link to={`/detail-booking?id=${items.reservationId}`}>
                  <div
                    className="columns is-mobile item"
                    style={{
                      marginBottom: 15,
                      paddingLeft: 25,
                      marginLeft: -35,
                      marginRight: -35,
                      paddingRight: 25,
                    }}
                  >
                    <div className="column is-5">
                      <p className="villaname" style={{ color: "#3b3b3b" }}>
                        <b>{items.villaName}</b>
                      </p>
                      {items.status === 2 ? (
                        <div className="columns circles is-mobile">
                          <div className="column is-4">
                            <figure className="image icon">
                              <img
                                src="/icon/11.3. Pembayaran Pending.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                          <div
                            className="column is-4"
                            style={{ paddingLeft: 13.5 }}
                          >
                            <figure className="image icon">
                              <img
                                src="/icon/12.4. Pembayaran Selesai.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                          <div className="column is-4">
                            <figure className="image icon">
                              <img
                                src="/icon/13.4. check out.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {items.status === 1 ||
                      items.status === 7 ||
                      items.status === 0 ? (
                        <div className="columns circles is-mobile">
                          <div className="column is-4">
                            <figure className="image icon">
                              <img
                                src="/icon/11.3. Pembayaran Pending.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                          <div
                            className="column is-4"
                            style={{ paddingLeft: 13.5 }}
                          >
                            <figure className="image icon">
                              <img
                                src="/icon/12.3. Pembayaran Selesai.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                          <div className="column is-4">
                            <figure className="image icon">
                              <img
                                src="/icon/13.4. check out.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="column is-4 loccheck">
                      <Location items={items} t={t} />
                    </div>
                    <div className="column is-3 loccheck">
                      <CheckIN app={this.props.app} items={items} />
                    </div>
                  </div>
                  <hr className="linemobile2" style={{ marginTop: -15 }} />
                </Link>
              ) : (
                ""
              )}
            </div>
          ))}
        {data.length === 0 ? (
          <center>
            <p style={{ fontSize: 16 }} className="nobookfound">
              {t.noactbook}
            </p>
          </center>
        ) : (
          ""
        )}
        <hr style={{ marginTop: 0 }} className="linemobile" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};
const mapTranslationsToProps = ({ t }) => ({ t });
const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(ActiveBookings));
