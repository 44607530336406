import React, { Component } from 'react';

export class GuestCounter extends Component {
  klikPlus = () => {
    if (this.props.guestCount >= this.props.max) {
    } else {
      const tambah = this.props.guestCount + 1;
      this.props.onChange(tambah);
    }
  };
  klikMin = () => {
    if (this.props.guestCount <= 1) {
    } else {
      const kurang = this.props.guestCount - 1;
      this.props.onChange(kurang);
    }
  };
  render() {
    const { guestCount } = this.props;
    return (
      <div className="columns boxGuestDetail is-mobile is-multiline">
        <div className="column is-5 guestImage field is-grouped is-mobile is-multiline">
          <img
            className="control image is-24x24"
            src="/icon/guest.png"
            alt="Nusalink"
          />
          <p className=" control"> Guests </p>
        </div>
        <div className="column is-6 is-mobile is-multiline tamu">
          <div className="field has-addons has-addons-centered border-guest">
            <p
              className={
                guestCount === 1
                  ? 'control button bg-guest-min-hilang'
                  : 'control button bg-guest-min'
              }
              onClick={(e) => this.klikMin(e)}
            >
              -
            </p>
            <div className="control jmlGuest">
              <input
                className="input has-text-centered txtGuest"
                value={guestCount}
                readOnly
              />
            </div>
            <p
              className={
                guestCount === this.props.max
                  ? 'control button bg-guest-plus-hilang'
                  : 'control button bg-guest-plus'
              }
              onClick={(e) => this.klikPlus(e)}
            >
              +
            </p>
          </div>
          <div className="columns">
            <div className="column is-4 is-offset-7">
              <br />
              <br />
              <p
                className="bg-done is-pulled-right"
                onClick={(e) => this.props.onDone(e)}
              >
                {' '}
                Done{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GuestCounter;
