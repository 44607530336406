import React from 'react';
import FormInput from './FormInput';

export default function InputCheckOut({ onChange, defaultValue, value }) {
  return (
    <FormInput
      label={'Check Out'}
      onChange={e => {
        let date = e.target.value;
        localStorage.setItem('CHECK_OUT', date);
        onChange(e);
      }}
      value={value}
      type={'date'}
      placeholder={'Check Out'}
      defaultValue={defaultValue}
    />
  );
}
