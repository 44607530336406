export default {
  t: {
    hello: 'Halo',
    sorry: 'Maaf',
    chooseYourHotel: 'Pilih Hotel Yang Anda Inginkan',
    login: 'Masuk',
    loginOke: 'Login Berhasil',
    logoutOke: 'Logout Berhasil',
    register: 'Daftar',
    destination: 'Kota Tujuan',
    guest: 'Tamu',
    guests: 'Tamu',
    date: 'Tanggal Menginap',
    duration: 'Durasi',
    search: 'Cari',
    rooms: 'Pilih Unit',
    around: 'Sekitar',
    currency: 'Rp',
    facilities: 'Fasilitas',
    // book: 'Pesan Kamar',
    viewMore: 'lihat lainnya',
    contact: 'Nama Kontak',
    idNumber: 'Nomor KTP',
    phone: 'Nomor Telepon',
    address: 'Alamat',
    guestName: 'Nama Tamu',
    guestToo: 'Saya adalah tamu',
    additional: 'Tambahan',
    roomType: 'Tipe Kamar',
    capacity: 'Kapasitas Unit',
    people: 'Orang',
    night: 'Malam',
    nigght: 'malam',
    book: 'Pilih',
    reserv: 'Pesan',
    information: 'Informasi Anda',
    informationGuest: 'Informasi Tamu',
    detail: 'Rincian Pesanan',
    other: 'Lainnya',
    room: 'Unit',
    breakfast: 'Sarapan',
    promo: 'Masukkan kode kupon',
    usePromo: 'Gunakan',
    promoNotFound: 'Kode kupon tidak ditemukan',
    promoExpire: 'Kode kupon sudah kadaluarsa',
    promoUse: 'Batas limit kupon sudah habis',
    haveAccount: 'Sudah memiliki akun? ',
    forgotPassword: 'Lupa password?',
    dontHaveAccount: 'Tidak memiliki akun?',
    or: 'atau masuk dengan',
    sendMail: 'Konfirmasi Email',
    desEmail: 'Kami akan mengirim token ke email anda untuk mengubah password',
    sendMailPassword: 'Kirim',
    emailNotRegister: 'Email tidak terdaftar',
    resetPasswordToken: 'Token untuk reset pasword terkirim ke email anda',
    newPassword: 'Password Baru',
    newPasswordRepeat: 'Ulangi Password Baru',
    incorrectToken: 'Token salah',
    passwordOke: 'Password berhasil diubah',
    profile: 'Lihat Profil',
    myProfile: 'Profil Anda',
    name: 'Nama Lengkap',
    editProfile: 'Ubah',
    changePassword: 'Ganti Password',
    save: 'Simpan',
    min: '',
    avail: '',
    desc: '',
    confirPass: 'Password baru tidak cocok dengan konfirmasi password',
    hotelNotFound:
      'Tidak ada hotel tersedia yang ditemukan. Mohon ganti pencarian Anda.',
    minDuration: 'Durasi minimal 1 malam',
    maxDuration: 'Durasi maksimal 90 malam',
    registrasi: 'Pendaftaran berhasil, silahkan cek email untuk aktivasi.',
    notHOtel:
      'Tidak ada hotel tersedia yang ditemukan. Mohon ganti pencarian Anda',
    available: 'Tersedia',
    nightMin: 'Minimal Malam',
    minstayf: 'Minimal',
    minstayb:
      'malam untuk melakukan reservasi, silahkan atur ulang durasi menginap Anda.',
    note:
      'Catatan: Permintaan khusus dapat dikenakan biaya tambahan di luar harga tertera. Biaya tambahan ditangani langsung antara Anda dan pihak hotel.',
    selectCity: 'Pilih Kota',
    alertCity: 'Mohon Pilih Kota',
    alertGuest: 'Mohon pilih jumlah tamu',
    alertDate: 'Mohon pilih tanggal check-in dan check-out',
    explore: 'Jelajahi',
    pernight: '/malam',
    average: 'Ini adalah harga rata - rata per malam',
    prev: '<',
    next: '>',
    about: 'Tentang Kami',
    term: 'Syarat & Ketentuan',
    privacyp: 'Kebijakan Privasi',
    folow: 'Ikuti Kami',
    customer: 'Hubungi Kami',
    dates: 'Tanggal',
    passlogin: 'Password',
    nameregister: 'Nama Lengkap',
    nomorreggister: 'No Telp',
    passregister: 'Password',
    konpassregister: 'Konfirmasi Password',
    resetpass: 'Reset Password',
    resetpassbtn: 'Reset',
    login_valid1: 'User dengan email',
    login_valid2: 'sudah terdaftar dengan akun',
    login_valid3:
      ', silahkan lakukan reset password untuk login menggunakan email.',
    register_valid1: 'Email sudah terdaftar dengan akun',
    register_valid2: '',
    notwrong: 'Konfirmasi password salah',
    des_reset_pass:
      'Masukkan password baru anda beserta token yang sudah kami kirim ke email anda.',
    pladates: 'Tanggal',
    incpassword: 'Username atau password salah',
    inverifikasi:
      'Akun belum diaktivasi, silahkan cek email Anda untuk melakukan aktivasi akun.',
    acchasbeen: 'Akun sudah terdaftar',
    confrPass: 'Konfirmasi Password',
    newPass: 'Password Baru',
    tokenrese: 'Token reset',
    noteSpecialRequest:
      'Catatan: Permintaan tambahan tidak dijamin ada dan mungkin tidak akurat. Biaya tambahan ditangani langsung oleh Anda dan pihak hotel.',
    optionSpecialRequest: 'Permintaan Tambahan (Opsional)',
    noteGuestName: 'Seperti pada Passport/KTP (tanpa gelar/karakter spesial)',
    stayDuration: 'Durasi Menginap',
    usePromoLabel: 'Kode Kupon',
    placeholderRequest:
      'Apakah terdapat layanan penjemputan kedatangan saya dari bandara? Jika ada, akan membantu sekali bagi saya.',
    guestReservation: 'Tamu',
    location: 'Lokasi',
    inputNewPassword: 'Masukkan password baru',
    inputConfrPassword: 'Masukkan konfirmasi password',
    inputToken: 'Masukkan token reset',
    successedit: 'Profil berhasil diubah',
    nointernet: 'Tidak ada koneksi internet',
    oldpass: 'Password Lama',
    confirmpass: 'Konfirmasi Password Baru',
    myaccount: 'Akun Saya',
    accountset: 'Pengaturan Akun',
    mybook: 'Pesanan Saya',
    logout: 'Keluar',
    incorrectpassword: 'Password salah',
    max: 'maksimal',
    character: '/ 670 karakter',
    register1: 'Dengan melakukan pendaftaran, saya setuju dengan ',
    register2: ' dan ',
    register3: ' dari Nusalink.',
    about1:
      'Nusalink.asia memiliki tujuan yaitu memberikan bantuan kepada masyarakat untuk melakukan pemesanan hotel milik pribadi maupun publik di Indonesia, sekaligus memberikan pengalaman menginap di hotel yang nyaman dan bersih dengan layanan terbaik, harga yang kompetitif, serta pembayaran yang mudah. Nusalink memiliki koneksi dengan hotel-hotel pada destinasi terbaik seperti. Bali, Lombok, Bandung, Tangerang, dan tempat-tempat lainnya di Indonesia.',
    about2: 'Nusalink.asia dibentuk pada tahun 2017 oleh Nusantech (Solusi Teknologi Nusantara) yang merupakan anak perusahaan dari Nusa Group dan telah di akuisisi oleh anak perusahaan lainnya yaitu PT Samala Kayana Jayasri, pada April 2018. ' +
      'Bisnisnya terfocus pada booking hotel dan berkembang menjadi Ecommerce.<br/>' +
      '<br/>' +
      'Nusalink.asia dapat membantu para Pemilik Property, baik Hotel, Kost, Apartemen, Vila, PO Bus, Maskapai, Kereta Api, Agent Umroh, dan lain sebagainya, sehingga dapat lebih produktif, menghasilkan income yang lebih besar.<br/>' +
      '<br/>' +
      'Nusalink.asia sampai saat ini sudah berhasil menjalin kerjasama dengan para owner property seluruh Indonesia. Nusalink juga telah menjalin kerjasama dengan partner bisnis untuk tercapainya pengembangan dan sinergi bisnis yang baik dan tepat. ' +
      'Kerjasama yang terjalin selama ini selalu mengutamakan kepercayaan, kualitas dan integritas.<br/>' +
      '<br/>' +
      'Nusalink.asia mengembangkan fitur-fitur bisnis seperti PPOB, Ewallet, Ticketing, dan akan terus dikembangkan kesemua produk bisnis yang ada, sehingga para pengguna cukup memiliki 1 aplikasi Nusalink.asia saja untuk memenuhi semua kebutuhannya.',
    abouttitle:
      'Nusalink siap membantu Anda mencari hotel dengan harga terbaik',
    notePhone:
      'cth. +6282143658709 untuk Kode Negara +62 dan Nomor Telepon 082143658709',
    actbooking: 'Pesanan Aktif',
    bookhistory: 'Riwayat Pesanan',
    cancel: 'Dibatalkan',
    messgePopMidtransLogin1: 'Reservasi dengan No. Pesanan',
    messgePopMidtransLogin2: 'berhasil dilakukan.',
    messgePopMidtransLogin3: 'Silahkan cek email untuk melihat status pesanan.',
    messgePopMidtrans1: 'Reservasi berhasil dilakukan (No. Pesanan :',
    messgePopMidtrans2: '), silahkan cek',
    messgePopMidtrans3: ' email Anda untuk melihat status pesanan.',
    pending: 'Menunggu Pembayaran',
    paid: 'Pembayaran Selesai',
    bookcancel: 'Pesanan Dibatalkan',
    checkedout: 'Selesai Menginap',
    orderid: 'No. Pesanan',
    gdetail: 'Rincian Tamu',
    totalguest: 'Tamu',
    pdetail: 'Rincian Harga',
    noactbook: 'Tidak Ada Pesanan Aktif',
    nobookhist: 'Tidak Ada Riwayat Pesanan',
    expire: 'Kadaluarsa',
    bookexpire: 'Pesanan Kadaluarsa',
    download: 'Unduh Voucher',
    specreq: 'Permintaan Tambahan',
    notePromo: 'Punya kupon potongan harga? Masukkan kode pada kolom tertera.',
    coupon: 'Kupon',
    optPayments: 'Opsi Pembayaran',
    payinfull: 'Bayar Penuh',
    payinfulldes:
      'Anda membayar pesanan Anda seluruhnya di awal. Tidak ada biaya tambahan yang akan ditagih saat kedatangan di hotel.',
    payatvilla: 'Tersedia bayar di properti',
    payatvilladesk:
      'Anda membayar pesanan Anda untuk 1 malam pertama di awal. Sisa pembayaran akan ditagih saat kedatangan Anda di hotel.',
    payatvilladesknote:
      'Catatan: Anda tidak dapat memilih opsi ini jika Anda memesan hanya untuk 1 malam. Tidak semua hotel menyediakan opsi pembayaran ini.',
    paylessupfront: 'Bayar Uang Muka',
    paylessupfrontdesk:
      'Anda membayar pesanan Anda untuk 1 malam pertama di awal. Sisa pembayaran akan ditagih saat kedatangan Anda di hotel.',
    paylessupfrontdesknote:
      'Catatan: Anda tidak dapat memilih opsi ini jika Anda memesan hanya untuk 1 malam.',
    payatvillades: 'Sisa pembayaran akan ditagih kemudian di hotel',
    paylessupfrontdes: 'Sisa pembayaran akan ditagih kemudian di hotel',
    paymentdetail: 'Rincian Pembayaran',
    paymentoption: 'Opsi Pembayaran',
    upfront: 'Biaya Uang Muka',
    arrivalfee: 'Biaya Kedatangan',
    payinfulldet: 'Bayar Penuh',
    payatvilladet: 'Bayar di Hotel',
    lessupdet: 'Bayar Uang Muka',
    showall: 'Lihat semua fasilitas',
    basic: 'Dasar',
    savepromo: 'Hemat',
    specdeal: 'dengan penawaran khusus kami!',
    findmore: 'Lihat lebih lanjut',
    showallrules: 'Lihat semua aturan',
    explorervilla: 'Hotel Populer',
    showallvilla: 'Lihat semua',
    resetfilter: 'Reset Filter',
    valiDate: 'Mohon pilih tanggal check-in dan check-out',
    validGuest: 'Mohon pilih jumlah tamu',
    validMinNight1: 'Minimal menginap ',
    validMinNight2: ' malam untuk rentang tanggal yang dipilih',
    konfBedroom: 'Kamar Tidur',
    konfBathroom: 'Kamar Mandi',
    konfBed: 'Ranjang',
    konfFloor: 'Lantai',
    rulesBetween: 'Antara',
    rulesAnd: 'dan',
    rulesCancel: 'hari sebelum kedatangan',
    rulesNotAllowed: 'Tidak diizinkan',
    rulesAllowed: 'Diizinkan',
    rules: 'Peraturan',
    rulesAfter: 'Sesudah',
    rulesBefore: 'Sebelum',
    rulesCancellaton: 'Pembatalan',
    rulesNoRefund: 'Tidak ada refund',
    rulesNotCancellaton: 'Tidak ada pembatalan',
    rulesPets: 'Peliharaan',
    rulesSmoking: 'Merokok',
    rulesParties: 'Pesta',
    accessibilitas: 'Aksesibilitas',
    roadaccess: 'Akses Jalan',
    bus: 'Bis',
    car: 'Mobil',
    bike: 'Sepeda',
    walk: 'Jalan Kaki',
    villaccess: 'Akses Hotel',
    whellchair: 'Ramah kursi roda',
    sowmoreakses: 'Lihat lebih rinci',
    spokenlang: 'Bahasa Lisan',
    tagsHOme: 'Pilihan Hotel Kami',
    similar: 'Hotel di ',

    // home
    payAtVilla: 'Tersedia bayar di properti',
    remaining: 'tersisa',
    startFrom: 'Mulai dari',
    notAvailable: 'Tidak tersedia',
    howToReserve: 'Cara Memesan',
    reservationCheck: 'Cek Reservasi',
    paymentStatus: 'Status Pembayaran',
    check: 'Cek',
    reservation: 'Reservasi',
    notFound: 'Tidak Ditemukan',
    upcomingEvents: 'Event yang akan datang',
    bePartner: 'Menjadi Partner',
    instruction: 'Panduan',
    arrivalHoursToProperty: 'Jam kedatangan ke properti',
    save2: 'Hemat',
    point: 'Poin',
    promoLogin: 'Promo Masuk',
    month: 'Bulan',
    stayYearly: 'Per Tahun',
    stayMonthly: 'Per Bulan',
    stayWeekly: 'Per Minggu',
    week: 'Minggu',
    year: 'Tahun',
    longStay: 'Menginap Lama',
    daily: 'Harian',
    cleanliness: 'Kebersihan',
    comfort: 'Kenyamanan',
    food: 'Makanan',
    service: 'Pelayanan',
    enterReview: 'Masukkan Review Anda',
    reviews: 'Ulasan',
    noReviews: 'Tidak Ada Ulasan',
    reply: 'Balasan',
    promoNusalink: 'Promo Nusalink ',
    paymentMethod: 'Metode Pembayaran',
    hourArrival: 'Jam Kedatangan',
    'Referral Code': 'Kode Referral',
    Check: 'Cek',
    required: 'wajib diisi',
    'not found': 'tidak ditemukan',
    found: 'ditemukan',
    'My Referral Obtained': 'Hasil referral saya',
    'Tourist Attraction': 'Tempat Wisata',
    'Nearest Property': 'Properti Terdekat',
    'Top Up Credits': 'Top Up Pulsa',
    'Phone Number': 'No. Handphone',
    'Active Period': 'Masa Aktif',
    Day: 'Hari',
    'Please insert phone number first':
      'Harap masukkan nomor telepon terlebih dahulu',
    'Top Up Data Packages': 'Top Up Paket Data',
    Credits: 'Pulsa',
    'Data Packages': 'Paket Data',
    'PLN Token': 'Token PLN',
    'PLN Number': 'Nomor PLN',
    'Bills & Top Up': 'Top Up & Tagihan',
    'Movie Ticket': 'Tiket Nonton',
    'More Info': 'Info Selengkapnya'
  }
};
