import React from 'react';
import ReactStars from 'react-rating-stars-component';
import { COLORS } from '../../constants';

export default function Rating({ label, value, count = 5, align = 'right' }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label && <div style={{ flex: 1 }}>{label}</div>}
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        }}
      >
        <ReactStars
          value={value / 2}
          edit={false}
          count={count}
          size={24}
          isHalf={true}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor={COLORS.primary}
        />
      </div>
    </div>
  );
}
