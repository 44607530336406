import React, { Component } from 'react';
import Slider from 'react-slick';
import { settings } from '../../constants';

class SliderImages extends Component {
  render() {
    const { images } = this.props;
    return (
      <Slider {...settings}>
        {images.length > 0 &&
          images.map((url, index) => (
            <div
              onClick={this.props.onClick}
              className="slide-item"
              style={{ marginTop: -15 }}
              key={index}
            >
              <figure image="true" is-4by3="true">
                <img
                  className="images-galery imgVillasList"
                  src={`${url}`}
                  alt={'Image ' + index}
                />
              </figure>
            </div>
          ))}
      </Slider>
    );
  }
}

export default SliderImages;
