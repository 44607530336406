import React, { Component } from 'react';
import StayMonthly from './StayMonthly';

class StayWeekly extends Component {
  render() {
    return <StayMonthly history={this.props.history} isWeekly />;
  }
}

export default StayWeekly;
