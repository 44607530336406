import React, { Component } from 'react';

class TableFull extends Component {
  render() {
    return (
      <table style={{ width: '100%' }}>
        <tbody>{this.props.children}</tbody>
      </table>
    );
  }
}

export default TableFull;
