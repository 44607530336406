import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import moment from 'moment';
import { toast } from 'react-toastify';
// import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import queryString from '../../utils/queryString';
import { toggleModal, setMessage } from '../../redux/actions';
import ApiCall from '../../services/ApiCall';
import './BoxBooking.css';
import LabelPrimary from '../others/LabelPrimary';
import Star from '../HotelDetail/Star';
import FormInput from '../form/FormInput';
import Helper, { moneyFormat } from '../../utils/helper';
import { validateMinHBooking } from '../../utils/BookingHelper'

class BoxBooking extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      bookModal: false,
      jumlahGuest: 1,
      jmlGuest: 0,
      focusedInput: null,
      focusedInput2: null,
      masuk: '',
      keluar: '',
      startDate: null,
      endDate: null,
      applyGuest: true,
      tglMasuk: '',
      tglKeluar: '',
      bookGuest: false,
      sttbookGuest: 0,
      params: [],
      duration: '',
      dateTanggal: '',
      dataAvailable: [],
      hotels: {},
      hotelPrice: '',
      departure: '',
      arrival: '',
      totalMurni: '',
      guest: ''
    };
  }

  componentWillMount() {}

  componentDidMount = async () => {
    const paramsString = queryString.parse(this.props.history.location.search);
    this.dataVillaFilter(paramsString);
    // this.props.onResize;
    this.setDefaultDate();
    // let FORM_FILTER = localStorage.getItem('FORM_FILTER');
    // if (FORM_FILTER) {
    //   FORM_FILTER = JSON.parse(FORM_FILTER);
    //   // let startDate = String(FORM_FILTER['arrival']).split('-');
    //   // startDate = startDate[2] + '-' + startDate[1] + '-' + startDate[0];
    //   // let endDate = String(FORM_FILTER['departure']).split('-');
    //   // endDate = endDate[2] + '-' + endDate[1] + '-' + endDate[0];
    //   this.setState({
    //     startDate: FORM_FILTER['arrival'],
    //     endDate: FORM_FILTER['departure'],
    //     guest: FORM_FILTER['guest']
    //   });
    // }
    let CHECK_IN = localStorage.getItem('CHECK_IN') || Helper.getToday();
    const duration2 = moment(localStorage.getItem('CHECK_IN')).diff(
      moment.now(),
      'd'
    );
    if (duration2 < 0) {
      CHECK_IN = Helper.getToday();
    }
    let CHECK_OUT = localStorage.getItem('CHECK_OUT') || Helper.getTomorrow();
    const duration3 = moment(CHECK_OUT).diff(moment(CHECK_IN), 'd');
    if (duration3 < 0) {
      CHECK_OUT = Helper.getTomorrow();
    }
    this.setState({
      startDate: CHECK_IN,
      endDate: CHECK_OUT,
      guest: 1
    });
  };

  setDefaultDate() {
    this.setState(state => {
      const newState = {};
      if (state.startDate === '' || state.startDate === null) {
        newState['startDate'] = Helper.getToday();
      }
      if (state.endDate === '' || state.endDate === null) {
        newState['endDate'] = Helper.getTomorrow();
      }
      return newState;
    });
  }

  addOnEventListener() {
    document.addEventListener('click', this.handleEventCLoseGuest());
  }

  removeOnEventListener() {
    document.removeEventListener('click', this.handleEventCLoseGuest());
  }

  handleEventCLoseGuest() {
    this.setState({ bookGuest: false });
  }

  bookModal = (e, action) => {
    this.props.toggleModal('modalBoxBooking');
  };

  bookModalClosed = (e, action) => {
    if (action === 'show') {
      this.setState({
        bookModal: true
      });
    } else {
      this.setState({
        bookModal: false
      });
    }
  };

  dataVillaFilter = paramsString => {
    // const arrivalParams = paramsString.arrival;
    // const arrivalDate = arrivalParams.split('-');
    // const departureParams = paramsString.departure;
    // const departureDate = departureParams.split('-');
    // const oneDay = 24 * 60 * 60 * 1000;
    // const arrival = new Date(
    //   arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1]
    // );
    // const tanggalSkrng = new Date();
    // const departure = new Date(
    //   departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1]
    // );
    // const durationInap = Math.round(
    //   Math.round((departure.getTime() - arrival.getTime()) / oneDay)
    // );
    // const tanggal = Math.round(
    //   Math.round((arrival.getTime() - tanggalSkrng.getTime()) / oneDay)
    // );
    // this.setState({
    //   params: paramsString,
    //   duration: durationInap,
    //   dateTanggal: tanggal
    // });
    // ApiCall.getDateAvailabelVilla(paramsString.villas)
    //   .then(response => {
    //     this.setState({ dataAvailable: response.data.data });
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       toast.error(error.response.data.message);
    //     } else {
    //       toast.error('Koneksi internet bermasalah');
    //     }
    //   });
    // ApiCall.getRooms(paramsString)
    //   .then(response => {
    //     let checkGuest = '';
    //     if (paramsString.guest === '' || paramsString.guest === '0') {
    //       checkGuest = 1;
    //     } else if (
    //       Math.floor(paramsString.guest) >
    //       Math.floor(response.data.hotel.capacity)
    //     ) {
    //       checkGuest = Math.floor(response.data.hotel.capacity);
    //     } else {
    //       checkGuest = paramsString.guest;
    //     }
    //     this.setState({
    //       hotels: response.data.hotel,
    //       hotelPrice: response.data.hotel.Price,
    //       departure: paramsString.departure,
    //       arrival: paramsString.arrival,
    //       totalMurni: response.data.hotel.totalMurni,
    //       guest: checkGuest,
    //       jumlahGuest: checkGuest,
    //       masuk: arrivalDate[0] + '-' + arrivalDate[1] + '-' + arrivalDate[2],
    //       keluar:
    //         departureDate[0] + '-' + departureDate[1] + '-' + departureDate[2],
    //       startDate: moment(
    //         arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1]
    //       ),
    //       endDate: moment(
    //         departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1]
    //       ),
    //       tglMasuk: moment(
    //         arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1]
    //       ),
    //       tglKeluar: moment(
    //         departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1]
    //       )
    //     });
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       toast.error(error.response.data.message);
    //     } else {
    //       toast.error('Koneksi internet bermasalah');
    //     }
    //     this.setState({ loading: false });
    //   });
  };

  handleGetGuest = e => {
    this.setState({ guest: e.target.value });
  };

  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle('show');
    popup.classList.toggle('lihat');
  };

  reservation = (e, id, minStay) => {
    if (
      this.state.startDate === '' ||
      this.state.startDate === null ||
      this.state.endDate === '' ||
      this.state.endDate === null ||
      this.state.jumlahGuest <= 0
    ) {
      toast.error('Complete the form first');
      return;
    }

    if (validateMinHBooking(this.state.startDate, this.props.property.min_h_booking, this.props.property.server_time)) {
      return
    }

    const { t } = this.props;
    // const { params } = this.state;
    const duration = moment(this.state.endDate).diff(this.state.startDate, 'd');
    console.log(this.state.endDate);
    if (minStay > duration) {
      toast.info(t.minstayf + ' ' + minStay + ' ' + t.minstayb);
    } else {
      this.props.history.push(
        `/reservation-form?guest=${this.state.jumlahGuest}&arrival=${this.state.startDate}&departure=${this.state.endDate}&duration=${duration}&villas=${this.props.propertyId}`
      );
    }
    this.setState({
      loading: false
    });
  };

  isOutRange = (day, dataAvailable) => {
    const days = moment(day).format('MM/DD/YYYY');
    for (let i = 0; i < dataAvailable.length; i++) {
      const startRange = moment(dataAvailable[i].date).format('MM/DD/YYYY');
      if (days === startRange) {
        return true;
      }
    }
    return isBeforeDay(day, moment());
  };

  changeDates = (startDate, endDate, dataAvailable) => {
    const arrDate = [];
    for (let i = 0; i < dataAvailable.length; i++) {
      const startRange = moment(dataAvailable[i].date).format('MM/DD/YYYY');
      arrDate.push(startRange);
    }
    if (startDate !== null && endDate !== null) {
      let count = 0;
      const a = moment(startDate);
      const b = moment(endDate);
      const remain = b.diff(a, 'days');
      for (let i = 1; i <= remain; i++) {
        const newDate = moment(startDate)
          .add(i, 'days')
          .format('MM/DD/YYYY');
        const found = arrDate.find(element => {
          return element === newDate;
        });
        if (found !== undefined) {
          count++;
        }
      }
      if (count > 0) {
        this.setState({
          startDate,
          endDate: '',
          focusedInput: 'endDate'
        });
      } else {
        this.setState({ startDate, endDate });
      }
    } else {
      this.setState({ startDate, endDate });
    }
  };
  apply = (e, _class) => {
    // const oneDay = 24 * 60 * 60 * 1000;
    // const arrivalDate = new Date(this.state.startDate);
    // const departureDate = new Date(this.state.endDate);
    // const durationInap = Math.round(
    //   Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
    // );
    // const tanggalIn = moment(arrivalDate).format('MM-DD-YYYY');
    // const tanggalOut = moment(departureDate).format('MM-DD-YYYY');

    this.setState({
      applyGuest: true,
      bookGuest: false,
      guest: this.state.jumlahGuest
    });
    // const { villas } = this.state.params;
    // if (
    //   this.state.startDate !== '' &&
    //   this.state.endDate !== '' &&
    //   e.target.value !== ''
    // ) {
    // const data = {
    //   villasId: villas,
    //   guest: this.state.jumlahGuest,
    //   arrival: tanggalIn,
    //   departure: tanggalOut,
    //   duration: durationInap
    // };
    // ApiCall.checkMinNight(data)
    //   .then(res => {
    //     const minNight = res.data.hotel.minNight;
    //     if (minNight > durationInap) {
    //       this.props.setMessage(
    //         this.props.t.validMinNight1 +
    //           minNight +
    //           this.props.t.validMinNight2
    //       );
    //       this.props.toggleModal('popupmessage');
    //       this.setState({
    //         startDate: this.state.tglMasuk,
    //         endDate: this.state.tglKeluar
    //       });
    //     } else {
    //       this.props.history.replace(
    //         `/detail-villas?guest=${this.state.jumlahGuest}&arrival=${tanggalIn}&departure=${tanggalOut}&duration=${durationInap}&villas=${villas}`
    //       );
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    // }
  };

  handleGetGuest = e => {
    e.preventDefault();
    const oneDay = 24 * 60 * 60 * 1000;
    const arrivalDate = new Date(this.state.startDate);
    const departureDate = new Date(this.state.endDate);
    const durationInap = Math.round(
      Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
    );
    const tanggalIn = moment(arrivalDate).format('MM-DD-YYYY');
    const tanggalOut = moment(departureDate).format('MM-DD-YYYY');
    const guest = this.state.jumlahGuest;
    if (
      this.state.startDate !== '' &&
      this.state.endDate !== '' &&
      e.target.value !== ''
    ) {
      this.props.history.replace(
        `/detail-villas?guest=${guest}&arrival=${tanggalIn}&departure=${tanggalOut}&duration=${durationInap}&villas=${this.props.propertyId}`
      );
    }
    e.preventDefault();
    this.setState({ guest: this.state.jumlahGuest });
  };

  klikMin = e => {
    if (Math.floor(this.state.jumlahGuest) === 1) {
      console.log('');
    } else {
      let kurang = 0;
      kurang = Math.floor(this.state.jumlahGuest) - 1;
      this.setState({
        jumlahGuest: kurang
      });
    }
  };

  klikPlus = () => {
    if (
      Math.floor(this.state.jumlahGuest) ===
      Math.floor(this.state.property.capacity)
    ) {
      console.log('');
    } else {
      let tambah = 0;
      tambah = Math.floor(this.state.jumlahGuest) + 1;
      this.setState({
        jumlahGuest: tambah
      });
    }
  };

  eventOnCloseGuest = () => {
    this.setState({
      bookGuest: false
    });
  };
  eventClickLabelGuest = (e, _class) => {
    this.setState({
      applyGuest: false,
      bookGuest: !this.state.bookGuest,
      guest: this.state.jumlahGuest
    });
  };

  eventOnCloseCalender = (e, dataAvailable) => {
    const arrDate = [];
    for (let i = 0; i < dataAvailable.length; i++) {
      const startRange = moment(dataAvailable[i].date).format('MM/DD/YYYY');
      arrDate.push(startRange);
    }
    const { villas } = this.state.params;
    const oneDay = 24 * 60 * 60 * 1000;
    const arrivalDate = new Date(e.startDate);
    const departureDate = new Date(e.endDate);
    const durationInap = Math.round(
      Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
    );
    const tanggalIn = moment(arrivalDate).format('MM-DD-YYYY');
    const tanggalOut = moment(departureDate).format('MM-DD-YYYY');
    if (e.startDate !== '' && e.endDate !== '') {
      let count = 0;
      const a = moment(e.startDate);
      const b = moment(e.endDate);
      const remain = b.diff(a, 'days');
      for (let i = 1; i <= remain; i++) {
        const newDate = moment(e.startDate)
          .add(i, 'days')
          .format('MM/DD/YYYY');
        const found = arrDate.find(element => {
          return element === newDate;
        });
        if (found !== undefined) {
          count++;
        }
      }
      if (count > 0) {
        this.setState({
          startDate: e.startDate,
          endDate: '',
          focusedInput: 'endDate'
        });
      } else {
        const data = {
          villasId: villas,
          guest: this.state.jumlahGuest,
          arrival: tanggalIn,
          departure: tanggalOut,
          duration: durationInap
        };
        ApiCall.checkMinNight(data)
          .then(res => {
            const minNight = res.data.hotel.minNight;
            if (minNight > durationInap) {
              this.props.setMessage(
                this.props.t.validMinNight1 +
                  minNight +
                  this.props.t.validMinNight2
              );
              this.props.toggleModal('popupmessage');
              this.setState({
                startDate: this.state.tglMasuk,
                endDate: this.state.tglKeluar
              });
            } else {
              const parms = {
                villas: this.state.params.villas,
                guest: this.state.jumlahGuest,
                arrival: tanggalIn,
                departure: tanggalOut,
                duration: durationInap
              };
              this.dataVillaFilter(parms);
              const guest = this.state.guest;
              this.props.history.replace(
                `/detail-villas?guest=${guest}&arrival=${tanggalIn}&departure=${tanggalOut}&duration=${durationInap}&villas=${villas}`
              );
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };

  closeModalBookBooking = () => {
    this.props.toggleModal('modalBoxBooking');
    this.setState({ bookGuest: false });
  };

  closedGuest = () => {
    if (this.state.bookGuest === true) {
      this.setState({ bookGuest: !this.state.bookGuest });
    }
  };

  changeStyleDate = (e, dataAvailable) => {
    const _date = moment(e).format('D');
    const days = moment(e).format('MM/DD/YYYY');
    for (let i = 0; i < dataAvailable.length; i++) {
      const startRange = moment(dataAvailable[i].date).format('MM/DD/YYYY');
      if (days === startRange) {
        return <span className="blockTanggal">{_date}</span>;
      }
    }
    return <span>{_date}</span>;
  };

  render() {
    const { t, property } = this.props;
    const {
      hotels
      // duration,
      // dataAvailable
    } = this.state;

    const startDateInput = (
      <FormInput
        defaultValue={this.state.startDate}
        type="date"
        onChange={e => {
          const startDate = e.target.value;
          const today = moment(Helper.getToday());
          if (moment(startDate).diff(today, 'd') < 0) {
            toast.error('error, check in must equal or greater than today');
            e.target.value = '';
            this.setState({ startDate: '' });
          } else {
            this.setState({ startDate });
          }
        }}
        placeholder="Start Date"
      />
    );

    const endDateInput = (
      <FormInput
        defaultValue={this.state.endDate}
        type="date"
        onChange={e => {
          // this.setState({ endDate: e.target.value });
          const startDate = this.state.startDate;
          if (startDate === '' || startDate === null) {
            toast.error('start date required');
            e.target.value = '';
            this.setState({ endDate: '' });
            return;
          }
          // alert(this.state.startDate);
          const endDate = e.target.value;
          // return;
          if (moment(endDate).diff(this.state.startDate, 'd') <= 0) {
            toast.error('error, check out must greater than check in');
            e.target.value = '';
            this.setState({ endDate: '' });
          } else {
            this.setState({ endDate });
          }
        }}
        placeholder="End Date"
      />
    );

    const paymentOptionLabel = property.payAtVilla === 1 && (
      <LabelPrimary rounded fontSize={12} text={t.payAtVilla} />
    );

    return (
      <div>
        <div className="d-none d-md-block">
          <div className="row">
            <div
              className="col-md-9"
              style={{ backgroundColor: '#F8F8F8', borderRadius: 10 }}
            >
              <div className="p-4">
                <div style={{ fontSize: 10, color: '#656565' }}>
                  {this.props.app.lang === 'id' ? 'Harga mulai' : 'Start from'}
                </div>
                <div
                  style={{
                    fontSize: 24,
                    color: '#333333',
                    fontWeight: 'bold'
                  }}
                >
                  {moneyFormat(
                    property.startFrom || property.low_price,
                    this.props.app.lang,
                    true
                  )}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    color: '#656565',
                    marginBottom: 15
                  }}
                >
                  {this.props.app.lang === 'id'
                    ? 'per kamar / malam'
                    : 'per room / night'}
                </div>
                <div>
                  <button
                    onClick={e => {
                      if (!localStorage.getItem('CHECK_IN')) {
                        toast.error('Check In required');
                        return;
                      }
                      if (!localStorage.getItem('CHECK_OUT')) {
                        toast.error('Check Out required');
                        return;
                      }

                      if (validateMinHBooking(localStorage.getItem('CHECK_IN'), this.props.property.min_h_booking, this.props.property.server_time)) {
                        return;
                      }

                      if (!localStorage.getItem('GUEST_COUNT')) {
                        // toast.error('Guest required');
                        localStorage.setItem('GUEST_COUNT', 1);
                        // return;
                      }
                      const duration2 = moment(
                        localStorage.getItem('CHECK_IN')
                      ).diff(moment.now(), 'd');
                      if (duration2 < 0) {
                        toast.error('Check In is invalid');
                        return;
                      }
                      const duration = moment(
                        localStorage.getItem('CHECK_OUT')
                      ).diff(localStorage.getItem('CHECK_IN'), 'd');
                      if (duration <= 0) {
                        toast.error('Check Out is invalid');
                        return;
                      }
                      this.props.history.push(
                        `/reservation-form?guest=${localStorage.getItem(
                          'GUEST_COUNT'
                        ) || 1}&arrival=${localStorage.getItem(
                          'CHECK_IN'
                        )}&departure=${localStorage.getItem(
                          'CHECK_OUT'
                        )}&duration=${duration}&villas=${this.props.propertyId}`
                      );
                      this.setState({
                        loading: false
                      });
                    }}
                    style={{
                      backgroundColor: '#F3722B',
                      paddingRight: 30,
                      paddingLeft: 30,
                      paddingTop: 15,
                      paddingBottom: 15,
                      color: 'white',
                      border: 'unset',
                      fontSize: 14,
                      borderRadius: 10,
                      cursor: 'pointer'
                    }}
                  >
                    {this.props.app.lang === 'id'
                      ? 'Pesan Sekarang'
                      : 'Booking Now'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: '100%' }} className="d-block d-md-none">
          <div
            className={this.props.app.lebelPromo ? 'boxStickynot' : 'boxSticky'}
            id="BoxDetailBooking"
          >
            <div className="columns is-mobile is-multiline boxHeaderModalA">
              <div className="column is-12 labelPayAtModalA1">
                {paymentOptionLabel}
              </div>
              <div className="column is-12 labelNameModalA1">
                <Star star={this.props.star} />
                <b>
                  <p>{property.name}</p>
                </b>
                <label className="labelTypeVilla180913">
                  {property.category_name}
                </label>
              </div>
            </div>
            <div className="columns is-multiline batasGarisModalAtasA1">
              <div className="column" />
            </div>
            <div className="columns is-mobile is-multiline boxDateA1">
              <div className="column">
                <div className="field">
                  <label className="labels">{t.dates}</label>
                  <div className="control hotelListBooking">
                    {startDateInput}
                    {endDateInput}
                  </div>
                </div>
              </div>
            </div>
            {this.state.bookGuest === true ? (
              <div
                className="bookGuestA1"
                onClick={() => this.eventOnCloseGuest()}
              />
            ) : (
              ''
            )}
            <div className="columns is-mobile is-multiline boxGuestA1">
              <div className="column">
                <div className="field">
                  <label className="labels">{t.guest}</label>
                  <div className="control">
                    <div
                      className="labelGuestDetailA2"
                      onChange={this.handleGetGuest}
                      onClick={e => this.eventClickLabelGuest(e)}
                    >
                      <p>
                        {this.state.guest} {t.guest}
                      </p>
                    </div>
                    {this.state.bookGuest === true ? (
                      <div className="columns boxGuestDetail is-mobile is-multiline">
                        <div className="column is-5 guestImage field is-grouped is-mobile is-multiline">
                          <img
                            className="control image is-24x24"
                            src="/icon/guest.png"
                            alt="Nusalink"
                          />
                          <p className=" control"> Guests </p>
                        </div>
                        <div className="column is-6 is-mobile is-multiline tamu">
                          <div className="field has-addons has-addons-centered border-guest">
                            {Math.floor(this.state.jumlahGuest) === 1 ? (
                              <p
                                className="control button bg-guest-min-hilang"
                                onClick={e => this.klikMin(e)}
                              >
                                -
                              </p>
                            ) : (
                              <p
                                className="control button bg-guest-min"
                                onClick={e => this.klikMin(e)}
                              >
                                -
                              </p>
                            )}
                            <div className="control jmlGuest">
                              <input
                                className="input has-text-centered txtGuest"
                                onChange={this.handleGetGuest}
                                value={this.state.jumlahGuest}
                                readOnly
                              />
                            </div>
                            {Math.floor(this.state.jumlahGuest) ===
                            Math.floor(property.capacity) ? (
                              <p
                                className="control button bg-guest-plus-hilang"
                                onClick={e => this.klikPlus(e)}
                              >
                                +
                              </p>
                            ) : (
                              <p
                                className="control button bg-guest-plus"
                                onClick={e => this.klikPlus(e)}
                              >
                                +
                              </p>
                            )}
                          </div>
                          <div className="columns">
                            <div className="column is-4 is-offset-7">
                              <br />
                              <br />
                              <p
                                className="bg-done is-pulled-right"
                                onClick={e => this.apply(e)}
                              >
                                {' '}
                                Done{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="columns btnReservation">
              <div className="column is-12 has-text-centered">
                <button
                  type="button"
                  onClick={e => this.reservation(e)}
                  className="button tombol-popup bg-primary btnBooking"
                >
                  {t.book}
                </button>
              </div>
            </div>
          </div>
          <div className="boxStickyMobile">
            <div className="column-buttom">
              <button
                type="button"
                onClick={e => this.props.toggleModal('modalBoxBooking')}
                className="button btn-Booking bg-primary"
              >
                {t.book}
              </button>
            </div>
          </div>
          <div
            className={
              'modal tes-modal ' +
              (this.props.app.modalBoxBooking ? 'is-active' : '')
            }
          >
            <div
              className="modal-background tes-modal"
              style={{ background: 'white', paddingTop: 90 }}
              onClick={e => this.closeModalBookBooking()}
            />
            <div
              className="btnClose"
              onClick={e => this.closeModalBookBooking()}
            />
            <div
              className="modal-content bokModalBooking bokModalBookingA"
              style={{
                width: 400,
                background: 'white',
                borderRadius: 10
              }}
            >
              {this.state.bookGuest === true ? (
                <div
                  className="bookGuestA1"
                  onClick={() => this.eventOnCloseGuest()}
                />
              ) : (
                ''
              )}
              <div className="columns is-mobile is-multiline boxHeaderModal">
                <div className="column is-12 labelNameModalA2">
                  {paymentOptionLabel}
                  <b>
                    <p>{property.name}</p>
                  </b>
                  <label className="labelTypeVilla180913">
                    {property.category_name}
                  </label>
                </div>
              </div>
              <div className="columns is-multiline batasGarisModalAtas">
                <div className="column" />
              </div>
              <div className="columns is-mobile is-multiline boxModalDateA2">
                <div className="column">
                  <div className="field">
                    <label className="labels">{t.dates}</label>
                    <div className="control hotelListBooking">
                      {startDateInput}
                      {endDateInput}
                      {/* <DateRangePicker
                      isOutsideRange={day =>
                        this.isOutRange(day, dataAvailable)
                      }
                      renderDayContents={day =>
                        this.changeStyleDate(day, dataAvailable)
                      }
                      numberOfMonths={1}
                      startDate={this.state.startDate}
                      startDateId="startDate2"
                      endDate={this.state.endDate}
                      endDateId="endDate2"
                      onDatesChange={({ startDate, endDate }) =>
                        this.changeDates(startDate, endDate, dataAvailable)
                      }
                      focusedInput={this.state.focusedInput2}
                      onFocusChange={focusedInput2 =>
                        this.setState({
                          focusedInput2,
                          bookGuest: false
                        })
                      }
                      displayFormat="D MMM"
                      startDatePlaceholderText="Check-in"
                      endDatePlaceholderText="Check-out"
                      onClose={e => this.eventOnCloseCalender(e, dataAvailable)}
                      readOnly
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-mobile is-multiline boxModalGuestA2">
                <div className="column">
                  <div className="field">
                    <label className="labels">{t.guest}</label>
                    <div className="control">
                      <div
                        className="labelGuestDetailA2"
                        onChange={this.handleGetGuest}
                        onClick={e => this.eventClickLabelGuest(e)}
                      >
                        <p>
                          {this.state.guest} {t.guest}
                        </p>
                      </div>
                      {this.state.bookGuest === true ? (
                        <div className="columns boxGuestDetail is-mobile is-multiline">
                          <div className="column is-5 guestImage field is-grouped is-mobile is-multiline">
                            <img
                              className="control image is-24x24"
                              src="/icon/guest.png"
                              alt="Nusalink"
                            />
                            <p className=" control"> Guests </p>
                          </div>
                          <div className="column is-6 is-mobile is-multiline tamu">
                            <div className="field has-addons has-addons-centered border-guest">
                              {Math.floor(this.state.jumlahGuest) === 1 ? (
                                <p
                                  className="control button bg-guest-min-hilang"
                                  onClick={e => this.klikMin(e)}
                                >
                                  -
                                </p>
                              ) : (
                                <p
                                  className="control button bg-guest-min"
                                  onClick={e => this.klikMin(e)}
                                >
                                  -
                                </p>
                              )}
                              <div className="control jmlGuest">
                                <input
                                  className="input has-text-centered txtGuest"
                                  onChange={this.handleGetGuest}
                                  value={this.state.jumlahGuest}
                                  readOnly
                                />
                              </div>
                              {Math.floor(this.state.jumlahGuest) ===
                              Math.floor(property.capacity) ? (
                                <p
                                  className="control button bg-guest-plus-hilang"
                                  onClick={e => this.klikPlus(e)}
                                >
                                  +
                                </p>
                              ) : (
                                <p
                                  className="control button bg-guest-plus"
                                  onClick={e => this.klikPlus(e)}
                                >
                                  +
                                </p>
                              )}
                            </div>
                            <div className="columns">
                              <div className="column is-4 is-offset-7">
                                <br />
                                <br />
                                <p
                                  className="bg-done is-pulled-right"
                                  onClick={e => this.apply(e)}
                                >
                                  {' '}
                                  Done{' '}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6 is-offset-3 has-text-centered">
                  <button
                    type="button"
                    onClick={e => this.reservation(e)}
                    className="button tombol-popup bg-primary btnBooking"
                  >
                    {this.props.app.lang === 'id'
                      ? 'Pesan sekarang'
                      : 'Booking now'}
                  </button>
                </div>
              </div>
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={e => this.closeModalBookBooking()}
            />
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = state => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, { toggleModal, setMessage });
const translator = translatable(translateToProps);
export default translator(connector(BoxBooking));
