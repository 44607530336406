import React, { Component } from 'react';

export class Card extends Component {
  render() {
    return (
      <div className="box-reservation">
        <div
          className={
            this.props.isDesktop
              ? 'columns is-desktop is-multiline'
              : 'columns is-mobile is-multiline'
          }
        >
          <div className="column is-12">
            <h5 className="title is-5">{this.props.title}</h5>
            <hr style={{ marginBottom: -4 }} />
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Card;
