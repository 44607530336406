import React, { Component } from 'react';
import Slider from 'react-slick';
import 'flatpickr/dist/themes/confetti.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import Pager from 'react-pager';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  toggleModal,
  showModal,
  setMessage,
  setPages,
  setMessageMId,
} from '../../redux/actions';
import Loader from '../../components/Loader/Loader';
import ApiCall from '../../services/ApiCall';
import { translatable } from 'react-multilingual';
import { BASE_URL_ADM } from '../../constants';
import queryString from '../../utils/queryString';

class HotelAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'en',
      city: '',
      dataCity: [],
      guest: '',
      arrival: '',
      departure: '',
      duration: '',
      loading: false,
      data: [],
      masuk: '',
      keluar: '',
      currentpage: '',
      total: 0,
      current: props.app.pages,
      visiblePage: 5,
      focusedInput: null,
      startDate: '',
      endDate: '',
      selisih: '',
      jumlahGuest: 1,
      applyGuest: false,
      bookGuest: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.callCalender('none');
    const paramsString = queryString.parse(this.props.location.search);
    const arrivalParams =
      paramsString.arrival === undefined ? '-' : paramsString.arrival;
    const arrivalDate = arrivalParams.split('-');
    const departureParams =
      paramsString.departure === undefined ? '-' : paramsString.departure;
    const departureDate = departureParams.split('-');
    const oneDay = 24 * 60 * 60 * 1000;
    const datenow = new Date();
    const arrival = new Date(
      arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1]
    );
    const departure = new Date(
      departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1]
    );
    const durationInap = Math.round(
      Math.round((departure.getTime() - arrival.getTime()) / oneDay)
    );
    const nowtoarrive = Math.round(
      Math.round((arrival.getTime() - datenow.getTime()) / oneDay)
    );
    let checkGuest = '';
    if (
      paramsString.guest === '' ||
      paramsString.guest === '0' ||
      paramsString.guest === undefined
    ) {
      checkGuest = '';
    } else {
      checkGuest = paramsString.guest;
    }
    this.setState({
      params: paramsString,
      duration: durationInap,
      city: paramsString.city,
      guest: checkGuest,
      selisih: nowtoarrive,
      arrival: paramsString.arrival,
      departure: paramsString.departure,
      masuk: arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1],
      keluar:
        departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1],
      startDate: '',
      endDate: '',
    });
    setTimeout(() => {
      const page = this.props.app.pages + 1;
      let queryString = window.location.search;
      if (queryString) {
        queryString = queryString.replace(/\?/g, '');
      }
      console.log(queryString);
      ApiCall.getHotelAll(page)
        .then((response) => {
          this.setState({
            data: response.data.hotels,
            total: response.data.totalPage,
            loading: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Koneksi internet bermasalah');
          }
          this.setState({ loading: false });
        });
    }, 1000);
  }

  componentDidMount() {
    setTimeout(() => {
      this.callCalender('none');
      const paramsString = queryString.parse(this.props.location.search);
      if (
        paramsString.order_id !== undefined &&
        paramsString.status_code !== undefined &&
        paramsString.transaction_status !== undefined &&
        paramsString.transaction_status !== 'deny' &&
        paramsString.status_code !== '202'
      ) {
        this.props.setMessageMId(
          'messagemid1',
          this.props.t.messgePopMidtransLogin1 +
            ' ' +
            paramsString.order_id +
            ' ' +
            this.props.t.messgePopMidtransLogin2
        );
        this.props.setMessageMId(
          'messagemid2',
          this.props.t.messgePopMidtransLogin3
        );
        this.props.toggleModal('popupmidtrans');
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
    }, 1000);
    window.scrollTo(0, 0);
    this.props.setPages(0);
  }

  resize() {
    if (window.innerWidth <= 640) {
      this.setState({ mobileDate: true });
    } else {
      this.setState({ mobileDate: false });
    }
  }

  changeLang = (lang) => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  handleGetCity = (e) => {
    e.preventDefault();
    const target = e.target;
    this.setState({ city: target.value });
  };

  handleGetGuest = (e) => {
    e.preventDefault();
    const target = e.target;
    this.setState({ guest: target.value });
  };
  tanggalKlik = (e) => {
    e.preventDefault();
    this.setState({
      click: !this.state.click,
    });
  };

  eventOnCloseCalender = () => {
    const startDate = document.getElementById('startDate1').value;
    const endDate = document.getElementById('endDate1').value;
    if (startDate === '' && endDate === '') {
      this.callCalender('none');
      this.callLabelCalender('block');
    } else {
      this.callCalender('block');
      this.callLabelCalender('none');
    }
  };

  eventClickLabelCalender = () => {
    this.callCalender('block');
    this.callLabelCalender('none');
    document.getElementById('startDate1').focus();
  };
  callCalender(value) {
    const x = document.getElementsByClassName('DateRangePicker')[0];
    if (x) {
      x.style.display = value;
    }
  }

  callLabelCalender(value) {
    const x = document.getElementsByClassName('labelDates')[0];
    if (x) {
      x.style.display = value;
    }
  }
  klikPlus = () => {
    if (this.state.jumlahGuest >= 20) {
      console.log('');
    } else {
      const tambah = this.state.jumlahGuest + 1;
      this.setState({
        jumlahGuest: tambah,
      });
    }
  };
  klikMin = () => {
    const x = document.getElementsByClassName('bg-guest-min')[0];
    if (this.state.jumlahGuest <= 1) {
      x.style.color = '#fbd1a7';
    } else {
      const kurang = this.state.jumlahGuest - 1;
      this.setState({
        jumlahGuest: kurang,
      });
    }
  };
  apply = () => {
    this.setState({
      applyGuest: true,
      bookGuest: false,
      guest: this.state.jumlahGuest,
    });
  };
  eventOnCloseGuest = () => {
    this.setState({
      bookGuest: false,
    });
  };
  eventClickLabelGuest = () => {
    this.setState({
      applyGuest: false,
      bookGuest: !this.state.bookGuest,
    });
  };
  handleDateChange = (e) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const arrival = new Date(e[0]);
    const departure = new Date(e[1]);
    const durationInap = Math.round(
      Math.round((departure.getTime() - arrival.getTime()) / oneDay)
    );
    if (e.length > 1) {
      if (durationInap > 90) {
        const { t } = this.props;
        toast.info(t.maxDuration);
        this.setState({
          arrival: '',
          departure: '',
          duration: '',
        });
      } else if (durationInap < 1) {
        const { t } = this.props;
        toast.info(t.minDuration);
        this.setState({
          arrival: '',
          departure: '',
          duration: '',
        });
      } else {
        this.setState({
          arrival: moment(arrival).format('MM-DD-YYYY'),
          departure: moment(departure).format('MM-DD-YYYY'),
          duration: durationInap,
        });
      }
    } else {
      this.setState({
        arrival: '',
        departure: '',
        duration: '',
      });
    }
  };
  filter = () => {
    const { guest } = this.state;
    const { t } = this.props;
    if (this.state.startDate === '' || this.state.endDate === '') {
      this.props.setMessage(t.alertDate);
      this.props.toggleModal('popupmessage');
    } else if (guest === '') {
      this.props.setMessage(t.alertGuest);
      this.props.toggleModal('popupmessage');
    } else {
      const oneDay = 24 * 60 * 60 * 1000;
      const arrivalDate = new Date(this.state.startDate);
      const departureDate = new Date(this.state.endDate);
      const durationInap = Math.round(
        Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
      );
      const arrival = this.state.startDate.format('MM-DD-YYYY');
      const departure = this.state.endDate.format('MM-DD-YYYY');
      this.props.setPages(0);
      this.props.history.push(
        `/villas?guest=${guest}&arrival=${arrival}&departure=${departure}&duration=${durationInap}`
      );
      window.location.reload();
    }
  };
  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle('show');
  };
  gotodetail = (e, hotelid) => {
    this.props.history.push(`/detail-villa?villas=${hotelid}`);
  };
  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };
  handlePageChanged = (newPage) => {
    window.scrollTo(0, 0);
    this.setState({ loading: true, data: [] });
    this.props.setPages(newPage);
    const pages = newPage + 1;

    this.setState({
      masuk: '',
      keluar: '',
    });
    ApiCall.getHotelAll(pages)
      .then((response) => {
        window.scrollTo(0, 0);
        this.setState({
          data: response.data.hotels,
          total: response.data.totalPage,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Koneksi internet bermasalah');
        }
        this.setState({ loading: false });
      });
  };
  render() {
    const { t } = this.props;
    const { loading, data, bookGuest } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      initialSlide: 0,
    };
    return (
      <section
        className="section2 mobile"
        style={{ backgroundColor: '#f8f8f8' }}
        onClick={this.closeHeader}
      >
        <section className="section2 mobile2">
          <Loader loading={loading} />
          <section
            className="section2 inner inputatas"
            style={{ backgroundColor: '#f8f8f8' }}
          >
            <div className="container inner">
              <div
                className="columns backAtas"
                onClick={(e) => this.eventOnCloseGuest(e)}
              >
                <div className="column is-12" />
              </div>
              <div
                className="columns bg-secondary long"
                style={{ borderRadius: 5, marginTop: -50 }}
              >
                <div
                  className="column is-narrow is-2 filter-ls"
                  style={{ paddingRight: 5 }}
                >
                  <div className="field">
                    <div
                      className="control hotelList"
                      onClick={(e) => this.eventOnCloseGuest(e)}
                    >
                      <div
                        className="labelDates"
                        onClick={(e) => this.eventClickLabelCalender(e)}
                      >
                        {t.dates}
                      </div>
                      <DateRangePicker
                        className="hilang"
                        numberOfMonths={1}
                        startDate={this.state.startDate}
                        startDateId="startDate1"
                        endDate={this.state.endDate}
                        endDateId="endDate1"
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        }
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        }
                        displayFormat="D MMM"
                        startDatePlaceholderText="In"
                        endDatePlaceholderText="Out"
                        readOnly
                        onClose={(e) => this.eventOnCloseCalender(e)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="column is-narrow is-2 filter-ls"
                  style={{ paddingLeft: 5 }}
                >
                  <div className="field">
                    <div className="control">
                      <div
                        className="labelGuestList"
                        onChange={this.handleGetGuest}
                        onClick={(e) => this.eventClickLabelGuest(e)}
                      >
                        <p>
                          {this.state.guest} {t.guest}{' '}
                        </p>
                      </div>
                      <br />
                      {bookGuest === true ? (
                        <div className="columns guestHilangListAll is-mobile is-multiline">
                          <div className="column is-5 guestImage field is-grouped is-mobile is-multiline">
                            <img
                              className="control image is-24x24"
                              src="/icon/guest.png"
                              alt="Nusalink"
                            />
                            <p className=" control"> Guests </p>
                          </div>
                          <div className="column is-6 is-mobile is-multiline tamu">
                            <div className="field has-addons has-addons-centered border-guest">
                              {this.state.jumlahGuest === 1 ? (
                                <p
                                  className="control button bg-guest-min-hilang"
                                  onClick={(e) => this.klikMin(e)}
                                >
                                  -
                                </p>
                              ) : (
                                <p
                                  className="control button bg-guest-min"
                                  onClick={(e) => this.klikMin(e)}
                                >
                                  -
                                </p>
                              )}
                              <p className="control jmlGuest">
                                <input
                                  className="input has-text-centered txtGuest"
                                  readOnly
                                  onChange={this.handleGetGuest}
                                  type="text"
                                  value={this.state.jumlahGuest}
                                />
                              </p>
                              {this.state.jumlahGuest === 20 ? (
                                <p
                                  className="control button bg-guest-plus-hilang"
                                  onClick={(e) => this.klikPlus(e)}
                                >
                                  +
                                </p>
                              ) : (
                                <p
                                  className="control button bg-guest-plus"
                                  onClick={(e) => this.klikPlus(e)}
                                >
                                  +
                                </p>
                              )}
                            </div>
                            <div className="columns">
                              <div className="column is-4 is-offset-7">
                                <br />
                                <br />
                                <p
                                  className="bg-done is-pulled-right"
                                  onClick={(e) => this.apply(e)}
                                >
                                  {' '}
                                  Done{' '}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <button
                    className="button is-primary bg-primary btn-searchbox tengahexplore isrounded btn-filterls"
                    onClick={this.filter}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section2 inner box-list"
            onClick={(e) => this.eventOnCloseGuest(e)}
            style={{ backgroundColor: '#f8f8f8' }}
          >
            <div
              className="container"
              onClick={(e) => this.eventOnCloseGuest(e)}
            >
              <div
                className="columns is-multiline long"
                onClick={(e) => this.eventOnCloseGuest(e)}
              >
                {data &&
                  data !== '' &&
                  data.map((items, index) => (
                    <div
                      className="column is-3 boxListVillas"
                      key={index.toString()}
                    >
                      <div
                        className="box boxheight"
                        style={{
                          padding: 0,
                          borderRadius: 4,
                          paddingBottom: 10,
                        }}
                      >
                        <div className="columns" style={{ marginBottom: -15 }}>
                          <div
                            className="column is-12"
                            style={{ paddingTop: 0 }}
                          >
                            <Slider {...settings}>
                              {items.images.length > 0 &&
                                items.images.map((i) => (
                                  <div
                                    className="slide-item"
                                    style={{ marginTop: -15 }}
                                    key={i.toString()}
                                  >
                                    <figure image is-4by3>
                                      <img
                                        className="images-galery imgVillasList"
                                        src={`${BASE_URL_ADM}/images/hotel/${i}`}
                                        alt=""
                                        onClick={(e) =>
                                          this.gotodetail(e, items.id)
                                        }
                                      />
                                    </figure>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        </div>
                        <div
                          className="columns kolomkotanama"
                          onClick={(e) => this.gotodetail(e, items.id)}
                        >
                          <div className="column is-12">
                            <div
                              className="columns is-mobile"
                              style={{ marginBottom: -5 }}
                            >
                              <div className="column is-6">
                                <div className="kolomkota">
                                  <label
                                    onClick={(e) =>
                                      this.gotodetail(e, items.id)
                                    }
                                    className="labelCityVilla"
                                  >
                                    {items.VillaLocations.length > 0
                                      ? this.toTitleCase(
                                          items.VillaLocations[0].nama_kota
                                        )
                                      : ''}
                                    {items.categories[items.category]}
                                  </label>
                                </div>
                              </div>
                              <div className="column is-6">
                                <div className="kolomkota">
                                  {items.payAtVilla === 1 ? (
                                    <label
                                      style={{ color: 'white' }}
                                      className="has-text-centered is-pulled-right payvilla"
                                    >
                                      <p style={{ marginLeft: 18 }}>
                                        {this.props.t.payAtVilla}
                                      </p>
                                      <figure
                                        className="cottagepic"
                                        style={{
                                          marginLeft: 16,
                                          height: 12,
                                          width: 12,
                                        }}
                                      >
                                        <img
                                          src="/icon/cottage.png"
                                          alt="Nusalink"
                                        />
                                      </figure>
                                    </label>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <b
                              className="kolomnama"
                              style={{ color: 'black', fontSize: 18 }}
                            >
                              {items.name}
                            </b>
                          </div>
                        </div>
                        <div
                          className="columns"
                          style={{ marginLeft: 5, marginRight: 5 }}
                        >
                          <div className="column is-12 kolomharga">
                            <span style={{ fontSize: 14, color: 'black' }}>
                              <p>
                                <label
                                  onClick={(e) => this.gotodetail(e, items.id)}
                                >
                                  {t.currency}{' '}
                                  {Numeral(Math.floor(items.low_price)).format(
                                    '0,0'
                                  )}
                                  {t.pernight}
                                </label>
                                {items.round === 1 ? (
                                  <span
                                    className="icon has-text-info popup"
                                    style={{ marginLeft: 10, marginBottom: -5 }}
                                    onClick={(e) => this.showpopup(e, index)}
                                  >
                                    <i>
                                      <figure className="image is-24x24">
                                        <img
                                          src="/icon/info.png"
                                          alt="Nusalink"
                                        />
                                      </figure>
                                    </i>
                                    <span className="popuptext" id={index}>
                                      <p
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {t.average}
                                      </p>
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {!data && (
                  <div className="column is-12">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {data && data.length === 0 && this.state.loading === false && (
                  <div className="column is-12">
                    <div className="has-text-centered">
                      <img
                        src={`${BASE_URL_ADM}/images/Not_Found.png`}
                        alt=""
                        width="500"
                        height="500"
                      />
                    </div>
                    <br />
                    <center>
                      <h4>{t.hotelNotFound}</h4>
                    </center>
                  </div>
                )}
              </div>
            </div>
          </section>
          <div style={{ clear: 'both', height: '50px' }} />
          <section
            className="section2 inner pagee"
            style={{ backgroundColor: '#f8f8f8' }}
          >
            <div className="container-fluid">
              <div className="columns centerpagination long">
                {data && data.length > 0 && (
                  <center>
                    <Pager
                      total={this.state.total}
                      current={this.props.app.pages}
                      visiblePages={this.state.visiblePage}
                      titles={{ prev: '<', next: '>' }}
                      className="pagination-sm pull-left bulet"
                      onPageChanged={this.handlePageChanged}
                    />
                  </center>
                )}
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;

  return { app };
};

const connector = connect(mapStateToProps, {
  showModal,
  toggleModal,
  setMessage,
  setPages,
  setMessageMId,
});

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(HotelAll));
