import React from 'react';

export default function ReservationCheckIcon() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5833 4.03168H1.41667C1.18655 4.03168 1 4.22638 1 4.46656V11.4247C1 11.6648 1.18655 11.8595 1.41667 11.8595H10.5833C10.8135 11.8595 11 11.6648 11 11.4247V4.46656C11 4.22638 10.8135 4.03168 10.5833 4.03168Z"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.0317V1.83011C8 1.63548 7.91571 1.44882 7.76569 1.3112C7.61566 1.17357 7.41217 1.09625 7.2 1.09625H4.8C4.58783 1.09625 4.38434 1.17357 4.23431 1.3112C4.08429 1.44882 4 1.63548 4 1.83011V4.0317"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
