import React from 'react';

function NusaCafe3Icon({ width = 42, height = 42 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="8" fill="#FFBA33" />
      <path
        d="M17 12.2002V14.7335"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7998 12.2002V14.7335"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 12.2002V14.7335"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3999 31.2002H29.5999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7044 31.2003C15.1212 30.5049 13.7798 29.3893 12.8396 27.9859C11.8993 26.5826 11.3996 24.9505 11.3999 23.2835V18.5337H29.5999V23.2835C29.6002 24.9506 29.1005 26.5826 28.1602 27.986C27.2199 29.3893 25.8785 30.5049 24.2953 31.2004"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0012 18.5337C31.0087 18.5337 31.975 18.8896 32.6874 19.523C33.3999 20.1565 33.8001 21.0156 33.8001 21.9115V22.7559C33.8001 23.6517 33.3999 24.5109 32.6874 25.1443C31.975 25.7778 31.0087 26.1337 30.0012 26.1337H29.6001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NusaCafe3Icon;
