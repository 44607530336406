import React, { Component } from 'react';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
import { BASE_URL_ADM } from '../../constants';

class Fasilitas extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      klikFasil: false
    };
  }

  componentDidMount() {}

  klikFasil = (e, action) => {
    if (action === 'show') {
      this.setState({
        klikFasil: true
      });
    } else {
      this.setState({
        klikFasil: false
      });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-12 is-multiline">
            <div
              className="columns is-multiline is-mobile"
              style={{ marginTop: -15 }}
            >
              {this.props.basic.length > 0 &&
                this.props.basic.map((items, index) => (
                  <div
                    key={index}
                    className="column is-3	desktop"
                    style={{ marginLeft: 13 }}
                  >
                    <img
                      src={`${BASE_URL_ADM}/icon/amenities/${items.images}`}
                      width="20"
                      alt=""
                    />
                    &nbsp;&nbsp;&nbsp;
                    <span className="text-fasilitas">{items.name}</span>
                  </div>
                ))}
            </div>
            {this.props.others.length > 0 ? (
              <div className="columns desktop">
                <div className="column is-8 ">
                  <a
                    className="read-more"
                    onClick={e => this.klikFasil(e, 'show')}
                  >
                    {' '}
                    {t.showall} {' >> '}
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className="columns is-multiline is-mobile"
              style={{ marginTop: -22 }}
            >
              {this.props.basic.length > 0 &&
                this.props.basic.map((items, index) => (
                  <div className="column is-6 responsive" key={index}>
                    <img
                      src={`${BASE_URL_ADM}/icon/amenities/${items.images}`}
                      width="20"
                      alt=""
                    />
                    &nbsp;&nbsp;&nbsp;
                    <span className="text-fasilitas">{items.name}</span>
                  </div>
                ))}
            </div>
            {this.props.others.length > 0 ? (
              <div className="columns ">
                <div
                  className="column is-6 responsive"
                  style={{ marginTop: 20 }}
                >
                  <a
                    className="read-more"
                    onClick={e => this.klikFasil(e, 'show')}
                  >
                    {' '}
                    {t.showall} {'>> '}
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={'modal ' + (this.state.klikFasil ? 'is-active' : '')}>
          <div
            className="modal-background"
            style={{ background: 'white', paddingTop: 90 }}
            onClick={e => this.klikFasil(e, 'hidden')}
          />
          <div
            className="btnClose"
            onClick={e => this.klikFasil(e, 'hidden')}
          />
          <div
            className="modal-content"
            style={{
              width: 500,
              background: 'white',
              borderRadius: 10
            }}
          >
            <div className="box-image2 facilitybox">
              <div className="column is-12 facilities">
                <b style={{ fontSize: 22 }}>{t.facilities}</b>
              </div>
              <div className="column is-12">
                <table className="facilitytable">
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: 18,
                          color: '#F16726'
                        }}
                      >
                        <b>{t.basic}</b>
                      </td>
                    </tr>
                    {this.props.basic.length > 0 &&
                      this.props.basic.map((items, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: 14 }}>{items.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <hr style={{ marginTop: 10 }} />
              <div className="column is-12" style={{ marginTop: -10 }}>
                <table className="facilitytable">
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: 18,
                          color: '#F16726'
                        }}
                      >
                        <b>{t.other}</b>
                      </td>
                    </tr>
                    {this.props.others.length > 0 &&
                      this.props.others.map((items, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: 14 }}>{items.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.klikFasil(e, 'hidden')}
          />
        </div>
      </div>
    );
  }
}
const StateToProps = state => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(Fasilitas));
