import React from 'react';
import { IconTopUpGame } from '../../assets/images/icons/topups';

function GameIcon({ width = 40, height = 40 }) {
  return <img src={IconTopUpGame} />;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M17 13H19"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13H13"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V15"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2502 10L11.9035 10.0179C11.0219 10.0179 10.1685 10.3136 9.49306 10.8529C8.81765 11.3923 8.36342 12.1409 8.21009 12.9672L8.2107 12.9674L7.03065 18.7443C6.95612 19.1468 7.01833 19.5615 7.20827 19.9281C7.3982 20.2947 7.70595 20.5941 8.08684 20.7828C8.46772 20.9716 8.90189 21.0398 9.3263 20.9776C9.75071 20.9155 10.1433 20.7262 10.4469 20.4372L10.4468 20.4371L13.5646 17.1564L18.2502 17.1386C19.2447 17.1386 20.1985 16.7625 20.9017 16.0932C21.6049 15.4238 22 14.5159 22 13.5693C22 12.6227 21.6049 11.7148 20.9017 11.0454C20.1985 10.376 19.2447 10 18.2502 10V10Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8939 13L22.972 18.7585C23.0401 19.1585 22.9832 19.5705 22.8096 19.9348C22.6359 20.2991 22.3545 20.5966 22.0063 20.7842C21.6581 20.9717 21.2611 21.0396 20.8731 20.9778C20.485 20.916 20.1261 20.7279 19.8485 20.4407L19.8486 20.4406L17 17.1691"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15"
          y1="5.71429"
          x2="15"
          y2="41.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F77E3C" />
          <stop offset="1" stopColor="#FF9B64" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GameIcon;
