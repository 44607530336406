import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
// import { toast } from 'react-toastify'

import ApiCall from '../../services/ApiCall';
import {
  setMessage,
  toggleModal,
  logout,
  setUser,
  setLang,
  setPromoLogin
} from '../../redux/actions';

import './Header.css';
import { BASE_URL } from '../../constants';
import { rupiah } from '../../utils/helper';
import flag_id from '../../assets/flags/id.png';
import flag_en from '../../assets/flags/gb.png';
import PropertyTypeIcon from '../Icons/PropertyTypeIcon';
import ReservationCheckIcon from '../Icons/ReservationCheckIcon';
import BePartnerIcon from '../Icons/BePartnerIcon';
import LongStayIcon from '../Icons/LongStayIcon';

class Header extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      lang: 'en',
      toggleMenu: false,
      banner: true,
      discount: '',
      images: '',
      point: null
    };
  }

  componentDidMount() {
    setTimeout(() => {
      ApiCall.getPromo()
        .then(response => {
          if (this.props.app.lebelPromo === false)
            this.props.toggleModal('lebelPromo');
          this.setState({
            discount: response.data.data[0].value,
            images: response.data.data.map(item => item['images'])
          });
        })
        .catch(error => {
          // if (this.props.app.lebelPromo === true) this.props.toggleModal('lebelPromo')
        });
      if (this.props.app.user !== '') {
        const userId = this.props.app.user.id;
        if (userId) {
          ApiCall.getCustomerPoint(userId)
            .then(res => {
              // console.log(res.data);
              this.setState({
                point: res.data.data
              });
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
      this.getPromoLogin();
    }, 2000);
  }

  getPromoLogin() {
    if (this.props.app.user !== '')
      ApiCall.getPromoLogin()
        .then(res => {
          // console.log(res.data.data)
          this.props.setPromoLogin(res.data.data.promoLogin);
        })
        .catch(err => {
          console.log(err);
        });
  }

  changeLang = langmaps => {
    this.setState({ lang: langmaps });
    this.props.changeLocale(langmaps);
    this.props.setLang(langmaps);
    this.toggle();
  };

  toggle = () => {
    // this.setState({toggleMenu: !this.state.toggleMenu})
    this.props.toggleModal('toggleMenu');
  };

  togglehome = () => {
    this.setState({ toggleMenu: false });
  };

  logout = () => {
    this.props.logout();
    this.props.setUser('');
    const pathname = window.location.pathname;
    setTimeout(() => {
      window.location.href = '/';
      // if (pathname === '/profile' || pathname === '/profile/edit') {
      //   window.location.href = '/';
      // } else {
      //   window.location.reload();
      // }
    }, 500);
    this.toggle();
  };

  closebanner = () => {
    // this.setState({banner: false})
    this.props.toggleModal('lebelPromo');
  };

  showmodalPromo = () => {
    this.props.toggleModal('modalVoucher');
    this.props.setMessage(this.state.images);
  };

  showmodalLogin = () => {
    this.props.toggleModal('modalLogin');
    this.toggle();
  };

  showmodalRegister = () => {
    this.props.toggleModal('modalRegister');
    this.toggle();
  };

  render() {
    const { t } = this.props;
    const langMenu = [
      // <div className="navbar-item menudropdown navbarhead has-dropdown is-hoverable ">
      //   <p className="navbar-link" style={{ backgroundColor: 'white' }}>
      //     {this.props.app.lang === ''
      //       ? 'ID'
      //       : this.props.app.lang.toUpperCase()}
      //   </p>
      //   <div className="navbar-dropdown menudropdown2">
      //     <a
      //       className="navbar-item menudropdownchild"
      //       onClick={() => this.changeLang('id')}
      //     >
      //       <span className="eng">Indonesia</span>
      //     </a>
      //     <a
      //       className="navbar-item menudropdownchild"
      //       onClick={() => this.changeLang('en')}
      //     >
      //       <span className="eng">English</span>
      //     </a>
      //   </div>
      // </div>,
      <a
        className="navbar-item menu"
        to="/reservation-check"
        key="reservation-check"
      >
        <img
          onClick={() => this.changeLang('id')}
          style={{ marginRight: 5 }}
          src={flag_id}
          alt="ID"
        />
        <img onClick={() => this.changeLang('en')} src={flag_en} alt="EN" />
      </a>
    ];

    let pointMenu = '';
    if (this.state.point !== null)
      pointMenu = (
        <a className="navbar-item menu">
          {rupiah(this.state.point.remaining_point)} {t.point}
        </a>
      );
    const stayMonthly = (
      <div
        className="navbar-item menudropdown navbarhead has-dropdown is-hoverable "
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LongStayIcon />
        <p
          className="navbar-link"
          style={{ backgroundColor: 'white', paddingLeft: 5 }}
        >
          {this.props.app.lang === 'en' ? 'Long Stay' : 'Kos-kosan Eksklusif'}
        </p>
        <div className="navbar-dropdown menudropdown2">
          <Link
            to="/stay-yearly"
            style={{ color: 'black !important' }}
            className="navbar-item menudropdownchild"
          >
            {t.stayYearly}
          </Link>
          <Link
            to="/stay-monthly"
            style={{ color: 'black !important' }}
            className="navbar-item menudropdownchild"
          >
            {t.stayMonthly}
          </Link>

          <Link
            to="/stay-weekly"
            style={{ color: 'black !important' }}
            className="navbar-item menudropdownchild"
          >
            {t.stayWeekly}
          </Link>
        </div>
      </div>
    );
    const bePartner = (
      <a
        target="_blank"
        className="navbar-item menu"
        href={BASE_URL + '/v1/be-partner'}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ paddingRight: 5 }}>
          <BePartnerIcon />
        </div>
        {t.bePartner}
      </a>
    );
    const reservationCheck = (
      <Link
        className="navbar-item menu"
        to="/reservation-check"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ paddingRight: 5 }}>
          <ReservationCheckIcon />
        </div>
        {t.reservationCheck}
      </Link>
    );
    const propertyTypeLink = (
      <div
        className="navbar-item menudropdown navbarhead has-dropdown is-hoverable"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <PropertyTypeIcon />
        <p className="navbar-link" style={{ backgroundColor: 'white' }}>
          {this.props.app.lang === 'en' ? 'Property Type' : 'Jenis Properti'}
        </p>
        <div className="navbar-dropdown menudropdown2">
          <Link
            className="navbar-item menudropdownchild"
            to="/properties/by-type/konvensional"
          >
            <span className="eng">Konvensional</span>
          </Link>
          <Link
            className="navbar-item menudropdownchild"
            to="/properties/by-type/syariah"
          >
            <span className="eng">Syariah</span>
          </Link>
        </div>
      </div>
    );

    return (
      <div>
        <div
          className="navbar is-fixed-top is-white isHeader"
          role="navigation"
          style={{ zIndex: 9991 }}
          aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link
                className="navbar-item navbar-logo menulogo"
                to="/"
                onClick={this.togglehome}
              >
                <img src="/img/logo nusalink 3.png" alt="Nusalink" />
              </Link>

              <div
                className={
                  'navbar-burger burger ' +
                  (this.props.app.toggleMenu ? 'is-active' : '')
                }
                data-target="navMenuExample"
                onClick={this.toggle}
              >
                <span />
                <span />
                <span />
              </div>
            </div>

            <div
              id="navMenuExample"
              className={
                'navbar-menu ' + (this.props.app.toggleMenu ? 'is-active' : '')
              }
              style={{ backgroundColor: 'white' }}
            >
              {this.props.app.user !== '' ? (
                <div className="navbar-end">
                  {propertyTypeLink}
                  {bePartner}
                  {reservationCheck}
                  {stayMonthly}
                  {pointMenu}
                  <div
                    className="navbar-item menudropdown navbarhead has-dropdown is-hoverable"
                    style={{ backgroundColor: 'white' }}
                  >
                    <p
                      className="navbar-link"
                      style={{ backgroundColor: 'white' }}
                    >
                      {this.props.app.user.name ? this.props.app.user.name : ''}
                    </p>
                    <div className="navbar-dropdown menudropdown2">
                      <Link
                        className="navbar-item menudropdownchild"
                        to="/profile"
                        onClick={this.toggle}
                      >
                        <span className="eng">{t.accountset}</span>
                      </Link>
                      <Link
                        className="navbar-item menudropdownchild"
                        to="/my-booking"
                        onClick={this.toggle}
                      >
                        <span className="eng">{t.mybook}</span>
                      </Link>
                      <a
                        className="navbar-item menudropdownchild"
                        onClick={this.logout}
                      >
                        <span className="eng">{t.logout}</span>
                      </a>
                    </div>
                  </div>
                  {langMenu}
                </div>
              ) : (
                <div className="navbar-end">
                  {propertyTypeLink}
                  {bePartner}
                  {reservationCheck}
                  {stayMonthly}
                  <a onClick={this.showmodalLogin} className="navbar-item menu">
                    {t.login}
                  </a>
                  {/* <a
                    onClick={this.showmodalRegister}
                    className="navbar-item menu"
                  >
                    {t.register}
                  </a> */}
                  {langMenu}
                </div>
              )}
            </div>
          </div>
          {this.props.app.lebelPromo === true && this.state.images !== '' ? (
            <div
              className="column is-12 promobanner forbanner"
              style={{
                width: '100%',
                backgroundColor: '#f16726',
                marginBottom: -50,
                color: 'white',
                top: 53,
                position: 'fixed'
              }}
            >
              <center className="labelbanner">
                <label className="discount">
                  <b>
                    {t.savepromo} {this.state.discount}% {t.specdeal}
                  </b>
                </label>
                <label
                  className="find-more has-text-centered"
                  onClick={this.showmodalPromo}
                >
                  {' '}
                  {t.findmore} {'>> '}
                </label>
                <label
                  className="closebanner is-pulled-right"
                  onClick={this.closebanner}
                >
                  <b>x</b>
                </label>
              </center>
            </div>
          ) : (
            ''
          )}
        </div>
        {this.props.app.lebelPromo === true && this.state.images !== '' ? (
          <div
            className="forbanner2"
            style={{ width: '100%', clear: 'both' }}
          />
        ) : (
          <div style={{ width: '100%', clear: 'both', height: 0 }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;

  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  setMessage,
  toggleModal,
  logout,
  setUser,
  setLang,
  setPromoLogin
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Header));
