import moment from 'moment'
import { toast } from 'react-toastify'
import React from 'react'

export function validateMinHBooking(arrival_start_date, min_h_booking, server_time) {
  if (!min_h_booking) {
    return false
  }

  const arrivalDate = new Date(arrival_start_date);
  const serverTime = new Date(server_time);

  const duration = moment(arrivalDate).startOf('day')
  .diff(moment(serverTime).startOf('day'), 'days');


  if (duration < min_h_booking) {
    toast.error(
      <div>
        {'We regret to inform you that this booking cannot be processed online' +
          ' as it is within the cancellation deadline' +
          ` (reservations can be processed online if the arrival date is h-${min_h_booking}).` +
          ' Please contact our Reservation departement' +
          ' to process booking offline. Full payment must be made' +
          ' immediately upon confirmation of the requested service,' +
          ' We regret the inconvenience caused.'}
        <div style={{ display: 'flex' }}>
          <div
            className="wa-cs"
            style={{ marginRight: 10 }}
            onClick={() => {
              window.open('https://wa.me/628121855557');
            }}
          >
            {/* <i className="fab fa-whatsapp" /> Customer Service */}
            <img
              src={require('../assets/others/wa-putih.png')}
              alt="WA Customer Service Nusalink"
            />
            CS 1
          </div>

          <div
            className="wa-cs"
            onClick={() => {
              window.open('https://wa.me/628111518557');
            }}
          >
            {/* <i className="fab fa-whatsapp" /> Customer Service */}
            <img
              src={require('../assets/others/wa-putih.png')}
              alt="WA Customer Service 2 Nusalink"
            />
            CS 2
          </div>
        </div>
      </div>
      , {
        autoClose: 10000,
      }
    )
    return true
  }

  return false
}
