import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translatable } from "react-multilingual";
import { logout, showModal, setUser, toggleModal } from "./../../redux/actions";

class Sidebar extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <h3 className="title" style={{ fontSize: 18 }}>
          {t.myaccount}
        </h3>
        <hr style={{ marginTop: -10 }} />
        <Link
          to="/profile"
          className="column is-12 unactive boxes"
          style={{
            height: 30,
            marginTop: -10,
            paddingTop: 3,
            paddingBottom: 5,
            marginLeft: -31,
          }}
        >
          <p style={{ fontSize: 14, color: "black" }}>{t.accountset}</p>
        </Link>
        <Link
          to="/my-booking"
          className="column activated boxes is-12"
          style={{
            height: 30,
            marginTop: 0,
            paddingTop: 3,
            paddingBottom: 5,
            marginLeft: -31,
          }}
        >
          <p style={{ fontSize: 14, color: "black" }}>{t.mybook}</p>
        </Link>
        <Link
          to="/"
          className="column boxes unactive is-12"
          style={{
            height: 30,
            marginTop: 0,
            paddingTop: 3,
            paddingBottom: 5,
            marginLeft: -31,
          }}
          onClick={this.logout}
        >
          <p style={{ fontSize: 14, color: "black" }}>{t.logout}</p>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};
const mapTranslationsToProps = ({ t }) => ({ t });
const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Sidebar));
