import React from 'react';

function CrossHairIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 14.25C11.7876 14.25 14.25 11.7876 14.25 8.75C14.25 5.71243 11.7876 3.25 8.75 3.25C5.71243 3.25 3.25 5.71243 3.25 8.75C3.25 11.7876 5.71243 14.25 8.75 14.25Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 2V4.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.75H4.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 15.5V13"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 8.75H13"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 10.75C9.85457 10.75 10.75 9.85457 10.75 8.75C10.75 7.64543 9.85457 6.75 8.75 6.75C7.64543 6.75 6.75 7.64543 6.75 8.75C6.75 9.85457 7.64543 10.75 8.75 10.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CrossHairIcon;
