import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../../components/form/PrimaryButton';
import SelectOption from '../../../../components/form/SelectOption';
import PageTitle from '../../../../components/others/PageTitle';
import { BASE_URL_ADM } from '../../../../constants';
import CreditApi from '../../../../services/api/CreditApi';
import TopUpApi from '../../../../services/api/TopUpApi';
import ApiCall from '../../../../services/ApiCall';
import TopUpService from '../../../../services/TopUpService';
import { moneyFormat } from '../../../../utils/helper';
import StringHelper from '../../../../utils/StringHelper';
import Container from '../../../layouts/Container';
import providerImage from './../../../../../src/assets/others/providers.PNG';

function Card({ title, description, price, onClick, isDataPackage = false }) {
  return (
    <div className="column is-4" onClick={onClick}>
      <div className="d-flex justify-content-between align-items-center border-radius p-20px border-grey cursor-pointer bg-pulsa">
        <div className="flex-1">
          <div className="mb-10px">{title}</div>
          {!isDataPackage && <span className="text-grey">{description}</span>}
        </div>
        <div className="text-right" style={{ width: 140 }}>
          {price === 'Sedang Gangguan' ? (
            <span
              style={{ fontSize: 12 }}
              className="text-primary ml-10px mr-10px"
            >
              {price}
            </span>
          ) : (
            <span className="text-primary ml-10px mr-10px">{price}</span>
          )}
          <i className="fa fa-chevron-right" />
        </div>
      </div>
    </div>
  );
}

class CreditsPage extends Component {
  state = {
    // menuActive: 'credits',
    // menuActive: 'e-wallet',
    menuActive: 'ticket',
    // menuActive: 'data-packages',
    credits: [],
    data_packages: [],
    all: [],
    provider: null,
    phone_number: null,
    token_listriks: [],
    pln_number: null,
    ewallet_type: null,
    ewallet_types: [],
    ewallet_type_selecteds: [],
    ewallet_number: null,
    // gopay_type:'DRIVER'
    movie_ticket_types: [],
    movie_tickets: [],
    movie_ticket_selecteds: [],
    pln_btn_check_disabled: false,
    pln_account: null,
    voucher_games: [],
    voucher_game_types: [],
    voucher_game_selecteds: [],
    emoney_types: [],
    emoney_selecteds: [],
    emoneys: [],
    card_no: null
  };

  constructor(props) {
    super(props);
    this._checkProvider = this._checkProvider.bind(this);
    this._submitTransaction = this._submitTransaction.bind(this);
    this._handleForm = this._handleForm.bind(this);
    this._onChangeEwallet = this._onChangeEwallet.bind(this);
    this._onChangeEmoney = this._onChangeEmoney.bind(this);
    this._onChangeMovieTicketType = this._onChangeMovieTicketType.bind(this);
    this._onChangeVoucherGameType = this._onChangeVoucherGameType.bind(this);
  }

  _handleForm(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async _getData() {
    try {
      const response = await CreditApi.getAll();
      const data = response.data.data;
      // const credits = data[0]['credits'];
      // const data_packages = data[0]['data_packages'];
      this.setState({
        all: data
        // credits,
        // data_packages
      });
    } catch (error) {
      console.log(error);
    }
  }

  async _getPlnToken() {
    try {
      const response = await TopUpApi.getPlnToken();
      const data = response.data.data;
      // const credits = data[0]['credits'];
      // const data_packages = data[0]['data_packages'];
      this.setState({
        token_listriks: data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async _getEwallet() {
    try {
      const response = await TopUpApi.getEwallet();
      const data = response.data.data;
      if (data.length > 0) {
        const ewallet_type = data[0]['type'];
        let ewallet_type_selecteds = data.find(
          type => type.type === ewallet_type
        );
        console.log(ewallet_type_selecteds);
        if (ewallet_type_selecteds !== null) {
          ewallet_type_selecteds = ewallet_type_selecteds['data'];
        }
        this.setState({
          ewallet_type,
          ewallet_types: data,
          ewallet_type_selecteds
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async _getEmoney() {
    try {
      const response = await TopUpApi.getEmoney();
      const data = response.data.data;
      if (data.length > 0) {
        const emoney_type = data[0]['type'];
        let emoney_selecteds = data.find(type => type.type === emoney_type);
        // console.log(ewallet_type_selecteds);
        if (emoney_selecteds !== null) {
          emoney_selecteds = emoney_selecteds['data'];
        }
        this.setState({
          emoney_types: data.map(item => item.type),
          emoneys: data,
          emoney_selecteds
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async _getMovieTickets() {
    try {
      const response = await TopUpApi.getMovieTickets();
      const data = response.data.data;
      if (data.length > 0) {
        const movie_ticket_types = data.map(item => item['type']);
        this.setState({
          movie_ticket_types,
          movie_tickets: response.data.data,
          movie_ticket_selecteds: response.data.data[0]['data']
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async _getVoucherGames() {
    try {
      const response = await TopUpApi.getVoucherGames();
      const data = response.data.data;
      if (data.length > 0) {
        const voucher_game_types = data.map(item => item['type']);
        this.setState({
          voucher_game_types,
          voucher_games: response.data.data,
          voucher_game_selecteds: response.data.data[0]['data']
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  _checkProvider() {
    let number = window.event.target.value;

    const set = (number, phone_number) => {
      const all = this.state.all;
      const provider = all.find(provider => {
        if (String(number).substr(0, 1) === '0') {
          return provider.number_prefix.includes(String(number));
        }
        return provider.number_prefix.includes('0' + String(number));
      });
      // console.log('halo');
      if (provider) {
        this.setState({
          credits: provider.credits,
          data_packages: provider.data_packages,
          provider: provider,
          phone_number
        });
      } else {
        this.setState({
          phone_number
        });
      }
    };

    if (number !== '') {
      if (String(number).length >= 3) set(String(number).substr(0, 4), number);
    } else {
      this.setState({
        credits: [],
        data_packages: [],
        provider: null,
        phone_number: number
      });
    }
  }

  async _submitTransaction({
    data_package_id,
    credit_id,
    token_id,
    type_id,
    movie_ticket_id,
    voucher_game_id,
    emoney_product_id
  }) {
    try {
      const user = this.props.app.user;
      let data = {
        email: user.email,
        buyer_name: user.name
      };
      let response = null;

      // pln
      if (token_id) {
        if (StringHelper.isEmpty(this.state.pln_number)) {
          // eslint-disable-next-line no-unused-expressions
          this.props.app.lang === 'id'
            ? 'Masukkan nomor PLN terlebih dahulu'
            : 'insert PLN number first';
        } else if (String(this.state.pln_number).length < 5) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Nomor PLN tidak valid'
              : 'PLN number is invalid'
          );
        } else {
          data = Object.assign(data, {
            topup_pln_id: token_id,
            pln_number: this.state.pln_number
          });
          response = await TopUpApi.postTransactionPlnToken(data);
        }
      }

      // tiket nonton
      else if (movie_ticket_id) {
        if (StringHelper.isEmpty(this.state.phone_number)) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Masukkan nomor tujuan terlebih dahulu'
              : 'insert destination number first'
          );
        } else if (String(this.state.phone_number).length < 5) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Nomor tujuan tidak valid'
              : 'destination number is invalid'
          );
        } else {
          data = Object.assign(data, {
            movie_ticket_id,
            phone_number: this.state.phone_number
          });
          response = await TopUpApi.postTransactionMovieTicket(data);
          console.log(data);
        }
      }

      // ewallet
      else if (type_id) {
        if (StringHelper.isEmpty(this.state.ewallet_number)) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Masukkan nomor tujuan terlebih dahulu'
              : 'insert destination number first'
          );
        } else if (String(this.state.ewallet_number).length < 9) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Nomor tujuan tidak valid'
              : 'destination number is invalid'
          );
        } else {
          data = Object.assign(data, {
            ewallet_id: type_id,
            phone_number: this.state.ewallet_number
          });
          response = await TopUpApi.postTransactionEwallet(data);
        }
      }

      // emoney
      else if (emoney_product_id) {
        if (StringHelper.isEmpty(this.state.card_no)) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Masukkan nomor kartu terlebih dahulu'
              : 'insert card no first'
          );
        } else if (String(this.state.card_no).length < 5) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Nomor kartu tidak valid'
              : 'card no is invalid'
          );
        } else {
          data = Object.assign(data, {
            emoney_product_id,
            emoney_account: this.state.card_no
          });
          response = await TopUpApi.postTransactionEmoney(data);
        }
      }

      // voucher game
      else if (voucher_game_id) {
        if (StringHelper.isEmpty(this.state.phone_number)) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'masukkan id game terlebih dahulu'
              : 'insert game id first'
          );
        } else if (String(this.state.phone_number).length < 4) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'id game tidak valid'
              : 'game id is invalid'
          );
        } else {
          data = Object.assign(data, {
            voucher_game_id,
            game_id: this.state.phone_number
          });
          response = await TopUpApi.postTransactionVoucherGames(data);
        }
      }

      // pulsa
      else {
        if (String(this.state.phone_number).length < 9) {
          toast.error(
            this.props.app.lang === 'id'
              ? 'Nomor telepon tidak valid'
              : 'Phone number is invalid'
          );
          return;
        }
        data = Object.assign(data, {
          phone_number: this.state.phone_number,
          data_package_id,
          credit_id
        });
        response = await CreditApi.postTransaction(data);
      }
      if (response !== null)
        if (response.data.status === 'success') {
          window.location.href = response.data.data.midtrans.redirect_url;
        }
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  _onChangeEwallet(e) {
    const value = e.target.value;

    this.setState(prevState => ({
      ewallet_type: value,
      ewallet_type_selecteds: prevState.ewallet_types.find(
        type => type.type === value
      )['data']
    }));
  }

  _onChangeEmoney(e) {
    const value = e.target.value;

    this.setState(prevState => ({
      // ewallet_type: value,
      emoney_selecteds: prevState.emoneys.find(type => type.type === value)[
        'data'
      ]
    }));
  }

  _onChangeMovieTicketType(e) {
    const value = e.target.value;

    this.setState(prevState => ({
      movie_ticket_selecteds: prevState.movie_tickets.find(
        item => item.type === value
      )['data']
    }));
  }

  _onChangeVoucherGameType(e) {
    const value = e.target.value;

    this.setState(prevState => ({
      voucher_game_selecteds: prevState.voucher_games.find(
        item => item.type === value
      )['data']
    }));
  }

  _onChangeJenisGopay(e) {}

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.ewallet_type !== this.state.ewallet_type) {
  //     if (this.state.ewallet_type === 'GOPAY') {

  //     }
  //   }
  // }

  componentDidMount() {
    TopUpService.platformStatus().then(res => {
      console.log(res.data);
      const { ios, android } = res.data.data;
      if (Number(ios) === 1 || Number(android) === 1) {
        this.setState({
          isTopUpEnabled: true
        });
        // if (StringHelper.isEmpty(this.props.app.user)) {
        //   this.props.history.push('/');
        // } else {
        this._getData();
        this._getPlnToken();
        this._getEwallet();
        this._getMovieTickets();
        this._getVoucherGames();
        this._getEmoney();
        // console.log(this.props.app);
        // }
        const menuActive = localStorage.getItem('topUp');
        if (menuActive) {
          this.setState({
            menuActive
          });
        }
      } else {
        this.props.history.push('/');
      }
    });
  }

  render() {
    const { t } = this.props;
    const { lang } = this.props.app;
    const insertPhoneNumberFirst = (
      <div className="column is-12">
        <div className="text-center">
          {t['Please insert phone number first']}
        </div>
      </div>
    );
    return (
      <Container>
        <PageTitle title={t['Bills & Top Up']} />
        <div>
          <div className="columns">
            {(this.state.menuActive === 'credits' ||
              this.state.menuActive === 'data-packages') && (
              <div className="column is-half is-offset-one-quarter">
                <img
                  src={providerImage}
                  alt="Provider"
                  style={{ marginBottom: 10 }}
                />
                <div className="input-pulsa-container">
                  <div>+62</div>
                  <input
                    onKeyUp={this._checkProvider}
                    type="number"
                    className="input-pulsa"
                    placeholder={t['Phone Number']}
                  />
                </div>
                <div className="text-center mt-10px d-flex justify-content-center align-items-center">
                  {this.state.provider !== null && [
                    'Provider: ',
                    this.state.provider['logo'] !== null ? (
                      <img
                        key={'gambarProvider'}
                        style={{ maxHeight: 40 }}
                        className="mw-100px ml-10px"
                        alt={this.state.provider['provider_name']}
                        src={
                          BASE_URL_ADM +
                          '/storage/top-up/providers/300/' +
                          this.state.provider['logo']
                        }
                      />
                    ) : (
                      this.state.provider['provider_name']
                    )
                  ]}
                </div>
              </div>
            )}
            {this.state.menuActive === 'token-listrik' && (
              <div className="column is-half is-offset-one-quarter">
                <div className="input-pulsa-container">
                  <input
                    onChange={this._handleForm}
                    type="number"
                    className="input-pulsa"
                    name="pln_number"
                    placeholder={t['PLN Number']}
                  />
                </div>

                <div
                  style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <PrimaryButton
                    onClick={async () => {
                      try {
                        if (StringHelper.isEmpty(this.state.pln_number)) {
                          this.setState({
                            pln_account: null
                          });
                          toast.error('PLN Number required');
                        } else if (this.state.pln_number.length <= 10) {
                          toast.error('PLN Number is invalid');
                          this.setState({
                            pln_account: null
                          });
                        } else {
                          this.setState({
                            pln_account: null,
                            pln_btn_check_disabled: true
                          });
                          if (!this.state.pln_btn_check_disabled) {
                            const { data } = await ApiCall.checkPlnNumber(
                              this.state.pln_number
                            );
                            if (data.status === 'success') {
                              this.setState({
                                pln_account: data.data
                              });
                            } else if (data.status === 'error') {
                              toast.error(data.message);
                            }
                          }
                          // console.log(data);
                        }
                      } catch (error) {
                      } finally {
                        this.setState({
                          pln_btn_check_disabled: false
                        });
                      }
                    }}
                    disabled={this.state.pln_btn_check_disabled}
                    label={
                      this.state.pln_btn_check_disabled
                        ? 'Checking...'
                        : 'Check'
                    }
                  />
                </div>
                {this.state.pln_account !== null && (
                  <div style={{ textAlign: 'center' }}>
                    Name: {this.state.pln_account.owner_name}
                    <br />
                    Volt: {this.state.pln_account.volt}
                  </div>
                )}
              </div>
            )}
            {this.state.menuActive === 'e-wallet' && (
              <div className="column is-half is-offset-one-quarter">
                <SelectOption
                  label={'Pilih jenis e-wallet'}
                  id="nomorTujuan"
                  name="nomorTujuan"
                  onChange={this._onChangeEwallet}
                >
                  {this.state.ewallet_types.map(type => (
                    <option key={type.type} value={type.type}>
                      {type.type}
                    </option>
                  ))}
                </SelectOption>

                <div
                  className="input-pulsa-container"
                  style={{ marginLeft: 0 }}
                >
                  <input
                    onChange={this._handleForm}
                    type="number"
                    className="input-pulsa"
                    name="ewallet_number"
                    placeholder={'Nomor tujuan'}
                  />
                </div>
              </div>
            )}
            {this.state.menuActive === 'e-money' && (
              <div className="column is-half is-offset-one-quarter">
                <SelectOption
                  label={'Pilih jenis e-money'}
                  id="provider"
                  name="provider"
                  onChange={this._onChangeEmoney}
                >
                  {this.state.emoney_types.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </SelectOption>

                <div
                  className="input-pulsa-container"
                  style={{ marginLeft: 0 }}
                >
                  <input
                    onChange={this._handleForm}
                    type="number"
                    className="input-pulsa"
                    name="card_no"
                    placeholder={'Nomor kartu'}
                  />
                </div>
              </div>
            )}

            {this.state.menuActive === 'ticket' && (
              <div className="column is-half is-offset-one-quarter">
                <SelectOption
                  label={'Pilih jenis tiket nonton'}
                  id="nomorTujuan"
                  name="nomorTujuan"
                  onChange={this._onChangeMovieTicketType}
                >
                  {this.state.movie_ticket_types.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </SelectOption>

                <div
                  className="input-pulsa-container"
                  style={{ marginLeft: 0 }}
                >
                  <input
                    onChange={this._handleForm}
                    type="number"
                    className="input-pulsa"
                    name="phone_number"
                    placeholder={'Nomor tujuan'}
                  />
                </div>
              </div>
            )}

            {this.state.menuActive === 'voucher-games' && (
              <div className="column is-half is-offset-one-quarter">
                <SelectOption
                  label={
                    this.props.app.lang === 'id' ? 'Pilih game' : 'Choose game'
                  }
                  id="nomorTujuan"
                  name="nomorTujuan"
                  onChange={this._onChangeVoucherGameType}
                >
                  {this.state.voucher_game_types.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </SelectOption>

                <div
                  className="input-pulsa-container"
                  style={{ marginLeft: 0 }}
                >
                  <input
                    onChange={this._handleForm}
                    type="number"
                    className="input-pulsa"
                    name="phone_number"
                    placeholder={
                      this.props.app.lang === 'id' ? 'ID Game' : 'Game ID'
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="tabs">
            <ul className="mt-0">
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'credits');
                  this.setState({
                    menuActive: 'credits'
                  });
                }}
                className={
                  this.state.menuActive === 'credits' ? 'is-active' : ''
                }
              >
                <a>{t['Credits']}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'data-packages');
                  this.setState({
                    menuActive: 'data-packages'
                  });
                }}
                className={
                  this.state.menuActive === 'data-packages' ? 'is-active' : ''
                }
              >
                <a>{t['Data Packages']}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'token-listrik');
                  this.setState({
                    menuActive: 'token-listrik'
                  });
                }}
                className={
                  this.state.menuActive === 'token-listrik' ? 'is-active' : ''
                }
              >
                <a>{t['PLN Token']}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'e-wallet');
                  this.setState({
                    menuActive: 'e-wallet'
                  });
                }}
                className={
                  this.state.menuActive === 'e-wallet' ? 'is-active' : ''
                }
              >
                <a>{'E-Wallet'}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'e-money');
                  this.setState({
                    menuActive: 'e-money'
                  });
                }}
                className={
                  this.state.menuActive === 'e-money' ? 'is-active' : ''
                }
              >
                <a>{'E-Money'}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'ticket');
                  this.setState({
                    menuActive: 'ticket'
                  });
                }}
                className={
                  this.state.menuActive === 'ticket' ? 'is-active' : ''
                }
              >
                <a>{t['Movie Ticket']}</a>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('topUp', 'voucher-games');
                  this.setState({
                    menuActive: 'voucher-games'
                  });
                }}
                className={
                  this.state.menuActive === 'voucher-games' ? 'is-active' : ''
                }
              >
                <a>
                  {this.props.app.lang === 'id'
                    ? 'Voucher Game'
                    : 'Game Vouchers'}
                </a>
              </li>
            </ul>
          </div>
          {this.state.menuActive === 'credits' && (
            <div id="creditsArea">
              <div className="columns is-multiline">
                {this.state.credits.length > 0
                  ? this.state.credits.map(credit => (
                      <Card
                        key={credit['id']}
                        title={moneyFormat(credit['credit'], lang, false)}
                        description={
                          t['Active Period'] +
                          ' ' +
                          credit['active_period'] +
                          ' ' +
                          t['Day']
                        }
                        price={
                          credit.status === 'Open'
                            ? moneyFormat(credit['price'], lang, true)
                            : 'Sedang Gangguan'
                        }
                        onClick={() => {
                          if (credit.status === 'Open')
                            this._submitTransaction({
                              credit_id: credit['id']
                            });
                          // else alert('Produk sedang gangguan');
                        }}
                      />
                    ))
                  : insertPhoneNumberFirst}
              </div>
            </div>
          )}
          {this.state.menuActive === 'data-packages' && (
            <div id="dataPackagesArea">
              <div className="columns is-multiline">
                {this.state.data_packages.length > 0
                  ? this.state.data_packages.map(data_package => (
                      <Card
                        isDataPackage={true}
                        key={data_package['id']}
                        title={data_package['package_name']}
                        description={data_package['description']}
                        price={
                          data_package.status === 'Open'
                            ? moneyFormat(data_package['price'], lang, true)
                            : 'Sedang Gangguan'
                        }
                        onClick={() => {
                          if (data_package.status === 'Open')
                            this._submitTransaction({
                              data_package_id: data_package['id']
                            });
                          else alert('Produk sedang gangguan');
                        }}
                      />
                    ))
                  : insertPhoneNumberFirst}
              </div>
            </div>
          )}
          {this.state.menuActive === 'token-listrik' &&
            this.state.pln_account !== null && (
              <div id="F">
                {/* Coming soon */}
                <div className="columns is-multiline">
                  {this.state.token_listriks.map(token => (
                    <Card
                      key={token['id']}
                      title={token['description']}
                      description={
                        'Admin Fee ' + moneyFormat(token['admin_fee'])
                      }
                      price={
                        token.status === 'Open'
                          ? moneyFormat(token['price'], lang, true)
                          : 'Sedang Gangguan'
                      }
                      onClick={() => {
                        if (token.status === 'Open')
                          this._submitTransaction({
                            token_id: token['id']
                          });
                        else alert('Produk sedang gangguan');
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

          {this.state.menuActive === 'e-wallet' && (
            <div id="eWalletArea">
              <div className="columns is-multiline">
                {this.state.ewallet_type_selecteds.map(type => (
                  <Card
                    isDataPackage={true}
                    key={type['id']}
                    title={type['description']}
                    description={'Admin Fee ' + moneyFormat(type['admin_fee'])}
                    price={
                      type.status === 'Open'
                        ? moneyFormat(type['price'], lang, true)
                        : 'Sedang Gangguan'
                    }
                    onClick={() => {
                      if (type.status === 'Open')
                        this._submitTransaction({
                          type_id: type['id']
                        });
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {this.state.menuActive === 'e-money' && (
            <div id="eMoneyArea">
              <div className="columns is-multiline">
                {this.state.emoney_selecteds.map(type => (
                  <Card
                    isDataPackage={true}
                    key={type['id']}
                    title={type['description']}
                    description={'Admin Fee ' + moneyFormat(type['admin_fee'])}
                    price={
                      type.status === 'Open'
                        ? moneyFormat(type['price'], lang, true)
                        : 'Sedang Gangguan'
                    }
                    onClick={() => {
                      if (type.status === 'Open')
                        this._submitTransaction({
                          emoney_product_id: type['id']
                        });
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {this.state.menuActive === 'ticket' && (
            <div id="ticketArea">
              {/* <h4 style={{ textAlign: 'center' }}>Coming soon</h4> */}
              <div className="columns is-multiline">
                {this.state.movie_ticket_selecteds.map(movie => (
                  <Card
                    key={movie['id']}
                    title={movie['description']}
                    description={''}
                    price={
                      movie.status === 'Open'
                        ? moneyFormat(movie['price'], lang, true)
                        : 'Sedang Gangguan'
                    }
                    onClick={() => {
                      if (movie.status === 'Open')
                        this._submitTransaction({
                          movie_ticket_id: movie['id']
                        });
                      else alert('Produk sedang gangguan');
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {this.state.menuActive === 'voucher-games' && (
            <div id="voucherGameArea">
              {/* <h4 style={{ textAlign: 'center' }}>Coming soon</h4> */}
              <div className="columns is-multiline">
                {this.state.voucher_game_selecteds.map(game => (
                  <Card
                    key={game['id']}
                    title={game['product_name']}
                    description={''}
                    price={
                      game.status === 'Open'
                        ? moneyFormat(game['price'], lang, true)
                        : 'Sedang Gangguan'
                    }
                    onClick={() => {
                      if (game.status === 'Open')
                        this._submitTransaction({
                          voucher_game_id: game['id']
                        });
                      else alert('Produk sedang gangguan');
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  const { app } = state;
  return { app };
};
const connector = connect(mapStateToProps);
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(CreditsPage));
