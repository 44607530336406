import React from 'react';

export default function BedRoomIcon() {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <rect y="10.3587" width="25.2729" height="6.65075" rx="1" />
      </mask>
      <rect
        y="10.3587"
        width="25.2729"
        height="6.65075"
        rx="1"
        stroke="#F3722B"
        strokeWidth="3"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <path d="M1.19702 1.51569C1.19702 0.963402 1.64474 0.515686 2.19702 0.515686H22.5435C23.0958 0.515686 23.5435 0.963401 23.5435 1.51569V11.6889H1.19702V1.51569Z" />
      </mask>
      <path
        d="M1.19702 1.51569C1.19702 0.963402 1.64474 0.515686 2.19702 0.515686H22.5435C23.0958 0.515686 23.5435 0.963401 23.5435 1.51569V11.6889H1.19702V1.51569Z"
        stroke="#F3722B"
        strokeWidth="3"
        mask="url(#path-2-inside-2)"
      />
      <rect
        x="4.74023"
        y="5.78812"
        width="6.4809"
        height="3.8206"
        rx="1.25"
        stroke="#F3722B"
        strokeWidth="1.5"
      />
      <rect
        x="14.0513"
        y="5.78812"
        width="6.4809"
        height="3.8206"
        rx="1.25"
        stroke="#F3722B"
        strokeWidth="1.5"
      />
      <rect
        x="3.104"
        y="16.3445"
        width="1.55184"
        height="4.65553"
        rx="0.775921"
        fill="#F3722B"
      />
      <rect
        x="6.20728"
        y="16.3445"
        width="1.33015"
        height="2.6603"
        rx="0.665075"
        fill="#F3722B"
      />
      <rect
        x="18.6221"
        y="16.3445"
        width="1.33015"
        height="2.6603"
        rx="0.665075"
        fill="#F3722B"
      />
      <rect
        x="21.7261"
        y="16.3445"
        width="1.33015"
        height="3.99045"
        rx="0.665075"
        fill="#F3722B"
      />
    </svg>
  );
}
