import React from 'react';
import { COLOR_PRIMARY } from '../../constants';

function ComingSoonPage() {
  return (
    <div
      style={{ position: 'relative' }}
      className="d-flex justify-content-center"
    >
      <img
        className="py-5 my-5"
        src={require('./../../assets/others/coming_soon.png')}
        alt="coming soon"
      />
      <div
        className="pt-5 mt-5"
        style={{ position: 'absolute', top: 0, width: '100%' }}
      >
        <div className="pt-4 mt-5">
          <h1
            className="text-center"
            style={{ fontSize: 48, fontWeight: 'bold' }}
          >
            COMING SOON!
          </h1>
          <h2
            className="text-center mb-3"
            style={{ fontSize: 16, color: COLOR_PRIMARY, fontWeight: 'bold' }}
          >
            NUSALINK ASIA
          </h2>
          <h3
            className="text-center"
            style={{ fontSize: 18, color: '#656565', fontWeight: 'bold' }}
          >
            “Experience Living in Fantastic Hotels You Never Had Before“
          </h3>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonPage;
