import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import FormTextarea from '../../components/others/FormTextarea';
import PageTitle from '../../components/others/PageTitle';
import Container from '../layouts/Container';
import ReactStars from 'react-rating-stars-component';
import { COLORS } from '../../constants';
import PrimaryButton from '../../components/form/PrimaryButton';
import ApiCall from '../../services/ApiCall';
import { queryStringToJSON } from '../../utils/helper';

export class Review extends Component {
  state = {
    cleanliness: 0,
    comfort: 0,
    food: 0,
    location: 0,
    service: 0,
    review: '',
    order_id: '',
    email: '',
    reservation_type: '',
  };

  constructor(props) {
    super(props);
    this.ratingChanged = this.ratingChanged.bind(this);
    this.submit = this.submit.bind(this);
  }

  ratingChanged(type, newRating) {
    this.setState({
      [type]: newRating,
    });
  }

  submit(e) {
    e.preventDefault();
    // console.log(queryStringToJSON());
    const {
      cleanliness,
      comfort,
      food,
      location,
      service,
      review,
    } = this.state;
    if (
      cleanliness === 0 ||
      comfort === 0 ||
      food === 0 ||
      location === 0 ||
      service === 0 ||
      review === ''
    ) {
      alert('complete the form');
    } else {
      ApiCall.postReview(this.state)
        .then((res) => {
          console.log(res.data);
          alert('Your review has been submited');
          this.props.history.push('/');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  checkReview(email, orderId, reservation_type) {
    ApiCall.findReview(email, orderId, reservation_type)
      .then((res) => {
        if (res.data.data !== null) {
          this.props.history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
        // this.props.history.push('/');
      });
  }

  componentDidMount() {
    const obj = queryStringToJSON();
    this.setState(obj);
    this.checkReview(obj.email, obj.order_id, obj.reservation_type);
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <PageTitle title="Review" />
        <div className="columns is-multiline long">
          <div className="column is-12">
            <FormTextarea
              label={t.enterReview}
              onChange={(e) => {
                this.setState({ review: e.target.value });
              }}
            />
          </div>
          <div className="column is-12">
            <div className="columns is-multiline long">
              <div className="column is-4">
                {t.cleanliness}
                <ReactStars
                  count={5}
                  onChange={(newRating) => {
                    this.ratingChanged('cleanliness', newRating * 2);
                  }}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor={COLORS.primary}
                />
              </div>
              <div className="column is-4">
                {t.comfort}
                <ReactStars
                  count={5}
                  onChange={(newRating) => {
                    this.ratingChanged('comfort', newRating * 2);
                  }}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor={COLORS.primary}
                />{' '}
              </div>
              <div className="column is-4">
                {t.food}
                <ReactStars
                  count={5}
                  onChange={(newRating) => {
                    this.ratingChanged('food', newRating * 2);
                  }}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor={COLORS.primary}
                />{' '}
              </div>
              <div className="column is-4">
                {t.location}
                <ReactStars
                  count={5}
                  onChange={(newRating) => {
                    this.ratingChanged('location', newRating * 2);
                  }}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor={COLORS.primary}
                />{' '}
              </div>
              <div className="column is-4">
                {t.service}
                <ReactStars
                  count={5}
                  onChange={(newRating) => {
                    this.ratingChanged('service', newRating * 2);
                  }}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor={COLORS.primary}
                />
              </div>
            </div>
          </div>
          <div className="column is-12">
            <PrimaryButton onClick={this.submit} label="Submit" />
          </div>
        </div>
      </Container>
    );
  }
}
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);
export default translator(Review);
