import React from 'react';

export default function InstagramIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 16.4062C14.6574 16.4062 16.4062 14.6574 16.4062 12.5C16.4062 10.3426 14.6574 8.59375 12.5 8.59375C10.3426 8.59375 8.59375 10.3426 8.59375 12.5C8.59375 14.6574 10.3426 16.4062 12.5 16.4062Z"
        stroke="#333333"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.7969 3.51562H8.20312C5.61429 3.51562 3.51562 5.61429 3.51562 8.20312V16.7969C3.51562 19.3857 5.61429 21.4844 8.20312 21.4844H16.7969C19.3857 21.4844 21.4844 19.3857 21.4844 16.7969V8.20312C21.4844 5.61429 19.3857 3.51562 16.7969 3.51562Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5781 8.59375C18.2253 8.59375 18.75 8.06908 18.75 7.42188C18.75 6.77467 18.2253 6.25 17.5781 6.25C16.9309 6.25 16.4062 6.77467 16.4062 7.42188C16.4062 8.06908 16.9309 8.59375 17.5781 8.59375Z"
        fill="black"
      />
    </svg>
  );
}
