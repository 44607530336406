import React, { Component } from "react";
import { toast } from "react-toastify";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";
// import moment from "moment";
import ApiCall from "../../services/ApiCall";
import ApiMaps from "../../services/ApiMaps";
// import Loader from "../../components/Loader/Loader";
// import ImageGaleryVillas from "../../components/Slider/ImageGaleryVillas";
import MapsVillas from "../../components/MapsVilla/MapsVillas";
// import Fasilitas from "../../components/Fasilitas/Fasilitas";
// import Rules from "../../components/Rules/Rules";
// import BoxBooking from "../../components/BoxBooking/BoxBooking";
// import BoxBookingKosong from "../../components/BoxBooking/BoxBookingKosong";
// import Accessibility from "../../components/Accessibility/Accessibility";
// import Spoken from "../../components/Spoken/Spoken";
// import Similar from "../../components/Similar/Similar";
import queryString from "../../utils/queryString";
import {
  BASE_URL_ADM,
  PublicFacilities,
  PublicAttraction,
} from "../../constants";
import {
  toggleModal,
  showModal,
  setBoxFilterDetail,
} from "../../redux/actions";

class MapHotelDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: "id",
      loading: true,
      rooms: [],
      params: "",
      duration: "",
      arrival: "",
      guest: "",
      departure: "",
      hotels: {
        VillaLocations: [],
      },
      fiturs: [],
      fitursothers: [],
      viewMore: false,
      imgHotel: [],
      imgHotels: [],
      imgView: [],
      imgTampil: [],
      imgRoom: [],
      imgBackup: [],
      imgRespon: [],
      lat: 0,
      long: 0,
      masuk: "",
      keluar: "",
      city: "",
      substrid: "",
      substren: "",
      idn: "",
      eng: "",
      click: false,
      cover: "",
      clickAv: false,
      bookModal: false,
      scrollPosition: this.getWindowScrollTop(),
      scrollActive: false,
      priceTotal: "",
      hotelPrice: "",
      viewMoreModal: false,
      dateTanggal: "",
      totalMurni: "",
      bathroom: [],
      bedroom: [],
      exterior: [],
      dining: [],
      indoor: [],
      kitchen: [],
      lain: [],
      layout: [],
      living: [],
      lobi: [],
      outdoor: [],
      swimming: [],
      marker: [],
      markerFacilities: [],
      markerAttraction: [],
      booking: false,
      dataAvailable: [],
      zero: false,
      viilaRules: [],
      villaAkses: [],
      villaSpoken: [],
      konfigurasi: {},
      similar: [],
      villaFilter: false,
    };
    this.handleInterval = this.handleInterval.bind(this);
    this.handleRequestAnimationFrame = this.handleRequestAnimationFrame.bind(
      this
    );
  }

  componentWillMount() {
    const INTERVAL = 20;
    this.intervalID = setInterval(this.handleInterval, INTERVAL);
    this.setState({ loading: true });
    const paramsString = queryString.parse(this.props.location.search);
    // if (paramsString.villas === undefined || paramsString.villas === "") {
    //   setTimeout(() => {
    //     this.props.history.push("/");
    //   }, 1000);
    // } else {
    this.dataVillaNotFilter(paramsString);
    // }
    this.setState({
      loading: false,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.getScrollBox.bind(this));
    window.addEventListener("resize", this.getScrollBox.bind(this));

    window.onload = () => {
      const paramsString = queryString.parse(this.props.location.search);
      if (
        paramsString.arrival !== undefined ||
        paramsString.departure !== undefined
      ) {
        this.props.setBoxFilterDetail(true);
      } else {
        this.props.setBoxFilterDetail(false);
      }
      // window.onpopstate = (event, history) => {
      //   window.location.reload();
      // };
      //  window.onpopstate = (event, history) => {

      // window.location.reload()
      // }
    };
    this.getScrollBox();
  }

  componentDidUpdate(e, b) {
    const paramsString = queryString.parse(e.history.location.search);
    if (paramsString.duration !== undefined) {
      // this.setDuration(paramsString.duration);
    }
    // if (paramsString.arrival !== undefined || paramsString.departure !== undefined) {
    //         this.props.setBoxFilterDetail(true)
    //      } else {
    //         this.props.setBoxFilterDetail(false)
    //     }
  }

  getWindowScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  setDuration(_dur) {
    // this.state.duration = _dur;
    this.setState({
      duration: _dur,
    });
  }
  getNearBy(_location, _lat, _lang) {
    const arrData = [];
    if (_location && _location.length > 0) {
      for (let i = 0; i < _location.length; i++) {
        const _data = {
          search: _location[i],
          lat: _lat,
          lang: _lang,
        };
        ApiMaps.getNearByMapsOneData(_data, (res) => {
          if (res) {
            let result = res.results !== undefined ? res.results[0] : undefined;
            if (result !== undefined) {
              const name = result.name;
              const id = result.place_id;
              const __lat = result.geometry.location.lat;
              const __lng = result.geometry.location.lng;
              const _street = this.getDistanceFromLatLonInKm(
                _lat,
                _lang,
                __lat,
                __lng
              ).toFixed(3);
              const _jarak = Math.floor(_street * 1000);
              const _arrData = {
                index: id,
                label: name,
                lat: __lat,
                lng: __lng,
                street: _street,
                jarak: _jarak,
                lok: _location[i],
              };
              arrData.push(_arrData);
            }
          }
        });
      }
    }
    return arrData;
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const _lat1 = this.deg2rad(lat1);
    const _lat2 = this.deg2rad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(_lat1) *
        Math.cos(_lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  getScrollBox() {
    if (document.getElementById("boxBooking")) {
      const box = document.getElementById("boxBooking").getBoundingClientRect();
      if (box.bottom < 780) {
        const boks = document.getElementById("BoxDetailBooking");
        boks.style.position = "sticky";
        // boks.style.width = (box.width - 30) + 'px'
      } else if (box.bottom > 480) {
        const boks = document.getElementById("BoxDetailBooking");
        boks.style.position = "fixed";
        // boks.style.width = (box.width - 30) + 'px'
      }
    }
  }

  deg2rad(deg) {
    return (deg * Math.PI) / 180;
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal("toggleMenu");
    }
  };

  handleInterval() {
    cancelAnimationFrame(this.requestID);
    this.requestID = requestAnimationFrame(this.handleRequestAnimationFrame);
  }

  handleRequestAnimationFrame() {
    const { scrollPosition } = this.state;
    const newScrollPosition = this.getWindowScrollTop();
    if (newScrollPosition !== scrollPosition) {
      this.setState({
        scrollPosition: newScrollPosition,
      });
    }
  }

  dataVillaNotFilter = (paramsString) => {
    this.setState({ params: paramsString, zero: true });

    ApiCall.getDateAvailabelVilla(paramsString.villas)
      .then((response) => {
        this.setState({
          dataAvailable: response.data.data,
        });
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Koneksi internet bermasalah");
        }
        this.setState({ loading: false });
      });
    ApiCall.getRooms(paramsString)
      .then((response) => {
        // let checkGuest = "";
        // if (paramsString.guest === "" || paramsString.guest === "0") {
        //   checkGuest = 1;
        // } else if (
        //   Math.floor(paramsString.guest) >
        //   Math.floor(response.data.hotel.capacity)
        // ) {
        //   checkGuest = Math.floor(response.data.hotel.capacity);
        // } else {
        //   checkGuest = paramsString.guest;
        // }
        const _markerFacilities = this.getNearBy(
          PublicFacilities,
          response.data.hotel.lat,
          response.data.hotel.long
        );
        const _markerAttraction = this.getNearBy(
          PublicAttraction,
          response.data.hotel.lat,
          response.data.hotel.long
        );
        this.setState({
          markerFacilities: _markerFacilities,
          markerAttraction: _markerAttraction,
          hotels: response.data.hotel,
          hotelPrice: response.data.hotel.Price,
          city: response.data.hotel.Cities,
          fiturs: response.data.hotel.Fiturs,
          konfigurasi: response.data.hotel.konfigurasi,
          fitursothers: response.data.hotel.FitursOther,
          departure: paramsString.departure,
          arrival: paramsString.arrival,
          totalMurni: response.data.hotel.totalMurni,
          imgHotel: response.data.hotel.images.split(";"),
          imgTampil: response.data.hotel.images.split(";"),
          imgBackup: response.data.hotel.images.split(";"),
          imgHotels: response.data.hotel.images.split(";"),
          imgRespon: response.data.hotel.images.split(";"),
          bathroom: response.data.hotel.images2.bathroom,
          bedroom: response.data.hotel.images2.bedroom,
          exterior: response.data.hotel.images2.exterior,
          dining: response.data.hotel.images2.dining,
          indoor: response.data.hotel.images2.indoor,
          kitchen: response.data.hotel.images2.kitchen,
          lain: response.data.hotel.images2.lain_lain,
          layout: response.data.hotel.images2.layout,
          living: response.data.hotel.images2.living,
          lobi: response.data.hotel.images2.lobi,
          outdoor: response.data.hotel.images2.outdoor,
          swimming: response.data.hotel.images2.swimming,
          guest: "",
          villaAkses: response.data.hotel.villa_aksesibilitas,
          villaSpoken: response.data.hotel.spoken,
          keluar: "",
          loading: false,
          startDate: "",
          endDate: "",
          booking: false,
          villaRules: response.data.hotel.villa_rules,
        });
        ApiCall.getSimilarVilla(this.state.hotels.id).then((responseData) => {
          console.log(responseData.data.data, "woy 34");
          this.setState({
            similar: responseData.data.data,
          });
        });
        const gambar = this.state.imgHotel;
        const img = [];
        if (gambar.length > 3) {
          img.push(gambar[0]);
          img.push(gambar[1]);
          img.push(gambar[2]);
          this.setState({
            imgHotel: img,
          });
        } else {
          this.setState({ imgHotel: gambar });
        }
        const respon = this.state.imgHotel;
        const picture = [];
        if (respon.length > 2) {
          picture.push(respon[0]);
          picture.push(respon[1]);
          this.setState({ imgRespon: picture });
        } else {
          this.setState({ imgRespon: respon });
        }
        setTimeout(() => {
          const strid = response.data.hotel.description_id;
          const resid = strid.substring(0, 310);
          const stren = response.data.hotel.description_en;
          const resen = stren.substring(0, 310);
          this.setState({
            substrid: resid,
            substren: resen,
            idn: response.data.hotel.description_id,
            eng: response.data.hotel.description_en,
          });
          if (this.props.app.toggleMenu === true) {
            this.props.toggleModal("toggleMenu");
          }
        }, 1000);
        setTimeout(() => {
          this.setState({
            lat: this.state.hotels.lat,
            long: this.state.hotels.long,
          });
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Koneksi internet bermasalah");
        }
        this.setState({ loading: false });
      });
  };

  viewMore = (e, actions, img) => {
    if (img !== "") {
      const images = this.state.imgBackup;
      const dtImages = [];
      for (let i = 0; i < images.length; i++) {
        const arrIamges = {
          original: BASE_URL_ADM + "/images/hotel/" + images[i],
          thumbnail: BASE_URL_ADM + "/images/hotel/" + images[i],
        };
        dtImages.push(arrIamges);
      }
      this.setState({ imgView: dtImages });
    } else {
      this.setState({});
    }

    if (actions === "show") {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
  };

  viewMoreClosed = (e, actions) => {
    if (actions === "show") {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
  };

  bookModal = (e, action) => {
    if (action === "show") {
      this.setState({
        bookModal: true,
      });
    } else {
      this.setState({
        bookModal: false,
      });
    }
  };
  bookModalClosed = (e, action) => {
    if (action === "show") {
      this.setState({
        bookModal: true,
      });
    } else {
      this.setState({
        bookModal: false,
      });
    }
  };

  klik = () => {
    this.setState({
      click: !this.state.click,
    });
  };

  viewMoreClosedModal = (e, actions) => {
    if (actions === "show") {
      this.setState({ viewMoreModal: true });
    } else {
      this.setState({ viewMoreModal: false });
    }
  };

  klikImage = (e, gambar) => {
    const gambarTampilan = this.state.imgHotel;
    const img = [];
    if (gambar === gambarTampilan[0]) {
      img.push(gambarTampilan[1]);
      img.push(gambarTampilan[2]);
      img.push(gambarTampilan[0]);
    } else if (gambar === gambarTampilan[1]) {
      img.push(gambarTampilan[2]);
      img.push(gambarTampilan[0]);
      img.push(gambarTampilan[1]);
    } else if (gambar === gambarTampilan[2]) {
      img.push(gambarTampilan[0]);
      img.push(gambarTampilan[1]);
      img.push(gambarTampilan[2]);
    }
    this.setState({
      imgHotels: [gambar],
    });
    setTimeout(() => {
      this.setState({
        imgHotels: img,
      });
    }, 3000);
  };

  handleScroll = () => {
    const scrollPositionY = +window.scrollY;
    return this.setState({ scrollPositionY });
  };
  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle("show");
    popup.classList.toggle("lihat");
  };

  changeLang = (lang) => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  reservation = (e, id, minStay) => {
    const { t } = this.props;
    if (minStay > this.state.duration) {
      toast.info(t.minstayf + " " + minStay + " " + t.minstayb);
    } else {
      this.props.history.push(
        `/reservation-form?guest=${this.state.guest}&arrival=${this.state.params.arrival}&departure=${this.state.params.departure}&duration=${this.state.duration}&villas=${this.state.params.villas}`
      );
    }
    this.setState({
      loading: false,
    });
  };

  render() {
    // const { t } = this.props;
    // const { loading, hotels, marker, konfigurasi } = this.state;
    const { marker } = this.state;
    // const settingGalery = {
    //   data: this.state.imgHotel,
    //   dataChild: this.state.imgHotel,
    //   dataAll: this.state.imgTampil,
    //   bathroom: this.state.bathroom,
    //   bedroom: this.state.bedroom,
    //   exterior: this.state.exterior,
    //   dining: this.state.dining,
    //   indoor: this.state.indoor,
    //   kitchen: this.state.kitchen,
    //   lain: this.state.lain,
    //   layout: this.state.layout,
    //   living: this.state.living,
    //   lobi: this.state.lobi,
    //   outdoor: this.state.outdoor,
    //   swimming: this.state.swimming,
    //   dataMore: this.state.imgHotel[0],
    // };
    // const PropsBoxBooking = {
    //   history: this.props.history,
    //   onResize: this.getScrollBox(),
    // };
    // const PropsBoxBookingZero = {
    //   hotels: this.state.hotels,
    //   city: this.state.city,
    //   priceTotal: this.state.priceTotal,
    //   guest: this.state.guest,
    //   history: this.props.history,
    //   villas: this.state.params.villas,
    //   arrival: this.state.startDate,
    //   departure: this.state.endDate,
    //   duration: this.state.duration,
    //   dateTanggal: this.state.dateTanggal,
    //   totalMurni: this.state.totalMurni,
    //   masuk: this.state.params.arrival,
    //   keluar: this.state.params.departure,
    //   dataAvailable: this.state.dataAvailable,
    // };
    // const PropsFasilitas = {
    //   basic: this.state.fiturs,
    //   others: this.state.fitursothers,
    // };

    const setMaps = {
      nearbyFacilities: this.state.markerFacilities,
      nearbyAtraction: this.state.markerAttraction,
    };
    // const PropsvillaRules = {
    //   villaRules: this.state.villaRules,
    // };
    // const propsvillaAkses = {
    //   villaAkses: this.state.villaAkses,
    // };
    // const propsvillaSpoken = {
    //   villaSpoken: this.state.villaSpoken,
    // };
    // const propsSimilar = {
    //   hotels: this.state.hotels,
    //   city: this.state.city,
    //   priceTotal: this.state.priceTotal,
    //   guest: this.state.guest,
    //   history: this.props.history,
    //   villas: this.state.params.villas,
    //   arrival: this.state.startDate,
    //   departure: this.state.endDate,
    //   duration: this.state.duration,
    //   dateTanggal: this.state.dateTanggal,
    //   totalMurni: this.state.totalMurni,
    //   masuk: this.state.params.arrival,
    //   keluar: this.state.params.departure,
    //   dataAvailable: this.state.dataAvailable,
    //   similarP: this.state.similar,
    // };
    return (
      <MapsVillas
        onlyMap={true}
        {...setMaps}
        lat={parseFloat(this.state.lat)}
        long={parseFloat(this.state.long)}
        marker={marker}
        isMarkerShown
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  toggleModal,
  showModal,
  setBoxFilterDetail,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(MapHotelDetail));
