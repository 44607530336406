import React from 'react';

export default function LongStayIcon() {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 11.8596V5.89203C13 5.82581 12.9868 5.76033 12.9611 5.69978C12.9355 5.63923 12.8981 5.58494 12.8512 5.5404L8.30546 1.21984C8.22178 1.14031 8.11276 1.09625 7.99967 1.09625C7.88659 1.09626 7.77757 1.14033 7.6939 1.21986L3.14876 5.54041C3.1019 5.58495 3.06447 5.63923 3.03885 5.69978C3.01323 5.76032 3 5.8258 3 5.89201V11.8596"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11.8596H15"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.8595V7.94562H6V11.8595"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
