export default {
  t: {
    hello: 'Hello',
    sorry: 'Sorry',
    chooseYourHotel: 'Choose Your Desired Hotel',
    login: 'Login',
    loginOke: 'Login Successfully',
    logoutOke: 'Logout Successfully',
    register: 'Register',
    destination: 'Destination',
    guest: 'Guest(s)',
    guests: 'Guests',
    date: 'Date',
    duration: 'Duration',
    search: 'Search',
    rooms: 'Select Unit',
    around: 'Around',
    currency: 'IDR',
    facilities: 'Amenities',
    // book: 'Book Room',
    viewMore: 'view more',
    contact: 'Contact Name',
    idNumber: 'ID Number',
    phone: 'Phone Number',
    address: 'Address',
    guestName: 'Guest Name',
    guestToo: 'I am the guest',
    additional: 'Additional',
    roomType: 'Room Type',
    capacity: 'Capacity',
    people: 'Guest(s)',
    night: 'Night(s)',
    nigght: 'night',
    book: 'Book',
    reserv: 'Checkout',
    information: 'Your Information',
    informationGuest: 'Guest Information',
    detail: 'Booking Details',
    other: 'Others',
    room: 'Unit(s)',
    breakfast: 'Breakfast',
    promo: 'Input coupon code',
    usePromo: 'Apply',
    promoNotFound: 'Coupon code not found',
    promoExpire: 'Coupon code has expired',
    promoUse: 'Coupon code has already been used',
    haveAccount: 'Already have an account?',
    forgotPassword: 'Forgot your password?',
    dontHaveAccount: "Don't have an account?",
    or: 'or login with',
    sendMail: 'Email Confirmation',
    desEmail: 'We will send you a password reset token to your email',
    sendMailPassword: 'Send',
    emailNotRegister: 'Email not registered',
    resetPasswordToken: 'Reset password token sent to your email',
    newPassword: 'New Password',
    newPasswordRepeat: 'Confirm New Password',
    incorrectToken: 'Incorrect token',
    passwordOke: 'Password successfully changed',
    profile: 'Profile',
    myProfile: 'Your Profile',
    name: 'Name',
    editProfile: 'Edit',
    changePassword: 'Change Password',
    save: 'Save',
    avail: 'avail',
    desc: 'description',
    confirPass: 'New password does not match the confirm password',
    network: 'Network connection error',
    // notFound: "User not found",
    hotelNotFound: 'No available hotel found. Please change your search.',
    minDuration: 'Durations is at least 1 night',
    maxDuration: 'Durations limit is 90 nights!',
    registrasi:
      'Registration successful, please check your email for activation.',
    notHOtel: 'No available hotel found. Please change your search',
    available: 'Available',
    nightMin: 'Night Min',
    min: 'min',
    minstayf: '',
    minstayb:
      'night(s) minimum to make a reservation, please reset the duration of your stay.',
    note:
      'Note: Special requests may incur additional charges beyond the stated price. Addtional charges are handled directly between you and the hotel.',
    selectCity: 'Select City',
    alertCity: 'Please select city',
    alertGuest: 'Select the number of guests',
    alertDate: 'Select the check-in and check-out date',
    explore: 'Explore',
    pernight: '/night',
    average: '  This is the average price per night',
    prev: '<',
    next: '>',
    about: 'About Us',
    term: 'Terms & Conditions',
    privacyp: 'Privacy Policy',
    folow: 'Follow Us',
    customer: 'Contact Us',
    dates: 'Dates',
    passlogin: 'Password',
    nameregister: 'Full Name',
    nomorreggister: 'Phone Number',
    passregister: 'Password',
    konpassregister: 'Confirm Password',
    resetpass: 'Password Reset',
    resetpassbtn: 'Reset',
    login_valid1: 'User with email',
    login_valid2: 'has been registered with',
    login_valid3: 'account, please reset your password to login by email.',
    register_valid1: 'Email has been registered with',
    register_valid2: 'account',
    notwrong: 'Incorrect password confirmation',
    des_reset_pass:
      "Input your new password along with the token we've sent to your email.",
    pladates: 'Dates',
    incpassword: 'Incorrect username or password',
    inverifikasi:
      'Account has not been activated, please check your email to activate your account.',
    acchasbeen: 'Account has been registered',
    newPass: 'New Password',
    tokenrese: 'Reset token',
    noteSpecialRequest:
      'Note: Special requests are not guaranteed and might be inaccurate. Additional charges are handled directly between you and the hotel.',
    optionSpecialRequest: 'Special Request (Optional)',
    noteGuestName:
      'As in Passport/Official ID Card (without title/special characters)',
    stayDuration: 'Stay Duration',
    usePromoLabel: 'Coupon Code',
    placeholderRequest:
      "Do you have shuttle service for my arrival at the airport? If you do, it'll help me alot.",
    guestReservation: 'Guests',
    location: 'Location',
    inputNewPassword: 'Please input new password',
    inputConfrPassword: 'Please input confirmation password',
    inputToken: 'Please input reset token',
    confrPass: 'Password Confirmation',
    successedit: 'Profile successfully updated',
    nointernet: 'No internet connection',
    oldpass: 'Old Password',
    confirmpass: 'Confirm New Password',
    myaccount: 'My Account',
    accountset: 'Account Settings',
    mybook: 'My Booking',
    logout: 'Logout',
    incorrectpassword: 'Incorrect password',
    max: 'max',
    character: '/ 670 characters',
    register1: 'By registering, I agree with ',
    register2: ' and ',
    register3: ' of Nusalink.',
    about1:
      'Nusalink.asia aims to provide people help in booking public and private hotels in Indonesia while giving a combined experience of comfortable, clean, and cozy place with good service, competitive price and easy payment. Nusalink has connections and networks with hotels in beautiful places such as Bali, Lombok, Bandung, Tangerang, and all over Indonesia.',
    about2: 'Nusalink.asia was founded in 2017 by Nusantech (Solusi Teknologi Nusantara) which is a subsidiary of the Nusa Group and has been acquired by another subsidiary, namely PT Samala Kayana Jayasri, in April 2018. His business is focused on booking hotels and developing into E-commerce.<br/>' +
      '<br/>' +
      'Nusalink.asia can help Property Owners, whether hotels, boarding houses, apartments, villas, PO Buses, airlines, trains, Umrah agents, and so on, so they can be more productive, generate greater income.<br/>' +
      '<br/>' +
      'Nusalink.asia so far has succeeded in collaborating with property owners throughout Indonesia. Nusalink has also collaborated with business partners to achieve good and appropriate business development and synergy. The cooperation that has been established so far has always prioritized trust, quality and integrity.<br/>' +
      '<br/>' +
      'Nusalink.asia develops business features such as PPOB, Ewallet, Ticketing, and will continue to develop all existing business products, so that users only need 1 Nusalink.asia application to meet all their needs.',
    abouttitle: 'Nusalink helps you find the best hotel with the best price',
    notePhone:
      'e.g. +6282143658709 for Country Code +62 and Phone Number 082143658709',
    actbooking: 'Active Bookings',
    bookhistory: 'Booking History',
    cancel: 'Cancelled',
    messgePopMidtransLogin1: 'Reservation with Order ID',
    messgePopMidtransLogin2: 'has been successful.',
    messgePopMidtransLogin3:
      'Please check your email to see your reservation status.',
    messgePopMidtrans1: 'Reservation successful (Order ID :',
    messgePopMidtrans2: '), please check',
    messgePopMidtrans3: ' your email to see your reservation status.',
    pending: 'Booking Pending',
    paid: 'Booking Paid',
    bookcancel: 'Booking Cancelled',
    checkedout: 'Checked-out',
    orderid: 'Order ID',
    gdetail: 'Guest Details',
    totalguest: 'Guests',
    pdetail: 'Price Details',
    noactbook: 'No Active Bookings Found',
    nobookhist: 'No Booking History Found',
    expire: 'Expired',
    bookexpire: 'Booking Expired',
    download: 'Download Voucher',
    specreq: 'Special Request',
    notePromo: 'Have a discount coupon? Enter the code below.',
    coupon: 'Coupon',
    optPayments: 'Payment Option',
    payinfull: 'Pay in Full',
    payinfulldes:
      'You pay your booking in full up front. No additional fees will be charged upon your arrival at the hotel.',
    payatvilla: 'Pay at Hotel',
    payatvilladesk:
      'You pay your booking for 1 night up front. The rest will be charged upon your arrival at the hotel.',
    payatvilladesknote:
      'Note: You cannot choose this option if you only book for 1 night. Not all hotels are accepting this payment option.',
    paylessupfront: 'Pay Less Up Front',
    paylessupfrontdesk:
      'You pay your booking for 1 night up front. The rest will be charged upon your arrival at the hotel.',
    paylessupfrontdesknote:
      'Note: You cannot choose this option if you only book for 1 night.',
    payatvillades: 'The rest will be charged later at the hotel',
    paylessupfrontdes: 'The rest will be charged later at the hotel',
    paymentdetail: 'Payment Details',
    paymentoption: 'Payment Option',
    upfront: 'Up Front Fee',
    arrivalfee: 'Arrival Fee',
    payinfulldet: 'Pay in Full',
    payatvilladet: 'Pay at Hotel',
    lessupdet: 'Less Up Front',
    showall: 'Show all amenities',
    basic: 'Basic',
    savepromo: 'Save',
    specdeal: 'with our special deal!',
    findmore: 'Find out more',
    showallrules: 'Show all rules',
    explorervilla: 'Popular Hotels',
    showallvilla: 'Show all',
    resetfilter: 'Reset Filter',
    valiDate: 'Select the check-in and check-out date',
    validGuest: 'Select the number of guests',
    validMinNight1: '',
    validMinNight2: ' nights minimum to stay on the selected date range',
    konfBedroom: 'Bedroom(s)',
    konfBathroom: 'Bathroom(s)',
    konfBed: 'Bed(s)',
    konfFloor: 'Floor(s)',
    rulesBetween: 'Between',
    rulesAnd: 'and',
    rulesCancel: 'days before arrival',
    rulesNotAllowed: 'Not allowed',
    rulesAllowed: 'Allowed',
    rules: 'Rules',
    rulesAfter: 'After',
    rulesBefore: 'Before',
    rulesCancellaton: 'Cancellation',
    rulesNoRefund: 'No refund',
    rulesNotCancellaton: 'No cancellation',
    rulesPets: 'Pets',
    rulesSmoking: 'Smoking',
    rulesParties: 'Parties',
    accessibilitas: 'Accessibility',
    roadaccess: 'Road Access',
    bus: 'Bus',
    car: 'Car',
    bike: 'Bike',
    walk: 'Walk',
    villaccess: 'Hotel Access',
    whellchair: 'Wheelchair-friendly',
    sowmoreakses: 'Show more details',
    spokenlang: 'Spoken Language',
    tagsHOme: 'Our Hotels Selection',
    similar: 'Similar hotels in ',

    // home
    payAtVilla: 'Pay at hotel',
    remaining: 'remaining',
    startFrom: 'Start from',
    notAvailable: 'Not available',
    howToReserve: 'How To Reserve',
    reservationCheck: 'Reservation Check',
    paymentStatus: 'Payment Status',
    check: 'Check',
    reservation: 'Reservation',
    notFound: 'Not Found',
    upcomingEvents: 'Upcoming Events',
    bePartner: 'Be Partner',
    instruction: 'Instruction',
    arrivalHoursToProperty: 'Arrival hours to property',
    save2: 'Save',
    point: 'Point',
    promoLogin: 'Promo Login',
    month: 'Month',
    stayYearly: 'Stay Yearly',
    stayMonthly: 'Stay Monthly',
    stayWeekly: 'Stay Weekly',
    week: 'Week',
    year: 'Year',
    longStay: 'Long Stay',
    daily: 'Daily',
    cleanliness: 'Cleanliness',
    comfort: 'Comfort',
    food: 'Food',
    service: 'Service',
    enterReview: 'Enter Your Review',
    reviews: 'Reviews',
    noReviews: 'No Reviews',
    reply: 'Reply',
    promoNusalink: 'Nusalink Promo',
    paymentMethod: 'Payment Method',
    hourArrival: 'Hour Arrival',
    'Referral Code': 'Referral Code',
    Check: 'Check',
    required: 'required',
    'not found': 'not found',
    found: 'found',
    'My Referral Obtained': 'My referral obtained',
    'Tourist Attraction': 'Tourist Attraction',
    'Nearest Property': 'Nearest Property',
    'Top Up Credits': 'Top Up Credits',
    'Phone Number': 'Phone Number',
    'Active Period': 'Active Period',
    Day: 'Day',
    'Please insert phone number first': 'Please insert phone number first',
    'Top Up Data Packages': 'Top Up Data Packages',
    Credits: 'Credits',
    'Data Packages': 'Data Packages',
    'PLN Token': 'PLN Token',
    'PLN Number': 'PLN Number',
    'Bills & Top Up': 'Bills & Top Up',
    'Movie Ticket': 'Movie Ticket',
    'More Info': 'More Info'
  }
};
