import React from 'react';

export default function BePartnerIcon() {
  return (
    <svg
      width={18}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7107 6.01619L15.0936 6.98867L13 2.1666L14.6336 1.18423C14.7563 1.11042 14.8982 1.09753 15.0288 1.14834C15.1593 1.19915 15.2681 1.3096 15.3316 1.45589L16.9406 5.16179C16.9727 5.23589 16.9923 5.31696 16.9981 5.40026C17.004 5.48356 16.996 5.56743 16.9746 5.64696C16.9532 5.7265 16.9188 5.80012 16.8735 5.86351C16.8282 5.9269 16.7728 5.97881 16.7107 6.01619V6.01619Z"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.90644 6.98867L1.28932 6.0162C1.22715 5.97881 1.1718 5.92691 1.12649 5.86351C1.08118 5.80012 1.04682 5.7265 1.02542 5.64697C1.00402 5.56743 0.99601 5.48356 1.00185 5.40026C1.00769 5.31696 1.02727 5.23589 1.05944 5.16179L2.6684 1.45589C2.73192 1.3096 2.84066 1.19916 2.97121 1.14835C3.10176 1.09753 3.24368 1.11042 3.36642 1.18422L5 2.1666L2.90644 6.98867Z"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.07763L13.9091 8.63161L11.4002 11.6865C11.3336 11.7676 11.2509 11.8263 11.1601 11.857C11.0693 11.8876 10.9736 11.889 10.8822 11.8612L6.9306 10.6583C6.85648 10.6358 6.78708 10.5945 6.7269 10.5372L3 6.98865"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.96715L11.6095 6.01019L10.6232 6.59728C10.1964 6.85133 9.67728 6.98867 9.14375 6.98867C8.61023 6.98867 8.0911 6.85133 7.66428 6.59728L7.24658 6.34865C7.17592 6.30659 7.11748 6.25297 7.0752 6.19143C7.03293 6.12989 7.00781 6.06185 7.00155 5.99193C6.99529 5.92202 7.00803 5.85185 7.03892 5.78618C7.0698 5.72051 7.1181 5.66087 7.18055 5.61131L10.1961 3.21803C10.2533 3.1726 10.3213 3.13657 10.3961 3.11198C10.4709 3.08739 10.551 3.07474 10.632 3.07474H13.7671"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 2.94647L8.36798 1.15603C8.4883 1.09207 8.61718 1.1087 8.73136 1.2029L11 3.07473"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.838L5.30898 13.3271C5.19978 13.3064 5.09857 13.2664 5.01362 13.2103L3 11.8811"
        stroke="#F3722B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
