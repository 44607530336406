import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { connect } from 'react-redux';
import Accessibility from '../../components/Accessibility/Accessibility';
import Fasilitas from '../../components/Fasilitas/Fasilitas';
import PrimaryButton from '../../components/form/PrimaryButton';
import HotelReview from '../../components/HotelDetail/HotelReview';
import Star from '../../components/HotelDetail/Star';
import Loader from '../../components/Loader/Loader';
import MapsVillas from '../../components/MapsVilla/MapsVillas';
import NewBoxBookingKosong from '../../components/NewBoxBooking/NewBoxBookingKosong';
import PropertyTags from '../../components/PropertyTags/PropertyTags';
import Rules from '../../components/Rules/Rules';
import ImageGaleryVillas from '../../components/Slider/ImageGaleryVillas';
import Spoken from '../../components/Spoken/Spoken';
import { IS_SHOW_MAP } from '../../constants';
import ApiCall from '../../services/ApiCall';

export class StayMonthlyDetail extends Component {
  state = {
    loading: false,
    property: { hehe: null },
    konfigurasi: {},
    marker: {},
    similar: [],
    isMonthly: true,
    propertyId: null
  };

  componentDidMount() {
    let propertyId = null;
    if (this.props.isWeekly) {
      propertyId = this.props.propertyId;
      this.setState({ isMonthly: false });
    } else if (this.props.isYearly) {
      propertyId = this.props.propertyId;
      this.setState({ isMonthly: false });
    } else {
      propertyId = this.props.match.params.propertyId;
    }
    this.setState({
      propertyId
    });
    let type = 'monthly';
    if (this.props.isYearly) {
      type = 'yearly';
    } else if (this.props.isWeekly) {
      type = 'weekly';
    }
    ApiCall.getPropertyDetailStayMonthly(propertyId, false, type)
      .then(res => {
        this.setState({
          property: res.data.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { loading, property, marker, konfigurasi } = this.state;
    if (property.hehe === null) {
      return <div></div>;
    }
    const { t } = this.props;
    const { images2 } = property;
    const settingGalery = {
      data: property.images,
      dataChild: property.images,
      dataAll: property.images,
      bathroom: images2.bathroom,
      bedroom: images2.bedroom,
      exterior: images2.exterior,
      dining: images2.dining,
      indoor: images2.indoor,
      kitchen: images2.kitchen,
      lain: images2.lain_lain,
      layout: images2.layout,
      living: images2.living,
      lobi: images2.lobi,
      outdoor: images2.outdoor,
      swimming: images2.swimming,
      dataMore: property.images[0]
    };

    const PropsBoxBookingZero = {
      property,
      history: this.props.history
    };
    const PropsFasilitas = {
      basic: this.state.property.fiturs
        ? this.state.property.fiturs.basics
        : [],
      others: this.state.property.fiturs
        ? this.state.property.fiturs.others
        : []
    };

    const setMaps = {
      // nearbyFacilities: this.state.markerFacilities,
      // nearbyAtraction: this.state.markerAttraction,
    };
    const PropsvillaRules = {
      villaRules: this.state.property.rules
    };
    const propsvillaAkses = {
      villaAkses: this.state.property.aksesibilitas
    };
    const propsvillaSpoken = {
      villaSpoken: this.state.property.spoken ? this.state.property.spoken : []
    };

    return (
      <section
        className="section"
        style={{ paddingBottom: 0, marginBottom: -50 }}
        onClick={this.closeHeader}
      >
        <Loader loading={loading} />
        <div className="container">
          <div className="columns is-mobile is-multiline">
            <div className="column is-12 is-mobile name-responsive">
              <label className="labelTypeVilla180913">
                {property.categoryName}
              </label>
              <Star star={property.star} />
              <b>
                <p style={{ marginTop: 2 }}>{property.name}</p>
              </b>
            </div>
            <div className="column is-12 is-mobile name-responsive">
              <div className="columns is-multiline is-mobile kontenDescDetail180914">
                <div className="column">
                  <p className="labelAddressHeaderVilla180913">
                    {property.address}
                  </p>
                </div>
                <div className="column">
                  {(property.payAtVilla === 1 &&
                    !this.props.app.filterDetail) ||
                  this.state.duration === 1 ? (
                    <label
                      style={{ color: 'white' }}
                      className="has-text-centered is-pulled-right payvilla"
                    >
                      <p style={{ marginLeft: 18 }}>{t.payAtVilla}</p>
                      <figure
                        className="cottagepic"
                        style={{
                          marginLeft: 16,
                          height: 12,
                          width: 12
                        }}
                      >
                        <img src="/icon/cottage.png" alt="Nusalink" />
                      </figure>
                    </label>
                  ) : (
                    ''
                  )}
                  {(property.payAtVilla === 1 &&
                    this.props.app.filterDetail &&
                    this.state.duration > 1) ||
                  this.state.duration === 1 ? (
                    <label
                      style={{ color: 'white' }}
                      className="has-text-centered is-pulled-right payvilla"
                    >
                      <p style={{ marginLeft: 18 }}>{t.payAtVilla}</p>
                      <figure
                        className="cottagepic"
                        style={{
                          marginLeft: 16,
                          height: 12,
                          width: 12
                        }}
                      >
                        <img src="/icon/cottage.png" alt="Nusalink" />
                      </figure>
                    </label>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="columns boxKOnfigurasi">
            <div className="column is-8 galery">
              <div className="columns is-multiline is-mobile">
                {this.state.property.images && (
                  <div className="column is-12" style={{ paddingTop: 5 }}>
                    <ImageGaleryVillas
                      hotel={this.state.property}
                      availability={this.state.dataAvailable}
                      {...settingGalery}
                    />
                  </div>
                )}
                <div
                  className="column is-12"
                  style={{
                    paddingBottom: 10,
                    marginTop: -10
                  }}
                >
                  <div className="column-conf">
                    <img
                      src="/icon/maksimal-jumlah-orang.png"
                      width="20"
                      alt=""
                      style={{ marginBottom: -5 }}
                    />
                    <label className="labelKonfr">
                      {t.max === 'max'
                        ? property.capacity + ' guest(s) max'
                        : 'Maksimal ' + property.capacity + ' orang'}
                    </label>
                  </div>
                  <div className="column-conf">
                    <img
                      src="/icon/bedroomskonfi.png"
                      width="20"
                      alt=""
                      style={{ marginBottom: -5 }}
                    />
                    <label className="labelKonfr">
                      {konfigurasi === null ? '' : konfigurasi.bedroom}{' '}
                      {t.konfBedroom}
                    </label>
                  </div>
                  <div className="column-conf column-conf-bottom">
                    <img
                      src="/icon/bedkonf.png"
                      width="20"
                      alt=""
                      style={{ marginBottom: -5 }}
                    />
                    <label className="labelKonfr">
                      {konfigurasi === null ? '' : konfigurasi.bed} {t.konfBed}
                    </label>
                  </div>
                  <div className="column-conf column-conf-bottom">
                    <img
                      src="/icon/bathroomkonfi.png"
                      width="20"
                      alt=""
                      style={{ marginBottom: -5 }}
                    />
                    <label className="labelKonfr">
                      {konfigurasi === null ? '' : konfigurasi.bathroom}{' '}
                      {t.konfBathroom}
                    </label>
                  </div>
                </div>
                <div className="column is-12" style={{ marginTop: -5 }}>
                  {this.state.click === false ? (
                    <div>
                      <p
                        style={{
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        {this.props.app.lang === ''
                          ? this.state.substrid
                          : this.props.app.lang === 'id'
                          ? this.state.substrid
                          : this.state.substren}

                        {/* {this.state.idn > this.state.substrid ? (
                          <a
                            className="read-more"
                            onClick={
                              (e) => this.klik(e)
                            }
                          >
                            {' '}
                            Read More...{' '}
                          </a>
                        ) : (
                          ''
                        )} */}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        {this.props.app.lang === ''
                          ? this.state.idn
                          : this.props.app.lang === 'id'
                          ? this.state.idn
                          : this.state.eng}
                      </p>
                      {/* <a className="read-more" onClick={(e) => this.klik(e)}>
                        {' '}
                        Read Less{' '}
                      </a> */}
                    </div>
                  )}
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -5 }} />
                </div>
                <div
                  className="column is-12 labelFasilitas"
                  style={{ marginTop: -27 }}
                >
                  <b style={{ fontSize: 18 }}>{t.facilities}</b>
                </div>
                <div className="column is-12">
                  <Fasilitas {...PropsFasilitas} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b>
                    <p className="font-title">{t.location}</p>
                  </b>
                  <h6 className="font-label">{property.address}</h6>
                  <PrimaryButton
                    onClick={() => {
                      window.open(
                        'https://www.google.com/maps/search/?api=1&query=' +
                          property.lat +
                          ',' +
                          property.long
                      );
                    }}
                    label={
                      this.props.app.lang === 'en'
                        ? 'Open in google maps'
                        : 'Buka di google maps'
                    }
                  />
                  <div style={{ marginTop: 10 }}>
                    <PropertyTags tag_villa={property.tag_villa} />
                  </div>
                </div>
                {IS_SHOW_MAP && (
                  <div className="column is-12 mapJarak">
                    {this.state.lat !== 0 && (
                      <MapsVillas
                        {...setMaps}
                        lat={parseFloat(this.state.lat)}
                        long={parseFloat(this.state.long)}
                        marker={marker}
                        isMarkerShown
                      />
                    )}
                  </div>
                )}
                <div className="column is-12">
                  <hr style={{ marginTop: 0 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.rules}</b>
                </div>
                <div className="column is-12">
                  <Rules {...PropsvillaRules} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.spokenlang}</b>
                </div>
                <div className="column is-9 is-multiline">
                  <Spoken {...propsvillaSpoken} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.accessibilitas}</b>
                </div>
                {property.aksesibilitas && (
                  <div className="column is-12">
                    <Accessibility {...propsvillaAkses} />
                  </div>
                )}
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                {this.state.propertyId !== null && (
                  <HotelReview t={t} propertyId={this.state.propertyId} />
                )}
                {this.state.similar.length === 0 ? (
                  ''
                ) : (
                  <div className="column is-12">
                    <hr style={{ marginTop: -12 }} />
                  </div>
                )}
              </div>
            </div>
            <div
              className="column is-4"
              style={{
                marginTop: 12,
                zIndex: 1,
                marginBottom: 21
              }}
              id="boxBooking"
            >
              <NewBoxBookingKosong
                isYearly={this.props.isYearly}
                isMonthly={this.state.isMonthly}
                isWeekly={this.props.isWeekly}
                {...PropsBoxBookingZero}
              />
            </div>
          </div>
          {this.state.similar.length === 0 ? (
            ''
          ) : (
            <div className="columns is-multiline">
              <div
                className="column is-12 desktopsTeks"
                style={{ marginLeft: 4 }}
              >
                <b className="tablet" style={{ fontSize: 18 }}>
                  {' '}
                  {t.similar}{' '}
                  {this.state.property.VillaLocations.length
                    ? this.state.property.VillaLocations[0]['nama_kota']
                    : ''}
                </b>
              </div>
              <div className="column is-12 mobiles" style={{ marginTop: -82 }}>
                <b style={{ fontSize: 18 }}> {t.similar}</b>
              </div>
              <div className="column is-12 boxSlide" style={{ marginTop: -40 }}>
                {/* <Similar {...propsSimilar} /> */}
              </div>
            </div>
          )}

          {/*modal booking */}
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.viewMoreClosed(e, 'hidden')}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  // toggleModal,
  // showModal,
  // setBoxFilterDetail,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(StayMonthlyDetail));
