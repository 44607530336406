import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';
import OrangeBtn from '../Buttons/OrangeBtn';

export class TouristAttraction extends Component {
  state = {
    touristAttractions: []
  };

  constructor(props) {
    super(props);

    this.toPage = this.toPage.bind(this);
  }

  toPage(id) {
    this.props.history.push('/tourist-attractions/' + id);
  }

  componentDidMount() {
    ApiCall.getTouristAttractions(false, true)
      .then(response => {
        this.setState({
          touristAttractions: response.data.data
        });
        // console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    if (this.state.touristAttractions.length === 0) return null;
    const { t } = this.props;
    const { touristAttractions } = this.state;
    return (
      <section className="section2Home inner box-list  mt-30px">
        {/* DESKTOP */}
        <div className="container">
          <div className="columns">
            <div className="column is-12 kolomResponbodyM">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ marginBottom: 5 }}
              >
                <p
                  style={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: 24,
                    marginTop: -10
                  }}
                >
                  {t['Tourist Attraction']}
                </p>
                <OrangeBtn
                  label={t.showallvilla}
                  onClick={() => {
                    this.props.history.push('/tourist-attractions-all');
                  }}
                />
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            {touristAttractions.slice(0, 3).map(item => {
              if (item.images.length <= 0) return null;
              return (
                <div className="col-md-4" key={item.id}>
                  <div
                    className="mr-30px"
                    style={{
                      textTransform: 'capitalize',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                    key={item.id}
                    onClick={() => {
                      this.toPage(item.id);
                    }}
                  >
                    <img
                      src={
                        BASE_URL_ADM +
                        '/storage/tourist-attractions/' +
                        item.images[0]['image']
                      }
                      alt={item.code}
                      style={{
                        width: 300,
                        height: 200,
                        objectFit: 'cover',
                        borderRadius: 30,
                        marginBottom: 10
                      }}
                    />
                    <div
                      className="has-text-centered"
                      style={{ color: '#9B9B9B' }}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* DESKTOP */}
      </section>
    );
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(TouristAttraction);
