import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { toast } from 'react-toastify';
import BtnMoreInfo from '../../components/Buttons/BtnMoreInfo';
import NusaCafe2Icon from '../../components/Icons/NusaCafe2Icon';
import NusaCafe3Icon from '../../components/Icons/NusaCafe3Icon';
import NusaMinang2Icon from '../../components/Icons/NusaMinang2Icon';
import NusaMinang3Icon from '../../components/Icons/NusaMinang3Icon';
import Loader from '../../components/Loader/Loader';
import MenuItem from '../../components/Restaurant/MenuItem';
import { GRADIENT_BG } from '../../constants';
import ApiCall from '../../services/ApiCall';

export class RestaurantPage extends Component {
  state = {
    promoMenus: [],
    menusWithType: [],
    loading: true,
    restaurant: null
  };

  constructor(props) {
    super(props);

    this.goToDetail = this.goToDetail.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getData();
    }
  }

  getData() {
    this.setState({
      loading: true
    });
    const slug = this.props.match.params.slug;
    ApiCall.getRestaurantContent(slug === 'nusa-cafe' ? '2' : 1)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            menusWithType: response.data.data.product_types,
            promoMenus: response.data.data.recomended_products,
            restaurant: response.data.data.restaurant
          });
        }
      })
      .catch(error => {})
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  goToDetail(e, propertyId) {
    // console.log(e, propertyId);
    let url = '/stay-monthly/' + propertyId;
    if (this.props.isWeekly) {
      url = '/stay-weekly/' + propertyId;
    } else if (this.props.isYearly) {
      url = '/stay-yearly/' + propertyId;
    }
    this.props.history.push(url);
    // window.location = url;
  }

  render() {
    const { promoMenus, menusWithType } = this.state;
    const { t, lang } = this.props;
    const isEn = lang === 'en';
    const slug = this.props.match.params.slug;
    if (this.state.loading) {
      return <Loader key="loader" loading={this.state.loading} />;
    }
    return [
      <section
        key="header"
        style={{
          background: GRADIENT_BG,
          position: 'relative'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundImage: `url('/images/city4.png')`,
            backgroundSize: '40% 100%',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat'
          }}
        />

        <div className="container py-3">
          <div className="py-3 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              {this.props.match.params.slug === 'nusa-minang' ? (
                <NusaMinang2Icon />
              ) : (
                <NusaCafe2Icon />
              )}
              <div className="px-3 text-white" style={{ fontSize: 20 }}>
                |
              </div>
              <h1
                className=""
                style={{
                  fontSize: 36,
                  color: 'white'
                }}
              >
                {this.props.match.params.slug === 'nusa-minang' ? (
                  <span>
                    Nusa<strong className="text-white">Minang</strong>
                  </span>
                ) : (
                  <span>
                    Nusa<strong className="text-white">Cafe</strong>
                  </span>
                )}
              </h1>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className="d-flex align-items-center"
              onClick={() => {
                this.props.history.push(
                  slug === 'nusa-minang'
                    ? '/restaurants/nusa-cafe'
                    : '/restaurants/nusa-minang'
                );
              }}
            >
              {slug === 'nusa-minang' ? (
                <div className="d-flex align-items-center flex-column ">
                  <NusaCafe3Icon />
                  <div className="text-white">
                    <strong className="text-white">Nusa Cafe</strong>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center flex-column ">
                  <NusaMinang3Icon />
                  <div className="text-white">
                    <strong className="text-white">Nusa Minang</strong>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>,
      <section
        key="body"
        className="section2 inner box-list"
        style={{ backgroundColor: '#f8f8f8' }}
      >
        <div className="container">
          <div className="columns is-multiline long">
            <div className="column is-12">
              <h2 className="is-size-4 font-bold mb-2">
                <strong>
                  {isEn ? 'Interesting Promotions' : 'Promo Menarik'}
                </strong>
              </h2>
              <div
                className={promoMenus.length > 0 ? 'mb-5' : ''}
                style={{
                  backgroundColor: '#C4C4C4',
                  height: '250px',
                  width: '100%',
                  borderRadius: 20
                }}
              ></div>
            </div>
            {promoMenus.length > 0 &&
              promoMenus.slice(0, 3).map((item, index) => (
                <div className="column is-4" key={item.id}>
                  <MenuItem menu={item} history={this.props.history} />
                </div>
              ))}
            {!promoMenus && (
              <div className="column is-12">
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </div>
        </div>
      </section>,
      <section
        key="body-type"
        className="section2 inner box-list"
        style={{ backgroundColor: '#f8f8f8' }}
      >
        {menusWithType.map(item => {
          return (
            <div className="container mb-4" key={item.id}>
              <div className="columns is-multiline long">
                <div className="column is-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="is-size-4 font-bold mb-2">
                      <strong>
                        {isEn ? item.product_type : item.product_type}
                      </strong>
                    </h2>
                    {item.products.length > 0 && (
                      <BtnMoreInfo
                        onClick={() => {
                          this.props.history.push(
                            '/restaurants/type/' +
                              this.state.restaurant.id +
                              '/' +
                              item.id
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                {item.products.length > 0 &&
                  item.products.slice(0, 3).map((item, index) => (
                    <div className="column is-4" key={item.id}>
                      <MenuItem
                        variant="simple"
                        menu={item}
                        history={this.props.history}
                      />
                    </div>
                  ))}
                {!this.state.loading && item.products.length === 0 && (
                  <div className="column is-12">
                    <div className="has-text-centered">
                      <img
                        src={require('./../../assets/backgrounds/data_not_found.png')}
                        alt="Info Not Available"
                        width="500"
                        height="500"
                      />
                    </div>
                    <br />
                    <div className="d-flex justify-content-center align-items-center">
                      <h4 style={{ color: '#B0B0B0', fontSize: 28 }}>
                        {isEn ? 'Info not available' : 'Info tidak tersedia'}
                      </h4>
                    </div>
                  </div>
                )}
                {this.state.loading && (
                  <Loader key="loader" loading={this.state.loading} />
                )}
              </div>
            </div>
          );
        })}
      </section>
    ];
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(RestaurantPage);
