import React, { Component } from 'react';

class FormTextarea extends Component {
  render() {
    return (
      <div className="field">
        <div className="control">
          <label>{this.props.label}</label>
          <textarea
            className={' textarea'}
            name="extra"
            maxLength={670}
            onChange={this.props.onChange}
            type="text"
          />
        </div>
      </div>
    );
  }
}

export default FormTextarea;
