import React, { useEffect, useState } from 'react';
import ApiCall from '../../services/ApiCall';
import Reviews from '../Reviews/Reviews';
import Rating from './Rating';

export default function HotelReview({ propertyId, t }) {
  const [reviewsHehe, setReviewsHehe] = useState(null);

  function getReviews(propertyId) {
    ApiCall.getReviewByPropertyId(propertyId)
      .then((res) => {
        // console.log(res.data);
        setReviewsHehe(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getReviews(propertyId);
  }, [propertyId]);

  return (
    <div className="column is-12">
      <div className="columns is-multiline">
        {reviewsHehe !== null ? (
          <div className="column is-12" style={{ marginTop: -27 }}>
            <b style={{ fontSize: 18 }}>
              {t.reviews} ({reviewsHehe.total_data})
            </b>
            <div className="columns">
              <div className="column is-3">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '50%',
                      height: 100,
                      width: 100,
                      fontSize: 30,
                      border: '5px solid #f36603',
                      color: '#f36603',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {reviewsHehe.avg}
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <Rating
                  label={t.cleanliness}
                  value={reviewsHehe.cleanliness_avg}
                />
                <Rating label={t.comfort} value={reviewsHehe.comfort_avg} />
                <Rating label={t.food} value={reviewsHehe.food_avg} />
                <Rating label={t.location} value={reviewsHehe.location_avg} />
                <Rating label={t.service} value={reviewsHehe.service_avg} />
              </div>
              <div className="column is-3"></div>
            </div>
          </div>
        ) : (
          <div className="column is-12" style={{ marginTop: -27 }}>
            <b style={{ fontSize: 18 }}>{t.reviews}</b>
            <p style={{ marginTop: 10 }}>{t.noReviews}</p>
          </div>
        )}
        <div className="column is-12">
          {reviewsHehe !== null && (
            <Reviews data={reviewsHehe.data} reviews={reviewsHehe} />
          )}
        </div>
      </div>
    </div>
  );
}
