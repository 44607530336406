import React, { useEffect, useState } from 'react';
import $ from 'jquery';

function ContainerWithBg({ children, h1Title, h2Title }) {
  const [height, setHeight] = useState(50);

  useEffect(() => {
    $(() => {
      // alert($('#gambarHeaderDong').height());
      setHeight(Number($('#gambarHeaderDong').height()));
    });
  }, []);

  return (
    <section
      className="section sectionHehe"
      style={
        {
          // paddingTop: 0
          // paddingLeft: 0,
          // paddingRight: 0
        }
      }
    >
      <div style={{ position: 'relative', height }}>
        <img
          id={'gambarHeaderDong'}
          style={{ width: '100%', position: 'absolute' }}
          src={require('./../../assets/backgrounds/header.png')}
          alt="Background"
        />
        {h1Title && h2Title && (
          <div className="container">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height,
                flexDirection: 'column'
              }}
            >
              {h1Title && <h1 className="h1Title">{h1Title}</h1>}
              {h2Title && <h2 className="h2Title">{h2Title}</h2>}
            </div>
          </div>
        )}
      </div>

      <div className="container" style={{ marginTop: 20 }}>
        <div className="content" style={{ textAlign: 'justify' }}>
          {children}
        </div>
      </div>
    </section>
  );
}

export default ContainerWithBg;
