import React from 'react'

export default function SnackVideoIcon() {
  return (
    <svg
      version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="25" height="25"
      viewBox="0 0 100.000000 100.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none"
      >
        <path
          d="M383 862 c-148 -53 -228 -175 -178 -273 14 -26 14 -33 2 -57 -8 -15
-33 -62 -56 -105 -72 -137 -53 -154 131 -121 33 6 40 3 60 -21 68 -82 72 -85
138 -85 52 0 67 -4 93 -26 26 -22 37 -25 71 -19 86 13 161 78 214 185 24 49
27 66 27 155 0 88 -3 107 -27 157 -37 80 -107 152 -186 190 -83 41 -206 50
-289 20z m184 -195 c45 -17 118 -71 139 -102 16 -24 16 -29 1 -60 -19 -40
-101 -97 -164 -116 -36 -10 -46 -10 -68 5 -37 24 -47 60 -43 151 5 127 39 157
135 122z"
        />
      </g>
    </svg>

  )
}
