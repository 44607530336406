import React, { Component } from "react";

export default class Container extends Component {
  render() {
    return (
      <section className="section">
        <div className="container">
          <div className="content" style={{ textAlign: "justify" }}>
            {this.props.children}
          </div>
        </div>
      </section>
    );
  }
}
