import React, { Component } from "react";
import { connect } from "react-redux";
import { translatable } from "react-multilingual";
import Slider from "react-slick";
import { BASE_URL_ADM } from "../../constants.js";

import "./ImageGaleryVillas.css";

class ImageGaleryVillas extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      imgHotel: "",
      imgHotels: [],
      imgOthers: [],
      imgOthers2: [],
      imgHotelClick: [],
      jmlhArray: "",
      jmlhArrayBed: "",
      totalArray: "",
      imageWidth: "",
      statusKlik: "Exterior",
      imageKe: 1,
      nav1: null,
      nav2: null,
      viewMore: false,
      changeImage: [],
      changeCaption: "",
      klikJml: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
      },
      settings2: {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: false,
        pauseOnHover: true,
      },
    };
  }

  componentWillMount() {
    // const jmlhArray = imgVar.length
    // const imgVar2 = ['statis.jpg','statis.jpg','statis.jpg','statis.jpg','statis.jpg','statis.jpg','statis.jpg','statis.jpg']
    // const jmlhArray2 = imgVar2.length
    // const totalArr = imgVar.length + imgVar2.length

    this.setState({
      // jmlhArray: jmlhArray,
      // imgOthers2: imgVar2,
      // jmlhArray2: jmlhArray2,
      // totalArray: totalArr,
      // imageWidth: this.props.bathroom[1]
    });
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  viewMore = (e, actions, index) => {
    const btns = document.getElementsByClassName("zoom");
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        const current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
    if (actions === "show") {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
    const imgVar = this.props.exterior;
    this.setState({
      changeImage: imgVar[0][0],
      changeCaption: imgVar[0][1],
      jmlhArray: imgVar.length,
    });
  };
  imageClick = (e, gambar, deskripsi, index, status) => {
    const indexKe = index + 1;
    this.setState({
      changeImage: gambar,
      changeCaption: deskripsi,
      imageKe: indexKe,
      klikJml: true,
      statusKlik: status,
    });
  };

  viewMoreClosed = (e, actions) => {
    if (actions === "show") {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
  };

  render() {
    if (this.props.data.length <= 0) {
      return null;
    }
    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-12">
            <Slider
              {...this.state.settings}
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
            >
              <div>
                <img
                  alt=""
                  className="imgSliderHeader"
                  src={`${BASE_URL_ADM}/images/hotel/${this.props.data[0]}`}
                />
              </div>
              <div>
                <img
                  alt=""
                  className="imgSliderHeader"
                  src={`${BASE_URL_ADM}/images/hotel/${this.props.data[1]}`}
                />
              </div>
              <div>
                <img
                  alt=""
                  className="imgSliderHeader"
                  src={`${BASE_URL_ADM}/images/hotel/${this.props.data[2]}`}
                />
              </div>
            </Slider>
          </div>
          <div className="column is-12 columnImgChild">
            <div className="columns">
              <div className="column is-9 childIMages">
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={3}
                  swipeToSlide
                  focusOnSelect
                >
                  {this.props.dataChild.length > 0 &&
                    this.props.dataChild.map((items, index) => (
                      <div key={index}>
                        <img
                          alt=""
                          className="imagesChile"
                          src={`${BASE_URL_ADM}/images/hotel/${items}`}
                        />
                      </div>
                    ))}
                </Slider>
              </div>
              <div className="column is-3 childIMagesMore">
                <div>
                  <img
                    alt=""
                    className="imagesMore"
                    src={`${BASE_URL_ADM}/images/hotel/${this.props.dataMore}`}
                  />
                  <div
                    className="seeMore"
                    onClick={(e) => this.viewMore(e, "show")}
                  >
                    See all photos
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            style={{ textAlign: "right", width: "100%", marginTop: "-10px" }}
          >
            {this.props.hotel.allotment ? (
              this.props.hotel.allotment.remain <= 5 ? (
                <label style={{ fontSize: "12px" }} className=" text-danger">
                  {this.props.hotel.allotment.remain}
                  {" " + this.props.t.remaining}
                </label>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {/* <label
              style={{ fontSize: "12px", marginRight: "20px" }}
              className=" text-danger"
            >
              1 remaining
            </label> */}
          </div>
        </div>

        {/* poup modal view more foto*/}
        <div
          className={"modal  " + (this.state.viewMore ? "is-active" : "")}
          style={{ background: "rgba(80,80,80,0.8)" }}
        >
          <div
            className="modal-background"
            onClick={(e) => this.viewMoreClosed(e, "hidden")}
          />
          <div className="modal-background-image-galery">
            <div className="box-image">
              <div className="columns">
                <div className="column is-8 is-offset-1">
                  <Slider {...this.state.settings2} className="sliderAll">
                    <img
                      alt=""
                      className="imgSliderAll"
                      src={`${BASE_URL_ADM}/images/hotel/${this.state.changeImage}`}
                    />
                  </Slider>
                  <p className="fontImage is-pulled-left">
                    {" "}
                    {this.state.changeCaption}{" "}
                  </p>
                  <div>
                    {this.state.klikJml === false ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.state.jmlhArray}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "exterior" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.state.jmlhArray}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "lobi" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.lobi.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "bedroom" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.bedroom.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "bathroom" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.bathroom.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "living" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.living.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "dining" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.dining.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "kitchen" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.kitchen.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "swimming" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.swimming.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "indoor" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.indoor.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "outdoor" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.outdoor.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "layout" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.layout.length}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.statusKlik === "lain" ? (
                      <p className="fontImagePer is-pulled-right">
                        {this.state.imageKe}/{this.props.lain.length}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="columns is-multiline ">
                <div className="column is-8 is-offset-1 scrollImage scrool">
                  <div className="columns">
                    <div className="column is-12	">
                      <div className="columns">
                        <div className="column is-3 text-image has-text-centered">
                          {/* EXTERIOR */}
                          <p> Exterior ({this.props.exterior.length}) </p>
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        {this.props.exterior.length > 0 &&
                          this.props.exterior.map((items, index) => (
                            <div
                              className="column is-2 imageKategori "
                              key={index}
                            >
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className={index === 0 ? "zoom active" : "zoom"}
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "exterior"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* LOBI */}
                      <div className="columns">
                        <div className="column is-3 text-image has-text-centered">
                          <p> Lobby ({this.props.lobi.length}) </p>
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        {this.props.lobi.length > 0 &&
                          this.props.lobi.map((items, index) => (
                            <div
                              className="column is-2 imageKategori"
                              key={index}
                            >
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "lobi"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/*  BEDROOM*/}
                      <div className="columns">
                        <div className="column is-3 text-image has-text-centered">
                          <p> Bedroom ({this.props.bedroom.length}) </p>
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        {this.props.bedroom.length > 0 &&
                          this.props.bedroom.map((items, index) => (
                            <div
                              className="column is-2 imageKategori "
                              key={index}
                            >
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "bedroom"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* BATHROOM */}
                      {this.props.bathroom.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Bathroom ({this.props.bathroom.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.bathroom.length > 0 &&
                          this.props.bathroom.map((items, index) => (
                            <div
                              key={index}
                              className="column is-2 imageKategori"
                            >
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "living"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* LIVING ROOM */}
                      {this.props.living.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Living Room ({this.props.living.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.living.length > 0 &&
                          this.props.living.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "living"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* DINIGROOM */}
                      {this.props.dining.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Dining Room ({this.props.dining.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.dining.length > 0 &&
                          this.props.dining.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "dining"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/*  KITCHEN*/}
                      {this.props.kitchen.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Kitchen ({this.props.kitchen.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.kitchen.length > 0 &&
                          this.props.kitchen.map((items, index) => (
                            <div
                              className="column is-2 imageKategori"
                              key={index}
                            >
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "kitchen"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>

                      {/*  SWIMING*/}
                      {this.props.swimming.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Swimming ({this.props.swimming.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.swimming.length > 0 &&
                          this.props.swimming.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "swimming"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* INDOOR */}
                      {this.props.indoor.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Indoor ({this.props.indoor.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.indoor.length > 0 &&
                          this.props.indoor.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "indoor"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* OUTDOOR */}
                      {this.props.outdoor.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Outdoor ({this.props.outdoor.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.outdoor.length > 0 &&
                          this.props.outdoor.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "outdoor"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* LAYOUT */}
                      {this.props.layout.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Layout ({this.props.layout.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.layout.length > 0 &&
                          this.props.layout.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "layout"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* LAIN LAIN */}
                      {this.props.lain.length === 0 ? (
                        ""
                      ) : (
                        <div className="columns">
                          <div className="column is-3 text-image has-text-centered">
                            <p> Others ({this.props.lain.length}) </p>
                          </div>
                        </div>
                      )}
                      <div className="columns is-multiline is-mobile">
                        {this.props.lain.length > 0 &&
                          this.props.lain.map((items, index) => (
                            <div className="column is-2 imageKategori">
                              <img
                                alt=""
                                className="imageKecil"
                                src={`${BASE_URL_ADM}/images/hotel/${items[0]}`}
                              />
                              <div
                                className="zoom"
                                onClick={(e) =>
                                  this.imageClick(
                                    e,
                                    items[0],
                                    items[1],
                                    index,
                                    "lain"
                                  )
                                }
                                id="myDIV"
                              >
                                &nbsp;
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={(e) => this.viewMoreClosed(e, "hidden")}
            />
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(ImageGaleryVillas));
