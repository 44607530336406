export const TOGGLE_MODAL = 'toggle_modal'
export const SHOW_MODAL = 'show_modal'
export const SET_USER = 'set_user'
export const USER_LOGOUT = 'user_logout'
export const SET_LANG = 'set_lang'
export const SET_MAPS = 'set_maps'
export const SET_MESSAGE = 'set_messages'
export const SET_PAGES = 'set_pages'
export const SET_MESSAGE_MID = 'set_messages_mid'
export const SET_BOX_FILTER_DETAIL = 'set_box_filter_detail'
