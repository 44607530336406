import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';

class StartFrom extends Component {
  render() {
    const { price } = this.props;
    // console.log(price);
    if (price) {
      return (
        <div
          style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              width: 95,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
              fontSize: '12px',
              background: `linear-gradient(180deg, #F77E3C 19.05%, #FF9B64 138.1%)`,
              borderRadius: 8,
              padding: '2px 6px',
              color: 'white',
              borderRadius: '10px',
              marginRight: 5
            }}
          >
            <div style={{ marginRight: 5, marginTop: 4 }}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 3.9375V4.8125"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 9.1875V10.0625"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.6875 9.1875H7.65625C7.94633 9.1875 8.22453 9.07227 8.42965 8.86715C8.63477 8.66203 8.75 8.38383 8.75 8.09375C8.75 7.80367 8.63477 7.52547 8.42965 7.32035C8.22453 7.11523 7.94633 7 7.65625 7H6.34375C6.05367 7 5.77547 6.88477 5.57035 6.67965C5.36523 6.47453 5.25 6.19633 5.25 5.90625C5.25 5.61617 5.36523 5.33797 5.57035 5.13285C5.77547 4.92773 6.05367 4.8125 6.34375 4.8125H8.3125"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {this.props.t.startFrom}
          </div>
          {price}
        </div>
      );
    }
    return (
      <label
        style={{
          fontSize: '12px',
          color: 'white',
          backgroundColor: '#F16726',
          padding: '5px 10px',
          borderRadius: '12px',
          marginRight: '10px'
        }}
      >
        {this.props.t.startFrom}
      </label>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(StartFrom));
