import moment from 'moment';
import StringHelper from './StringHelper';

export function rupiah(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function moneyFormat(x, lang = 'id', withPrefix = false) {
  if (StringHelper.isEmpty(x)) {
    return '';
  }
  let result = '';
  let prefix = 'Rp ';
  if (lang === 'id') {
    result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  if (lang === 'en') {
    prefix = 'IDR ';
    result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (withPrefix) {
    return prefix + result;
  }
  return result;
}

export function getToday(format = 'YYYY-MM-DD') {
  return moment().format(format);
}

export function getTomorrow(format = 'YYYY-MM-DD') {
  return moment()
    .add(1, 'd')
    .format(format);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function ucwords(str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function($1) {
    return $1.toUpperCase();
  });
}

export function queryStringToJSON(qs = null) {
  qs = qs || window.location.search.slice(1);

  var pairs = qs.split('&');
  var result = {};
  pairs.forEach(function(p) {
    var pair = p.split('=');
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || '');

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

function kota(kota) {
  return ucwords(
    String(kota)
      // .replace('KABUPATEN', '')
      // .replace('KOTA', '')
      .toLowerCase()
  );
}

const Helper = {
  capitalize,
  kota,
  getToday,
  getTomorrow,
  dateFormat(date, format = 'YYYY-MM-DD') {
    return moment(date).format(format);
  },
  ucwords
};

export default Helper;
