import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import { logout, showModal, setUser, toggleModal } from '../../redux/actions';
import { dateEN, dateIN } from '../../constants';

export function Location({ t, items }) {
  return (
    <div>
      <p style={{ fontSize: 13, color: '#3b3b3b' }}>{t.location}</p>
      <p style={{ fontSize: 13, color: '#3b3b3b' }}>
        <b>{items.villaCity || items.property.location.nama_kota}</b>
      </p>
    </div>
  );
}

export function CheckIN({ app, items }) {
  const checkIn = items.checkIn || items.check_in;
  return (
    <div>
      <p style={{ fontSize: 13, color: '#3b3b3b' }}>Check-in</p>
      <p style={{ fontSize: 13, color: '#3b3b3b' }}>
        <b>
          {checkIn === ''
            ? ''
            : moment(checkIn).format('D') +
              ' ' +
              (app.lang === '' || app.lang === 'id'
                ? dateIN[moment(checkIn).format('M') - 1]
                : dateEN[moment(checkIn).format('M') - 1]) +
              ' ' +
              moment(checkIn).format('Y')}
        </b>
      </p>
    </div>
  );
}

export function ImageStatus({ paymentStatus, t }) {
  let imgSrc = '/icon/15.1. Expired Pembayaran.png';
  let status = t.expire;
  if (paymentStatus === 'pending') {
    imgSrc = '/icon/11.3. Pembayaran Pending.png';
    status = t.pending;
  }
  return (
    <div className="columns circles is-mobile">
      <div className="column is-4">
        <figure className="image icon">
          <img src={imgSrc} alt={status} />
        </figure>
      </div>
      <div className="column is-8" style={{ paddingTop: 15 }}>
        <p style={{ fontSize: 12, color: '#3b3b3b' }}>{status}</p>
      </div>
    </div>
  );
}

export function Icon({ status, t }) {
  let imgSrc = '/icon/15.1. Expired Pembayaran.png';
  if (status === 'Pending') {
    imgSrc = '/icon/11.3. Pembayaran Pending.png';
    status = t.pending;
  } else if (status === 'Canceled') {
    imgSrc = '/icon/14.3. cancel.png';
    status = t.canceled;
  } else if (status === 'Settlement') {
    imgSrc = '/icon/12.3. Pembayaran Selesai.png';
    status = t.settlement;
  } else if (status === 'Check-Out') {
    imgSrc = '/icon/13.3. check out.png';
    status = 'Check-Out';
  } else if (status === 'Expired') {
    imgSrc = '/icon/15.1. Expired Pembayaran.png';
    status = t.expire;
  }
  return (
    <div className="columns circles is-mobile">
      <div className="column is-4">
        <figure className="image icon">
          <img src={imgSrc} alt={'Nusalink ' + status} />
        </figure>
      </div>
      <div className="column is-8" style={{ paddingTop: 15 }}>
        <p style={{ fontSize: 12, color: '#3b3b3b' }}>{status}</p>
      </div>
    </div>
  );
}

class BookingHistory extends Component {
  render() {
    const { t, data } = this.props;
    const canceledIcon = (
      <div className="columns circles is-mobile">
        <div className="column is-4">
          <figure className="image icon">
            <img src="/icon/14.3. cancel.png" alt="Nusalink" />
          </figure>
        </div>
        <div className="column is-8" style={{ paddingTop: 15 }}>
          <p style={{ fontSize: 12, color: '#3b3b3b' }}>{t.cancel}</p>
        </div>
      </div>
    );
    return (
      <div>
        <h3 className="title" style={{ fontSize: 18 }}>
          {t.bookhistory}
        </h3>
        <hr style={{ marginTop: -10 }} />
        {data.length > 0 &&
          data.map((items, index) => {
            let to = `/detail-booking?id=${items.reservationId}`;
            if (items.transaction) {
              to = `/detail-booking?id=${items.id}&isMonthly=true`;
            }
            return (
              <div key={index}>
                <Link to={to}>
                  <div
                    className="columns is-mobile item"
                    style={{
                      marginBottom: 15,
                      paddingLeft: 25,
                      marginLeft: -35,
                      marginRight: -35,
                      paddingRight: 25
                    }}
                  >
                    {items.paymentStatus && (
                      <div className="column is-5">
                        <p className="villaname" style={{ color: '#3b3b3b' }}>
                          <b>{items.villaName || items.room_name}</b>
                        </p>
                        <p style={{ color: 'black', fontSize: 10 }}>
                          Order ID : {items.order_id}
                        </p>

                        <ImageStatus
                          t={t}
                          paymentStatus={items.paymentStatus}
                        />
                        {items.status === 3 ||
                          (items.status === 6 && canceledIcon)}

                        {items.status === 4 && (
                          <div className="columns circles is-mobile">
                            <div className="column is-4">
                              <figure className="image icon">
                                <img
                                  src="/icon/11.3. Pembayaran Pending.png"
                                  alt="Nusalink"
                                />
                              </figure>
                            </div>
                            <div
                              className="column is-4"
                              style={{ paddingLeft: 13.5 }}
                            >
                              <figure className="image icon">
                                <img
                                  src="/icon/12.3. Pembayaran Selesai.png"
                                  alt="Nusalink"
                                />
                              </figure>
                            </div>
                            <div className="column is-4">
                              <figure className="image icon">
                                <img
                                  src="/icon/13.3. check out.png"
                                  alt="Nusalink"
                                />
                              </figure>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {items.transaction && (
                      <div className="column is-5">
                        <p className="villaname" style={{ color: '#3b3b3b' }}>
                          <b>{items.villaName || items.room_name}</b>
                        </p>
                        <p style={{ color: 'black', fontSize: 10 }}>
                          Order ID : {items.order_id}
                        </p>
                        <Icon status={items.transaction.status} t={t} />
                      </div>
                    )}

                    <div className="column is-4 loccheck">
                      <Location items={items} t={t} />
                    </div>
                    <div className="column is-3 loccheck">
                      <CheckIN app={this.props.app} items={items} />
                    </div>
                  </div>
                  <hr className="linemobile2" style={{ marginTop: -15 }} />
                </Link>
              </div>
            );
          })}
        {data.length === 0 && (
          <center>
            <p style={{ fontSize: 16 }} className="nobookfound">
              {t.nobookhist}
            </p>
          </center>
        )}
        <hr style={{ marginTop: 0 }} className="linemobile" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};
const mapTranslationsToProps = ({ t }) => ({ t });
const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(BookingHistory));
