const StringHelper = {
  ucwords(str) {
    return (str + '')
      .toLowerCase()
      .replace(/^([a-z])|\s+([a-z])/g, function($1) {
        return $1.toUpperCase();
      });
  },

  isFilled(value) {
    if (typeof value === 'undefined') return false;
    if (value === null) {
      return false;
    }
    if (String(value).trim() === '') return false;
    return true;
  },

  isEmpty(value) {
    return !this.isFilled(value);
  }
};

export default StringHelper;
