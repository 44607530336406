import React, { Component } from "react";
import { compose, withProps, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";
import "./MapsVillas.css";
import { GOOGLE_MAP_API_KEY } from "./../../constants.js";

const MyMapComponent = compose(
  withProps({
    // one terdekat https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=money+changer&inputtype=textquery&fields=formatted_address,name,geometry&locationbias=point:-6.1649172,106.7778225&key=AIzaSyCDS72zHXRjAwUu6USWzyQWVhowcISqGqA
    // all https://maps.googleapis.com/maps/api/place/textsearch/json?location=-6.1649172,106.7778225&radius=50000&query=day+care&key=AIzaSyCDS72zHXRjAwUu6USWzyQWVhowcISqGqA
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "600px" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withStateHandlers(
    () => ({
      isOPen: false,
    }),
    {
      onToggleOpen: ({ isOPen }) => () => ({
        isOPen: !isOPen,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={16}
    center={{ lat: parseFloat(props.lat), lng: parseFloat(props.long) }}
    defaultCenter={{
      lat: parseFloat(props.lat),
      lng: parseFloat(props.long),
    }}
  >
    <Marker
      position={{
        lat: parseFloat(props.lat),
        lng: parseFloat(props.long),
      }}
      icon="/icon/pin nusalink.png"
    />
    {props.checkFacilities &&
      props.nearbyFacilities.map((items, index) =>
        index < 5 ? (
          <Marker
            key={index}
            position={{
              lat: parseFloat(items.lat),
              lng: parseFloat(items.lng),
            }}
            icon={`/icon/pin-fasilitas-${index}.png`}
            // onClick={() => props.onClickFac(index)}
          >
            {props.modalFac[index] ? (
              <InfoBox
                // onCloseClick={props.onToggleOpen}
                options={{
                  closeBoxURL: "",
                  enableEventPropagation: true,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#20BED6",
                    opacity: 0.75,
                    padding: "12px",
                    width: 200,
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {items.label}
                  </div>
                </div>
              </InfoBox>
            ) : (
              ""
            )}
          </Marker>
        ) : (
          ""
        )
      )}
    {props.checkAttraction &&
      props.nearbyAtraction.map((items, index) =>
        index < 5 ? (
          <Marker
            key={index}
            position={{
              lat: parseFloat(items.lat),
              lng: parseFloat(items.lng),
            }}
            icon={`/icon/pin-attraction-${index}.png`}
            // onClick={() => props.onClickAtt(index)}
          >
            {props.modalAtt[index] ? (
              <InfoBox
                // onCloseClick={props.onToggleOpen}
                options={{
                  closeBoxURL: "",
                  enableEventPropagation: true,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#D2B44F",
                    opacity: 0.75,
                    padding: "12px",
                    width: 200,
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {items.label}
                  </div>
                </div>
              </InfoBox>
            ) : (
              ""
            )}
          </Marker>
        ) : (
          ""
        )
      )}
  </GoogleMap>
));

class MapsVillas extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      nearbyFacilities: props.nearbyFacilities.sort((a, b) => {
        return a.jarak - b.jarak;
      }),
      nearbyAtraction: props.nearbyAtraction.sort((a, b) => {
        return a.jarak - b.jarak;
      }),
      checkFacilities: true,
      checkAttraction: true,
      isMarkerShown: true,
      modalFac: [false, false, false, false, false],
      modalAtt: [false, false, false, false, false],
    };
  }

  componentWillMount() {}

  chengeFacilites = () => {
    this.setState({
      checkFacilities: !this.state.checkFacilities,
    });
  };

  togglePinMaps = (index) => {
    alert(index);
  };

  chengeAttraction = () => {
    this.setState({
      checkAttraction: !this.state.checkAttraction,
    });
  };

  showLabelFac = (e) => {
    this.setState({ modalAtt: [false, false, false, false, false] });
    const label = this.state.modalFac[e];
    if (e === 0 && label === false) {
      this.setState({ modalFac: [true, false, false, false, false] });
    } else if (e === 1 && label === false) {
      this.setState({ modalFac: [false, true, false, false, false] });
    } else if (e === 2 && label === false) {
      this.setState({ modalFac: [false, false, true, false, false] });
    } else if (e === 3 && label === false) {
      this.setState({ modalFac: [false, false, false, true, false] });
    } else if (e === 4 && label === false) {
      this.setState({ modalFac: [false, false, false, false, true] });
    } else {
      this.setState({ modalFac: [false, false, false, false, false] });
    }
  };

  showLabelAtt = (e) => {
    this.setState({ modalFac: [false, false, false, false, false] });
    const label = this.state.modalAtt[e];
    if (e === 0 && label === false) {
      this.setState({ modalAtt: [true, false, false, false, false] });
    } else if (e === 1 && label === false) {
      this.setState({ modalAtt: [false, true, false, false, false] });
    } else if (e === 2 && label === false) {
      this.setState({ modalAtt: [false, false, true, false, false] });
    } else if (e === 3 && label === false) {
      this.setState({ modalAtt: [false, false, false, true, false] });
    } else if (e === 4 && label === false) {
      this.setState({ modalAtt: [false, false, false, false, true] });
    } else {
      this.setState({ modalAtt: [false, false, false, false, false] });
    }
  };

  render() {
    if (this.props.onlyMap) {
      return (
        <MyMapComponent
          isMarkerShown
          onClickFac={this.showLabelFac}
          onClickAtt={this.showLabelAtt}
          modalFac={this.state.modalFac}
          modalAtt={this.state.modalAtt}
          lat={parseFloat(this.props.lat)}
          long={parseFloat(this.props.long)}
          nearbyFacilities={this.state.nearbyFacilities}
          nearbyAtraction={this.state.nearbyAtraction}
          checkFacilities={this.state.checkFacilities}
          checkAttraction={this.state.checkAttraction}
        />
      );
    }
    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-12">
            <MyMapComponent
              isMarkerShown
              onClickFac={this.showLabelFac}
              onClickAtt={this.showLabelAtt}
              modalFac={this.state.modalFac}
              modalAtt={this.state.modalAtt}
              lat={parseFloat(this.props.lat)}
              long={parseFloat(this.props.long)}
              nearbyFacilities={this.state.nearbyFacilities}
              nearbyAtraction={this.state.nearbyAtraction}
              checkFacilities={this.state.checkFacilities}
              checkAttraction={this.state.checkAttraction}
            />
          </div>
          {this.props.onlyMap === undefined && (
            <div className="column">
              <h2>
                <b>Getting Around</b>
              </h2>
            </div>
          )}
        </div>
        {this.props.onlyMap === undefined && (
          <div className="columns is-multiline">
            <div className="column">
              <div className="columns is-multiline">
                <div className="column is-12 facilitiesmap">
                  <div className="iconSelectFacilities">
                    <input
                      type="checkbox"
                      name="facilities"
                      id="facilities"
                      onChange={() => {}}
                      checked={this.state.checkFacilities}
                    />
                    <div
                      className="selectFacilites"
                      onClick={this.chengeFacilites}
                    />
                  </div>
                  <label>Public Facilities </label>
                </div>
                {this.props.nearbyFacilities &&
                  this.state.nearbyFacilities.map((items, index) =>
                    index < 5 ? (
                      <div className="column is-12 listFacilities">
                        <img alt="" src={`/icon/pin-fasilitas-${index}.png`} />
                        {items.label}
                        <div className="batasJarak" />
                        <label className="kilometermap">
                          ({items.street} km)
                        </label>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </div>
            <div className="column">
              <div className="columns is-multiline">
                <div className="column is-12 facilitiesmap">
                  <div className="iconSelectFacilities">
                    <input
                      defaultChecked={this.state.checkAttraction}
                      type="checkbox"
                      name="att"
                      id="att"
                    />
                    <div
                      className="selectFacilites"
                      onClick={this.chengeAttraction}
                    />
                  </div>
                  <label>Attraction</label>
                </div>
                {this.props.nearbyAtraction &&
                  this.state.nearbyAtraction.map((items, index) =>
                    index < 5 ? (
                      <div className="column is-12 listFacilities">
                        <img alt="" src={`/icon/pin-attraction-${index}.png`} />
                        {items.label}
                        <div className="batasJarak" />
                        <label className="kilometermap">
                          ({items.street} km)
                        </label>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(MapsVillas));
