import React, { Component } from 'react';
import StayMonthlyReservationForm from './StayMonthlyReservationForm';

class StayYearlyReservationForm extends Component {
  state = {};

  render() {
    return <StayMonthlyReservationForm isYearly history={this.props.history} />;
  }
}

export default StayYearlyReservationForm;
