import React, { Component } from 'react';
import Slider from 'react-slick';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import StartFrom from '../../layouts/StartFrom';
import LabelPrimary from '../../../components/others/LabelPrimary';
import PayAtProperty from '../../../components/others/PayAtProperty';

class RoomList extends Component {
  constructor(props) {
    super(props);

    this.setRoom.bind(this);
  }

  setRoom(room) {
    // if (room.unit === null || room.unit <= 0) {
    //   alert('allotment not available')
    // } else {
    this.props.setRoom(room);
    // }
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      initialSlide: 0
    };

    const { t } = this.props;
    const hotel = this.props.hotel;
    // const hotel = null;
    // console.log("hotel", hotel);
    // console.log("rooms", this.props.rooms);
    if (hotel)
      if (hotel.VillaLocations)
        return this.props.rooms.map((item, index) => (
          <div className="column is-6" key={item.id}>
            <div
              className="box boxheight"
              style={{ padding: 0, borderRadius: 4, paddingBottom: 10 }}
            >
              <div className="columns" style={{ marginBottom: -15 }}>
                <div className="column is-12" style={{ paddingTop: 0 }}>
                  {hotel.payAtVilla === 1 && <PayAtProperty />}
                  <Slider {...settings}>
                    {item.images.length > 0 &&
                      item.images.map((i, ii) => (
                        <div
                          onClick={() => {
                            this.setRoom(item);
                          }}
                          className="slide-item"
                          style={{ marginTop: -15 }}
                          key={item.id + ii}
                        >
                          <figure image="true" is-4by3="true">
                            <img
                              className="images-galery imgVillasList"
                              src={`${i.image_url}`}
                              alt={item.name + ' ' + ii}
                            />
                          </figure>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
              <div className="columns kolomkotanama">
                <div className="column is-12">
                  <label className="labelTypeVilla180913">
                    {hotel.category_name}
                  </label>
                  <br />
                  <b
                    onClick={() => {
                      this.setRoom(item);
                    }}
                    className="kolomnama"
                    style={{ color: 'black', fontSize: 18 }}
                  >
                    {item.name}
                  </b>
                </div>
              </div>
              <div
                className="columns"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <div className="column is-12 kolomharga">
                  <span style={{ fontSize: 14, color: 'black' }}>
                    <div>
                      <label>
                        <StartFrom />

                        {item.is_promo ? (
                          <span>
                            <strike>
                              {t.currency}{' '}
                              {Numeral(
                                Math.floor(
                                  (item.roomRate *
                                    (100 - this.props.hotel.fee_percent)) /
                                    100
                                )
                              ).format('0,0')}
                              {t.pernight}
                            </strike>
                            <br />
                            <div style={{ marginTop: 10 }}>
                              {t.currency}{' '}
                              {Numeral(
                                Math.floor(
                                  (item.promo_price *
                                    (100 - this.props.hotel.fee_percent)) /
                                    100
                                )
                              ).format('0,0')}
                              {t.pernight}
                              <div style={{ marginTop: 10 }}>
                                <LabelPrimary
                                  fontSize={12}
                                  rounded
                                  style={{ marginRight: 5 }}
                                  text={
                                    t.save2 +
                                    ' ' +
                                    item.promo.promo_percent +
                                    '%'
                                  }
                                />
                                <LabelPrimary
                                  fontSize={12}
                                  rounded
                                  text={item.promo.promo_name}
                                />
                              </div>
                            </div>
                          </span>
                        ) : (
                          <span>
                            {t.currency}{' '}
                            {Numeral(
                              Math.floor(
                                (item.roomRate *
                                  (100 - this.props.hotel.fee_percent)) /
                                  100
                              )
                            ).format('0,0')}
                            {t.pernight}
                          </span>
                        )}

                        <br />
                        {item.unit === null ||
                          (Number(item.unit) <= 5 &&
                            (item.unit === null || Number(item.unit) <= 0 ? (
                              <span style={{ color: 'red' }}>
                                {t.notAvailable}
                              </span>
                            ) : (
                              <span style={{ color: 'red' }}>
                                {item.unit} {t.remaining}
                              </span>
                            )))}
                      </label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ));
    return <div></div>;
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(RoomList));
