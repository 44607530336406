import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import { Link } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/rrui.css'
// import 'react-phone-number-input/style.css'

import ApiCall from '../services/ApiCall';
import { logout, setUser, toggleModal, setMessage } from '../redux/actions';

class ProfileEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: props.app.user.password,
      name: '',
      email: '',
      idUser: '',
      data: {},
      loading: false,
      lang: 'id',
      log: '',
      direct: false,
      code: '',
      phone: '',
      statusRegister: '',
      nameGuest: '',
      phoneGuest: '',
      phoneCodeGuest: ''
    };
  }

  componentWillMount() {
    setTimeout(() => {
      if (this.props.app.user === '') {
        window.location.href = '/';
      }
      this.setState({
        data: this.props.app.user,
        nameGuest: this.props.app.user.name,
        phoneGuest: this.props.app.user.phone,
        phoneCodeGuest:
          this.props.app.user.code === '' ? '+62' : this.props.app.user.code,
        code: this.props.app.user.code === '' ? '+62' : this.props.app.user.code
      });
    }, 1000);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (
        this.props.app.user === '' ||
        this.props.app.user.name === '' ||
        this.props.app.user.name === undefined
      ) {
        window.location.href = '/';
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
    }, 1000);
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    // const newData = this.state.data

    // newData[name] = value

    this.setState({ [name]: value });
  };

  editProfile = e => {
    e.preventDefault();
    this.setState({ loading: true });
    const KodeTelp =
      this.state.phoneCodeGuest === '' ||
      this.state.phoneCodeGuest === undefined
        ? '+62'
        : this.state.phoneCodeGuest;
    const data = {
      name: this.state.nameGuest,
      idNumber: this.state.data.idNumber,
      email: this.state.data.email,
      code: KodeTelp,
      phone: this.state.phoneGuest,
      telp: KodeTelp + '-' + this.state.phoneGuest,
      address: this.state.data.address,
      password: this.state.data.password,
      id: this.props.app.user.id,
      statusRegister: this.props.app.user.statusRegister
    };
    ApiCall.putUser(data)
      .then(response => {
        this.setState({ loading: false });
        this.props.setMessage(this.props.t.successedit);
        this.props.toggleModal('popupmessage');
        this.props.setUser(data);
        this.setState({ direct: true });
        this.props.history.push('/profile');
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(this.props.t.nointernet);
        }
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  logout = () => {
    const pathname = window.location.pathname;
    setTimeout(() => {
      if (pathname === '/profile' || pathname === '/profile/edit') {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }, 500);
  };

  changeCodePhone = (status, value, countryData, number, id) => {
    this.setState({ phoneCodeGuest: value });
  };

  render() {
    const { loading, nameGuest, phoneGuest } = this.state;
    const { t } = this.props;

    // if (this.state.direct) {
    // 	return (
    // 		<Redirect to={{pathname: '/profile/' + this.props.app.user.name, state: { from: this.props.location }}} />
    // 		)
    // }
    return (
      <section className="section mobileedit" onClick={this.closeHeader}>
        <div className="container profpage">
          <div className="columns">
            <div className="column is-4 myaccountbox">
              <h3 className="title" style={{ fontSize: 18 }}>
                {t.myaccount}
              </h3>
              <hr style={{ marginTop: -10 }} />
              <Link
                to="/profile"
                className="column is-12 activated boxes"
                style={{
                  height: 30,
                  marginTop: -10,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.accountset}</p>
              </Link>
              <Link
                to="/my-booking"
                className="column boxes unactive is-12"
                style={{
                  height: 30,
                  marginTop: 0,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.mybook}</p>
              </Link>
              <Link
                to="/"
                className="column boxes unactive is-12"
                style={{
                  height: 30,
                  marginTop: 0,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
                onClick={this.logout}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.logout}</p>
              </Link>
            </div>
            <div className="column is-8 accountsettingbox">
              <form onSubmit={this.editProfile}>
                <h3 className="title" style={{ fontSize: 18 }}>
                  {t.accountset}
                </h3>
                <hr style={{ marginTop: -10 }} />
                <div
                  className="columns is-mobile is-multiline"
                  style={{ marginTop: -20, marginLeft: 0 }}
                >
                  <div
                    className="column is-6 is-narrow mobilecolumn"
                    style={{ paddingLeft: 0 }}
                  >
                    <b style={{ fontSize: 14 }}>{t.name}</b>
                    <br />
                    <input
                      type="text"
                      name="nameGuest"
                      className="input inputbox"
                      style={{ fontSize: 14, marginTop: 5 }}
                      value={nameGuest ? nameGuest : ''}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>
                  <div className="column is-6 is-narrow mobilecolumn telpbox">
                    <b style={{ fontSize: 14 }}>{t.phone}</b> <br />
                    <div
                      className="field has-addons boxPhoneProfile"
                      style={{ marginTop: 5 }}
                    >
                      <div className="control">
                        <IntlTelInput
                          defaultCountry="id"
                          value={this.state.phoneCodeGuest}
                          style={{ width: '100%', backgroundColor: 'red' }}
                          onPhoneNumberChange={this.changeCodePhone}
                        />
                        {/*
												<PhoneInput
												country="ID"
													  value={this.state.phoneCodeGuest}
													  onChange={(value) => this.setState({phoneCodeGuest: value})}/>

												<span className="select">
													<select name="phoneCodeGuest" ref="city" onChange={this.handleInputChange} style={{fontSize: 14}} value={(phoneCodeGuest === '' ? '+62' : phoneCodeGuest)}>
														<option value="+62">+62</option>
														<option value="+1">+1</option>
														<option value="+33">+33</option>
														<option value="+44">+44</option>
														<option value="+49">+49</option>
														<option value="+65">+65</option>
														<option value="+81">+81</option>
														<option value="+82">+82</option>
														<option value="+86">+86</option>
													</select>
												</span>
										*/}
                      </div>
                      <div className="field-label labelCode">
                        <div className="labelCodePhone">
                          {this.state.phoneCodeGuest}
                        </div>
                      </div>
                      <div
                        className="control codePhoneProfile"
                        style={{ width: '100%' }}
                      >
                        <input
                          className="input phone-register-profile"
                          type="number"
                          name="phoneGuest"
                          onChange={this.handleInputChange}
                          required
                          value={phoneGuest}
                          style={{ width: '100%', fontSize: 14 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="linemobile" />
                <div className="column is-12 buttons" style={{ marginTop: -8 }}>
                  <button
                    style={{ paddingBottom: 15, paddingTop: 15 }}
                    type="submit"
                    className={
                      'button is-primary bg-primary savebutton' +
                      (loading ? 'is-loading' : '')
                    }
                  >
                    <b>{t.save}</b>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  logout,
  setUser,
  toggleModal,
  setMessage
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(ProfileEdit));
