import React, { useEffect, useState } from 'react';
import translatable from 'react-multilingual/dist/translatable';
// import PrimaryButton from '../../components/form/PrimaryButton';
import Loader from '../../components/Loader/Loader';
import NotFound from '../../components/others/NotFound';
import PageTitle from '../../components/others/PageTitle';
// import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';
import Container from '../layouts/Container';

const TouristAttractionAll = ({ match, t, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [attractions, setAttractions] = useState([]);

  useEffect(() => {
    ApiCall.getTouristAttractions(true)
      .then(res => {
        // console.log(res.data);
        setAttractions(res.data.data);
      })
      .catch(err => {
        console.log('page not found');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container>
      <PageTitle title="Tourist Attraction" />
      {!isLoading && attractions.length === 0 && <NotFound />}
      <Loader loading={isLoading} />
      {attractions.length > 0 && (
        <div className="columns is-multiline">
          {attractions.map((item, index) => (
            <div key={index} className="column is-3">
              <img
                onClick={() => {
                  const link = '/tourist-attractions/' + item.id;
                  // window.location.href = link;
                  history.push(link);
                }}
                style={{
                  cursor: 'pointer',
                  objectFit: 'cover',
                  width: '100%',
                  height: 250
                }}
                src={
                  // BASE_URL_ADM +
                  // '/storage/tourist-attractions/' +
                  // item.images[0]
                  item.image_url_500
                }
                alt={item.title}
              />
              <div
                onClick={() => {
                  const link = '/tourist-attractions/' + item.id;
                  // window.location.href = link;
                  history.push(link);
                }}
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  cursor: 'pointer'
                }}
              >
                {item.title}
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(TouristAttractionAll);
