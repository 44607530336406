import React from 'react';

function Loader({ loading }) {
  return (
    <div className={'modal ' + (loading ? 'is-active' : '')}>
      <div className="modal-background" />
      <div className="modal-content has-text-centered modal-load">
        <div className="columns is-mobile">
          <div className="column is-1 is-offset-5">
            <div className="loader" />
            <div className="has-text-white" style={{ marginTop: 5 }}>
              Loading...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
