import React, { Component } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { connect } from "react-redux";
import { translatable } from "react-multilingual";
import { toggleModal } from "../../redux/actions";

const composeResult = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCs430gwd70yH5uaAWFivuR8r8_W50IZ94&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `282px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
);

const MyMapComponent = composeResult((props) => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: parseFloat(-6.165988), lng: parseFloat(106.7783637) }}
  >
    {props.isMarkerShown && (
      <Marker
        position={{ lat: parseFloat(-6.165988), lng: parseFloat(106.7783637) }}
      />
    )}
  </GoogleMap>
));

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal("toggleMenu");
      }
    }, 1000);
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal("toggleMenu");
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="container aboutpage noname" onClick={this.closeHeader}>
        <p className="title-about">{t.abouttitle}</p>
        <div className="columns is-gapless">
          <div className="column is-4">
            <div className="slide-item firstpict">
              <img src="/img/background-banner.jpg" alt="" />
            </div>
          </div>
          <div className="column is-8">
            <p className="firstparagraph">{t.about1}</p>
            <p className="secondparagraph" dangerouslySetInnerHTML={{ __html: t.about2}} />
          </div>
        </div>
        <hr className="linemobile2" />
        <div className="columns is-gapless Aligner">
          <div className="column is-4 displayempty" />
          <div className="column is-4">
            <p className="Aligner-item">
              <b>PT Samala Kayana Jayasri</b>
              <br />
              Komp. Ruko Aldiron Hero Bakti
              <br />
              Jl. Daan Mogot 119 Blok D No. 8A
              <br />
              Kel. Duri Kepa, Kec. Kebon Jeruk
              <br />
              Jakarta Barat - 11510
            </p>
          </div>
          <div className="column is-4 displaymobile">
            <MyMapComponent
              isMarkerShown
              lat={parseFloat(-6.165988)}
              long={parseFloat(106.7783637)}
            />
          </div>
        </div>
        <div className="column is-4 linemobile2">
          <MyMapComponent
            isMarkerShown
            lat={parseFloat(-6.165988)}
            long={parseFloat(106.7783637)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, { toggleModal });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(About));
