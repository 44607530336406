import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import Loader from '../../components/Loader/Loader';
import NotFound from '../../components/others/NotFound';
import PageTitle from '../../components/others/PageTitle';
import PropertyList from '../../components/PropertyList/PropertyList';
import Sliders from '../../components/Sliders/Sliders';
import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';
import ContainerWithBg from '../layouts/ContainerWithBg';

export class TouristAttraction extends Component {
  state = {
    isLoading: true,
    touristAttraction: null,
    properties: []
  };

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    const { id } = this.props.match.params;
    Promise.all([
      ApiCall.findTouristAttractions(id),
      ApiCall.getPropertyByTouristAttractionId(id)
    ])
      .then(responses => {
        // console.log(responses);
        this.setState({
          isLoading: false,
          touristAttraction: responses[0].data.data,
          properties: responses[1].data.data
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    const { t, history } = this.props;
    const { isLoading, touristAttraction, properties } = this.state;
    return (
      <ContainerWithBg
        h1Title="Tourist Attraction"
        h2Title="Temukan berbagai penginapan menarik untuk menemani liburan panjangmu!"
      >
        <PageTitle
          title={
            touristAttraction === null
              ? t['Tourist Attraction']
              : touristAttraction.title
          }
        />

        {!isLoading && touristAttraction === null && <NotFound />}
        <Loader loading={isLoading} />
        {!isLoading && touristAttraction !== null && (
          <div>
            <div
              className="has-text-centered"
              // style={{ display: 'flex', justifyContent: 'center' }}
            >
              <center>
                <div style={{ maxWidth: 600 }}>
                  <Sliders>
                    {touristAttraction.images.map((item, index) => (
                      <img
                        onClick={() => {
                          window.open(
                            BASE_URL_ADM +
                              '/storage/tourist-attractions/' +
                              item['image']
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                        src={
                          BASE_URL_ADM +
                          '/storage/tourist-attractions/' +
                          item['image']
                        }
                        className="tourist-attraction-img"
                        alt={touristAttraction.title + ' ' + index}
                      />
                    ))}
                  </Sliders>
                </div>
              </center>
              {/* <h3>Code: {voucher.code}</h3> */}
            </div>
            <div className="has-text-centered">
              <div className="columns">
                <div className="column is-3"></div>
                <div className="column is-6">
                  <div className="has-text-centered" style={{ marginTop: 20 }}>
                    {/* <h3>{t.term}</h3> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: touristAttraction.description
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <PageTitle title={t['Nearest Property']} />
        <div className="columns is-multiline long">
          <PropertyList history={history} properties={properties} />
        </div>
      </ContainerWithBg>
    );
  }
}
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);
export default translator(TouristAttraction);
