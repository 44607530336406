import React from 'react';

function DeleteAccount() {
  return (
    <section className="section">
      <div className="container">
        <div className="content" style={{ textAlign: 'justify' }}>
          <h1>Penghapusan Akun</h1>
          <hr />
          <p align="justify">
            Untuk permintaan penghapusan akun aplikasi Nusalink.asia, kamu bisa
            mengajukan penghapusan akun melalui email dengan cara:
            <ul>
              <li>Isi penerima dengan “cs@nusalink.asia”</li>
              <li>
                Isi subject dengan “Permintaan Penghapusan Akun Nusalink.asia”
              </li>
              <li>
                Isi pesan dengan format berikut:
                <ul>
                  <li>Nama : </li>
                  <li>Email : </li>
                  <li>Nomor Telepon : </li>
                  <li>Alasan Penghapusan Akun : </li>
                </ul>
              </li>
              <li>Pengajuanmu sudah diterima dan akan diproses</li>
            </ul>

            Lihat <a href="/terms-and-conditions" style={{color: 'blue'}}>Syarat & Ketentuan</a> serta{' '}
            <a href="/privacy-policy" style={{color: 'blue'}}>Kebijakan Privasi</a> untuk info lengkap
            tentang penghapusan akun
          </p>
        </div>
      </div>
    </section>
  );
}

export default DeleteAccount;

