import React from 'react';

export default function TwitterIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.438 5.92394C22.5875 7.14157 21.5264 8.19752 20.3046 9.04207L20.3046 9.04205C20.2273 11.1779 19.6038 13.2582 18.4937 15.0846C17.3836 16.9109 15.8239 18.4222 13.9636 19.4741C12.1032 20.5261 10.0042 21.0837 7.867 21.0937C5.7298 21.1036 3.62572 20.5656 1.75562 19.531L1.75625 19.5298C4.19829 19.496 6.57633 18.744 8.59383 17.3677L8.59376 17.3678C6.4341 15.8958 4.78923 13.7849 3.88976 11.331C2.9903 8.87707 2.88133 6.20318 3.57814 3.6842L3.5781 3.68423C4.57612 5.2212 5.89991 6.5199 7.45569 7.48833C9.01147 8.45676 10.7612 9.07122 12.5809 9.28818L12.5806 9.28824C12.389 8.37477 12.5307 7.42288 12.9801 6.60484C13.4295 5.7868 14.1569 5.15666 15.0306 4.82846C15.9043 4.50026 16.8667 4.49569 17.7435 4.81557C18.6204 5.13545 19.3537 5.75865 19.8109 6.57238L19.8109 6.57281C21.0452 6.53598 22.2674 6.31734 23.438 5.92394"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
