import { GOOGLE_MAP_API_KEY } from "./../constants";

function getNearByMapsOneData(data, res) {
  let search = "";
  let lat = "";
  let lang = "";
  let _input = "";
  if (data) {
    search = data.search ? data.search : "";
    lat = data.lat ? data.lat : "";
    lang = data.lang ? data.lang : "";
    // _input = search.replace(' ', '+')
    _input = search;
  }
  const _xht = new XMLHttpRequest();
  _xht.responseType = "json";
  _xht.onerror = function(resp) {
    if (typeof res === "function") {
      res(resp);
    }
  };
  _xht.onreadystatechange = function() {
    if (_xht.readyState === 4 && _xht.status === 200) {
      if (typeof res === "function") {
        res(_xht.response);
      }
    }
  };
  // _xht.open('GET', 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=' + _input + '&inputtype=textquery&fields=formatted_address,geometry,icon,id,name,photos,types&locationbias=point:' + lat + ',' + lang + '&key=AIzaSyCDS72zHXRjAwUu6USWzyQWVhowcISqGqA', true)
  _xht.open(
    "GET",
    "https://maps.googleapis.com/maps/api/place/nearbysearch/json?type=" +
      _input +
      "&rankby=distance&location=" +
      lat +
      "," +
      lang +
      "&key=" +
      GOOGLE_MAP_API_KEY,
    true
  );
  _xht.send(null);
}

function getNearByMapsOneDataDistance(data, res) {
  let lat1 = "";
  let lang1 = "";
  let lat2 = "";
  let lang2 = "";
  if (data) {
    lat1 = data._lat1;
    lang1 = data._lang1;
    lat2 = data._lat2;
    lang2 = data._lang2;
  }
  const _xht = new XMLHttpRequest();
  _xht.responseType = "json";
  _xht.onerror = function(resp) {
    if (typeof res === "function") {
      res(resp);
    }
  };
  _xht.onreadystatechange = function() {
    if (_xht.readyState === 4 && _xht.status === 200) {
      if (typeof res === "function") {
        res(_xht.response);
      }
    }
  };
  _xht.open(
    "GET",
    "https://maps.googleapis.com/maps/api/directions/json?origin=" +
      lat1 +
      "," +
      lang1 +
      "&destination=" +
      lat2 +
      "," +
      lang2 +
      "&sensor=false&units=metric&mode=driving&key=" +
      GOOGLE_MAP_API_KEY,
    true
  );
  _xht.send(null);
}

const ApiMaps = {
  getNearByMapsOneData,
  getNearByMapsOneDataDistance
};

export default ApiMaps;
