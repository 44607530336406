import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import numeral from 'numeral';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk';
import localForage from 'localforage';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bulma/css/bulma.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import './style/helper.css';

import reducers from './redux/reducers';
import App from './App';
import { unregister } from './registerServiceWorker';
import MapHotelDetail from './pages/Public/MapHotelDetail';
import { KEY_API_GOOGLE } from './constants'

export const history = createBrowserHistory();

class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rehydrating: true
    };

    const enhancer = compose(applyMiddleware(thunk), autoRehydrate());

    this.store = createStore(reducers, enhancer);

    persistStore(this.store, {});

    numeral.register('locale', 'id', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      ordinal: number => {
        return number === 1 ? 'er' : 'ème';
      },
      currency: {
        symbol: 'Rp'
      }
    });
    numeral.locale('id');
  }

  componentWillMount() {
    persistStore(this.store, { storage: localForage }, () => {
      this.setState({ rehydrating: false });
    });
  }

  render() {
    // return <ContohAutoComplete />;
    return (
      <GoogleOAuthProvider clientId={KEY_API_GOOGLE}>
      <Provider store={this.store}>
        <div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            style={{
              zIndex: 10000
            }}
          />
          <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
            <Switch>
              <Route path="/map" component={MapHotelDetail} />
              <Route path="/" component={App} />
            </Switch>
          </Router>
          {/* <ContohAutoComplete /> */}
        </div>
      </Provider>
      </GoogleOAuthProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
// ReactDOM.render(<ContohAutoComplete />, document.getElementById('root'));
// registerServiceWorker();
unregister();
