import React from 'react';
import { IconTopUpEmoney } from '../../assets/images/icons/topups';

function EMoneyIcon({ width = 40, height = 40 }) {
  return <img src={IconTopUpEmoney} />;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M15 12V13"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 19V20"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 23C18.866 23 22 19.866 22 16C22 12.134 18.866 9 15 9C11.134 9 8 12.134 8 16C8 19.866 11.134 23 15 23Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 19H15.75C16.0815 19 16.3995 18.842 16.6339 18.5607C16.8683 18.2794 17 17.8978 17 17.5C17 17.1022 16.8683 16.7206 16.6339 16.4393C16.3995 16.158 16.0815 16 15.75 16H14.25C13.9185 16 13.6005 15.842 13.3661 15.5607C13.1317 15.2794 13 14.8978 13 14.5C13 14.1022 13.1317 13.7206 13.3661 13.4393C13.6005 13.158 13.9185 13 14.25 13H16.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15"
          y1="5.71429"
          x2="15"
          y2="41.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F77E3C" />
          <stop offset="1" stopColor="#FF9B64" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EMoneyIcon;
