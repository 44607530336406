import React from 'react';

function NusaMinang2Icon({ width = 40, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3335 4.42871V8.85728"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3335 14.7617V26.5712"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7778 4.42871L14.7619 10.3335C14.7619 11.508 14.2953 12.6344 13.4648 13.4649C12.6343 14.2955 11.5079 14.762 10.3334 14.762C9.15883 14.762 8.0324 14.2955 7.20188 13.4649C6.37137 12.6344 5.90479 11.508 5.90479 10.3335L6.88891 4.42871"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0953 19.1906H17.7144C17.7144 19.1906 19.296 6.27395 25.0953 4.42871V26.5716"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NusaMinang2Icon;
