import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import { connect } from 'react-redux';
import { getRandomNumber, rupiah } from '../../utils/helper';
import Card from '../others/Card';
import moment from 'moment';
import { dateEN, dateIN } from '../../constants';
import Row from './Row';
import FormRadio from '../form/FormRadio';
import TableFull from './TableFull';
import ApiCall from '../../services/ApiCall';
import Star from '../HotelDetail/Star';

class DetailOrder extends Component {
  state = {
    subTotal: 0,
    total: 0,
    room: null,
    propertyName: null,
    dp: 0,
    lamaMenyewa: 1,
    dp_percent: 0,
    total_full: 0,
    payment_option: 'Transfer',
    unique_code: getRandomNumber(100, 999)
  };

  constructor(props) {
    super(props);

    this.order = this.order.bind(this);
  }

  order(e) {
    e.preventDefault();
    const detail = this.props.detail;
    const {
      name,
      phoneNumber,
      area,
      guestCount,
      checkIn,
      checkOut,
      email
    } = detail;
    const {
      room,
      subTotal,
      total,
      dp,
      dp_percent,
      total_full,
      payment_option,
      unique_code
    } = this.state;
    const roomPrice = room.stayMonthly.price;
    const formData = {
      guest: {
        name,
        phoneNumber,
        area,
        count: guestCount,
        user_id: this.props.app.user !== '' ? this.props.app.user.id : 0,
        email
      },
      reservation: {
        specialRequest: detail.specialRequest,
        roomId: room.id,
        checkIn,
        checkOut,
        lang: this.props.app.lang,
        duration: this.state.lamaMenyewa,
        reservation_type: this.props.isWeekly
          ? 'weekly'
          : this.props.isYearly
          ? 'yearly'
          : 'monthly'
      },
      transaction: {
        subTotal,
        total,
        dp,
        dp_percent,
        roomPrice,
        total_full,
        payment_option,
        unique_code,
        per: room.stayMonthly.per,
        promo_name: room.stayMonthly.promo_name,
        promo_idr: room.stayMonthly.promo_idr
      }
    };
    // console.log(formData);
    // return;
    ApiCall.postReservationStayMonthly(formData)
      .then(res => {
        // console.log(res.data);
        if (res.data.data.midtrans) {
          window.location = res.data.data.midtrans;
        }
      })
      .catch(err => {
        console.log(err);
      });
    console.table(formData);
  }

  formatDate(date) {
    const momentDate = moment(date);
    return date === ''
      ? ''
      : momentDate.format('D') +
          ' ' +
          (this.props.app.lang === '' || this.props.app.lang === 'id'
            ? dateIN[momentDate.format('M') - 1]
            : dateEN[momentDate.format('M') - 1]) +
          ' ' +
          momentDate.format('Y');
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('prevProps', prevProps);
    // console.log('currentProps', this.props);
    // console.log('prevState', prevState);
    if (prevProps.detail.roomIndex !== this.props.detail.roomIndex) {
      const { roomIndex, lamaMenyewa, property } = this.props.detail;
      const room = property.rooms[roomIndex];
      const subTotal =
        lamaMenyewa * (room.stayMonthly.price / room.stayMonthly.per);
      const dp_percent = room.stayMonthly.dp_percent;
      const dp = (dp_percent * subTotal) / 100;
      const total =
        dp + prevState.unique_code - (room.stayMonthly.promo_idr || 0);
      const total_full =
        subTotal + prevState.unique_code - (room.stayMonthly.promo_idr || 0);
      this.setState({
        room,
        subTotal,
        total,
        dp,
        lamaMenyewa,
        dp_percent,
        total_full
      });
    }
    if (
      prevState.propertyName === null &&
      prevState.propertyName !== this.props.detail.property.name
    ) {
      this.setState({
        propertyName: this.props.detail.property.name
      });
    }
  }

  render() {
    const { t, detail, app } = this.props;
    const isEn = app.lang === 'en';
    const {
      subTotal,
      total,
      total_full,
      dp,
      room,
      propertyName,
      lamaMenyewa,
      dp_percent
    } = this.state;

    if (propertyName === null) return <div />;

    return (
      <Card title={t.detail}>
        <div className="column">
          <Star star={detail.property.star} />
          <label className="label" style={{ marginBottom: 10 }}>
            {propertyName}
          </label>
          <div className="columns is-mobile is-multiline">
            <div className="column is-12">
              {room !== null && room.name}

              <hr style={{ marginTop: 10, marginBottom: 20 }} />
              <table className="detail-reser" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>{t.guestName}</td>
                    <td className="has-text-right">{detail.name}</td>
                  </tr>
                  <tr>
                    <td>{t.phone}</td>
                    <td className="has-text-right">
                      {detail.area + '' + detail.phoneNumber}
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td className="has-text-right">{detail.email}</td>
                  </tr>
                </tbody>
              </table>
              <hr style={{ marginTop: 10, marginBottom: 20 }} />
              <table style={{ width: '100%' }} className="detail-reser">
                <tbody>
                  <tr>
                    <td>Check-in</td>
                    <td className="has-text-right">
                      {this.formatDate(detail.checkIn)}
                    </td>
                  </tr>
                  <tr>
                    <td>Check-out </td>
                    <td className="has-text-right">
                      {this.formatDate(detail.checkOut)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t.stayDuration}</td>
                    <td className="has-text-right">
                      {detail.lamaMenyewa}{' '}
                      {this.props.isWeekly
                        ? t.week
                        : this.props.isYearly
                        ? t.year
                        : t.month}
                    </td>
                  </tr>
                  <tr>
                    <td>{t.guestReservation}</td>
                    <td className="has-text-right">
                      {detail.guestCount} {t.people}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr style={{ marginTop: -13, marginBottom: 20 }} />
          {room !== null ? (
            <div className="columns is-mobile is-multiline">
              <div className="column is-12">
                <table className="detail-reser" style={{ width: '100%' }}>
                  <tbody>
                    {/* <tr>
                      <th
                        colSpan="2"
                        className=""
                        style={{ paddingBottom: 0, width: '47%' }}
                      >
                        {t.usePromoLabel}
                      </th>
                      <td
                        className="has-text-right"
                        style={{ paddingBottom: 0, width: '53%' }}
                      >
                        <label className="switchs">
                          <input
                            type="checkbox"
                            name="sliders"
                            checked={promoactived}
                            onClick={this.activedPromo}
                          />
                          <span className="sliders round" />
                        </label>
                      </td>
                    </tr>
                    <tr
                      className={
                        promoactived ? 'promo-active' : 'promo-nonactive'
                      }
                    >
                      <th colSpan="3" className="list-promo">
                        <div className="columns is-mobile is-multiline">
                          <div
                            className="column is-12"
                            style={{
                              marginBottom: -15,
                              marginTop: -25,
                            }}
                          >
                            <p className="label-down" align="justify">
                              {t.notePromo}{' '}
                            </p>
                          </div>
                          <div
                            className="column is-12"
                            style={{ marginBottom: 15 }}
                          >
                            <div className="field">
                              <div
                                className="control"
                                style={{
                                  width: '65%',
                                  float: 'left',
                                }}
                              >
                                <input
                                  className="input"
                                  type="text"
                                  onChange={(e) =>
                                    this.handleInputChangeVoucher(e)
                                  }
                                  value={kodeVoucher}
                                />
                              </div>
                              <div
                                className="control"
                                style={{
                                  width: '30%',
                                  float: 'left',
                                  marginLeft: '5%',
                                }}
                              >
                                <button
                                  type="button"
                                  className="btnPromo"
                                  onClick={this.checkVoucher}
                                >
                                  {t.usePromo}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                    {this.props.app.user !== '' && detail.point > 0 && (
                      <tr>
                        <td>{t.point}</td>
                        <td colSpan="2" className="has-text-right">
                          {rupiah(detail.point)}
                        </td>
                      </tr>
                    )}
                    {this.props.app.user !== '' && detail.point > 0 && (
                      <tr>
                        <td>Use point?</td>
                        <td colSpan="2" className="has-text-right">
                          <input
                            value="1"
                            type="checkbox"
                            name="sliders"
                            defaultChecked={detail.usePoint}
                            onChange={(e) => {
                              this.setState({
                                usePoint: e.target.checked,
                              });
                              this.setTotal();
                            }}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="3">{detail.room.name}</td>
                    </tr> */}

                    {/* rate by date */}
                    {/* {detail.room.rates.map((item) => (
                      <tr
                        style={{
                          color: item.unit <= 0 ? 'red' : 'black',
                        }}
                        key={item.id}
                      >
                        <td>
                          {item.rateDate}
                          {item.unit <= 0 && (
                            <small>
                              <br />
                              {this.props.app.lang === 'en'
                                ? 'Allotment not available'
                                : 'Tidak tersedia'}
                            </small>
                          )}
                        </td>
                        <td colSpan="2" className="has-text-right">
                          {t.currency} {rupiah(item.fix_price)}
                        </td>
                      </tr>
                    ))} */}

                    <Row
                      text={[
                        'x' +
                          lamaMenyewa +
                          ' ' +
                          (this.props.isWeekly
                            ? t.week
                            : this.props.isYearly
                            ? t.year
                            : t.month),
                        t.currency +
                          ' ' +
                          rupiah(room.stayMonthly.price / room.stayMonthly.per)
                      ]}
                    />

                    {/* sub total */}
                    <tr>
                      <td colSpan="3">
                        <hr style={{ margin: 0 }} />
                      </td>
                    </tr>
                    <Row
                      text={['Sub Total', t.currency + ' ' + rupiah(subTotal)]}
                    />
                    <Row
                      text={[
                        'DP (' + dp_percent + '%)',
                        t.currency + ' ' + rupiah(dp)
                      ]}
                    />
                    <Row
                      text={[
                        this.props.app.lang === 'id'
                          ? 'Kode Unik'
                          : 'Unique Code',
                        t.currency + ' ' + rupiah(this.state.unique_code)
                      ]}
                    />
                    {room !== null && room.stayMonthly.promo_idr !== null && (
                      <Row
                        text={[
                          room.stayMonthly.promo_name,
                          '-' +
                            t.currency +
                            ' ' +
                            rupiah(room.stayMonthly.promo_idr)
                        ]}
                      />
                    )}

                    {/* voucher */}
                    {/* {valueVoucher > 0 && (
                      <tr
                        className={
                          promovalactived ? 'promo-active' : 'promo-nonactive'
                        }
                      >
                        <td>{t.coupon}</td>
                        <td colSpan="2" className="has-text-right">
                          - {t.currency} {rupiah(valueVoucher)}
                        </td>
                      </tr>
                    )} */}

                    {/* use point */}
                    {/* {this.props.app.user !== '' && detail.usePoint && (
                      <tr>
                        <td>{t.point}</td>
                        <td colSpan="2" className="has-text-right">
                          - {t.currency} {rupiah(detail.point)}
                        </td>
                      </tr>
                    )} */}

                    {/* promo login */}
                    {/* {this.props.app.user !== '' &&
                      this.props.app.promoLogin > 0 && (
                        <tr>
                          <td>{t.promoLogin}</td>
                          <td colSpan="2" className="has-text-right">
                            - {t.currency} {rupiah(detail.promoLogin)}
                          </td>
                        </tr>
                      )} */}
                  </tbody>
                </table>
              </div>

              {/* total */}
              <div
                className="column is-12"
                style={{ paddingTop: 0, marginTop: -20 }}
              >
                <hr style={{ marginTop: 5 }} />
                <table
                  style={{
                    width: '100%',
                    marginTop: -15,
                    marginBottom: -15
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ fontSize: 14, fontWeight: 'bold' }}>
                        Total
                      </td>
                      <td
                        className="has-text-right"
                        style={{ fontSize: 14, fontWeight: 'bold' }}
                      >
                        {t.currency} {rupiah(total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
              <div
                className="column is-12"
                style={{ marginTop: -25, marginBottom: -10 }}
              >
                <label className="labelOptionPayment">
                  {t.optPayments}
                  {/* <label onClick={(e) => this.showMethodPayment()}>
                    <i>
                      <img
                        style={{
                          width: 20,
                          height: 20,
                          marginLeft: 10,
                          marginBottom: -5,
                          cursor: 'pointer',
                        }}
                        src="/icon/info.png"
                        alt="Nusalink"
                      />
                    </i>
                  </label> */}
                </label>
              </div>
              <div className="column is-12">
                <TableFull>
                  <Row
                    bold
                    text={[
                      <FormRadio
                        checked={this.state.payment_option === 'Transfer'}
                        value={'Transfer'}
                        label={'Transfer'}
                        onChange={e => {
                          this.setState({
                            payment_option: 'Transfer'
                          });
                        }}
                      />,
                      t.currency + ' ' + rupiah(total)
                    ]}
                  />
                  <Row
                    bold
                    text={[
                      <FormRadio
                        checked={this.state.payment_option === 'Transfer Full'}
                        value={'Transfer Full'}
                        label={'Transfer Full'}
                        onChange={() => {
                          this.setState({
                            payment_option: 'Transfer Full'
                          });
                        }}
                      />,
                      t.currency + ' ' + rupiah(total_full)
                    ]}
                  />
                </TableFull>
              </div>
              <div className="column is-12">
                <button
                  onClick={this.order}
                  disabled={detail.notAvailable}
                  style={{
                    backgroundColor: detail.notAvailable ? '#ccc' : '#f16726'
                  }}
                  className="btnCheckout"
                  type="submit"
                >
                  {t.reserv}
                </button>
                {room !== null && room.stayMonthly.note !== null && (
                  <div
                    className="p-3"
                    style={{
                      marginTop: 10,
                      backgroundColor: '#fffaeb',
                      color: '#946c00',
                      textAlign: 'justify'
                    }}
                  >
                    <small>
                      <strong
                        style={{
                          color: '#946c00'
                        }}
                      >
                        Catatan
                      </strong>
                      <br />
                      {room.stayMonthly.note}
                    </small>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="notification"
              style={{ backgroundColor: '#feecf0', color: '#cc0f35' }}
            >
              {isEn ? 'Choose room first' : 'Pilih tipe kamar dulu'}
            </div>
          )}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps);

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(DetailOrder));
