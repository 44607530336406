import { http } from '../ApiCall';

const PLN_TOKEN = 'top-up/pln/all';
const PLN_TOKEN_TRANSACTION = 'top-up/transactions/pln';
const MOVIE_TICKET_TRANSACTION = 'top-up/transactions/movie-ticket';
const EWALLET_TRANSACTION = 'top-up/transactions/e-wallet';
const EMONEY_TRANSACTION = 'top-up/transactions/emoney';
const EWALLET = 'top-up/e-wallet/all';
const EMONEY = 'top-up/e-money/all';
const MY_TRANSACTIONS = 'top-up/mine';
const MOVIE_TICKET_ALL = 'top-up/movie-tickets/all';
const VOUCHER_GAMES = 'top-up/game-vouchers/all';

const TopUpApi = {
  async getPlnToken() {
    return await http.get(PLN_TOKEN);
  },

  async getVoucherGames() {
    return await http.get(VOUCHER_GAMES);
  },

  async postTransactionVoucherGames(data) {
    return await http.post('top-up/transactions/game-vouchers', data);
  },

  async postTransactionPlnToken(data) {
    return await http.post(PLN_TOKEN_TRANSACTION, data);
  },

  async postTransactionMovieTicket(data) {
    return await http.post(MOVIE_TICKET_TRANSACTION, data);
  },

  async postTransactionEwallet(data) {
    return await http.post(EWALLET_TRANSACTION, data);
  },

  async postTransactionEmoney(data) {
    return await http.post(EMONEY_TRANSACTION, data);
  },

  async getEwallet() {
    return await http.get(EWALLET);
  },

  async getEmoney() {
    return await http.get(EMONEY);
  },

  async getMovieTickets() {
    return await http.get(MOVIE_TICKET_ALL);
  },

  async getMyTransactions(userId) {
    return await http.get(MY_TRANSACTIONS + '/' + userId);
  }
};

export default TopUpApi;
