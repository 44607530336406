import React, { Component } from "react";
import Axios from "axios";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";

class ReservationCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        // orderId: "1486812971",
        // email: "hairulanam21@gmail.com"
        // orderId: "2267502661",
        // email: "y.luthfi.r@gmail.com"
        orderId: "",
        email: ""
      },
      checkButtonDisabled: false,
      reservation: false
      // reservation: {
      //   id: 1,
      //   arrival: null,
      //   departure: null,
      //   RoomId: 6,
      //   codeVoucher: "",
      //   hotelName: "Arsa Entire Villa Bali #3",
      //   roomName: "Arsa Entire Villa Bali #3",
      //   name: "Yusuf Luthfi Ramdhani",
      //   nip: "",
      //   phone: "+358-55667788",
      //   email: "y.luthfi.r@gmail.com",
      //   address: "",
      //   extra: "",
      //   total_guest: 2,
      //   user_Id: "3",
      //   status: 1,
      //   order_id: "2267502661",
      //   createdAt: null,
      //   updatedAt: null,
      //   Transaction: {
      //     id: 1,
      //     codeTransaction: "2267502661",
      //     ReservationId: 1,
      //     voucherValue: 0,
      //     roomPrice: 500000,
      //     subTotal: 1000000,
      //     debt: 1000000,
      //     dp: 500000,
      //     statusPay: 1,
      //     totalMurni: 0,
      //     createdAt: null,
      //     updatedAt: null,
      //     Payment: {
      //       id: 1,
      //       transactionId: "2267502661",
      //       pdfName:
      //         "Voucher_2267502661_Arsa Entire Villa Bali #3_Yusuf Luthfi Ramdhani.pdf",
      //       status: "dibatalkan",
      //       createdAt: null,
      //       updatedAt: null
      //     }
      //   }
      // }
    };
    this.checkReservation = this.checkReservation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  checkReservation(event) {
    event.preventDefault();
    this.setState({
      reservation: false
    });
    const formData = this.state.formData;
    let errorMessage = "";
    if (!formData.email) {
      errorMessage = "Email required\n";
    }
    if (!formData.orderId) {
      errorMessage += "Order ID required\n";
    }
    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    console.log(this.state.formData);
    this.setState({
      checkButtonDisabled: true
    });
    Axios.get("/reservations/" + formData.orderId + "/" + formData.email)
      .then(res => {
        console.log(res.data);
        this.setState({
          reservation: res.data.data
        });
      })
      .catch(err => {
        this.setState({
          reservation: null
        });
        console.log(err);
      })
      .finally(() => {
        this.setState({
          checkButtonDisabled: false
        });
      });
  }

  render() {
    let reservationResult = "";
    const { t } = this.props;

    if (this.state.reservation === null) {
      reservationResult = (
        <div
          className="notification is-danger is-light"
          style={{ marginTop: "10px" }}
        >
          {t.reservation} {t.notFound}
        </div>
      );
    }

    if (this.state.reservation !== false && this.state.reservation !== null) {
      let status = "Failed";
      const Payment = this.state.reservation.Transaction.payment_relation
      if (Payment) {
        status = Payment.status;
      }

      reservationResult = (
        <div>
          <table
            style={{ marginTop: "10px" }}
            className="table is-bordered is-hovered mt-3"
          >
            <tbody>
              <tr>
                <td>Order ID</td>
                <td>{this.state.reservation.order_id}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{this.state.reservation.email}</td>
              </tr>
              <tr>
                <td>{t.name}</td>
                <td>{this.state.reservation.name}</td>
              </tr>
              <tr>
                <td>{t.phone}</td>
                <td>{this.state.reservation.phone}</td>
              </tr>
              <tr>
                <td>Hotel</td>
                <td>{this.state.reservation.hotelName}</td>
              </tr>
              <tr>
                <td>Check In</td>
                <td>{this.state.reservation.arrival}</td>
              </tr>
              <tr>
                <td>Check Out</td>
                <td>{this.state.reservation.departure}</td>
              </tr>
              <tr>
                <td>{t.paymentStatus}</td>
                <td>{status === "Waiting" ? "Waiting Payment" : status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <section className="section">
        <div className="container">
          <div className="content" style={{ textAlign: "justify" }}>
            <h2> {t.reservationCheck} </h2>
            <form>
              <div className="columns">
                <div className="column">
                  <label> Order ID </label>
                  <div className="control">
                    <input
                      value={this.state.formData.orderId}
                      onChange={this.handleInputChange}
                      name="orderId"
                      className="input"
                      type="text"
                    />
                  </div>
                </div>
                <div className="column">
                  <label> Email </label>
                  <div className="control">
                    <input
                      value={this.state.formData.email}
                      className="input"
                      onChange={this.handleInputChange}
                      name="email"
                      type="email"
                    />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <button
                    disabled={this.state.checkButtonDisabled}
                    onClick={this.checkReservation}
                    className="button bg-primary mb-2 text-white"
                  >
                    {t.check}
                  </button>
                </div>
              </div>
            </form>
            {reservationResult}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(ReservationCheck));
