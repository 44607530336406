import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropertyItem from '../PropertyItem/PropertyItem';
import NotFound from '../others/NotFound';

export class PropertyList extends Component {
  static propTypes = {
    properties: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.goToDetail = this.goToDetail.bind(this);
  }

  goToDetail(e, propertyId) {
    // console.log(e, propertyId);
    const url = '/detail-villas?villas=' + propertyId;
    this.props.history.push(url);
    // window.location = url;
  }

  render() {
    const { properties } = this.props;
    if (properties.length) {
      return properties.map((item, index) => (
        <PropertyItem
          per="night"
          onClick={this.goToDetail}
          property={item}
          key={item.name}
        />
      ));
    }
    return <NotFound />;
  }
}

export default PropertyList;
