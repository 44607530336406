import React, { Component } from 'react';
import { translatable } from 'react-multilingual';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'flatpickr/dist/themes/confetti.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Numeral from 'numeral';
// import { DateRangePicker } from 'react-dates';
import {
  BASE_URL_ADM,
  COLORS,
  dateEN,
  dateIN,
  IS_MOBILE
} from '../../constants.js';
import ApiCall from '../../services/ApiCall';
import queryString from '../../utils/queryString';
import {
  toggleModal,
  showModal,
  setMessage,
  setPages,
  setMessageMId,
  setBoxFilterDetail
} from '../../redux/actions';
import '../../style/home.css';
import StartFrom from '../layouts/StartFrom.js';
// import CitySelect from '../layouts/CitySelect.js';
import EventList from '../../components/EventList/EventList.js';
import PayAtProperty from '../../components/others/PayAtProperty.js';
import './Home.css';
import Star from '../../components/HotelDetail/Star.js';
import FormInput from '../../components/form/FormInput.js';
import Helper from '../../utils/helper.js';
import text from './../../text';
import TouristAttraction from '../../components/TouristAttraction/TouristAttraction.js';
import StringHelper from '../../utils/StringHelper.js';
import ShowAll from '../../components/Buttons/ShowAll/ShowAll.js';
import PLN from '../../components/Icons/PLN.js';
import PaketData from '../../components/Icons/PaketData.js';
import Pulsa from '../../components/Icons/Pulsa.js';
import ExploreBtn from '../../components/Buttons/ExploreBtn.js';
import SelectOption from '../../components/form/SelectOption.js';
import Autocomplete from 'react-autocomplete';
import EWalletIcon from '../../components/Icons/EWalletIcon.js';
import TicketIcon from '../../components/Icons/TicketIcon.js';
import SelectGuest from '../../components/form/SelectGuest.js';
import GameIcon from '../../components/Icons/GameIcon.js';
import KosEksklusif from '../../components/Home/KosEksklusif.js';
import OrangeBtn from '../../components/Buttons/OrangeBtn.js';
import CrossHairIcon from '../../components/Icons/CrossHairIcon.js';
import RightArrowIcon from '../../components/Icons/RightArrowIcon.js';
import NusaMinangIcon from '../../components/Icons/NusaMinangIcon.js';
import NusaCafeIcon from '../../components/Icons/NusaCafeIcon.js';
import EMoneyIcon from '../../components/Icons/EMoneyIcon.js';
import TopUpService from '../../services/TopUpService.js';
import { toast } from 'react-toastify';
// import Modal from 'react-modal';

const WIDTH_TOPUP_ITEM = IS_MOBILE ? '80px' : '80px';
class Home extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      activeCities: [],
      loading: false,
      popupPromo: null,
      isModalPromo: true,
      voucherModal: null,
      lang: 'id',
      dataCity: [],
      cityId: null,
      guest: '',
      city: '',
      arrival: null,
      departure: null,
      duration: '',
      click: false,
      focusedInput: null,
      startDate: null,
      endDate: null,
      startDateOriginal: null,
      endDateOriginal: null,
      mobileDate: false,
      hotelHome: [],
      jumlahGuest: 1,
      applyGuest: false,
      popUp: false,
      jumlahTotal: '',
      tagsHotels: [],
      eventHome: [],
      vouchers: [],
      propertyName: '',
      cities: [],
      giveaway: [],
      propertyNames: {
        default: [],
        used: []
      },
      showAllCity: false,
      topUp: {
        iconSize: 40,
        fontSize: 10
      },
      isTopUpEnabled: false
    };
  }

  async getCities() {
    try {
      const responseCities = await ApiCall.getHotelCities();
      const cities = responseCities.data.data;
      if (cities.length) {
        this.setState({
          cities,
          activeCities: [...cities].slice(0, 5)
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  searchToObject() {
    var pairs = window.location.search.substring(1).split('&'),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === '') continue;

      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  async componentDidMount() {
    const search = this.searchToObject();
    if (search.reservation_id) {
      if (search.order_from === 'web') {
        window.location.href = '/detail-booking?id=' + search.reservation_id;
      } else {
        window.location.href =
          'nusalink://HomeScreen/' +
          search.order_id +
          '/' +
          search.reservation_id +
          '/0';
      }
    }
    if (search.top_up_ref_id) {
      if (search.order_from === 'web') {
        // window.location.href = '/detail-booking?id=' + search.reservation_id;
      } else {
        window.location.href =
          'nusalink://HomeScreen/' +
          search.top_up_ref_id +
          '/' +
          search.transaction_id +
          '/2';
      }
    }
    if (search.order_id) {
      try {
        const res = await ApiCall.findByOrderId(search.order_id, '?select=id');
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          window.location.href =
            'nusalink://HomeScreen/' +
            search.order_id +
            '/' +
            res.data.data.id +
            '/0';
        } else {
          window.location.href = '/detail-booking?id=' + res.data.data.id;
        }
      } catch (error) {
        this.initPage();
      }
    } else {
      this.initPage();
    }
  }

  initPage() {
    this.props.setPages(0);
    ApiCall.findPopupPromo()
      .then(response => {
        if (response.data.status === 'success')
          this.setState({
            popupPromo: response.data.data
          });
      })
      .catch(error => {
        console.log(error);
      });
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    ApiCall.getHotelHome(true).then(response => {
      this.setState({
        hotelHome: response.data.hotels
      });
    });
    ApiCall.getHtelsTags(true).then(response => {
      this.setState({
        tagsHotels: response.data.data
      });
    });
    ApiCall.getEventHome().then(res => {
      this.setState({
        eventHome: res.data.data
      });
    });
    ApiCall.getPropertyName().then(res => {
      if (res.data.status === 'success') {
        this.setState({
          propertyNames: {
            used: res.data.data.slice(0, 5).map(item => ({
              label: item.name,
              ...item
              // lower: item.name.toLowerCase()
            })),
            default: [
              ...res.data.data.map(item => ({
                label: item.name,
                ...item
                // lower: item.name.toLowerCase()
              }))
            ]
          }
        });
      }
    });
    TopUpService.platformStatus().then(res => {
      console.log(res.data);
      const { ios, android } = res.data.data;
      if (Number(ios) === 1 || Number(android) === 1) {
        this.setState({
          isTopUpEnabled: true
        });
      }
    });
    if (window.innerWidth <= 425) {
      this.setState({
        topUp: {
          iconSize: 60,
          fontSize: 8
        }
      });
    } else {
      this.setState({
        topUp: {
          iconSize: 50,
          fontSize: 10
        }
      });
    }
    setTimeout(() => {
      this.callCalender('none');
      const paramsString = queryString.parse(this.props.location.search);
      if (
        paramsString.order_id !== undefined &&
        paramsString.status_code !== undefined &&
        paramsString.transaction_status !== undefined &&
        paramsString.transaction_status !== 'deny' &&
        paramsString.status_code !== '202'
      ) {
        this.props.setMessageMId(
          'messagemid1',
          this.props.t.messgePopMidtransLogin1 +
            ' ' +
            paramsString.order_id +
            ' ' +
            this.props.t.messgePopMidtransLogin2
        );
        this.props.setMessageMId(
          'messagemid2',
          this.props.t.messgePopMidtransLogin3
        );
        this.props.toggleModal('popupmidtrans');
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
      this.getVouchers();
      this.getCities();
    }, 1000);
    window.scrollTo(0, 0);
    this.props.setPages(0);
    const CHECK_IN = localStorage.getItem('CHECK_IN') || Helper.getToday();
    const CHECK_OUT = localStorage.getItem('CHECK_OUT') || Helper.getTomorrow();
    this.setState({
      startDate: CHECK_IN,
      endDate: CHECK_OUT,
      guest: 1
    });
    ApiCall.getGiveAway()
      .then(res => {
        if (res.data.status === 'success') {
          this.setState({
            giveaway: res.data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getVouchers() {
    ApiCall.getPromo()
      .then(response => {
        if (response.data.message) {
          if (response.data.message === 'voucher not found') {
          } else {
            this.setState({
              vouchers: response.data.data
            });
          }
        } else {
        }
      })
      .catch(error => {
        // if (this.props.app.lebelPromo === true) this.props.toggleModal('lebelPromo')
      });
  }

  resize() {
    if (window.innerWidth <= 640) {
      this.setState({ mobileDate: true });
    } else {
      this.setState({ mobileDate: false });
    }
  }

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  handleGetCity = e => {
    e.preventDefault();
    const target = e.target;
    this.setState({ city: target.value });
  };
  apply = () => {
    this.setState({
      applyGuest: true,
      guest: this.state.jumlahGuest,
      popUp: !this.state.popUp
    });
  };
  klikPlus = e => {
    if (this.state.jumlahGuest >= 20) {
      console.log('');
    } else {
      const plus = this.state.jumlahGuest + 1;
      this.setState({
        jumlahGuest: plus
      });
    }
  };
  klikMin = () => {
    if (this.state.jumlahGuest === 1) {
      console.log('');
    } else {
      const kurang = this.state.jumlahGuest - 1;
      this.setState({
        jumlahGuest: kurang
      });
    }
  };

  onChangeGuest(e) {
    // alert(e.target.value);
    this.setState({
      jumlahGuest: e.target.value
    });
  }

  eventOnCloseGuest = () => {
    this.setState({
      popUp: false
    });
  };
  eventClickLabelGuest = () => {
    this.setState({
      applyGuest: false,
      popUp: !this.state.popUp
    });
  };
  handleGetGuest = e => {
    e.preventDefault();
    const target = e.target;
    this.setState({ guest: target.value });
  };
  tanggalKlik = e => {
    e.preventDefault();
    this.setState({
      click: !this.state.click
    });
  };

  eventOnCloseCalender = () => {
    const startDate = document.getElementById('startDate1').value;
    const endDate = document.getElementById('endDate1').value;
    if (startDate === '' && endDate === '') {
      this.callCalender('none');
      this.callLabelCalender('block');
    } else {
      this.callCalender('block');
      this.callLabelCalender('none');
    }
  };

  eventClickLabelCalender = () => {
    this.callCalender('block');
    this.callLabelCalender('none');
    document.getElementById('startDate1').focus();
  };

  callCalender(value) {
    const x = document.getElementsByClassName('DateRangePicker')[0];
    if (x) {
      x.style.display = value;
    }
  }

  callLabelCalender(value) {
    const x = document.getElementsByClassName('labelDates')[0];
    if (x) {
      x.style.display = value;
    }
  }

  handleDateChange = e => {
    const oneDay = 24 * 60 * 60 * 1000;
    const arrival = new Date(e.startDate);
    const departure = new Date(e.endDate);
    const durationInap = Math.round(
      Math.round((departure.getTime() - arrival.getTime()) / oneDay)
    );
    if (e.length > 1) {
      if (durationInap > 90) {
        const { t } = this.props;
        this.props.setMessage(t.maxDuration);
        this.props.toggleModal('popupmessage');
        this.setState({
          arrival: new Date(e.startDate),
          departure: new Date(e.endDate),
          duration: ''
        });
      } else if (durationInap < 1) {
        this.props.setMessage('Durasi minimal 1 malam');
        this.props.toggleModal('popupmessage');
        this.setState({
          arrival: '',
          departure: '',
          duration: ''
        });
      } else {
        this.setState({
          arrival: e.startDate.format('MM-DD-YYYY'),
          departure: e.startDate.format('MM-DD-YYYY'),
          duration: durationInap
        });
      }
    } else {
      this.setState({
        arrival: '',
        departure: '',
        duration: ''
      });
    }
  };

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };
  gotodetail = (e, hotelid) => {
    this.props.setBoxFilterDetail(false);
    this.props.history.push(`/detail-villas?villas=${hotelid}`);
  };

  gotoAllPropertyWithCity(cityId) {
    this.props.history.push(`/villas?city=${cityId}`);
  }

  closeModalPopup = () => {
    this.props.toggleModal('modalPopDates');
    this.setState({
      arrival: '',
      departure: ''
    });
  };

  filter = () => {
    const { t } = this.props;
    const { guest, propertyName } = this.state;
    if (this.state.startDate === '' || this.state.endDate === '') {
      this.props.setMessage(t.alertDate);
      this.props.toggleModal('popupmessage');
    } else if (guest === '') {
      this.props.setMessage(t.alertGuest);
      this.props.toggleModal('popupmessage');
    } else {
      const oneDay = 24 * 60 * 60 * 1000;
      console.log(this.state.startDate, this.state.endDate);
      const startDate = moment(this.state.startDate);
      const endDate = moment(this.state.endDate);
      const arrivalDate = new Date(startDate);
      const departureDate = new Date(endDate);
      const durationInap = Math.round(
        Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
      );
      const arrival = startDate.format('MM-DD-YYYY');
      const departure = endDate.format('MM-DD-YYYY');
      this.props.setPages(0);
      const { cityId } = this.state;
      localStorage.setItem(
        'FORM_FILTER',
        JSON.stringify({
          guest,
          arrival: this.state.startDateOriginal,
          departure: this.state.endDateOriginal
        })
      );
      this.props.history.push(
        `/villas?guest=${guest}&arrival=${arrival}&departure=${departure}&duration=${durationInap}&city=${cityId}&propertyName=${propertyName}`
      );
    }
  };

  toTitleCase = str => {
    let _string = str.replace('KOTA', '');
    _string = _string.replace('KABUPATEN', '');
    return _string.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  render() {
    const settingsResponsive = {
      dots: false,
      infinite: false,
      speed: 500,
      className: 'center',
      centerPadding: '30px',
      centerMode: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      initialSlide: 0
    };
    const sliderSettings = { ...settings, autoplay: true, lazyLoad: true };
    const settingsImage = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      swipeToSlide: false,
      swipe: false,
      initialSlide: 0
    };
    const { t, app } = this.props;
    const isEn = app.lang === 'en';
    // console.log(this.state.eventHome, "EVENT HOME");
    const voucherSliders = (
      <div>
        {this.state.vouchers.length > 0 && (
          <div
            className="hide-on-desktop"
            style={{
              color: 'rgb(0, 0, 0)',
              fontWeight: 'bold',
              fontSize: '24px',
              marginBottom: '5px',
              marginTop: '-10px'
            }}
          >
            Promo Menarik
          </div>
        )}
        {this.state.vouchers.length > 0 && (
          <hr className="hide-on-desktop mb-20px" />
        )}
        <Slider {...sliderSettings}>
          {this.state.vouchers.map((item, index) => (
            <div
              key={item.id}
              style={{
                width: '100%'
              }}
            >
              <img
                onClick={() => {
                  window.open('/promo/' + item.id);
                }}
                src={BASE_URL_ADM + '/images/voucher/' + item.images}
                alt={item.code}
                style={{
                  width: '100%',
                  borderRadius: 20,
                  objectFit: 'cover',
                  cursor: 'pointer'
                  // WebkitBoxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`,
                  // boxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`,
                  // MozBoxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`,
                  // padding: 10
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
    return (
      <div onClick={this.closeHeader}>
        <div
          style={{
            backgroundImage: `url('/images/home_bg.png')`
          }}
        >
          <div className="container">
            <div
              style={{
                marginBottom: '-0.75rem',
                paddingTop: 60,
                paddingBottom: 40
              }}
              className="row"
            >
              <div className="col-md-5 hide-on-mobile">{voucherSliders}</div>
              <div className="col-md-7" style={{ height: 'auto' }}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="hello">
                    {this.props.app.lang === 'id'
                      ? 'Halo selamat datang'
                      : 'Hello, welcome'}
                  </div>
                  {!this.props.app.user && (
                    <a
                      onClick={() => {
                        this.props.toggleModal('modalRegister');
                      }}
                      className="text-white"
                    >
                      {this.props.app.lang === 'id'
                        ? 'Belum punya akun?'
                        : "Doesn't have an account yet?"}
                    </a>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    padding: 30,
                    WebkitBoxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`,
                    boxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`,
                    MozBoxShadow: `-1px 4px 14px 3px rgba(0,0,0,0.16)`
                  }}
                >
                  <div className="columns is-mobile is-multiline">
                    <div className="column is-12 ">
                      <div className="field">
                        <label className="labels">
                          {isEn
                            ? 'City, your destination, or property name'
                            : 'Kota, tujuan anda, atau nama properti'}
                        </label>
                        <div className="control">
                          <Autocomplete
                            shouldItemRender={(item, value) => {
                              // console.log(item);
                              return (
                                item.label
                                  .toLowerCase()
                                  .indexOf(value.toLowerCase()) > -1 ||
                                item.location
                                  .toLowerCase()
                                  .indexOf(value.toLowerCase()) > -1
                              );
                            }}
                            wrapperStyle={{ width: '100%' }}
                            renderInput={props => {
                              return (
                                <input
                                  id="inputPropertyNameSearch"
                                  {...props}
                                  style={{
                                    paddingBottom: '5px',
                                    paddingTop: '6px',
                                    height: 'auto',
                                    backgroundColor: 'rgb(240, 240, 240)',
                                    borderRadius: '8px',
                                    boxShadow: 'unset',
                                    border: 'unset',
                                    lineHeight: 1.3,
                                    fontSize: '1rem',
                                    width: '100%'
                                  }}
                                />
                              );
                            }}
                            getItemValue={item => item.label}
                            items={this.state.propertyNames.used}
                            renderMenu={(items, value, style) => {
                              return (
                                <div
                                  style={{
                                    ...style,
                                    zIndex: 9999,
                                    border: '1px solid #aaa',
                                    maxHeight: 300,
                                    overflowY: 'auto'
                                  }}
                                >
                                  {value === '' ? (
                                    <div className="item">
                                      Masukkan nama properti
                                    </div>
                                  ) : this.state.loading ? (
                                    // <div className="item">Loading...</div>
                                    <div></div>
                                  ) : items.length === 0 ? (
                                    // <div className="item">No matches for {value}</div>
                                    <div></div>
                                  ) : (
                                    items
                                  )}
                                </div>
                              );
                              // return (
                              //   <div
                              //     style={{
                              //       ...style,
                              //       zIndex: 9999,
                              //       border: '1px solid #aaa'
                              //     }}
                              //     children={items}
                              //   />
                              // );
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.id}
                                style={{
                                  background: isHighlighted
                                    ? 'lightgray'
                                    : 'white',
                                  padding: 10,
                                  borderBottom: '1px solid #aaa'
                                }}
                              >
                                <strong>{item.label}</strong>
                                <br />
                                <span style={{ textTransform: 'capitalize' }}>
                                  <small>{item.location.toLowerCase()}</small>
                                </span>
                              </div>
                            )}
                            value={this.state.propertyName}
                            onChange={e => {
                              const propertyName = e.target.value;
                              if (propertyName.length > 3) {
                                this.setState(prevState => {
                                  console.log(propertyName);
                                  const used = prevState.propertyNames.default.filter(
                                    item => {
                                      // console.log(item);
                                      return (
                                        item.label
                                          .toLowerCase()
                                          .includes(
                                            propertyName.toLowerCase()
                                          ) ||
                                        item.location
                                          .toLowerCase()
                                          .includes(propertyName.toLowerCase())
                                      );
                                    }
                                  );
                                  // console.log(prevState.propertyNames.default, used);
                                  // console.log(prevState.propertyNames.default);
                                  // if (
                                  //   prevState.propertyNames.default.includes(
                                  //     propertyName
                                  //   )
                                  // ) {
                                  // console.log('halo');
                                  return {
                                    // loading: false,
                                    propertyNames: {
                                      used,
                                      // .map(item => ({ label: item }))
                                      default: prevState.propertyNames.default
                                    },
                                    propertyName
                                  };
                                  // }
                                  // return { propertyName };
                                });
                              } else {
                                // console.log('galo');

                                this.setState(prevState => {
                                  const used = [
                                    ...prevState.propertyNames.default
                                  ];
                                  //   .slice(
                                  //   0,
                                  //   5
                                  // );
                                  // console.log(used);
                                  return {
                                    propertyName,
                                    propertyNames: {
                                      used,
                                      // .map(item => ({ label: item }))
                                      default: prevState.propertyNames.default
                                    }
                                  };
                                });
                              }
                            }}
                            onSelect={(val, item) => {
                              // console.log(item);
                              const villaId = item.id;
                              this.props.history.push(
                                '/detail-villas?villas=' + villaId
                              );
                              this.setState({
                                propertyName: val
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column is-4-desktop is-6-mobile">
                      <FormInput
                        label={'Check In'}
                        onChange={e => {
                          let date = e.target.value;
                          // const startDate = moment(date);
                          const today = moment(Helper.getToday());
                          if (moment(date).diff(today, 'd') < 0) {
                            alert(
                              'error, check in must equal or greater than today'
                            );
                            e.target.value = '';
                            this.setState({
                              startDate: '',
                              startDateOriginal: null
                            });
                            date = null;
                          } else {
                            this.setState({
                              startDate: date,
                              startDateOriginal: date
                            });
                          }
                          localStorage.setItem('CHECK_IN', date);
                        }}
                        type={'date'}
                        placeholder={'Check In'}
                        defaultValue={this.state.startDate}
                      />
                    </div>
                    <div className="column is-4-desktop is-6-mobile">
                      <FormInput
                        label={'Check Out'}
                        onChange={e => {
                          const startDate = this.state.startDate;
                          if (startDate === '') {
                            alert('start date required');
                            e.target.value = '';
                            this.setState({ endDate: '' });
                            return;
                          }
                          let date = e.target.value;
                          // alert(this.state.startDate);
                          // const endDate = moment(date);
                          // return;
                          if (
                            moment(date).diff(this.state.startDate, 'd') < 0
                          ) {
                            alert(
                              'error, check out must greater than check in'
                            );
                            e.target.value = '';
                            this.setState({
                              endDate: '',
                              endDateOriginal: null
                            });
                            date = null;
                          } else {
                            this.setState({
                              endDate: date,
                              endDateOriginal: date
                            });
                          }
                          localStorage.setItem('CHECK_OUT', date);
                        }}
                        type={'date'}
                        placeholder={'Check Out'}
                        defaultValue={this.state.endDate}
                      />
                    </div>

                    <div className="column is-12-mobile is-4-desktop">
                      <SelectGuest
                        onChange={e => {
                          this.onChangeGuest(e);
                        }}
                      />
                    </div>
                    <div className="column is-12-mobile is-12-desktop">
                      <div className="d-flex justify-content-end align-items-end explore-btn">
                        <ExploreBtn onClick={e => this.filter(e)} />
                      </div>
                    </div>
                  </div>

                  <hr style={{ margin: 0 }} />
                  <div className="pt-2 ps-2">
                    <strong>Top Up</strong>
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'credits');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <Pulsa
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSize: 11
                          }}
                        >
                          {this.props.app.lang === 'id'
                            ? 'ISI PULSA'
                            : 'CREDITS'}
                        </strong>
                      </div>
                    </div>

                    {/* <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginRight: 5,
                        width: WIDTH_TOPUP_ITEM,
                        fontSize: this.state.topUp.fontSize
                      }}
                    >
                      <a
                        onClick={() => {
                          localStorage.setItem('topUp', 'data-packages');
                          localStorage.setItem('redirect', 'top-up/credits');
                          if (StringHelper.isEmpty(this.props.app.user)) {
                            this.props.toggleModal('modalLogin');
                          } else {
                            if(this.state.isTopUpEnabled)
                            this.props.history.push('top-up/credits');
                            else
                            alert('Feature is under development')
                          }
                        }}
                      >
                        <PaketData
                          height={this.state.topUp.iconSize}
                          width={this.state.topUp.iconSize}
                        />
                      </a>
                      <strong
                        style={{
                          color: 'black',
                          fontSize:11
                        }}
                      >
                        {this.props.app.lang === 'id'
                          ? 'PAKET DATA'
                          : 'DATA PACKAGES'}
                      </strong>
                    </div> */}

                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'token-listrik');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <PLN
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSize: 11
                          }}
                        >
                          PLN
                        </strong>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'e-wallet');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <EWalletIcon
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSize: 11
                          }}
                        >
                          E WALLET
                        </strong>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'ticket');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <TicketIcon
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSize: 11
                          }}
                        >
                          {this.props.app.lang === 'id'
                            ? 'TIKET NONTON'
                            : 'MOVIE TICKETS'}
                        </strong>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'voucher-games');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <GameIcon
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSize: 11
                          }}
                        >
                          {this.props.app.lang === 'id' ? 'PERMAINAN' : 'GAMES'}
                        </strong>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginRight: 5,
                          width: WIDTH_TOPUP_ITEM,
                          fontSize: this.state.topUp.fontSize
                        }}
                      >
                        <a
                          onClick={() => {
                            localStorage.setItem('topUp', 'e-money');
                            localStorage.setItem('redirect', 'top-up/credits');
                            if (StringHelper.isEmpty(this.props.app.user)) {
                              this.props.toggleModal('modalLogin');
                            } else {
                              if (this.state.isTopUpEnabled)
                                this.props.history.push('top-up/credits');
                              else
                                toast.error(
                                  isEn
                                    ? 'Feature is under development'
                                    : 'Fitur sedang dalam tahap pengembangan'
                                );
                            }
                          }}
                        >
                          <EMoneyIcon
                            height={this.state.topUp.iconSize}
                            width={this.state.topUp.iconSize}
                          />
                        </a>
                        <strong
                          style={{
                            color: 'black',
                            fontSi11: 14
                          }}
                        >
                          {this.props.app.lang === 'id' ? 'EMONEY' : 'EMONEY'}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className={IS_MOBILE ? 'pt-5 ps-2' : 'pt-2 ps-2'}>
                    <strong>Nusa Food</strong>
                  </div>
                  <div style={{ marginTop: 5, display: 'flex' }}>
                    {/* <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginRight: 5,
                        width: 80,
                        fontSize: this.state.topUp.fontSize
                      }}
                    >
                      <a
                        onClick={() => {
                          this.props.history.push('/restaurants/nusa-minang');
                        }}
                      >
                        <NusaMinangIcon
                          height={this.state.topUp.iconSize}
                          width={this.state.topUp.iconSize}
                        />
                      </a>
                      <strong
                        style={{
                          color: 'black',
                          fontS11e:14
                        }}
                      >
                        {this.props.app.lang === 'id'
                          ? 'NUSA MINANG'
                          : 'NUSA MINANG'}
                      </strong>
                    </div> */}

                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginRight: 5,
                        width: 80,
                        fontSize: this.state.topUp.fontSize
                      }}
                    >
                      <a
                        onClick={() => {
                          this.props.history.push('/restaurants/nusa-cafe');
                        }}
                      >
                        <NusaCafeIcon
                          height={this.state.topUp.iconSize}
                          width={this.state.topUp.iconSize}
                        />
                      </a>
                      <strong
                        style={{
                          color: 'black',
                          fontSize: 11
                        }}
                      >
                        {this.props.app.lang === 'id'
                          ? 'NUSA CAFE'
                          : 'NUSA CAFE'}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="quotes"
          style={{
            marginTop: -200,
            backgroundColor: '#F9F9F9',
            paddingTop: 230,
            textAlign: 'center',
            color: '#9B9B9B',
            paddingBottom: 50,
            fontWeight: 'bold'
          }}
        >
          {this.props.app.lang === 'id'
            ? '“Pengalaman Tinggal Di Hotel Fantastis Yang Belum Pernah Anda Rasakan Sebelumnya“'
            : '“Experience Living In Fantastic Hotels You Never Had Before“'}
        </section>

        <KosEksklusif history={this.props.history} />

        <TouristAttraction history={this.props.history} />

        {/* promo */}
        {this.state.vouchers.length > 0 && (
          <section
            className="section2Home inner box-list hide-on-mobile"
            style={{ marginTop: 30 }}
          >
            {/* DESKTOP */}
            <div className="container">
              <div
                className="p-5"
                style={{
                  backgroundColor: '#F3722B',
                  borderRadius: 20
                }}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div
                      className="d-flex justify-content-center flex-column"
                      style={{ height: '100%' }}
                    >
                      <div className="text-promo-menarik mb-3 ">
                        Promo Menarik
                      </div>
                      <div style={{ color: 'white' }} className="mb-4">
                        Dapatkan berbagai info promo terbaru hanya di
                        Nusalink.asia
                      </div>
                      <div>
                        <div
                          onClick={() => {
                            this.props.history.push('promo/all');
                          }}
                          style={{
                            background: `linear-gradient(180deg, #F77E3C 19.05%, #FF9B64 138.1%)`,
                            borderRadius: 10,
                            display: 'inline-block',
                            cursor: 'pointer'
                          }}
                          className="text-white px-2"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.125 4.15625V14.8438"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1.78125 12.4087C1.78122 12.2719 1.8285 12.1393 1.91506 12.0334C2.00162 11.9274 2.12214 11.8547 2.25619 11.8275C2.7926 11.7179 3.27469 11.4264 3.62092 11.0023C3.96714 10.5782 4.15625 10.0475 4.15625 9.5C4.15625 8.95251 3.96714 8.42182 3.62092 7.99771C3.27469 7.57359 2.7926 7.28209 2.25619 7.1725C2.12214 7.14528 2.00162 7.07256 1.91506 6.96664C1.8285 6.86072 1.78122 6.72814 1.78125 6.59135V4.75C1.78125 4.59253 1.84381 4.44151 1.95516 4.33016C2.06651 4.21881 2.21753 4.15625 2.375 4.15625H16.625C16.7825 4.15625 16.9335 4.21881 17.0448 4.33016C17.1562 4.44151 17.2188 4.59253 17.2188 4.75V6.59135C17.2188 6.72814 17.1715 6.86073 17.0849 6.96664C16.9984 7.07256 16.8779 7.14529 16.7438 7.1725C16.2074 7.28209 15.7253 7.5736 15.3791 7.99771C15.0329 8.42183 14.8437 8.95251 14.8437 9.5C14.8437 10.0475 15.0329 10.5782 15.3791 11.0023C15.7253 11.4264 16.2074 11.7179 16.7438 11.8275C16.8779 11.8547 16.9984 11.9274 17.0849 12.0334C17.1715 12.1393 17.2188 12.2719 17.2188 12.4087V14.25C17.2188 14.4075 17.1562 14.5585 17.0448 14.6698C16.9335 14.7812 16.7825 14.8438 16.625 14.8438H2.375C2.21753 14.8438 2.06651 14.7812 1.95516 14.6698C1.84381 14.5585 1.78125 14.4075 1.78125 14.25V12.4087Z"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {/* <i>Anam</i> */}
                            &nbsp;&nbsp;&nbsp; Dapatkan Sekarang!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.vouchers.slice(0, 2).map(item => (
                    <div
                      className="col-md-4"
                      style={{
                        // border: '2px solid ' + COLORS.primary,
                        // marginRight: 10,
                        textTransform: 'capitalize',
                        color: 'white',
                        borderRadius: 5
                      }}
                      key={item.id}
                      onClick={() => {
                        window.open('/promo/' + item.id);
                        // this.setState({
                        //   isModalPromo: true,
                        //   voucherModal: item
                        // });
                      }}
                    >
                      <img
                        style={{
                          maxWidth: 280,
                          borderRadius: 5,
                          height: 280,
                          cursor: 'pointer'
                        }}
                        src={BASE_URL_ADM + '/images/voucher/' + item.images}
                        alt={item.code}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* DESKTOP */}
          </section>
        )}
        {/* promo */}

        {/* Give Away */}
        {this.state.giveaway.length > 0 && (
          <section
            className="section2Home inner box-list"
            style={{ marginTop: 30 }}
          >
            {/* DESKTOP */}
            <div className="container">
              <div className="columns">
                <div className="column is-12 kolomResponbodyM">
                  <p
                    style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: 24,
                      marginBottom: 5,
                      marginTop: -10
                    }}
                  >
                    Give Away
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'unwrap',
                  maxWidth: '100%',
                  overflowX: 'auto'
                }}
              >
                {this.state.giveaway.map(item => (
                  <div
                    style={{
                      // border: '2px solid ' + COLORS.primary,
                      marginRight: 10,
                      textTransform: 'capitalize',
                      color: 'white',
                      borderRadius: 5
                    }}
                    key={item.id}
                    onClick={() => {
                      this.props.history.push('give-away-detail/' + item.id);
                      // this.setState({
                      //   isModalPromo: true,
                      //   voucherModal: item
                      // });
                    }}
                  >
                    <img
                      style={{
                        maxWidth: 280,
                        borderRadius: 5,
                        height: 280,
                        cursor: 'pointer'
                      }}
                      src={item.image_url}
                      alt={'Give away' + item.id}
                    />
                  </div>
                ))}
              </div>
              <ShowAll
                onClick={() => {
                  this.props.history.push('/give-away/all');
                }}
              />
            </div>
            {/* DESKTOP */}
          </section>
        )}
        {/* Give Away */}

        {/* VILLA POPULER */}
        <section
          onClick={e => this.eventOnCloseGuest(e)}
          className="section2Home inner box-list"
        >
          <div className="hide-on-desktop">{voucherSliders}</div>
          {/* DESKTOP */}
          <div
            className="container desktopsHome"
            onClick={e => this.eventOnCloseGuest(e)}
          >
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <p
                style={{
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: 24,
                  marginBottom: 5,
                  marginTop: -10
                }}
              >
                {t.explorervilla}{' '}
              </p>
              <OrangeBtn
                label={t.showallvilla}
                onClick={() => {
                  this.props.history.push('/villas?popular=true');
                }}
              />
            </div>

            <div
              className="columns is-multiline"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              {this.state.hotelHome &&
                this.state.hotelHome !== '' &&
                this.state.hotelHome.map((items, index) => (
                  <div
                    className="column is-4 boxListVillas"
                    key={index.toString()}
                  >
                    <div
                      className="box boxheight"
                      style={{
                        padding: 0,
                        borderRadius: 4,
                        paddingBottom: 10
                      }}
                    >
                      <div
                        className="columns is-multiline"
                        style={{ marginBottom: -15 }}
                      >
                        <div
                          className="column is-12 text-right"
                          style={{ paddingTop: 0 }}
                        >
                          {items.payAtVilla === 1 && <PayAtProperty />}
                          <Slider {...settings}>
                            {items.images.length > 0 &&
                              [items.images[0]].map(i => (
                                <div
                                  className="slide-item"
                                  style={{
                                    marginTop: -15
                                  }}
                                  key={i.toString()}
                                >
                                  <figure image="true" is-4by3="true">
                                    <img
                                      className="images-galery imgVillasList"
                                      onClick={e =>
                                        this.gotodetail(e, items.id)
                                      }
                                      src={`${BASE_URL_ADM}/images/hotel/${i}`}
                                      alt=""
                                    />
                                  </figure>
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </div>
                      <div className="columns kolomkotanama">
                        <div className="column is-12">
                          <div
                            className="columns is-mobile"
                            style={{
                              marginBottom: -5
                            }}
                          >
                            <div className="column is-12 boxKonten180913">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  style={{ width: 'auto' }}
                                  onClick={e => this.gotodetail(e, items.id)}
                                  className="labelTypeVilla180913"
                                >
                                  {items.category_name}
                                </label>
                                <Star star={items.star} />
                              </div>
                              <div
                                onClick={e => this.gotodetail(e, items.id)}
                                className="labelCityName180913"
                              >
                                <img
                                  src="/icon/point-location.png"
                                  alt="Nusalink"
                                  className="iconLocationVilla180913"
                                />
                                <label className="labelAddress180914">
                                  {items.VillaLocations.length > 0
                                    ? this.toTitleCase(
                                        items.VillaLocations[0].nama_kota
                                      ) +
                                      ', ' +
                                      this.toTitleCase(
                                        items.VillaLocations[0].nama_provinsi
                                      )
                                    : ''}
                                </label>
                              </div>
                            </div>
                          </div>
                          <b
                            className="kolomnama labelNameVilla180914"
                            onClick={e => this.gotodetail(e, items.id)}
                          >
                            {/*(items.name).length > 30 ? (items.name).substring(0, 26) + '...' : items.name*/}
                            {items.name}
                          </b>
                        </div>
                      </div>
                      <div
                        className="columns"
                        style={{
                          marginLeft: 5,
                          marginRight: 5
                        }}
                      >
                        <div className="column is-12 kolomharga">
                          <span
                            style={{
                              fontSize: 14,
                              color: 'black'
                            }}
                          >
                            <StartFrom
                              price={
                                t.currency +
                                ' ' +
                                Numeral(Math.floor(items.startFrom)).format(
                                  '0,0'
                                ) +
                                ' ' +
                                t.pernight
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {!this.state.hotelHome && (
                <div className="column is-12">
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}
              {this.state.hotelHome &&
                this.state.hotelHome.length === 0 &&
                this.state.loading === false && (
                  <div className="column is-12">
                    <div className="has-text-centered">
                      <img
                        src={`${BASE_URL_ADM}/images/Not_Found.png`}
                        alt=""
                        width="500"
                        height="500"
                      />
                    </div>
                    <br />
                    <center>
                      <h4>{t.hotelNotFound}</h4>
                    </center>
                  </div>
                )}
            </div>
          </div>

          {/* MOBILE */}
          <div
            className="container is-fullhd mobilesHome"
            onClick={e => this.eventOnCloseGuest(e)}
          >
            <div className="columns " onClick={e => this.eventOnCloseGuest(e)}>
              <div
                className="column is-12 kolomResponhead"
                onClick={e => this.eventOnCloseGuest(e)}
              >
                <p
                  style={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: 24,
                    marginBottom: 5,
                    marginTop: -10
                  }}
                >
                  {' '}
                  {t.explorervilla}{' '}
                </p>
              </div>
            </div>
            <div
              className="columns is-multiline"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <div
                className="column is-12"
                style={{ marginLeft: -20, paddingRight: 0 }}
              >
                <Slider {...settingsResponsive}>
                  {this.state.hotelHome &&
                    this.state.hotelHome !== '' &&
                    this.state.hotelHome.map((items, index) => (
                      <div
                        className="column is-4 boxListVillas"
                        key={index.toString()}
                      >
                        <div
                          className="box boxheight"
                          style={{
                            padding: 0,
                            borderRadius: 4,
                            paddingBottom: 10
                          }}
                        >
                          <div
                            className="columns"
                            style={{
                              marginBottom: -15
                            }}
                          >
                            <div
                              className="column is-12 text-right"
                              style={{
                                paddingTop: 0
                              }}
                            >
                              {items.payAtVilla === 1 && <PayAtProperty />}
                              <Slider {...settingsImage}>
                                {items.images.length > 0 &&
                                  [items.images[0]].map(i => (
                                    <div
                                      className="slide-item"
                                      style={{
                                        marginTop: -15
                                      }}
                                      key={i.toString()}
                                    >
                                      <figure image="true" is-4by3="true">
                                        <img
                                          className="images-galery imgVillasList"
                                          onClick={e =>
                                            this.gotodetail(e, items.id)
                                          }
                                          src={`${BASE_URL_ADM}/images/hotel/${i}`}
                                          alt=""
                                        />
                                      </figure>
                                    </div>
                                  ))}
                              </Slider>
                            </div>
                          </div>
                          <div className="columns kolomkotanama">
                            <div className="column is-12">
                              <div
                                className="columns is-mobile"
                                style={{
                                  marginBottom: -5
                                }}
                              >
                                <div className="column is-12 boxKonten180913">
                                  <label
                                    onClick={e => this.gotodetail(e, items.id)}
                                    className="labelTypeVilla180913"
                                  >
                                    {items.category_name}
                                  </label>
                                  <Star star={items.star} />
                                  <br />
                                  <div
                                    onClick={e => this.gotodetail(e, items.id)}
                                    className="labelCityName180913"
                                  >
                                    <img
                                      src="/icon/point-location.png"
                                      alt="Nusalink"
                                      className="iconLocationVilla180913"
                                    />
                                    <label className="labelAddress180914">
                                      {items.VillaLocations.length > 0
                                        ? this.toTitleCase(
                                            items.VillaLocations[0].nama_kota
                                          ) +
                                          ', ' +
                                          this.toTitleCase(
                                            items.VillaLocations[0]
                                              .nama_provinsi
                                          )
                                        : ''}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <b
                                className="kolomnama labelNameVilla180914"
                                onClick={e => this.gotodetail(e, items.id)}
                              >
                                {/*(items.name).length > 27 ? (items.name).substring(0, 22) + '...' : items.name*/}
                                {items.name}
                              </b>
                            </div>
                          </div>
                          <div
                            className="columns"
                            style={{
                              marginLeft: 5,
                              marginRight: 5
                            }}
                          >
                            <div className="column is-12 kolomharga">
                              <span
                                style={{
                                  fontSize: 14,
                                  color: 'black'
                                }}
                              >
                                <StartFrom
                                  price={
                                    t.currency +
                                    ' ' +
                                    Numeral(Math.floor(items.startFrom)).format(
                                      '0,0'
                                    ) +
                                    ' ' +
                                    t.pernight
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
                {!this.state.hotelHome && (
                  <div className="column is-12">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {this.state.hotelHome &&
                  this.state.hotelHome.length === 0 &&
                  this.state.loading === false && (
                    <div className="column is-12">
                      <div className="has-text-centered">
                        <img
                          src={`${BASE_URL_ADM}/images/Not_Found.png`}
                          alt=""
                          width="500"
                          height="500"
                        />
                      </div>
                      <br />
                      <center>
                        <h4>{t.hotelNotFound}</h4>
                      </center>
                    </div>
                  )}
              </div>
              <section
                onClick={e => this.eventOnCloseGuest(e)}
                className="section2Home inner box-list"
              >
                <div className="container">
                  <div className="columns">
                    <div className="column" style={{ marginTop: -35 }}>
                      <Link to="/villas?popular=true">
                        <span className="read-more-home">
                          {' '}
                          {t.showallvilla}{' '}
                        </span>
                        &nbsp;
                        <span className="read-more-home"> {'>'} </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        {/* VILLAPOPULER */}

        {/* VILLA TAG */}
        {this.state.tagsHotels.length > 0 && (
          <section
            onClick={e => this.eventOnCloseGuest(e)}
            className="section2Home inner box-list"
          >
            {/* DESKTOP */}
            <div
              className="container desktopsHome"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <div
                className="d-flex justify-content-between align-items-center mb-4"
                onClick={e => this.eventOnCloseGuest(e)}
              >
                <p
                  style={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: 24,
                    marginBottom: 5,
                    marginTop: -10
                  }}
                >
                  {t.tagsHOme}{' '}
                </p>
                <OrangeBtn
                  label={t.showallvilla}
                  onClick={() => {
                    this.props.history.push('/villas');
                  }}
                />
              </div>

              <div
                className="columns is-multiline"
                onClick={e => this.eventOnCloseGuest(e)}
              >
                {this.state.tagsHotels.map((items, index) => (
                  <div
                    className="column is-4 boxListVillas"
                    key={index.toString()}
                  >
                    <div
                      className="box boxheight"
                      style={{
                        padding: 0,
                        borderRadius: 4,
                        paddingBottom: 10
                      }}
                    >
                      <div className="columns" style={{ marginBottom: -15 }}>
                        <div className="column is-12" style={{ paddingTop: 0 }}>
                          {items.payAtVilla === 1 && <PayAtProperty />}
                          <Slider {...settings}>
                            {items.images.length > 0 &&
                              [items.images[0]].map(i => (
                                <div
                                  className="slide-item"
                                  style={{
                                    marginTop: -15
                                  }}
                                  key={i.toString()}
                                >
                                  <figure image="true" is-4by3="true">
                                    <img
                                      className="images-galery imgVillasList"
                                      onClick={e =>
                                        this.gotodetail(e, items.id)
                                      }
                                      src={`${BASE_URL_ADM}/images/hotel/${i}`}
                                      alt=""
                                    />
                                  </figure>
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </div>
                      <div className="columns kolomkotanama">
                        <div className="column is-12">
                          <div
                            className="columns is-mobile"
                            style={{
                              marginBottom: -5
                            }}
                          >
                            <div className="column is-12 boxKonten180913">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  style={{ width: 'auto' }}
                                  onClick={e => this.gotodetail(e, items.id)}
                                  className="labelTypeVilla180913"
                                >
                                  {items.category_name}
                                </label>
                                <Star star={items.star} />
                              </div>
                              <div
                                onClick={e => this.gotodetail(e, items.id)}
                                className="labelCityName180913"
                              >
                                <img
                                  src="/icon/point-location.png"
                                  alt="Nusalink"
                                  className="iconLocationVilla180913"
                                />
                                <label className="labelAddress180914">
                                  {items.VillaLocations.length > 0
                                    ? this.toTitleCase(
                                        items.VillaLocations[0].nama_kota
                                      ) +
                                      ', ' +
                                      this.toTitleCase(
                                        items.VillaLocations[0].nama_provinsi
                                      )
                                    : ''}
                                </label>
                              </div>
                            </div>
                          </div>
                          <b
                            className="kolomnama labelNameVilla180914"
                            onClick={e => this.gotodetail(e, items.id)}
                          >
                            {/*(items.name).length > 30 ? (items.name).substring(0, 26) + '...' : items.name*/}
                            {items.name}
                          </b>
                        </div>
                      </div>
                      <div
                        className="columns"
                        style={{
                          marginLeft: 5,
                          marginRight: 5
                        }}
                      >
                        <div className="column is-12 kolomharga">
                          <span
                            style={{
                              fontSize: 14,
                              color: 'black'
                            }}
                          >
                            <StartFrom
                              price={
                                t.currency +
                                ' ' +
                                Numeral(Math.floor(items.startFrom)).format(
                                  '0,0'
                                ) +
                                ' ' +
                                t.pernight
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* DESKTOP */}
            {/*  MOBILE*/}
            <div
              className="container is-fullhd mobilesHome"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <div
                className="columns "
                onClick={e => this.eventOnCloseGuest(e)}
              >
                <div
                  className="column is-12 kolomResponheadTag"
                  onClick={e => this.eventOnCloseGuest(e)}
                >
                  <p
                    style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: 24,
                      marginBottom: 5,
                      marginTop: -10
                    }}
                  >
                    {' '}
                    {t.tagsHOme}{' '}
                  </p>
                </div>
              </div>
              <div
                className="columns is-multiline"
                onClick={e => this.eventOnCloseGuest(e)}
              >
                <div
                  className="column is-12"
                  style={{ marginLeft: -20, paddingRight: 0 }}
                >
                  <Slider {...settingsResponsive}>
                    {this.state.tagsHotels &&
                      this.state.tagsHotels !== '' &&
                      this.state.tagsHotels.map((items, index) => (
                        <div
                          className="column is-4 boxListVillas"
                          key={index.toString()}
                        >
                          <div
                            className="box boxheight"
                            style={{
                              padding: 0,
                              borderRadius: 4,
                              paddingBottom: 10
                            }}
                          >
                            <div
                              className="columns"
                              style={{
                                marginBottom: -15
                              }}
                            >
                              <div
                                className="column is-12 text-right"
                                style={{
                                  paddingTop: 0
                                }}
                              >
                                {items.payAtVilla === 1 && <PayAtProperty />}
                                <Slider {...settingsImage}>
                                  {items.images.length > 0 &&
                                    items.images.map(i => (
                                      <div
                                        className="slide-item"
                                        style={{
                                          marginTop: -15
                                        }}
                                        key={i.toString()}
                                      >
                                        <figure image="true" is-4by3="true">
                                          <img
                                            className="images-galery imgVillasList"
                                            onClick={e =>
                                              this.gotodetail(e, items.id)
                                            }
                                            src={`${BASE_URL_ADM}/images/hotel/${i}`}
                                            alt=""
                                          />
                                        </figure>
                                      </div>
                                    ))}
                                </Slider>
                              </div>
                            </div>
                            <div className="columns kolomkotanama">
                              <div className="column is-12">
                                <div
                                  className="columns is-mobile"
                                  style={{
                                    marginBottom: -5
                                  }}
                                >
                                  <div className="column is-12 boxKonten180913">
                                    <label
                                      onClick={e =>
                                        this.gotodetail(e, items.id)
                                      }
                                      className="labelTypeVilla180913"
                                    >
                                      {items.category_name}
                                    </label>
                                    <br />
                                    <div
                                      onClick={e =>
                                        this.gotodetail(e, items.id)
                                      }
                                      className="labelCityName180913"
                                    >
                                      <img
                                        src="/icon/point-location.png"
                                        alt="Nusalink"
                                        className="iconLocationVilla180913"
                                      />
                                      <label className="labelAddress180914">
                                        {items.VillaLocations.length > 0
                                          ? this.toTitleCase(
                                              items.VillaLocations[0].nama_kota
                                            ) +
                                            ', ' +
                                            this.toTitleCase(
                                              items.VillaLocations[0]
                                                .nama_provinsi
                                            )
                                          : ''}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <b
                                  className="kolomnama labelNameVilla180914"
                                  onClick={e => this.gotodetail(e, items.id)}
                                >
                                  {/*(items.name).length > 27 ? (items.name).substring(0, 22) + '...' : items.name*/}
                                  <Star star={items.star} />
                                  {items.name}
                                </b>
                              </div>
                            </div>
                            <div
                              className="columns"
                              style={{
                                marginLeft: 5,
                                marginRight: 5
                              }}
                            >
                              <div className="column is-12 kolomharga">
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: 'black'
                                  }}
                                >
                                  <StartFrom
                                    price={
                                      t.currency +
                                      ' ' +
                                      Numeral(
                                        Math.floor(items.startFrom)
                                      ).format('0,0') +
                                      ' ' +
                                      t.pernight
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                  {!this.state.tagsHotels && (
                    <div className="column is-12">
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  )}
                  {this.state.tagsHotels &&
                    this.state.tagsHotels.length === 0 &&
                    this.state.loading === false && (
                      <div className="column is-12">
                        <div className="has-text-centered">
                          <img
                            src={`${BASE_URL_ADM}/images/Not_Found.png`}
                            alt=""
                            width="500"
                            height="500"
                          />
                        </div>
                        <br />
                        <center>
                          <h4>{t.hotelNotFound}</h4>
                        </center>
                      </div>
                    )}
                </div>
                <section
                  onClick={e => this.eventOnCloseGuest(e)}
                  className="section2Home inner box-list"
                >
                  <div className="container">
                    <div className="columns">
                      <div className="column" style={{ marginTop: -35 }}>
                        <Link to="/villas">
                          <span className="read-more-home">
                            {' '}
                            {t.showallvilla}{' '}
                          </span>
                          &nbsp;
                          <span className="read-more-home"> {'>'} </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {/* MOBILE */}
          </section>
        )}
        {/* VILLATAG */}

        {/* EVENT */}
        {this.state.eventHome.length > 0 && (
          <section
            onClick={e => this.eventOnCloseGuest(e)}
            className="section2Home inner box-list"
          >
            {/* DESKTOP */}
            <div
              className="container desktopsHome"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <div className="columns" onClick={e => this.eventOnCloseGuest(e)}>
                <div
                  className="column is-12 kolomResponbodyM"
                  onClick={e => this.eventOnCloseGuest(e)}
                >
                  <p
                    style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: 24,
                      marginBottom: 5,
                      marginTop: -10
                    }}
                  >
                    Upcoming Events
                  </p>
                </div>
              </div>

              <EventList />
              <section
                onClick={e => this.eventOnCloseGuest(e)}
                className="section2Home inner box-list"
              >
                <div className="container">
                  <div className="columns">
                    <div className="column" style={{ marginTop: 20 }}>
                      <Link to="/events">
                        <span className="read-more-home">
                          {' '}
                          {t.showallvilla}{' '}
                        </span>
                        &nbsp;
                        <span className="read-more-home"> {'>'} </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* DESKTOP */}
            {/* MOBILE */}
            <div
              className="container mobilesHome"
              onClick={e => this.eventOnCloseGuest(e)}
            >
              <div className="columns" onClick={e => this.eventOnCloseGuest(e)}>
                <div
                  className="column is-12 kolomResponbody"
                  onClick={e => this.eventOnCloseGuest(e)}
                >
                  <p
                    style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: 24,
                      marginBottom: 5,
                      marginTop: -10
                    }}
                  >
                    Upcoming Events
                  </p>
                </div>
              </div>
              <div
                className="columns is-multiline"
                onClick={e => this.eventOnCloseGuest(e)}
              >
                <div className="column is-12" style={{ marginLeft: -20 }}>
                  <Slider {...settingsResponsive}>
                    {this.state.eventHome.map((items, index) => (
                      <div
                        className="column is-3 boxListVillas"
                        key={index.toString()}
                      >
                        <div
                          className="box boxheight"
                          style={{
                            padding: 0,
                            borderRadius: 4,
                            paddingBottom: 10
                          }}
                        >
                          <div
                            className="columns"
                            style={{
                              marginBottom: -15
                            }}
                          >
                            <div
                              className="column is-12"
                              style={{
                                paddingTop: 0
                              }}
                            >
                              <div
                                className="slide-item"
                                style={{
                                  marginTop: -15
                                }}
                              >
                                <figure image is-4by3>
                                  <img
                                    alt=""
                                    className="images-galery imgVillasList"
                                    onClick={e => this.gotodetail(e, items.id)}
                                    src="/img/architecture-beach.jpg"
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                          <div className="columns kolomeventnama">
                            <div className="column is-12">
                              <p className="namaevent"> {items.category} </p>
                            </div>
                          </div>
                          <div className="columns kolomkotanama">
                            <div className="column is-12">
                              <b
                                className="kolomnama"
                                onClick={e => this.gotodetail(e, items.id)}
                                style={{
                                  color: 'black',
                                  fontSize: 18
                                }}
                              >
                                {' '}
                                {items.nameEvent}{' '}
                              </b>
                            </div>
                          </div>
                          <div className="columns kolomkotanama">
                            <div className="column is-12">
                              <div
                                className="columns is-mobile"
                                style={{
                                  marginTop: -30
                                }}
                              >
                                <div className="column is-12">
                                  <label
                                    onClick={e => this.gotodetail(e, items.id)}
                                    className="labelCityName180913"
                                  >
                                    <img
                                      src="/icon/point-location.png"
                                      alt="Nusalink"
                                      className="iconLocationVilla180913"
                                    />
                                    {items.nama_kota}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="columns kolomkalender is-multiline is-mobile">
                            <div className="column is-2 is-multiline is-mobile">
                              <img
                                src="/icon/calander-home.png"
                                width="20"
                                alt=""
                              />
                            </div>
                            <div
                              className="column is-10 is-multiline is-mobile"
                              style={{
                                marginLeft: -20
                              }}
                            >
                              {items.schedule.map((jadwal, indexX) => (
                                <label
                                  style={{
                                    paddingLeft: 10
                                  }}
                                >
                                  {moment(jadwal.eventDates).format('D') +
                                    ' ' +
                                    (this.props.app.lang === '' ||
                                    this.props.app.lang === 'id'
                                      ? dateIN[
                                          moment(jadwal.eventDates).format(
                                            'M'
                                          ) - 1
                                        ]
                                      : dateEN[
                                          moment(jadwal.eventDates).format(
                                            'M'
                                          ) - 1
                                        ]) +
                                    ' ' +
                                    moment(jadwal.eventDates).format('Y')}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <section
                  onClick={e => this.eventOnCloseGuest(e)}
                  className="section2Home inner box-list"
                >
                  <div className="container">
                    <div className="columns">
                      <div className="column" style={{ marginTop: 20 }}>
                        <Link to="/villas">
                          <span className="read-more-home">
                            {' '}
                            {t.showallvilla}{' '}
                          </span>
                          &nbsp;
                          <span className="read-more-home"> {'>'} </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {/* MOBILE */}
          </section>
        )}

        {/* hotel list city*/}
        <section
          style={{
            backgroundImage: `url('/images/home_bg_2.png')`
          }}
        >
          {/* DESKTOP */}
          <div className="container">
            <div className="columns">
              <div className="column is-12 kolomResponbodyM">
                <p
                  style={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: 24,
                    marginTop: 10
                  }}
                >
                  Property Popular In Cities
                </p>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 20 }}
            >
              {this.state.activeCities.map(item => (
                <div
                  style={{
                    // backgroundColor: COLORS.primary,
                    background: `linear-gradient(180deg, #F77E3C 19.05%, #FF9B64 138.1%)`,
                    padding: '5px 10px',
                    marginRight: 10,
                    textTransform: 'capitalize',
                    marginBottom: 5,
                    color: 'white',
                    borderRadius: 10,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    this.props.history.push('/villas?city=' + item.id);
                  }}
                  key={item.id}
                >
                  <CrossHairIcon /> &nbsp;
                  {Helper.kota(item.nama_kota)}
                </div>
              ))}
              {this.state.activeCities.length !== this.state.cities.length && (
                <div
                  style={{
                    // backgroundColor: COLORS.primary,
                    background: '#E3E3E3',
                    padding: '5px 20px',
                    marginRight: 10,
                    textTransform: 'capitalize',
                    marginBottom: 5,
                    color: 'white',
                    borderRadius: 10,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    // this.props.history.push('/villas?city=' + item.id);
                    this.setState({
                      activeCities: [...this.state.cities]
                    });
                  }}
                >
                  <RightArrowIcon />
                </div>
              )}
            </div>
          </div>
          {/* DESKTOP */}
        </section>

        {/* hotel list city */}

        <div
          className={
            'modal ' + (this.props.app.modalPopDates ? 'is-active' : '')
          }
        >
          <div
            className="modal-background"
            onClick={() => this.props.toggleModal('modalPopDates')}
          />
          <div className="modal-content" style={{ width: 400 }}>
            <div className="box">
              <h6 className="subtitle is-6 has-text-centered">{t.alertDate}</h6>
              <button
                className="button bg-primary is-fullwidth"
                onClick={() => this.props.toggleModal('modalPopDates')}
              >
                Close
              </button>
            </div>
            <br />
          </div>
        </div>
        <div
          className={'modal ' + (this.props.app.modalGuest ? 'is-active' : '')}
        >
          <div
            className="modal-background"
            onClick={() => this.props.toggleModal('modalGuest')}
          />
          <div className="modal-content" style={{ width: 400 }}>
            <div className="box">
              <h6 className="subtitle is-6 has-text-centered">
                {t.alertGuest}
              </h6>
              <button
                className="button bg-primary is-fullwidth"
                onClick={() => this.props.toggleModal('modalGuest')}
              >
                Close
              </button>
            </div>
            <br />
          </div>
        </div>
        {/* <section
          onClick={(e) => this.eventOnCloseGuest(e)}
          className="section2Home inner box-list"
        >
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <div className="emptybox" />
              </div>
            </div>
          </div>
        </section> */}

        {/* {this.state.isModalPromo && this.state.voucherModal !== null && (
          <div
            onClick={() => {
              this.setState({ isModalPromo: false });
            }}
            style={{
              position: 'fixed',
              zIndex: 9999,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column'
              }}
            >
              <img
                src={
                  BASE_URL_ADM +
                  '/images/voucher/' +
                  this.state.voucherModal.images
                }
                alt={this.state.voucherModal.code}
                style={{ borderRadius: 20, objectFit: 'cover', maxWidth: 800 }}
              />
              <a
                style={{ color: 'white', fontSize: 20, marginTop: 10 }}
                onClick={() => {
                  this.setState({ isModalPromo: false });
                }}
              >
                X
              </a>
            </div>
          </div>
        )} */}
        {this.state.isModalPromo && this.state.popupPromo !== null && (
          <div
            onClick={e => {
              // console.log(
              //   e.target,
              //   e.target === document.getElementById('HAHAHA')
              // );
              if (e.target === document.getElementById('HAHAHA')) {
                this.setState({ isModalPromo: false });
              }
            }}
            style={{
              position: 'fixed',
              zIndex: 9999,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }}
          >
            <div
              id={'HAHAHA'}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column'
              }}
            >
              <div
                className="popup-promo"
                style={{
                  width: '100%'
                }}
              >
                {this.state.popupPromo.length > 0 && (
                  <Slider {...sliderSettings}>
                    {this.state.popupPromo.map((item, index) => (
                      <div>
                        <img
                          key={item.id}
                          onClick={() => {
                            window.open('/promo/' + item.id);
                          }}
                          src={item.image_url}
                          className="popup-image"
                          alt={item.code}
                          style={{
                            borderRadius: 20,
                            objectFit: 'contain',
                            cursor: 'pointer',
                            height: '90vh',
                            margin: 'auto'
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
              {/* <img
                onClick={() => {
                  window.open('/promo/' + this.state.popupPromo.id);
                }}
                src={
                  BASE_URL_ADM +
                  '/images/voucher/' +
                  this.state.popupPromo.images
                }
                className="popup-image"
                alt={this.state.popupPromo.code}
                style={{
                  borderRadius: 20,
                  objectFit: 'cover',
                  cursor: 'pointer'
                }}
              /> */}
              <a
                style={{ color: 'white', fontSize: 20, marginTop: 10 }}
                onClick={() => {
                  this.setState({ isModalPromo: false });
                }}
              >
                X
              </a>
            </div>
          </div>
        )}
      </div>
      //modal popup </div>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {
  showModal,
  toggleModal,
  setMessage,
  setPages,
  setMessageMId,
  setBoxFilterDetail
});
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Home));
