import React, { Component } from 'react';

export class PageTitle extends Component {
  render() {
    return (
      <div className="column is-12">
        <h1 className="is-size-4" style={{ fontWeight: 'bold' }}>
          {this.props.title}
        </h1>
        <hr />
      </div>
    );
  }
}

export default PageTitle;
