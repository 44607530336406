import React, { useEffect } from 'react';
import { COLORS } from '../../constants';
// import FormInput from '../form/FormInput';
import SelectOption from '../form/SelectOption';
import { rupiah } from './../../utils/helper';
// import $ from 'jquery';

export default function MutipleBooking({
  multiples,
  rooms,
  onChange,
  currency,
  setRoomSelected,
  roomSelected
}) {
  const hehe = (
    <tr>
      <td colSpan="4">
        <table style={{ width: '100%' }}>
          <tbody>
            {multiples.map((multiple, multipleIndex) => {
              return [
                <tr key={multipleIndex + 1}>
                  <td>{multiple.date}</td>
                </tr>,
                <tr key={multipleIndex + 2}>
                  <td>
                    {multiple.rooms.map((room, roomIndex) => {
                      return [
                        <div
                          key={roomIndex + multipleIndex}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                            // borderBottom: '1px solid black'
                          }}
                        >
                          <SelectOption
                            onChange={e => {
                              const roomId = e.target.value;
                              // alert(roomId);
                              const rateSelected = rooms
                                .find(
                                  roomMaster =>
                                    Number(roomMaster.id) === Number(roomId)
                                )
                                .rates.find(
                                  rate => rate.rateDate === multiple.date
                                );
                              const rate = rateSelected.fix_price;
                              console.log('rateSelected', rateSelected);
                              const newMultiples = [...multiples];
                              newMultiples[multipleIndex]['rooms'][
                                roomIndex
                              ] = {
                                rate,
                                roomId
                              };
                              let subTotal = 0;
                              // console.log(newMultiples);let subTotal = 0;
                              newMultiples.forEach(item => {
                                subTotal += item.rooms
                                  .map(room => room.rate)
                                  .reduce((a, b) => a + b);
                              });
                              // console.log(newMultiples);
                              onChange({
                                multiples: newMultiples,
                                subTotal
                              });
                            }}
                            id={'roomSelectOption' + multipleIndex + roomIndex}
                          >
                            {rooms.map(roomMaster => (
                              <option
                                key={roomMaster.id}
                                value={roomMaster.id}
                                defaultValue={room.roomId}
                              >
                                {roomMaster.name}
                              </option>
                            ))}
                          </SelectOption>
                          <div style={{ marginLeft: 10 }}>
                            {currency + ' '}
                            {rupiah(room.rate)}

                            {roomIndex !== 0 && (
                              <a
                                style={{ color: 'red' }}
                                onClick={() => {
                                  const newMultiples = [...multiples];
                                  newMultiples[multipleIndex]['rooms'].splice(
                                    multipleIndex,
                                    1
                                  );
                                  let subTotal = 0;
                                  // console.log(newMultiples);let subTotal = 0;
                                  newMultiples.forEach(item => {
                                    subTotal += item.rooms
                                      .map(room => room.rate)
                                      .reduce((a, b) => a + b);
                                  });
                                  // console.log(newMultiples);
                                  onChange({
                                    multiples: newMultiples,
                                    subTotal
                                  });
                                }}
                              >
                                X
                              </a>
                            )}
                          </div>
                        </div>
                        // <hr style={{ marginTop: 2, marginBottom: 2 }} />
                      ];
                      // return [
                      //   <tr key={multipleIndex + roomIndex}>
                      //     <td>
                      //       <SelectOption
                      //         onChange={e => {
                      //           // const roomId = e.target.value;
                      //           // const rate = rooms
                      //           //   .find(item => Number(item.id) === Number(roomId))
                      //           //   .rates.find(rate => rate.rateDate === item.date).rate;
                      //           // // console.log('rate')
                      //           // const newMultiples = [...multiples];
                      //           // newMultiples[index]['rooms'][index] = {
                      //           //   rate,
                      //           //   roomId
                      //           // };
                      //           // onChange(newMultiples);
                      //         }}
                      //       >
                      //         {rooms.map(roomMaster => (
                      //           <option
                      //             key={roomMaster.id}
                      //             defaultValue={room.roomId}
                      //           >
                      //             {roomMaster.name}
                      //           </option>
                      //         ))}
                      //       </SelectOption>
                      //     </td>
                      //     <td>{rupiah(room.rate)}</td>
                      //   </tr>
                      // ];
                    })}
                  </td>
                </tr>,
                <tr key={multipleIndex + 3}>
                  <td>
                    <a
                      onClick={() => {
                        const firstRoom = rooms[0];
                        const newMultiples = [...multiples];
                        const roomId = firstRoom.id;
                        const rate = firstRoom.rates.find(
                          rate => rate.rateDate === multiple.date
                        ).fix_price;
                        newMultiples[multipleIndex]['rooms'] = [
                          ...newMultiples[multipleIndex]['rooms'],
                          {
                            rate,
                            roomId
                          }
                        ];
                        let subTotal = 0;
                        newMultiples.forEach(item => {
                          subTotal += item.rooms
                            .map(room => room.rate)
                            .reduce((a, b) => a + b);
                        });
                        // console.log(newMultiples);
                        onChange({ multiples: newMultiples, subTotal });
                      }}
                      style={{ color: COLORS.primary }}
                    >
                      Add new room
                    </a>
                  </td>
                </tr>,
                <tr key={multipleIndex + 4}>
                  <td>
                    <hr style={{ marginTop: 2, marginBottom: 2 }} />
                  </td>
                </tr>
              ];
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );

  useEffect(() => {}, []);

  return hehe;

  // return [
  //   <tr>
  //     <td colSpan="4">
  //       <SelectOption
  //         id="roomSelectOption"
  //         onChange={e => {
  //           const roomId = e.target.value;
  //           setRoomSelected(roomId);
  //           setTimeout(() => {
  //             const room = rooms.find(
  //               room => Number(room.id) === Number(roomId)
  //             );
  //             const rates = room.rates;
  //             const newMultiples = [...multiples];
  //             const subTotal = rates
  //               .map((item, index) => item.fix_price * multiples[index]['qty'])
  //               .reduce((a, b) => a + b);
  //             onChange({ subTotal, multiples: newMultiples });
  //           }, 500);
  //           // const roomId = e.target.value;
  //           // const rate = rooms
  //           //   .find(item => Number(item.id) === Number(roomId))
  //           //   .rates.find(rate => rate.rateDate === item.date).rate;
  //           // // console.log('rate')
  //           // const newMultiples = [...multiples];
  //           // newMultiples[indexM]['rooms'][index] = {
  //           //   rate,
  //           //   roomId
  //           // };
  //           // onChange(newMultiples);
  //         }}
  //       >
  //         {rooms.map(room => (
  //           <option key={room.id} value={room.id} defaultValue={rooms[0]['id']}>
  //             {room.name}
  //           </option>
  //         ))}
  //       </SelectOption>
  //     </td>
  //   </tr>,
  //   multiples.map((item, indexM) => {
  //     const room = rooms.find(room => Number(room.id) === Number(roomSelected));
  //     const rates = room.rates;
  //     const price =
  //       room.rates.find(rate => rate.rateDate === item.date).fix_price *
  //       item.qty;
  //     return (
  //       <tr key={item.date}>
  //         <td width="33%">{item.date}</td>
  //         <td width="33%">
  //           <strong>qty</strong>{' '}
  //           <input
  //             onChange={e => {
  //               const newMultiples = [...multiples];
  //               newMultiples[indexM]['qty'] = Number(e.target.value);
  //               const subTotal = rates
  //                 .map(
  //                   (item, index) => item.fix_price * multiples[index]['qty']
  //                 )
  //                 .reduce((a, b) => a + b);
  //               onChange({ subTotal, multiples: newMultiples });
  //             }}
  //             style={{ width: 50 }}
  //             type="number"
  //             min="1"
  //             max="5"
  //             id={'qty' + indexM}
  //             className="qty"
  //             defaultValue={item.qty}
  //           />
  //         </td>
  //         <td className="has-text-right">
  //           {currency}
  //           {rupiah(price)}
  //         </td>
  //       </tr>
  //     );
  //   })
  // ];
}
