import React, { Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';

class PhoneNumberInput extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <label className="labels">{t.phone}</label>
        <div className="field has-addons">
          <div className="control">
            <IntlTelInput
              defaultCountry="id"
              value={this.props.area}
              style={{ width: '100%', backgroundColor: 'red' }}
              onPhoneNumberChange={this.props.onChange}
            />
          </div>
          <div className="field-label labelCode">
            <div className="labelCodePhone">{this.props.area}</div>
          </div>
          <div className="control" style={{ width: '100%' }}>
            <input
              style={{
                paddingBottom: 5,
                paddingTop: 6,
                height: 'auto',
                borderRadius: 4,
              }}
              className="input input-reservation2"
              type="number"
              name="guestNoTelp"
              onChange={this.props.onChange2}
              value={this.props.phoneNumber}
              required
            />
          </div>
        </div>
        <p className="label-down" style={{ marginTop: -10 }}>
          {t.notePhone}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps);
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);
export default translator(connector(PhoneNumberInput));
