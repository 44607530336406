import React, { Component } from 'react';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
import StartFrom from '../../pages/layouts/StartFrom';
import SliderImages from './SliderImages';
import CityAndCategory from './CityAndCategory';
import PayAtVilla from './PayAtVilla';
import { rupiah } from '../../utils/helper';
import LabelPrimary from '../others/LabelPrimary';

class NewRoomList extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(index) {
    // if (room.unit === null || room.unit <= 0) {
    //   alert('allotment not available');
    // } else {
    this.props.onClick(index);
    // }
  }

  render() {
    const { t } = this.props;
    const property = this.props.property;
    const { categoryName, nama_kota, rooms } = property;
    const isEn = this.props.app.lang === 'en';
    return rooms.map((item, index) => {
      let perLabel = this.props.isWeekly
        ? t.week
        : this.props.isYearly
        ? t.year
        : t.month;
      perLabel =
        item.stayMonthly.per === 1
          ? perLabel
          : item.stayMonthly.per + ' ' + perLabel;
      return (
        <div className="column is-6" key={item.id}>
          <div
            className="box boxheight"
            style={{ padding: 0, borderRadius: 4, paddingBottom: 10 }}
          >
            <div className="columns" style={{ marginBottom: -15 }}>
              <div className="column is-12" style={{ paddingTop: 0 }}>
                <div style={{ position: 'relative' }}>
                  <label
                    style={{
                      fontSize: '12px',
                      color: 'white',
                      backgroundColor: '#F16726',
                      padding: '5px 10px',
                      borderRadius: '12px',
                      position: 'absolute',
                      marginBottom: 30,
                      marginRight: 10,

                      bottom: 0,
                      right: 0
                    }}
                  >
                    {item.stayMonthly.promo_name}
                  </label>
                  <SliderImages
                    images={item.images}
                    onClick={() => {
                      this.onClick(index);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="columns kolomkotanama">
              <div className="column is-12">
                <div className="columns is-mobile" style={{ marginBottom: -5 }}>
                  <div
                    className={
                      property.PayAtVilla === 1 ? 'column is-6' : 'column is-12'
                    }
                  >
                    <CityAndCategory
                      categoryName={categoryName}
                      city={nama_kota}
                    />
                  </div>
                  {property.PayAtVilla === 1 && (
                    <div className="column is-6">
                      <PayAtVilla />
                    </div>
                  )}
                </div>
                <b
                  onClick={() => {
                    this.onClick(index);
                  }}
                  className="kolomnama"
                  style={{ color: 'black', fontSize: 18 }}
                >
                  {item.name}
                </b>
              </div>
            </div>
            <div
              className="columns is-desktop"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <div
                className="column is-12 kolomharga"
                style={{ fontSize: 14, color: 'black' }}
              >
                <StartFrom />
                {t.currency} {rupiah(item.stayMonthly.price)} /{' '}
                <span style={{ textTransform: 'lowercase' }}>{perLabel}</span>
                <br />
                <br />
                <LabelPrimary
                  onClick={() => {
                    this.onClick(index);
                  }}
                  text={isEn ? 'Choose this room' : 'Pilih kamar ini'}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {});
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(NewRoomList));
