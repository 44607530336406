import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toggleModal } from "./../../redux/actions";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";
import "flatpickr/dist/themes/confetti.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BASE_URL_ADM } from "./../../constants";

import "./Promo.css";

class EventList extends Component {
  state = {
    imagePosition: 0,
  };

  closeModalPromo = () => {
    this.props.toggleModal("modalVoucher");
  };

  componentDidMount() {}

  render() {
    const images = this.props.app.message;
    // console.log("images", images);
    const image = images[this.state.imagePosition];
    return (
      <div
        className={"modal " + (this.props.app.modalVoucher ? "is-active" : "")}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div
          className="modal-background"
          onClick={(e) => this.closeModalPromo(e)}
        />
        <div
          className="modal-content promopopup"
          style={{ display: "inline-block" }}
        >
          <div
            className="arrow arrow-left"
            onClick={() => {
              if (this.state.imagePosition <= 0) {
                this.setState({
                  imagePosition: images.length - 1,
                });
              } else {
                this.setState({
                  imagePosition: this.state.imagePosition - 1,
                });
              }
            }}
          >
            <img src="/images/arrow-left.png" alt="left arrow" />
          </div>
          <center className="promopopup">
            <img
              className="promopopup"
              src={`${BASE_URL_ADM}/images/voucher/${image}`}
              alt=""
            />
          </center>
          <div
            className="arrow arrow-right"
            onClick={() => {
              if (this.state.imagePosition >= images.length - 1) {
                this.setState({
                  imagePosition: 0,
                });
              } else {
                this.setState({
                  imagePosition: this.state.imagePosition + 1,
                });
              }
            }}
          >
            <img src="/images/arrow-right.png" alt="right arrow" />
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={(e) => this.closeModalPromo(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  toggleModal,
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(withRouter(EventList)));
