import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import Numeral from 'numeral';
import moment from 'moment';
import {
  toggleModal,
  setMessage,
  setBoxFilterDetail
} from '../../redux/actions';
// import ApiCall from '../../services/ApiCall';

import './../BoxBooking/BoxBooking.css';
import { stayMonthlyArray } from '../../constants';
import SelectOption from '../form/SelectOption';
import FormInput from '../form/FormInput';
import GuestCounter from '../GuestCounter/GuestCounter';
import { getToday } from '../../utils/helper';
import Star from '../HotelDetail/Star';
// import history from '../../utils/history';

class NewBoxBookingKosong extends Component {
  constructor(props, content) {
    super(props, content);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      checkIn: '',
      isDisabled: true,
      bookModal: false,
      guest: '',
      jumlahGuest: 1,
      jmlGuest: 1,
      applyGuest: false,
      focusedInput: null,
      focusedInput2: null,
      bookGuest: false,
      sttGuest: false,
      lamaMenyewa: 1,
      guestCount: 1
    };

    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.callGuest('none', 'guestHilangDetail');
    this.callGuest('none', 'guestHilangDetailRes');

    // restore from storage
    this.checkButton();
  }

  checkButton() {
    let checkIn = localStorage.getItem('stayMonthlyCheckIn') || '';
    if (!checkIn) {
      checkIn = getToday();
    } else if (moment(checkIn).isBefore(getToday())) {
      checkIn = getToday();
    }
    if (checkIn !== '') {
      this.setState({
        checkIn,
        lamaMenyewa: Number(localStorage.getItem('lamaMenyewa')) || 1,
        guestCount: Number(localStorage.getItem('guestCount')) || 1,
        isDisabled: false
      });
    }
    // if (!this.props.isYearly)
    //   setTimeout(() => {
    //     this.setState({ isDisabled: this.state.checkIn === '' });
    //   }, 500);
  }

  bookModal = (e, action) => {
    if (action === 'show') {
      this.setState({
        bookModal: true
      });
    } else {
      this.setState({
        bookModal: false
      });
    }
  };

  eventOnCloseGuest = () => {
    this.setState({
      bookGuest: false
    });
  };

  eventClickLabelGuest = (e, _class) => {
    this.setState({
      applyGuest: false,
      bookGuest: !this.state.bookGuest,
      guest: this.state.jumlahGuest
    });
  };

  callGuest(value, _class) {
    const x = document.getElementsByClassName(_class)[0];
    if (x) {
      x.style.display = value;
    }
  }

  callLabelGuest(value) {
    const x = document.getElementsByClassName('labelGuest')[0];
    if (x) {
      x.style.display = value;
    }
  }

  apply = (e, _class) => {
    const guest = document.getElementsByClassName('jmlGuest').value;
    if (guest === '') {
      this.callGuest('none', _class);
      this.callLabelGuest('block');
    } else {
      this.callGuest('none', _class);
      this.callGuest('none');
    }
    this.setState({
      applyGuest: true,
      bookGuest: false,
      sttGuest: true,
      jumlahGuest: this.state.jmlGuest
    });
  };

  handleGetGuest = (e, _class) => {
    e.preventDefault();
    const oneDay = 24 * 60 * 60 * 1000;
    const arrivalDate = new Date(this.state.startDate);
    const departureDate = new Date(this.state.endDate);
    const durationInap = Math.round(
      Math.round((departureDate.getTime() - arrivalDate.getTime()) / oneDay)
    );
    const tanggalIn = moment(arrivalDate).format('MM-DD-YYYY');
    const tanggalOut = moment(departureDate).format('MM-DD-YYYY');
    if (
      this.state.startDate !== '' &&
      this.state.endDate !== '' &&
      e.target.value !== ''
    ) {
      this.props.history.replace(
        `/detail-villas?guest=${e.target.value}&arrival=${tanggalIn}&departure=${tanggalOut}&duration=${durationInap}&villas=${this.props.villas}`
      );
      // window.location.reload()
      this.props.setBoxFilterDetail(true);
    }
    e.preventDefault();
    // const target = e.target
    this.setState({ guest: this.state.jumlahGuest });
  };

  eventClickLabelCalender = () => {
    this.callCalender('block');
    this.callLabelCalender('none');
    document.getElementById('hilangRespon').focus();
  };

  callCalender(value) {
    const x = document.getElementsByClassName('DateRangePicker')[0];
    if (x) {
      x.style.display = value;
    }
  }

  callLabelCalender(value) {
    const x = document.getElementsByClassName('labelDates_1')[0];
    if (x) {
      x.style.display = value;
    }
  }

  bookModalClosed = (e, action) => {
    if (action === 'show') {
      this.setState({
        bookModal: true
      });
    } else {
      this.setState({
        bookModal: false
      });
    }
  };

  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle('show');
    popup.classList.toggle('lihat');
  };

  reservation = e => {
    if (this.state.guestCount > this.props.property.capacity) {
      alert(
        this.props.app.lang === 'en'
          ? 'Max ' + this.props.property.capacity + ' guest(s)'
          : 'Maksimal ' + this.props.property.capacity + ' tamu'
      );
      return;
    }
    const date = new Date(this.state.checkIn).getTime();
    const today = new Date(getToday()).getTime();
    if (date < today) {
      alert(
        this.props.app.lang === 'en' ? 'Minimal today' : 'Minimal hari ini'
      );
      // e.preventDefault();
      return;
    }
    // alert(this.state.lamaMenyewa);
    const data = {
      checkIn: this.state.checkIn,
      // lamaMenyewa: Number(localStorage.getItem('lamaMenyewa')),
      lamaMenyewa: Number(document.getElementsByName('lamaMenyewa')[0].value),

      guestCount: this.state.guestCount,
      propertyId: this.props.property.id
    };
    window.localStorage.setItem('stayMonthly', JSON.stringify(data));
    if (this.props.isMonthly)
      this.props.history.push('/stay-monthly/checkout/reservation-form');
    else if (this.props.isYearly)
      this.props.history.push('/stay-yearly/checkout/reservation-form');
    else this.props.history.push('/stay-weekly/checkout/reservation-form');
    // window.location = '/stay-monthly/checkout/reservation-form';
  };

  closedGuest = () => {
    if (this.state.bookGuest === true) {
      this.setState({ bookGuest: !this.state.bookGuest });
    }
  };

  closeModalBookBooking = () => {
    this.props.toggleModal('modalBoxBooking');
    this.setState({ bookGuest: false });
  };

  handleForm(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.checkButton();
  }

  onChangeCheckIn(e) {
    const isEn = this.props.app.lang === 'en';
    const date = new Date(e.target.value).getTime();
    const today = new Date(getToday()).getTime();
    let dateValue = e.target.value;
    if (date < today) {
      alert(isEn ? 'Minimal today' : 'Minimal hari ini');
      dateValue = getToday();
    }
    this.setState({
      checkIn: dateValue
    });
    localStorage.setItem('stayMonthlyCheckIn', dateValue);
  }

  render() {
    const {
      t,
      property
      // dataAvailable
    } = this.props;
    const isEn = this.props.app.lang === 'en';
    const payAtVilla = property.payAtVilla === 1;

    const checkout = (
      <div>
        <div className="columns is-mobile is-multiline boxHeaderModalA">
          <div
            className={
              payAtVilla
                ? 'column is-8 labelNameModalA1'
                : 'column is-12 labelNameModalA1'
            }
          >
            <Star star={property.star} />
            <b>
              <p>{property.name}</p>
            </b>
            <label className="labelTypeVilla180913">
              {property.categoryName}
            </label>
          </div>
          {payAtVilla && (
            <div className="column is-4 has-text-right labelPayAtModalA1">
              <label
                style={{ color: 'white' }}
                className="has-text-centered is-pulled-right payvilla"
              >
                <p style={{ marginLeft: 18 }}>{t.payAtVilla}</p>
                <figure
                  className="cottagepic"
                  style={{ marginLeft: 16, height: 12, width: 12 }}
                >
                  <img src="/icon/cottage.png" alt="Nusalink" />
                </figure>
              </label>
            </div>
          )}
        </div>
        <div className="columns is-multiline batasGarisModalAtasA1">
          <div className="column" />
        </div>
        <div className="columns is-mobile is-multiline boxDateA1">
          <div className="column is-12">
            <SelectOption
              defaultValue={window.localStorage.getItem('lamaMenyewa')}
              label={
                this.props.isMonthly
                  ? isEn
                    ? 'Long rented (month)'
                    : 'Lama Menyewa (bulan)'
                  : this.props.isWeekly
                  ? isEn
                    ? 'Long rented (week)'
                    : 'Lama Menyewa (minggu)'
                  : isEn
                  ? 'Long rented (year)'
                  : 'Lama Menyewa (tahun)'
              }
              name="lamaMenyewa"
              onChange={e => {
                this.handleForm(e);
                localStorage.setItem('lamaMenyewa', e.target.value);
              }}
            >
              {stayMonthlyArray
                .filter(item => item % property.monthlyPrices[0].per === 0)
                .map(item => (
                  <option
                    defaultValue={Number(this.state.lamaMenyewa)}
                    key={item}
                    value={item}
                  >
                    {item}
                  </option>
                ))}
            </SelectOption>
          </div>
          <div className="column is-12">
            <FormInput
              onChange={this.onChangeCheckIn.bind(this)}
              type="date"
              name="checkIn"
              label="Check-In"
              value={this.state.checkIn}
              defaultValue={this.state.checkIn}
            />
          </div>
        </div>

        <div className="columns is-mobile is-multiline boxGuestA1">
          <div className="column">
            <div className="field">
              <label className="labels">{t.guest}</label>
              <div className="control">
                <div
                  className="labelGuestDetailA2"
                  onChange={this.handleGetGuest}
                  onClick={e => this.eventClickLabelGuest(e)}
                >
                  <p>
                    {this.state.guestCount} {t.guest}
                  </p>
                </div>
                {this.state.bookGuest && (
                  <GuestCounter
                    onChange={guestCount => {
                      this.setState({
                        guestCount
                      });
                      localStorage.setItem('guestCount', guestCount);
                    }}
                    max={property.capacity}
                    guestCount={this.state.guestCount}
                    onDone={e => {
                      this.apply(e);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="columns btnReservation">
          <div className="column is-12 has-text-centered">
            <button
              disabled={this.state.isDisabled}
              style={{
                backgroundColor: this.state.isDisabled ? '#ccc' : '#f36603'
              }}
              type="button"
              onClick={e => this.reservation(e)}
              className="button tombol-popup bg-primary btnBooking"
            >
              {/* {this.state.isDisabled ? 'Coming soon' : t.book} */}
              {this.state.checkIn === null || this.state.checkIn === ''
                ? 'Pilih tanggal check in dulu'
                : t.book}
            </button>
          </div>
        </div>
      </div>
    );

    return (
      <div style={{ height: '100%' }}>
        <div
          className={this.props.app.lebelPromo ? 'boxStickynot' : 'boxSticky'}
          id="BoxDetailBooking"
        >
          {checkout}
        </div>

        {/* ketika mobile */}

        <div className="boxStickyMobile">
          <div className="column-price">
            <label className="lblPrice">
              {t.currency}{' '}
              {Numeral(Math.floor(property.startFrom)).format('0,0')}
            </label>{' '}
            /{' '}
            <span style={{ textTransform: 'lowercase' }}>
              {property.monthlyPrices[0].per === 1
                ? t.month
                : property.monthlyPrices[0].per + ' ' + t.month}
            </span>
          </div>
          <div className="column-buttom">
            <button
              type="button"
              onClick={e => this.props.toggleModal('modalBoxBooking')}
              className="button btn-Booking bg-primary"
            >
              {t.book}
            </button>
          </div>
        </div>

        <div
          className={
            'modal tes-modal ' +
            (this.props.app.modalBoxBooking ? 'is-active' : '')
          }
        >
          <div
            className="modal-background tes-modal"
            style={{ background: 'white', paddingTop: 90 }}
            onClick={e => this.closeModalBookBooking()}
          />
          <div
            className="btnClose"
            onClick={e => this.closeModalBookBooking()}
          />
          <div
            className="modal-content bokModalBooking"
            style={{
              width: 400,
              background: 'white',
              borderRadius: 10,
              paddingLeft: 5
            }}
          >
            {checkout}
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={() => this.closeModalBookBooking()}
          />
        </div>
      </div>
    );
  }
}
const StateToProps = state => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {
  setMessage,
  toggleModal,
  setBoxFilterDetail
});
const translator = translatable(translateToProps);
export default translator(connector(NewBoxBookingKosong));
