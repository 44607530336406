import React from 'react';
import { translatable } from 'react-multilingual';
import OrangeBtn from './OrangeBtn';

function BtnMoreInfo({ t, onClick }) {
  return <OrangeBtn onClick={onClick} label={t['More Info']} />;
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(BtnMoreInfo);
