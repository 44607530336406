import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';

class Reviews extends Component {
  render() {
    const { data, t } = this.props;
    return (
      <div
        className="columns is-multiline is-mobile"
        style={{ maxHeight: 300, overflowY: 'auto', border: '1px solid #ccc' }}
      >
        {data.map((item) => (
          <div className="column is-12  is-mobile" key={item.id}>
            <div className="columns is-multiline">
              <div className="column is-12  is-mobile">
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <strong>{item.name}</strong>
                    <div style={{ fontSize: 10 }}>{item.created_at_format}</div>
                  </div>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    ({item.avg}/10)
                  </div>
                </div>
                <p>{item.review}</p>
              </div>
              {item.review_answer && (
                <div className="column is-12  is-mobile">
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flex: 1 }}>
                        <strong>Admin</strong>{' '}
                        <small style={{ fontSize: 10 }}>{t.reply}</small>
                      </div>
                    </div>
                    <p>{item.review_answer}</p>
                  </div>
                </div>
              )}
              <div
                className="column is-12  is-mobile"
                style={{ paddingTop: 2, paddingBottom: 2 }}
              >
                <hr />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(Reviews);
