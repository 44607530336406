import axios from 'axios';
import { BASE_URL } from '../constants';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['App-Key'] = 'nusanlinkfor1ndonesi4';
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

const getHeaders = async () => {
  return {};
};

export const GET = async (url, params = {}) => {
  return axios.get(url, {
    headers: await getHeaders(),
    params
  });
};

