import React from 'react';
import Slider from 'react-slick';
import ExampleImage from './../../assets/example.png';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  initialSlide: 0
};
const sliderSettings = { ...settings, autoplay: true };

function ModalImages({ isShowed, onClose, images = [], onClick = () => {} }) {
  return (
    isShowed && (
      <div
        onClick={e => {
          if (e.target === document.getElementById('HAHAHA')) {
            onClose();
          }
        }}
        style={{
          position: 'fixed',
          zIndex: 9999,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        }}
      >
        <div
          id={'HAHAHA'}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column'
          }}
        >
          <div className="popup-promo">
            {isShowed && (
              <Slider {...sliderSettings}>
                {images.length === 0
                  ? [0, 1, 2, 3].map((item, index) => (
                      <img
                        key={item}
                        onClick={() => {
                          onClick(item, index);
                        }}
                        src={ExampleImage}
                        className="popup-image"
                        alt={'alt'}
                        style={{
                          borderRadius: 20,
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                      />
                    ))
                  : images.map((item, index) => (
                      <img
                        key={item}
                        onClick={() => {
                          onClick(item, index);
                        }}
                        src={item.src}
                        className="popup-image"
                        alt={item.alt}
                        style={{
                          borderRadius: 20,
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                      />
                    ))}
              </Slider>
            )}
          </div>
          <a
            style={{ color: 'white', fontSize: 20, marginTop: 10 }}
            onClick={onClose}
          >
            X
          </a>
        </div>
      </div>
    )
  );
}

export default ModalImages;
