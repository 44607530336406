import React from 'react';

export default function BedIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 18.375V8.75H22.75C23.6783 8.75 24.5685 9.11875 25.2249 9.77513C25.8812 10.4315 26.25 11.3217 26.25 12.25V18.375"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 22.75V5.25"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 18.375H26.25V22.75"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.375 8.75H1.75"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
