import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translatable } from 'react-multilingual'
import { toggleModal } from '../../redux/actions'

class Privacy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
		setTimeout(() => {
			if (this.props.app.toggleMenu === true) {
				this.props.toggleModal('toggleMenu')
			}
		}, 1000)
	}

	closeHeader = () => {
		if (this.props.app.toggleMenu === true) {
			this.props.toggleModal('toggleMenu')
		}
	}

	render() {
		return (
			<section className="section" onClick={this.closeHeader}>
				<div className="container">
					<div className="content" style={{textAlign: 'justify'}}>
						<h1>Kebijakan Privasi</h1>
						<hr />
						<p align="justify">
							<h3>Pengantar</h3>
							Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, memproses, dan mengungkapkan informasi Anda di seluruh Platform Nusalink.asia. 
							"Platform Nusalink.asia" mengacu pada situs web Nusalink.asia, ponsel kami, tablet dan aplikasi pintar lainnya, dan semua layanan Nusalink.asia yang terkait. 
							Ketika kebijakan ini menyebutkan “Nusalink.asia,” atau “kami,” ini mengacu pada PT Samala Kayana Jayasri dengan kantor utamanya berlokasi di Komp.
							Perkantoran Aldiron Hero Bhakti Jln. Daan Mogot Blok D 8A Jakarta Barat Dalam kebijakan ini, kami menggunakan beberapa istilah yang didefinisikan sebagai berikut:
							“Anggota” adalah siapa saja yang telah mendaftarkan akun Nusalink.asia, termasuk Pelanggan dan Pemasok. "Pelanggan" adalah seseorang yang memesan, atau mencari buku,
							Layanan Pemasok. "Pemasok" adalah seseorang yang menawarkan layanan ("Layanan Pemasok") melalui daftar yang dipublikasikan di Platform Nusalink.asia ("Daftar"). 
							"Akomodasi" berarti liburan atau properti lainnya. “Layanan Pembayaran” berarti setiap layanan pemrosesan pembayaran yang disediakan melalui atau sehubungan dengan
							penggunaan Platform Nusalink.asia oleh Anggota.
						</p>
						<label style={{fontSize: 22}}><b>1. Informasi yang Kami Kumpulkan</b></label><br />
						<p>Ada tiga kategori informasi umum yang kami kumpulkan.</p>
						<p><b>1.1. Informasi yang Anda Beri Kami</b></p>
						<p align="justify">
							Kami mengumpulkan informasi yang Anda bagikan kepada kami ketika Anda menggunakan Platform Nusalink.asia untuk memastikan kinerja kontrak yang memadai antara Anda dan Nusalink.asia,
							atau antara Anda dan Pemasok. Penyediaan informasi ini diperlukan untuk menggunakan Platform Nusalink.asia dan, tanpa itu, kami tidak akan dapat memberi Anda layanan yang Anda minta.
							<br />
							<ol className="privacy">
							<li> Informasi Akun: ketika Anda mendaftar untuk akun Nusalink.asia atau mengirimkan permintaan untuk informasi pemesanan Akomodasi, kami memerlukan informasi tertentu seperti nama dan alamat email Anda. </li>
							<li> Informasi Profil: untuk menggunakan fitur tertentu dalam Platform Nusalink.asia, kami mungkin meminta Anda untuk melengkapi profil, yang mungkin termasuk alamat dan nomor telepon Anda.</li>
							<li> Informasi Terkait-Otentikasi Lainnya: untuk membantu menciptakan dan memelihara lingkungan tepercaya, dan sejauh diizinkan oleh undang-undang yang berlaku, kami dapat mengumpulkan
							identifikasi yang Anda berikan (seperti foto tanda pengenal resmi yang dikeluarkan pemerintah) atau informasi otentikasi lainnya.</li>
							<li> Informasi Pembayaran: ketika Anda menggunakan Layanan Pembayaran (seperti saat memesan Akomodasi atau pengalaman terkait perjalanan melalui kami atau membangun hubungan Pemasok melalui kami),
							kami memerlukan informasi keuangan tertentu (seperti rekening bank atau informasi kartu kredit Anda) untuk memproses pembayaran dan mematuhi hukum yang berlaku. Jika Anda seorang Pelanggan,
							kami dapat menyimpan informasi keuangan Anda untuk membantu Anda dengan pemesanan pengalaman terkait perjalanan dengan pihak ketiga. Jika Anda adalah Pemasok, kami mungkin memerlukan informasi
							tambahan seperti ID atau ID pajak Anda (jika diizinkan oleh hukum yang berlaku), dan bukti identifikasi atau verifikasi lainnya untuk memverifikasi identitas Anda, memberikan Layanan Pembayaran
							kepada Anda, dan mematuhi hukum yang berlaku.</li>
							<li> Komunikasi dengan Nusalink.asia: ketika Anda berkomunikasi dengan Nusalink.asia, kami mengumpulkan informasi tentang komunikasi Anda dan informasi apa pun yang Anda pilih untuk berikan. </li>
							<li> Informasi Lainnya: Anda mungkin memilih untuk memberikan informasi kepada kami (seperti jadwal perjalanan Anda, alergi makanan, informasi kontak darurat, preferensi perjalanan,
							dan informasi tentang tamu lain yang menemani Anda selama masa tinggal Anda) ketika Anda mengisi formulir, berbicara kepada, atau berkomunikasi dengan perwakilan Nusalink.asia,
							melakukan pencarian, memperbarui atau menambahkan informasi ke akun Nusalink.asia Anda, menanggapi survei, berpartisipasi dalam promosi, atau menggunakan fitur lain dari Platform
							Nusalink.asia.</li>
							</ol>
						</p>
						<p><b>1.2. Informasi yang Kami Kumpulkan Secara Otomatis dari Penggunaan Anda terhadap Platform Nusalink.asia</b></p>
						<p align="justify">
							Ketika Anda menggunakan Platform Nusalink.asia, kami mengumpulkan informasi tentang layanan yang Anda gunakan dan bagaimana Anda menggunakannya, bahkan jika Anda tidak mendaftar untuk akun Nusalink.asia. 
							Kami memproses informasi ini untuk kegiatan dan dasar hukum yang dijelaskan dalam Bagian 2 di bawah ini.<br />
							<ol className="privacy">
							<li> Informasi Penggunaan: kami mengumpulkan informasi tentang interaksi Anda dengan Platform Nusalink.asia, seperti halaman atau konten lain yang Anda lihat, penelusuran Anda untuk vila,
							pemesanan yang Anda buat, pengalaman dan kegiatan yang Anda pesan dengan orang lain melalui kami, dan tindakan lain di Platform Nusalink.asia.</li>
							<li> Informasi Lokasi: ketika Anda menggunakan fitur tertentu dari Platform Nusalink.asia, kami dapat mengumpulkan berbagai jenis informasi tentang lokasi umum Anda (misalnya kota, negara).</li>
							<li> Data Log: kami secara otomatis mengumpulkan informasi log ketika Anda menggunakan Platform Nusalink.asia, bahkan jika Anda belum membuat akun Nusalink.asia atau masuk. Informasi itu termasuk,
							antara lain: detail tentang bagaimana Anda menggunakan Platform Nusalink.asia (termasuk jika Anda mengklik tautan ke aplikasi pihak ketiga), alamat IP, waktu akses, informasi
							perangkat keras dan perangkat lunak, informasi perangkat, informasi peristiwa perangkat (mis. jenis peramban, macet), pengidentifikasi unik, dan halaman yang pernah Anda lihat
							atau terlibat dengan sebelumnya atau setelah menggunakan Platform Nusalink.asia.</li>
							<li> Informasi Transaksi: kami mengumpulkan informasi terkait dengan transaksi Anda di Platform Nusalink.asia atau transaksi yang dilakukan melalui Platform Nusalink.asia,
							termasuk tanggal dan waktu, jumlah yang dibebankan, dan rincian transaksi terkait lainnya.</li>
							<li> Cookie dan Teknologi Serupa: kami menggunakan cookie dan teknologi serupa lainnya, seperti suar web, piksel, dan pengidentifikasi seluler. Kami juga dapat mengizinkan mitra bisnis kami untuk menggunakan teknologi pelacakan ini di Platform Nusalink.asia, atau melibatkan orang lain untuk melacak perilaku Anda atas nama kami. Anda dapat menonaktifkan penggunaan cookie melalui pengaturan browser Anda.</li>
							</ol>
						</p>
						<p><b>1.3. Informasi yang Kami Kumpulkan dari Pihak Ketiga</b></p>
						<p align="justify">
							Kami dapat mengumpulkan informasi yang orang lain berikan tentang Anda ketika mereka menggunakan Platform Nusalink.asia, atau memperoleh informasi dari sumber lain dan menggabungkannya
							dengan informasi yang kami kumpulkan melalui Platform Nusalink.asia. Nusalink.asia tidak mengontrol, mengawasi atau menanggapi bagaimana pihak ketiga yang memberikan informasi Anda
							memproses data pribadi Anda, dan setiap permintaan informasi mengenai pengungkapan informasi pribadi Anda ke Nusalink.asia harus diarahkan ke pihak ketiga tersebut.<br />
							<ol className="privacy">
							<li> Layanan Pihak Ketiga: jika Anda menautkan, menghubungkan, atau masuk ke akun Nusalink.asia Anda dengan layanan pihak ketiga (misalnya, Facebook, Twitter, Google),
							kami dapat mengumpulkan informasi dari layanan pihak ketiga, seperti pendaftaran dan informasi profil Anda. Informasi ini bervariasi dan dikendalikan oleh layanan itu atau
							sebagaimana diizinkan oleh Anda melalui pengaturan privasi Anda pada layanan</li>
							<li> Informasi Latar Belakang: untuk Anggota tertentu di Amerika Serikat, sejauh diizinkan oleh undang-undang yang berlaku, Nusalink.asia dapat memperoleh laporan dari
							catatan publik dari hukuman pidana atau pendaftaran pelanggar seks. Untuk Anggota di luar Amerika Serikat, sejauh diizinkan oleh undang-undang yang berlaku, Nusalink.asia dapat
							memperoleh versi latar belakang lokal atau pemeriksaan pelanggar seks terdaftar. Kami dapat menggunakan informasi Anda, termasuk nama lengkap dan tanggal lahir Anda, untuk
							mendapatkan laporan tersebut. Kami hanya akan mengumpulkan informasi ini jika diwajibkan oleh hukum yang berlaku atau jika perlu untuk memastikan kinerja layanan kami yang
							memadai untuk Anda.</li>
							<li> Sumber Lain: sejauh diizinkan oleh undang-undang yang berlaku, kami dapat menerima informasi tambahan tentang Anda, seperti data demografi atau informasi deteksi penipuan,
							dari penyedia layanan pihak ketiga dan / atau mitra (seperti SafelyStay) dan menggabungkannya dengan informasi yang kami miliki. tentang Anda. Misalnya, kami mungkin menerima
							hasil pemeriksaan latar belakang atau peringatan penipuan dari penyedia layanan seperti layanan verifikasi identitas untuk upaya pencegahan penipuan dan penilaian risiko kami. 
							Kami dapat menerima informasi tentang Anda dan aktivitas Anda di dalam dan di luar Platform Nusalink.asia, termasuk tentang interaksi Anda dari jaringan iklan mitra kami. 
							Misalnya, jika Anda memesan reservasi restoran melalui Nusalink.asia, restoran dapat mengirim informasi kepada kami yang Anda kirimkan kepada mereka, seperti preferensi makan
							atau aktivitas bersantap Anda.</li>
							</ol>
						</p>
						<label style={{fontSize: 22}}><b>2. Bagaimana Kami Menggunakan Informasi yang Kami Kumpulkan</b></label><br />
						<p>
							Kami menggunakan, menyimpan, dan memproses informasi tentang Anda untuk menyediakan, memahami, meningkatkan, dan mengembangkan Platform Nusalink.asia, dan untuk
							menciptakan dan memelihara lingkungan yang tepercaya dan lebih aman.
						</p>
						<p><b>2.1. Menyediakan, Meningkatkan, dan Mengembangkan Platform Nusalink.asia</b></p>
						<p align="justify">
							<ol className="privacy">
							<li> Memungkinkan Anda untuk mengakses dan menggunakan Platform Nusalink.asia.</li>
							<li> Hubungi Anda tentang permintaan pemesanan Anda, rencanakan masa tinggal Anda di salah satu vila kami, dan buat serta kelola pemesanan Anda.</li>
							<li> Memungkinkan Anda berkomunikasi dengan Anggota lain.</li>
							<li> Mengoperasikan, menyediakan, meningkatkan dan mengoptimalkan Platform dan pengalaman Nusalink.asia, seperti dengan melakukan analisis dan melakukan penelitian. </li>
							<li> Mempersonalisasi atau menyesuaikan pengalaman Anda dengan, antara lain membuat saran Akomodasi, memberi peringkat hasil pencarian atau menampilkan iklan berdasarkan
							riwayat pencarian atau pemesanan Anda, dan preferensi apa pun yang Anda berikan kepada kami, atau yang ditandai oleh riwayat pencarian dan pemesanan Anda.</li>
							<li> Memungkinkan Anda untuk mengakses dan menggunakan Layanan Pembayaran, termasuk mengumpulkan pembayaran atas nama Pemasok.</li>
							<li> Menyediakan layanan pelanggan.</li>
							<li> Kirimi Anda pesan layanan atau dukungan, seperti pembaruan, peringatan keamanan, dan pemberitahuan akun.</li>
							<li> Jika Anda memberikan kami informasi kontak Anda, kami dapat menggunakan dan menyimpan informasi ini:
								<ol className="numb">
									<li> untuk memfasilitasi undangan referral Anda,</li>
									<li> untuk deteksi dan pencegahan penipuan, dan </li>
									<li> untuk tujuan lain yang Anda beri kuasa pada saat pengumpulan .</li>
								</ol>
							</li>
							</ol>
							Kami memproses informasi ini karena kami memiliki kepentingan yang sah dalam meningkatkan Platform Nusalink.asia dan pengalaman pengguna kami dengan hal itu, dan untuk
							memastikan kinerja kontrak kami yang memadai dengan Anda.<br />
						</p>
						<p><b>2.2. Membuat dan Mempertahankan Lingkungan yang Terpercaya dan Aman</b></p>
						<p align="justify">
							<ol className="privacy">
							<li> Mendeteksi dan mencegah penipuan, spam, penyalahgunaan, insiden keamanan, dan aktivitas berbahaya lainnya.</li>
							<li> Melakukan investigasi dan penilaian risiko.</li>
							<li> Verifikasi atau autentikasi informasi atau identifikasi yang diberikan oleh Anda (seperti untuk memverifikasi alamat Akomodasi Anda atau membandingkan foto identifikasi Anda dengan foto lain yang Anda berikan). </li>
							<li> Melakukan pemeriksaan terhadap basis data dan sumber informasi lainnya.</li>
							<li> Mematuhi kewajiban hukum kita.</li>
							<li> Selesaikan sengketa dengan salah satu pengguna kami dan terapkan perjanjian kami dengan pihak ketiga.</li>
							<li> Menegakkan Persyaratan Layanan, perjanjian Anggota, dan kebijakan kami.</li>
							</ol>
							Kami memproses informasi ini karena kami memiliki kepentingan sah dalam melindungi Platform Nusalink.asia, untuk memverifikasi dan memastikan kinerja kontrak kami yang memadai dengan Anda, dan untuk mematuhi hukum yang berlaku.<br />
						</p>
						<p><b>2.3. Berikan, Personalisasikan, Ukur, dan Tingkatkan Iklan dan Pemasaran kami</b></p>
						<p align="justify">
							<ol className="privacy">
							<li> Kirimkan pesan promosi, pemasaran, iklan, dan informasi lain yang mungkin menarik bagi Anda berdasarkan preferensi komunikasi Anda (termasuk informasi tentang Nusalink.asia atau kampanye dan layanan mitra). </li>
							<li> Personalisasi, ukur, dan tingkatkan iklan. </li>
							<li> Mengurus program rujukan, penghargaan, survei, undian, kontes, atau kegiatan atau acara promosi lainnya yang disponsori atau dikelola oleh Nusalink.asia atau mitra bisnis pihak ketiganya.</li>
							<li> Melaksanakan pembuatan profil tentang karakteristik dan preferensi Anda (berdasarkan informasi yang Anda berikan kepada kami, interaksi Anda dengan layanan kami, dan riwayat pencarian dan pemesanan Anda) untuk mengirimkan pesan promosi, pemasaran, iklan, dan informasi lainnya yang kami pikir mungkin menarik bagi Anda.</li>
							</ol>
							Kami memproses informasi ini karena kami memiliki kepentingan sah dalam melindungi Platform Nusalink.asia, untuk mengukur kinerja kontrak kami yang memadai dengan Anda, dan untuk mematuhi hukum yang berlaku.<br />
						</p>
						<label style={{fontSize: 22}}><b>3. Berbagi & Pengungkapan</b></label><br />
						<p><b>3.1. Dengan Persetujuan Anda atau Di Mana yang Diotorisasi oleh Hukum</b></p>
						<p align="justify">
							<ol className="privacy">
							<li> Kami dapat membagikan informasi Anda ke arah Anda atau sebagaimana yang kami jelaskan kepada Anda pada saat berbagi, seperti jika Anda, sebagai Pelanggan, telah meminta kami untuk menghubungkan Anda dengan vendor pihak ketiga atau untuk membeli layanan dari sepertiga vendor pihak atas nama Anda, sehubungan dengan pemesanan Anda (misalnya, penyedia asuransi atau penyedia kegiatan), atau jika Anda, sebagai Pemasok, telah setuju untuk mengizinkan kami memasarkan Akomodasi Anda melalui, atau merujuk Anda ke, platform pihak ketiga. Dalam situasi ini, Anda memberi wewenang kepada Nusalink.asia untuk mengungkapkan informasi vendor pihak ketiga ini yang diperlukan untuk memesannya dan untuk menerima layanan yang relevan dari mereka. Ini dapat mencakup preferensi atau permintaan khusus, dan informasi tamu tambahan apa pun.</li>
							<li> Kami juga dapat membagikan rincian kontak tertentu tentang Anda, seperti nama Anda, email dan pemesanan sebelumnya di Nusalink.asia, ke perusahaan lain dan mitra bisnis dari Nusalink.asia, yang kemudian dapat mengirimkan komunikasi pemasaran tentang produk atau layanan yang mungkin menarik bagi Anda.</li>
							</ol>
						</p>
						<p><b>3.2. Berbagi antara Pelanggan dan Pemasok</b></p>
						<p align="justify">
							<ol className="privacy">
							<li> Ketika Anda sebagai Pelanggan mengajukan permintaan pemesanan, informasi tertentu tentang Anda dapat dibagikan dengan Pemasok untuk mengoordinasikan pemesanan, seperti nama Anda, rincian kontak, dan tanggal kedatangan. </li>
							<li> Ketika Anda sebagai Pemasok memiliki pemesanan yang dikonfirmasi, beberapa informasi tentang Anda dapat dibagikan dengan Pelanggan untuk mengoordinasikan pemesanan, seperti nama lengkap Anda, nomor telepon, dan alamat Akomodasi.</li>
							</ol>
							Membagi informasi ini diperlukan untuk menyediakan Pelanggan dengan layanan kami, dan kami tidak membagikan informasi penagihan dan pembayaran Anda dengan Anggota lain.<br />
						</p>
						<p><b>3.3. Ulasan, Daftar, dan Informasi Publik lainnya</b></p>
						<p align="justify">
							Platform Nusalink.asia menerbitkan informasi yang dapat dilihat oleh masyarakat umum. Misalnya:<br />
							<ol className="privacy">
							<li> Halaman Daftar Publik dapat dilihat oleh publik dan mencakup informasi seperti perkiraan atau lokasi tepat Lokasi, deskripsi Cantuman, ketersediaan kalender, dan informasi tambahan apa pun yang dipilih oleh Pemasok untuk dibagikan. </li>
							<li> Setelah menyelesaikan pemesanan, Pelanggan dapat menulis ulasan yang mungkin muncul secara publik pada halaman Daftar (dan halaman lain di mana kami dapat memasarkan Akomodasi) di bawah nama depan, awal awal, dan lokasi umum mereka. </li>
							<li> Jika Anda mengirimkan konten di blog kami, di forum publik, atau di pos media sosial, atau menggunakan fitur serupa di Platform Nusalink.asia, konten itu dapat dilihat oleh publik.</li>
							</ol>
							Informasi yang Anda bagikan secara publik di Platform Nusalink.asia dapat diindeks melalui mesin pencari pihak ketiga. Jika Anda mengubah pengaturan atau konten publik Anda, mesin pencari ini mungkin tidak memperbarui database mereka. Kami tidak mengontrol praktik mesin pencari pihak ketiga, dan mereka mungkin menggunakan cache yang berisi informasi usang Anda.<br />
						</p>
						<p><b>3.4. Penyedia Layanan</b></p>
						<p align="justify">
							Kami menggunakan berbagai penyedia layanan pihak ketiga untuk membantu kami menyediakan layanan yang terkait dengan Platform Nusalink.asia dan memastikan kami dapat menyediakan layanan kami secara memadai. Misalnya, penyedia layanan dapat membantu kami: <br />
							<ol className="privacy">
							<li> memverifikasi atau mengesahkan identifikasi Anda, </li>
							<li> memeriksa informasi dengan basis data publik, </li>
							<li> membantu kami dengan pemeriksaan latar belakang, pencegahan penipuan, dan penilaian risiko, atau </li>
							<li> menyediakan layanan pelanggan, iklan, atau layanan pembayaran. Penyedia ini memiliki akses terbatas ke informasi Anda untuk melakukan tugas-tugas ini atas nama kami, dan secara kontrak diwajibkan untuk menggunakannya sesuai dengan kebijakan ini.</li>
							</ol>
						</p>
						<p><b>3.5. Layanan Tambahan oleh Pemasok</b></p>
						<p align="justify">
							Pemasok dapat menggunakan layanan pihak ketiga yang tersedia melalui Platform Nusalink.asia untuk membantu mengelola Akomodasi mereka atau menyediakan layanan tambahan, seperti layanan pembersihan, asuransi atau penyedia kegiatan. Pemasok dapat menggunakan fitur-fitur pada Platform Nusalink.asia untuk berbagi informasi tentang Pelanggan (seperti tanggal dan tanggal penerimaan Pelanggan, tanggal, nomor telepon, dan alamat email) dengan penyedia layanan pihak ketiga tersebut untuk tujuan mengoordinasikan masa tinggal, mengelola Akomodasi, atau memberikan layanan lain.
						</p>
						<p><b>3.6. Keamanan dan Kepatuhan terhadap Hukum</b></p>
						<p align="justify">
							Nusalink.asia dapat mengungkapkan informasi Anda ke pengadilan, penegak hukum atau otoritas pemerintah, atau pihak ketiga yang berwenang, jika dan sejauh yang kami diwajibkan atau diizinkan untuk melakukannya oleh hukum atau jika pengungkapan tersebut diperlukan secara wajar: <br />
							<ol className="privacy">
							<li> untuk mematuhi proses hukum dan untuk menanggapi klaim yang ditegaskan terhadap Nusalink.asia, </li>
							<li> untuk menanggapi permintaan yang diverifikasi yang terkait dengan investigasi kriminal atau dugaan atau dugaan aktivitas ilegal atau kegiatan lain yang dapat mengekspos kami, Anda, atau pengguna lain mana pun dari kami untuk mendapatkan kewajiban, </li>
							<li> untuk menegakkan dan mengatur Syarat Penggunaan Situs Web kami atau perjanjian lain dengan Anggota,</li>
							<li> untuk investigasi dan pencegahan penipuan, penilaian risiko, dukungan pelanggan, pengembangan produk dan tujuan debugging, atau</li>
							<li> untuk melindungi hak, properti atau keselamatan pribadi dari Nusalink.asia, karyawannya, Anggota, atau anggota masyarakatnya.</li>
							</ol>
							Kami akan berusaha memberi tahu Anggota tentang permintaan ini kecuali: <br />
							<ol className="privacy">
							<li> memberikan pemberitahuan dilarang oleh proses hukum itu sendiri, dengan perintah pengadilan yang kami terima, atau oleh hukum yang berlaku, atau </li>
							<li> kami percaya bahwa memberikan pemberitahuan akan sia-sia, tidak efektif, membuat risiko cedera atau membahayakan tubuh individu atau kelompok, atau menciptakan atau meningkatkan risiko penipuan atas properti Nusalink.asia, Anggota dan Platform Nusalink.asia (secara kolektif, "Skenario Risiko").</li>
							</ol>
							Jika kami mematuhi permintaan hukum tanpa pemberitahuan karena alasan ini, kami akan berusaha memberi tahu Anggota tersebut tentang permintaan tersebut setelah fakta jika kami memutuskan dengan itikad baik bahwa kami tidak lagi dilarang secara hukum untuk melakukan hal itu dan tidak ada Skenario Risiko yang berlaku.
						</p>
						<p><b>3.7. Afiliasi Korporasi</b></p>
						<p align="justify">
							Untuk membantu kami menyediakan Platform Nusalink.asia, kami dapat membagikan informasi Anda di dalam keluarga perusahaan perusahaan kami (baik entitas finansial dan non-keuangan) yang terkait dengan kepemilikan atau kontrol umum. Selain itu, kami membagikan informasi Anda dengan afiliasi kami untuk mendukung, mengintegrasikan, mempromosikan, dan meningkatkan Platform Nusalink.asia dan layanan afiliasi kami.
						</p>
						<p><b>3.8. Widget</b></p>
						<p align="justify">
							Kami dapat menampilkan bagian dari Platform Nusalink.asia di situs yang dioperasikan oleh mitra bisnis Nusalink.asia, menggunakan teknologi seperti widget atau API.
						</p>
						<p><b>3.9. Otoritas Pajak</b></p>
						<p align="justify">
							Di yurisdiksi tempat kami memfasilitasi pengumpulan dan pengiriman pajak okupansi, Pelanggan dan Pemasok secara tegas memberikan kami izin, tanpa pemberitahuan lebih lanjut, untuk mengungkapkan data Pelanggan dan Pemasok dan informasi lain yang berkaitan dengan mereka atau transaksi, pemesanan, Akomodasi, dan pajak hunian kepada otoritas pajak yang relevan, termasuk, namun tidak terbatas pada, nama Pelanggan atau Pemasok, daftar alamat, tanggal dan jumlah transaksi, nomor identifikasi pajak, jumlah pajak yang diterima (atau jatuh tempo) oleh Pemasok dari Pelanggan, dan informasi kontak.
						</p>
						<p><b>3.10. Transfer Bisnis</b></p>
						<p align="justify">
							Jika Nusalink.asia melakukan atau terlibat dalam merger, akuisisi, reorganisasi, penjualan aset, kepailitan, atau acara insolvensi, maka kami dapat menjual, mentransfer atau berbagi sebagian atau semua aset kami, termasuk informasi pribadi Anda sehubungan dengan transaksi tersebut atau dalam kontemplasi dari transaksi tersebut (misalnya, uji tuntas). Dalam acara ini, kami akan memberi tahu Anda sebelum informasi pribadi Anda ditransfer dan menjadi subyek kebijakan privasi yang berbeda.
						</p>
						<p><b>3.11. Data Gabungan</b></p>
						<p align="justify">
							Kami juga dapat berbagi informasi agregat (informasi tentang pengguna kami yang kami gabungkan bersama sehingga tidak lagi mengidentifikasi atau mereferensikan pengguna individual) dan informasi lain yang tidak teridentifikasi untuk analisis industri dan pasar, kepatuhan terhadap peraturan, profil demografis, pemasaran dan periklanan. , dan tujuan bisnis lainnya.<br />
						</p>
						<label style={{fontSize: 22}}><b>4. Informasi Penting Lainnya</b></label><br />
						<p><b>4.1. Menganalisis Komunikasi Anda</b></p>
						<p align="justify">
							Kami dapat meninjau, memindai, atau menganalisis komunikasi Anda di Platform Nusalink.asia untuk pencegahan penipuan, penilaian risiko, kepatuhan terhadap peraturan, penyelidikan, pengembangan produk, penelitian, dan tujuan dukungan pelanggan. Misalnya, sebagai bagian dari upaya pencegahan penipuan kami, kami memindai dan menganalisis pesan untuk menyembunyikan informasi kontak dan referensi ke situs web lain. Dalam beberapa kasus, kami juga dapat memindai, meninjau, atau menganalisis pesan untuk debug, meningkatkan, dan memperluas penawaran produk. Kami menggunakan metode otomatis jika mungkin. Namun, terkadang kami mungkin perlu meninjau secara manual beberapa komunikasi, seperti untuk investigasi penipuan dan dukungan pelanggan, atau untuk menilai dan meningkatkan fungsionalitas alat-alat otomatis ini. Kami tidak akan meninjau, memindai, atau menganalisis komunikasi Anda untuk mengirim pesan pemasaran pihak ketiga kepada Anda, dan kami tidak akan menjual ulasan atau analisis dari komunikasi ini. Kegiatan-kegiatan ini dilakukan berdasarkan kepentingan sah Kementerian Kehutanan dalam memastikan kepatuhan terhadap hukum yang berlaku, mencegah penipuan, meningkatkan keselamatan, dan meningkatkan serta memastikan kinerja layanan kami yang memadai.
						</p>
						<p><b>4.2. Menghubungkan Akun Pihak Ketiga</b></p>
						<p align="justify">
							Anda dapat menghubungkan akun Nusalink.asia Anda dengan akun Anda di layanan jejaring sosial pihak ketiga. Ketika Anda membuat tautan ini: <br />
							<ol className="privacy">
							<li> informasi yang Anda berikan kepada kami dari tautan akun Anda dapat disimpan, diproses, dan dikirimkan untuk tujuan pencegahan penipuan dan penilaian risiko; dan </li>
							<li> publikasi dan tampilan informasi yang Anda berikan kepada Nusalink.asia melalui tautan ini tunduk pada pengaturan dan otorisasi Anda di Platform Nusalink.asia dan situs pihak ketiga.</li>
							</ol>
							Kami hanya mengumpulkan informasi Anda dari akun pihak ketiga terkait sejauh yang diperlukan untuk memastikan kinerja yang memadai dari kontrak kami dengan Anda, atau untuk memastikan bahwa kami mematuhi hukum yang berlaku, atau dengan persetujuan Anda.
						</p>
						<p><b>4.3 Bagian Google Maps / Earth</b></p>
						<p align="justify">
							Platform Nusalink.asia menggunakan layanan Google Maps / Earth, termasuk Google Maps API (s). Penggunaan Google Maps / Earth tunduk pada Ketentuan Penggunaan Google Maps / Earth Tambahan dan Kebijakan Privasi Google .
						</p>
						<label style={{fontSize: 22}}><b>5. Mitra & Integrasi Pihak Ketiga</b></label><br />
						<p align="justify">
							Platform Nusalink.asia dapat berisi tautan ke situs web atau layanan pihak ketiga, seperti integrasi pihak ketiga, layanan bermerek, atau layanan merek pihak ketiga ("Mitra Pihak Ketiga"). Nusalink.asia tidak memiliki atau mengontrol Mitra Pihak Ketiga ini dan ketika Anda berinteraksi dengan mereka, Anda mungkin memberikan informasi langsung kepada Mitra Pihak Ketiga. Mitra Pihak Ketiga ini akan memiliki aturannya sendiri tentang pengumpulan, penggunaan, dan pengungkapan informasi. Kami mendorong Anda untuk meninjau kebijakan privasi dari situs web lain yang Anda kunjungi.
						</p>
						<label style={{fontSize: 22}}><b>6. Pilihan Anda</b></label><br />
						<p><b>6.1. Managing Your Information</b></p>
						<p align="justify">
							You may access, update, and correct most of your basic personal information by logging into your Nusalink.asia account and reviewing your settings and profile. Where permitted by applicable law, you may request written copies of your personal information held by us. In some cases and where permitted by law, we may charge a fee which will be disclosed to you before we comply with your request. You are responsible for keeping your personal information up-to-date.
						</p>
						<p><b>6.2 Penyimpanan Data & Pembatalan Akun</b></p>
						<p align="justify">
							Kami biasanya menyimpan informasi Anda selama yang diperlukan untuk menyediakan Platform Nusalink.asia kepada Anda, atau selama akun Anda aktif. Jika Anda tidak lagi ingin kami menggunakan informasi Anda untuk menyediakan Platform Nusalink.asia kepada Anda, Anda dapat membatalkan akun Anda atau meminta kami untuk menghapus informasi pribadi Anda. Untuk membatalkan akun Nusalink.asia Anda, hubungi kami. Jika Anda membatalkan akun Anda atau meminta penghapusan informasi pribadi Anda, perhatikan bahwa: <br />
							<ol className="privacy">
							<li> Informasi yang telah Anda bagikan dengan orang lain (seperti ulasan Cantuman) dapat terus terlihat secara publik di Platform Nusalink.asia yang dikaitkan dengan nama depan Anda, bahkan setelah Anda Akun Nusalink.asia dibatalkan.</li>
							<li> Beberapa salinan informasi Anda (misalnya catatan log) dapat tetap ada dalam basis data kami, tetapi dipisahkan dari pengenal pribadi. </li>
							<li> Karena kami mempertahankan Platform Nusalink.asia untuk melindungi dari kehilangan dan penghancuran yang tidak disengaja atau jahat, sisa salinan informasi Anda mungkin tidak dihapus dari sistem cadangan kami untuk jangka waktu terbatas. </li>
							<li> Nusalink.asia dapat menyimpan informasi tertentu sejauh yang diperlukan untuk mematuhi kewajiban hukumnya.</li>
							<li> Nusalink.asia dapat menyimpan informasi tertentu seperlunya untuk kepentingan bisnisnya yang sah, seperti pendeteksian dan pencegahan penipuan, meningkatkan keamanan pengguna, dan membela diri terhadap tuntutan hukum. Misalnya, jika Nusalink.asia menghapus akun pengguna untuk alasan penipuan atau keamanan pengguna, Nusalink.asia dapat menyimpan informasi tertentu dari akun tersebut untuk mencegah pengguna tersebut membuka akun Nusalink.asia baru di masa mendatang.</li>
							</ol>
						</p>
						<p><b>6.3 Persetujuan Penarikan</b></p>
						<p align="justify">
							Di beberapa yurisdiksi, hukum yang berlaku dapat memberi Anda hak untuk menarik izin yang sebelumnya Anda berikan kepada Nusalink.asia untuk memproses informasi Anda dan untuk mengakhiri penggunaan Platform Nusalink.asia dengan membatalkan akun Anda seperti yang dijelaskan dalam bagian ini. Selain itu, Anda mungkin memiliki hak untuk membatasi cara kami menggunakan informasi pribadi Anda.
						</p>
						<p><b>6.4 Portabilitas Data</b></p>
						<p align="justify">
							Di beberapa yurisdiksi, hukum yang berlaku dapat memberi Anda hak untuk meminta salinan informasi pribadi Anda yang telah Anda berikan kepada kami dan / atau meminta kami untuk memindahkan informasi pribadi tersebut ke penyedia layanan lain. Dalam kasus semacam itu, dan sejalan dengan kewajiban hukum kami, kami akan memberi Anda atau penyedia layanan informasi dalam format yang terstruktur dan umum digunakan.
						</p>
						<p><b>6.5 Membatasi Pengolahan</b></p>
						<p align="justify">
							Dalam keadaan luar biasa tertentu (misalnya di mana Anda berpikir bahwa Nusalink.asia tidak menyimpan data yang akurat tentang Anda) dan sesuai dengan hukum yang berlaku, Anda dapat meminta Nusalink.asia untuk sementara membatasi penggunaan informasi pribadi Anda untuk jangka waktu terbatas sampai keadaan luar biasa yang membenarkan pembatasan berakhir.
						</p>
						<p><b>6.6 Penolakan untuk Mengolah (termasuk Pembuatan Keputusan Otomatis)</b></p>
						<p align="justify">
							Di beberapa yurisdiksi, hukum yang berlaku dapat memberi Anda hak untuk meminta Nusalink.asia untuk tidak memproses informasi pribadi Anda untuk tujuan tertentu tertentu, seperti profil atau tujuan pemasaran.
						</p>
						<p><b>6.7 Hak Berolahraga dan Pengaduan Tempat Tinggal</b></p>
						<p align="justify">
							Jika Anda ingin mempelajari lebih lanjut tentang, atau melaksanakan apa pun dari, hak-hak yang dijelaskan dalam Bagian 6 ini, hubungi kami di info@nusalink.asia. Kami mungkin meminta Anda untuk memverifikasi identitas Anda sebelum mengambil tindakan lebih lanjut atas permintaan Anda. Anda memiliki hak untuk mengajukan keluhan tentang kegiatan pemrosesan data yang dilakukan oleh Nusalink.asia sebelum otoritas perlindungan data yang kompeten.
						</p>
						<label style={{fontSize: 22}}><b>7. Beroperasi Secara Global</b></label><br />
						<p align="justify">
							Sebagaimana dijelaskan dalam Kebijakan Privasi ini, kami terkadang dapat membagikan informasi Anda di dalam keluarga perusahaan kami atau dengan pihak ketiga.
						</p>
						<p><b>7.1 Penyedia Layanan</b></p>
						<p align="justify">
							Di mana kami melibatkan penyedia layanan pihak ketiga untuk membantu kami menyediakan layanan yang terkait dengan Platform Nusalink.asia, kami mungkin perlu mentransfer mereka informasi pribadi Anda untuk tujuan yang dijelaskan dalam Kebijakan Privasi ini. Penyedia layanan ini dapat ditempatkan di dalam atau di luar Wilayah Ekonomi Eropa ("EEA"), termasuk di negara-negara yang dianggap memberikan tingkat perlindungan data yang setara dengan yang disediakan di EEA (seperti Kanada) dan negara-negara lain yang tidak , termasuk Amerika Serikat. Ketika mentransfer informasi pribadi Anda ke penyedia layanan di negara-negara ini, kami akan memastikan bahwa informasi tersebut diproses di sana dengan cara yang konsisten dengan Kebijakan Privasi ini. Ketika Anda menggunakan Platform Nusalink.asia, Anda mengizinkan kami untuk mentransfer, menyimpan, dan memproses informasi Anda di negara-negara tersebut. Hukum di negara-negara ini mungkin berbeda dari hukum yang berlaku di tempat Anda tinggal. Dengan menggunakan Platform Nusalink.asia, Anda setuju untuk mentransfer data Anda ke negara-negara ini.
						</p>
						<label style={{fontSize: 22}}><b>8. Keamanan</b></label><br />
						<p align="justify">
							Kami terus menerapkan dan memperbarui tindakan keamanan administratif, teknis, dan fisik untuk membantu melindungi informasi Anda dari akses, kehilangan, perusakan, atau pengubahan yang tidak sah.
						</p>
						<label style={{fontSize: 22}}><b>9. Perubahan Kebijakan Privasi ini</b></label><br />
						<p align="justify">
							Nusalink.asia berhak mengubah Kebijakan Privasi ini kapan saja sesuai dengan ketentuan ini. Jika kami membuat perubahan pada Kebijakan Privasi ini, kami akan memposting Kebijakan Privasi yang telah direvisi pada Platform Nusalink.asia dan memperbarui tanggal "Terakhir Diperbarui" di bagian bawah Kebijakan Privasi ini. Kami juga akan memberi Anda pemberitahuan tentang modifikasi melalui email setidaknya tiga puluh (30) hari sebelum tanggal efektif. Jika Anda tidak setuju dengan Kebijakan Privasi yang direvisi, Anda dapat membatalkan Akun Anda. Jika Anda tidak membatalkan akun Nusalink.asia Anda sebelum tanggal Kebijakan Privasi yang direvisi menjadi efektif, akses Anda yang berkelanjutan atau penggunaan Platform Nusalink.asia akan merupakan penerimaan dari Kebijakan Privasi yang telah direvisi.
						</p>
						<label style={{fontSize: 22}}><b>10. Kontak</b></label><br />
						<p align="justify">
							Jika Anda memiliki pertanyaan atau keluhan tentang Kebijakan Privasi ini atau praktik penanganan informasi Nusalink.asia (termasuk bagaimana penyedia layanan kami memproses informasi pribadi Anda), Anda dapat menghubungi Nusalink.asia di: Petugas Perlindungan Data, PT Samala Kayana Jayasri Komp Perkantoran Aldiron Hero Bhakti Jln. Daan Mogot No.119 Blok D 8A Jakarta Barat.
						</p>
					</div>
				</div>
			</section>
		)
	}
}

const mapStateToProps = (state) => {
	const { app } = state
	return { app }
}

const mapTranslationsToProps = ({t}) => ({t})

const connector = connect(mapStateToProps, { toggleModal })
const translator = translatable(mapTranslationsToProps)

export default translator(connector(Privacy))
