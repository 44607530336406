import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import { logout, showModal, setUser, toggleModal } from '../redux/actions';
import Loader from '../components/Loader/Loader';
import ApiCall from '../services/ApiCall';
import Sidebar from './MyBooking/Sidebar';
import ActiveBookings from './MyBooking/ActiveBookings';
import BookingHistory from './MyBooking/BookingHistory';
import { COLORS } from '../constants';
import MyTopUpList from './MyBooking/MyTopUpList';

class MyBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      name: '',
      email: '',
      data: [],
      loading: false,
      lang: 'id',
      statusRegister: '',
      totalactive: -1,
      totalnonactive: -1,
      bookingHistory: [],
      active: {
        backgroundColor: COLORS.primary,
        color: 'white',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10
      },
      nonActive: {
        backgroundColor: COLORS.grey,
        color: 'black',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10
      },
      activeMenu: 'daily',
      // activeMenu: 'top-up',
      bookingHistory2: [],
      data2: [],
      isDailyLoaded: false,
      isLongStayLoaded: false,
      isTopUpBillsLoaded: false,
      topUpsData: null
    };
  }

  searchToObject() {
    var pairs = window.location.search.substring(1).split('&'),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === '') continue;

      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.activeMenu !== prevState.activeMenu &&
      this.state.activeMenu === 'longstay' &&
      this.state.isLongStayLoaded === false
    ) {
      window.scrollTo(0, 0);
      setTimeout(() => this.getLongStay(), 300);
      // this.getLongStay();
      // alert(1);
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    setTimeout(async () => {
      if (this.props.app.user === '') {
        window.location.href = '/';
      } else {
        const search = this.searchToObject();
        if (search.reservation_id) {
          if (search.order_from === 'web') {
            window.location.href =
              '/detail-booking?id=' + search.reservation_id;
          } else {
            window.location.href =
              'nusalink://HomeScreen/' +
              search.order_id +
              '/' +
              search.reservation_id +
              '/0';
          }
        }
        if (search.order_id) {
          try {
            const res = await ApiCall.findByOrderId(
              search.order_id,
              '?select=id'
            );
            if (
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
            ) {
              window.location.href =
                'nusalink://HomeScreen/' +
                search.order_id +
                '/' +
                res.data.data.id +
                '/0';
            } else {
              window.location.href = '/detail-booking?id=' + res.data.data.id;
            }
          } catch (error) {
            this.getDaily();
          }
        } else {
          this.getDaily();
        }
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
    }, 1000);
  }

  async getLongStay() {
    try {
      const email = this.props.app.user.email;
      this.setState({ loading: true });
      const responseMonthBookingList = await ApiCall.getBookingMonthList(email);
      const data2 = responseMonthBookingList.data.data.filter(item => {
        return item.transaction.status === 'Settlement';
      });
      const bookingHistory2 = responseMonthBookingList.data.data.filter(
        item => {
          return item.transaction.status !== 'Settlement';
        }
      );
      this.setState({
        loading: false,
        data2,
        bookingHistory2,
        isLongStayLoaded: true
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async getDaily() {
    try {
      this.setState({ loading: true });
      const userid = this.props.app.user.id;
      const response = await ApiCall.getBooking(userid);
      const activeData = response.data.data.filter(function(item) {
        return item.paymentStatus === 'settlement';
      });
      const bookingHistory = response.data.data.filter(function(item) {
        return item.paymentStatus !== 'settlement';
      });
      this.setState({
        data: activeData,
        bookingHistory,
        loading: false
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError = error => {
    if (error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error(this.props.t.nointernet);
    }
    this.setState({ loading: false });
  };

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  logout = () => {
    this.props.logout();
    const pathname = window.location.pathname;
    setTimeout(() => {
      if (pathname === '/profile' || pathname === '/profile/edit') {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }, 500);
  };

  render() {
    const { data, bookingHistory } = this.state;
    const { t } = this.props;
    // console.log('data', data);
    return (
      <section className="section mobileprof2" onClick={this.closeHeader}>
        <Loader loading={this.state.loading} />
        <div className="container bookpage">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column is-8">
              <div style={{ marginLeft: 30 }}>
                <a
                  onClick={() => {
                    this.setState({ activeMenu: 'daily' });
                  }}
                  style={
                    this.state.activeMenu === 'daily'
                      ? this.state.active
                      : this.state.nonActive
                  }
                >
                  {t.daily}
                </a>
                <a
                  onClick={() => {
                    this.setState({ activeMenu: 'longstay' });
                  }}
                  style={
                    this.state.activeMenu === 'longstay'
                      ? this.state.active
                      : this.state.nonActive
                  }
                >
                  {t.longStay}
                </a>
                <a
                  onClick={() => {
                    this.setState({ activeMenu: 'top-up' });
                  }}
                  style={
                    this.state.activeMenu === 'top-up'
                      ? this.state.active
                      : this.state.nonActive
                  }
                >
                  Top Up & Bills
                </a>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4 myaccountbox">
              <Sidebar />
            </div>
            {this.state.activeMenu !== 'top-up' && (
              <div className="column is-8 activebook">
                <ActiveBookings
                  data={
                    this.state.activeMenu === 'daily' ? data : this.state.data2
                  }
                />
              </div>
            )}
            {this.state.activeMenu === 'top-up' && (
              <MyTopUpList
                isLoaded={this.state.isTopUpBillsLoaded}
                onComplete={data => {
                  this.setState({
                    isTopUpBillsLoaded: true,
                    topUpsData: data
                  });
                }}
                data={this.state.topUpsData}
                userId={this.props.app.user.id}
              />
            )}
          </div>
          {this.state.activeMenu !== 'top-up' && (
            <div className="columns">
              <div className="column is-4 emptybooking" />
              <div className="column is-8 bookhistory">
                <BookingHistory
                  data={
                    this.state.activeMenu === 'daily'
                      ? bookingHistory
                      : this.state.bookingHistory2
                  }
                />
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}
const mapStateToProps = state => {
  const { app } = state;
  return { app };
};
const mapTranslationsToProps = ({ t }) => ({ t });
const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(MyBooking));
