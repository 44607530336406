import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/others/PageTitle';
import PropertyItem from '../../components/PropertyItem/PropertyItem';
import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';
import Helper from '../../utils/helper';

class PropertyListByType extends Component {
  state = {
    properties: [],
    propertyType: '',
    loading: true
  };

  constructor(props) {
    super(props);

    this.goToDetail = this.goToDetail.bind(this);
  }

  getData() {
    this.setState(
      {
        properties: [],
        loading: true
      },
      () => {
        ApiCall.getPropertyByType(this.state.propertyType)
          .then(res => {
            this.setState({
              properties: res.data.data
            });
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.setState({
              loading: false
            });
          });
      }
    );
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.match.params.propertyType !==
      prevProps.match.params.propertyType
    ) {
      this.setState({ propertyType: this.props.match.params.propertyType });
      this.getData();
    }
  };

  componentDidMount() {
    const { propertyType } = this.props.match.params;
    this.setState({ propertyType });
    this.getData();
  }
  goToDetail(e, propertyId) {
    // console.log(e, propertyId);
    const url = '/detail-villas?villas=' + propertyId;
    this.props.history.push(url);
    // window.location = url;
  }
  render() {
    const { properties } = this.state;
    const { t } = this.props;
    return (
      <section
        className="section2 inner box-list"
        style={{ backgroundColor: '#f8f8f8' }}
      >
        <div className="container">
          <div className="columns is-multiline long">
            <PageTitle title={Helper.capitalize(this.state.propertyType)} />
            {properties.length > 0 &&
              properties.map((item, index) => (
                <PropertyItem
                  per="night"
                  onClick={this.goToDetail}
                  property={item}
                  key={item.name}
                />
              ))}
            {!properties && (
              <div className="column is-12">
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
            {properties.length === 0 && !this.state.loading && (
              <div className="column is-12">
                <div className="has-text-centered">
                  <img
                    src={`${BASE_URL_ADM}/images/Not_Found.png`}
                    alt=""
                    width="500"
                    height="500"
                  />
                </div>
                <br />
                <center>
                  <h4>{t.hotelNotFound}</h4>
                </center>
              </div>
            )}
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </section>
    );
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(PropertyListByType);
