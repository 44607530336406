import React, { Component } from "react";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";

class Spoken extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      klikAccess: false,
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  klikAccess = (e, action) => {
    if (action === "show") {
      this.setState({
        klikAccess: true,
      });
    } else {
      this.setState({
        klikAccess: false,
      });
    }
  };
  render() {
    const { villaSpoken } = this.props;
    return (
      <div>
        <div
          className="columns is-multiline is-mobile"
          style={{ marginTop: 10 }}
        >
          {villaSpoken.length > 0 &&
            villaSpoken.map((items, index) => (
              <div
                key={index}
                className="column is-6 is-multiline is-mobile field is-grouped"
                style={{ backgroundColor: "white", marginTop: -15 }}
              >
                <p className="control">
                  <img
                    alt=""
                    width="25"
                    src={`/icon/flags/${items.country_id}.png`}
                  />
                </p>
                <p className="control">
                  <span className="text-spoken">{items.name_country}</span>
                </p>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(Spoken));
