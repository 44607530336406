import React from 'react';
import FormInput from './FormInput';

export default function InputCheckIn({ onChange, defaultValue, value }) {
  return (
    <FormInput
      label={'Check In'}
      onChange={e => {
        let date = e.target.value;
        localStorage.setItem('CHECK_IN', date);
        onChange(e);
      }}
      type={'date'}
      placeholder={'Check In'}
      value={value}
      defaultValue={defaultValue}
    />
  );
}
