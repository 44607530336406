import React, { Component } from 'react';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
// import { BASE_URL_ADM } from '../../constants'
import './Accessibility.css';
class Accessibility extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      klikAccess: false
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  klikAccess = (e, action) => {
    if (action === 'show') {
      this.setState({
        klikAccess: true
      });
    } else {
      this.setState({
        klikAccess: false
      });
    }
  };
  render() {
    const { t, villaAkses } = this.props;
    // console.log(villaAkses, "PROPS");
    return (
      <div>
        <div className="columns is-multiline is-mobile">
          <div className="column is-12 is-multiline is-mobile ">
            <div className="columns is-mobile">
              <div className="column is-12 is-mobile">
                <p style={{ fontSize: 18 }}> {t.roadaccess} </p>
              </div>
            </div>
            <div
              className="columns is-multiline is-mobile"
              style={{ marginTop: -25 }}
            >
              <div className="column is-9 is-multiline is-mobile desktop-akses">
                <div className="columns is-multiline is-mobile">
                  {villaAkses.bus !== 'on' ? (
                    ''
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/bus.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.bus} </span>
                      </p>
                    </div>
                  )}
                  {villaAkses.car !== 'on' ? (
                    ''
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/car.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.car} </span>
                      </p>
                    </div>
                  )}
                  {villaAkses.bike !== 'on' ? (
                    ''
                  ) : villaAkses.car === 'on' && villaAkses.bus === 'on' ? (
                    <div
                      className=" column is-6 is-multiline field is-grouped"
                      style={{ marginTop: -20 }}
                    >
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/bicycle.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.bike} </span>
                      </p>
                    </div>
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/bicycle.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.bike} </span>
                      </p>
                    </div>
                  )}
                  {villaAkses.walk !== 'on' ? (
                    ''
                  ) : villaAkses.car === 'on' && villaAkses.bus === 'on' ? (
                    <div
                      className=" column is-6 is-multiline field is-grouped"
                      style={{ marginTop: -20 }}
                    >
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/parade.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.walk} </span>
                      </p>
                    </div>
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/parade.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.walk} </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="column is-12 mobile-akses">
                <div className="columns is-multiline is-mobile ">
                  {villaAkses.bus !== 'on' ? (
                    ''
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/bus.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.bus} </span>
                      </p>
                    </div>
                  )}

                  {villaAkses.car !== 'on' ? (
                    ''
                  ) : (
                    <div className=" column is-6 is-multiline field is-grouped">
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/car.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.car}</span>
                      </p>
                    </div>
                  )}

                  {villaAkses.bike !== 'on' ? (
                    ''
                  ) : (
                    <div
                      className=" column is-6 is-multiline field is-grouped"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/bicycle.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.bike} </span>
                      </p>
                    </div>
                  )}
                  {villaAkses.walk !== 'on' ? (
                    ''
                  ) : (
                    <div
                      className=" column is-6 is-multiline field is-grouped"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <p className="control">
                        <img
                          alt=""
                          className="imageAkses"
                          src="/icon/resized/parade.png"
                          width="25"
                        />
                      </p>
                      <p className="control">
                        <span className="text-akses"> {t.walk} </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {villaAkses.wheel !== 'on' ? (
            ''
          ) : (
            <div className="column is-12">
              <div className="columns">
                <div className="column is-12">
                  <p style={{ fontSize: 18 }}> {t.villaccess} </p>
                </div>
              </div>
              <div className="columns" style={{ marginTop: -18 }}>
                <div className="column is-2 is-multiline field is-grouped">
                  <p className="control">
                    <img
                      alt=""
                      className="imageAkses"
                      src="/icon/resized/disabled-sign.png"
                      width="25"
                    />
                  </p>
                  <p className="control">
                    <span className="text-akses"> {t.whellchair} </span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {villaAkses.deskripsi === '' ? (
            ''
          ) : (
            <div className="column is-12">
              <a
                className="read-more"
                onClick={e => this.klikAccess(e, 'show')}
              >
                {' '}
                {t.sowmoreakses} {'> '}
              </a>
            </div>
          )}
        </div>

        <div className={'modal ' + (this.state.klikAccess ? 'is-active' : '')}>
          <div
            className="modal-background"
            style={{ background: 'white', paddingTop: 90 }}
            onClick={e => this.klikAccess(e, 'hidden')}
          />
          <div
            className="btnClose"
            onClick={e => this.klikAccess(e, 'hidden')}
          />
          <div
            className="modal-content boxAkses"
            style={{
              width: 500,
              height: 'auto',
              background: 'white',
              borderRadius: 10,
              padding: 20
            }}
          >
            <div className="box-akses">
              <div className="columns is-multiline">
                <div className="column is-12 is-multiline">
                  <label className="headerModalRules" style={{ fontSize: 18 }}>
                    {t.accessibilitas}
                  </label>
                </div>
                <div className="column is-12 is-multiline">
                  <p
                    style={{
                      fontSize: 17,
                      marginTop: -10,
                      marginBottom: 5,
                      color: '#f27602',
                      fontWeight: 'bold'
                    }}
                  >
                    {' '}
                    {t.roadaccess}{' '}
                  </p>
                </div>
                {villaAkses.bus !== 'on' ? (
                  ''
                ) : (
                  <div
                    className=" column is-12 is-multiline field is-grouped"
                    style={{ marginTop: -15 }}
                  >
                    <p className="control">
                      <img
                        alt=""
                        className="imageAkses"
                        src="/icon/resized/bus.png"
                        width="25"
                      />
                    </p>
                    <p className="control">
                      <span className="text-akses"> {t.bus} </span>
                    </p>
                  </div>
                )}

                {villaAkses.car !== 'on' ? (
                  ''
                ) : (
                  <div
                    className=" column is-12 is-multiline field is-grouped"
                    style={{ marginTop: -15 }}
                  >
                    <p className="control">
                      <img
                        alt=""
                        className="imageAkses"
                        src="/icon/resized/car.png"
                        width="25"
                      />
                    </p>
                    <p className="control">
                      <span className="text-akses"> {t.car} </span>
                    </p>
                  </div>
                )}

                {villaAkses.bike !== 'on' ? (
                  ''
                ) : (
                  <div
                    className=" column is-12 is-multiline field is-grouped"
                    style={{ marginTop: -15 }}
                  >
                    <p className="control">
                      <img
                        alt=""
                        className="imageAkses"
                        src="/icon/resized/bicycle.png"
                        width="25"
                      />
                    </p>
                    <p className="control">
                      <span className="text-akses"> {t.bike} </span>
                    </p>
                  </div>
                )}
                {villaAkses.walk !== 'on' ? (
                  ''
                ) : (
                  <div
                    className=" column is-12 is-multiline field is-grouped"
                    style={{ marginTop: -15 }}
                  >
                    <p className="control">
                      <img
                        alt=""
                        className="imageAkses"
                        src="/icon/resized/parade.png"
                        width="25"
                      />
                    </p>
                    <p className="control">
                      <span className="text-akses"> {t.walk} </span>
                    </p>
                  </div>
                )}
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                {villaAkses.wheel !== 'on' ? (
                  ''
                ) : (
                  <div className="column is-12" style={{ marginTop: 3 }}>
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: -35,
                        color: '#f27602',
                        fontWeight: 'bold'
                      }}
                    >
                      {' '}
                      {t.villaccess}{' '}
                    </p>
                  </div>
                )}
                {villaAkses.wheel !== 'on' ? (
                  ''
                ) : (
                  <div
                    className="column is-12 field is-grouped"
                    style={{ marginTop: -15 }}
                  >
                    <p className="control">
                      <img
                        alt=""
                        className="imageAkses"
                        src="/icon/resized/disabled-sign.png"
                        width="25"
                      />
                    </p>
                    <div className="control">
                      <p style={{ fontSize: 14 }}> {t.whellchair} </p>
                    </div>
                  </div>
                )}
                <div className="column is-12" style={{ marginTop: -20 }}>
                  <p className="has-text-justified" style={{ fontSize: 14 }}>
                    {villaAkses.deskripsi}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.klikAccess(e, 'hidden')}
          />
        </div>
      </div>
    );
  }
}
const StateToProps = state => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(Accessibility));
