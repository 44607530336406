import React, { Component } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Numeral from 'numeral';
import { connect } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
import { translatable } from 'react-multilingual';
// import { BASE_LINK } from '../../constants'
import queryString from '../../utils/queryString';
import Loader from '../../components/Loader/Loader';
import ApiCall from '../../services/ApiCall';
import { setUser, setMessage, toggleModal } from '../../redux/actions';
import RoomList from './ReservationForm/RoomList';
import {
  APPSTORE_LINK,
  dateEN,
  dateIN,
  HOUR_ARRIVALS,
  PLAYSTORE_LINK
} from './../../constants';
import Helper, { getRandomNumber, rupiah } from './../../utils/helper';
// import SelectOption from '../../components/form/SelectOption';
import Star from '../../components/HotelDetail/Star';
import MultipleBooking from './../../components/ReservationForm/MutipleBooking';
import FormInput from '../../components/form/FormInput';
import PrimaryButton from '../../components/form/PrimaryButton';

class ReservationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: HOUR_ARRIVALS,
      hour_arrival: '14:00',
      rooms: [],
      userName: this.props.app.user.name,
      userIdNumber: this.props.app.user.IdNumber,
      userNoTelp: this.props.app.user.phone,
      userEmail: this.props.app.user.email,
      userAddress: this.props.app.user.address,
      guestName: '',
      guestIdNumber: '',
      guestNoTelp: '',
      guestEmail: '',
      guestAddress: '',
      extraBed: '',
      extraBreakfast: '',
      hotel: {},
      extraOther: '',
      inputExtraOther: false,
      loading: false,
      isCheck: false,
      params: [],
      kodeVoucher: '',
      valueVoucher: 0,
      subtotal: 0,
      total: 0,
      lang: 'id',
      arrival: '',
      departure: '',
      language: '',
      promoactived: false,
      promovalactived: false,
      requestspecial: false,
      extra: '',
      area: '+62',
      panah: false,
      user_Id: '',
      length: '0',
      duration: '',
      guest: '',
      optionPayment: '0',
      debt: 0,
      payfull: true,
      payatvil: false,
      payupf: false,
      rangeArrival: 0,
      totalMurni: 0,
      room: {},
      optionPay: 0,
      point: 0,
      usePoint: false,
      pointUsed: 0,
      promoLogin: 0,
      promoLoginPercent: 0,
      notAvailable: false,
      promoNusalink: 0,
      promoNusalinkPercent: 0,
      isPopupPromoCode: false,
      items: [
        {
          room_id: null
        }
      ],
      multiples: [],
      roomSelected: null,
      promo: null,
      promoPrice: 0,
      dp: 0,
      payDp: false,
      showPayAtProperty: true,
      showPayDp: false,
      promo_property_percent: null,
      promo_property_nominal: null,
      promo_referral_percent: 0,
      promo_referral_nominal: 0,
      subTotal: 0,
      promo_coupon_percent: 0,
      promo_coupon_value: 0,
      referral_code: '',
      promo_referral_for_referrer_percent: 0,
      promo_referral_for_referrer_nominal: 0,
      promoReferralForReferrers: [],
      unique_code: getRandomNumber(100, 999),
      is_using_promo_reff: false
    };
  }

  setTotal() {
    if (this.state.hotel.is_multiple) {
      this.setState(prevState => {
        let pointUsed = prevState.usePoint ? prevState.point : 0;
        const { totalMurni } = prevState;
        let promoPrice = 0;
        if (prevState.promo !== null) {
          promoPrice = Math.floor((prevState.promo.promo * totalMurni) / 100);
        }
        const showPayAtProperty = !this.state.multiples.some(item => {
          return item.rooms.length > 1;
        });
        const afterPromo = totalMurni - promoPrice;
        const valueVoucher =
          prevState.promo_coupon_percent > 0
            ? Math.floor((prevState.promo_coupon_percent * afterPromo) / 100)
            : prevState.promo_coupon_value;
        const afterVoucher = afterPromo - valueVoucher;
        const promo_referral_nominal = Math.floor(
          (prevState.promo_referral_percent * afterVoucher) / 100
        );
        const afterReferralForGuest = afterVoucher - promo_referral_nominal;
        // const promoReferralForReferrers =
        //   prevState.promoReferralForReferrers.length > 0
        //     ? prevState.promoReferralForReferrers
        //         .filter(item => item.is_used === 1)
        //         .map(item => item.promo_percent)
        //     : [];
        // const promo_referral_for_referrer_percent =
        //   promoReferralForReferrers.length > 0
        //     ? promoReferralForReferrers.reduce((a, b) => a + b)
        //     : 0;
        const promo_referral_for_referrer_percent = prevState.is_using_promo_reff
          ? prevState.promo.user.reff_results.remaining > 50
            ? 50
            : prevState.promo.user.reff_results.remaining
          : 0;
        const promo_referral_for_referrer_nominal = Math.floor(
          (promo_referral_for_referrer_percent * afterReferralForGuest) / 100
        );
        const showPayDp = !showPayAtProperty;
        let totalTanpaPoint =
          totalMurni -
          promoPrice -
          valueVoucher -
          promo_referral_nominal -
          promo_referral_for_referrer_nominal;
        if (pointUsed > totalMurni / 2) {
          pointUsed = Math.floor(totalMurni / 2);
        }
        let total = totalTanpaPoint - pointUsed + this.state.unique_code;
        if (total < 0) {
          pointUsed = totalTanpaPoint;
          total = 0;
        }
        const dp = (prevState.hotel.dp_multiple_booking * total) / 100;
        let promo_property_nominal = 0;
        if (prevState.promo_property_percent)
          promo_property_nominal =
            (prevState.promo_property_percent * totalMurni) / 100;

        return {
          pointUsed,
          promoPrice,
          total,
          dp,
          showPayAtProperty,
          showPayDp,
          promo_property_nominal,
          subTotal: totalMurni,
          valueVoucher,
          promo_referral_nominal,
          promo_referral_for_referrer_nominal,
          promo_referral_for_referrer_percent
        };
      });
    } else {
      if (this.state.room.rates)
        this.setState(prevState => {
          let pointUsed = prevState.usePoint ? prevState.point : 0;
          const subTotal = prevState.room.rates
            .map(item => item.fix_price)
            .reduce((a, b) => a + b);
          const promoPrice = Math.floor(
            (prevState.promo.promo * subTotal) / 100
          );
          const afterPromo = subTotal - promoPrice;
          const valueVoucher =
            prevState.promo_coupon_percent > 0
              ? Math.floor((prevState.promo_coupon_percent * afterPromo) / 100)
              : prevState.promo_coupon_value;
          const afterVoucher = afterPromo - valueVoucher;
          const promo_referral_nominal = Math.floor(
            (prevState.promo_referral_percent * afterVoucher) / 100
          );
          const afterReferralForGuest = afterVoucher - promo_referral_nominal;
          const promoReferralForReferrers =
            prevState.promoReferralForReferrers.length > 0
              ? prevState.promoReferralForReferrers
                  .filter(item => item.is_used === 1)
                  .map(item => item.promo_percent)
              : [];
          const promo_referral_for_referrer_percent =
            promoReferralForReferrers.length > 0
              ? promoReferralForReferrers.reduce((a, b) => a + b)
              : 0;
          const promo_referral_for_referrer_nominal = Math.floor(
            (promo_referral_for_referrer_percent * afterReferralForGuest) / 100
          );
          let totalTanpaPoint =
            subTotal -
            promoPrice -
            valueVoucher -
            promo_referral_nominal -
            promo_referral_for_referrer_nominal;
          if (pointUsed > subTotal / 2) {
            pointUsed = Math.floor(subTotal / 2);
          }
          let total = totalTanpaPoint - pointUsed + this.state.unique_code;
          if (total < 0) {
            pointUsed = totalTanpaPoint;
            total = 0;
          }
          return {
            total,
            pointUsed,
            subTotal,
            promoPrice,
            valueVoucher,
            promo_referral_nominal,
            promo_referral_for_referrer_nominal,
            promo_referral_for_referrer_percent
          };
        });
    }
  }

  setPromoLoginPercent() {
    if (this.props.app.promoLogin > 0) {
      this.setState({
        promoLogin: Math.floor(
          (this.props.app.promoLogin * this.state.totalMurni) / 100
        ),
        promoLoginPercent: this.props.app.promoLogin
      });
    }
  }

  getPromoReferralForReferrer() {
    const userId = this.props.app.user.id;
    ApiCall.getPromoReferralForReferrer(userId)
      .then(res => {
        this.setState({
          promoReferralForReferrers: res.data
        });
      })
      .catch(err => {
        console.log('getPromoReferralForReferrer error');
      });
  }

  getPromoByPropertyId(propertyId) {
    setTimeout(() => {
      const isLogin = this.props.app.user !== '';
      ApiCall.getPromoByPropertyId(
        propertyId,
        isLogin,
        this.props.app.user.id
      ).then(res => {
        this.setState(prevState => ({
          promo: res.data,
          promoPrice: (res.data.promo * prevState.totalMurni) / 100,
          promo_property_percent: res.data.promoProperty
        }));
        setTimeout(() => {
          this.setTotal();
          // alert('halo');
        }, 500);
      });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const condition1 =
      this.state.promo_referral_percent !== prevState.promo_referral_percent;
    const condition2 =
      this.state.promoReferralForReferrers !==
      prevState.promoReferralForReferrers;
    if (this.state.promo !== prevState.promo || condition1 || condition2) {
      this.setTotal();
    }
    if (prevState.multiples !== this.state.multiples) {
      this.setTotal();
    }
    if (prevState.room !== this.state.room) {
      this.setTotal();
    }
    if (
      prevState.promo_coupon_percent !== this.state.promo_coupon_percent ||
      prevState.is_using_promo_reff !== this.state.is_using_promo_reff
    ) {
      this.setTotal();
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    const paramsString = queryString.parse(this.props.location.search);
    if (
      paramsString.guest === undefined ||
      paramsString.arrival === undefined ||
      paramsString.departure === undefined ||
      paramsString.villas === undefined ||
      paramsString.villas === ''
    ) {
      window.location.href = '/';
    }
    // const arrivalParams = paramsString.arrival;
    // const arrivalDate = arrivalParams.split('-');
    // const departureParams = paramsString.departure;
    // const departureDate = departureParams.split('-');
    const datenow = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    // const arrival = new Date(
    //   arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1]
    // );
    // const departure = new Date(
    //   departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1]
    // );
    const arrival = new Date(paramsString.arrival);
    const departure = new Date(paramsString.departure);
    const durationInap = Math.round(
      Math.round((departure.getTime() - arrival.getTime()) / oneDay)
    );
    const nowtoarrive = Math.round(
      Math.round((arrival.getTime() - datenow.getTime()) / oneDay)
    );
    let checkGuest = '';
    if (paramsString.guest === '' || paramsString.guest === '0') {
      checkGuest = 1;
    } else if (parseInt(paramsString.guest, 10) > 8) {
      checkGuest = 8;
    } else {
      checkGuest = paramsString.guest;
    }
    this.setState({ params: paramsString });
    ApiCall.getRooms(paramsString)
      .then(response => {
        // console.log("hotel detail", response.data.hotel);
        this.setState({
          rooms: response.data.hotel.rooms,
          hotel: response.data.hotel,
          items: [{ room_id: response.data.hotel.rooms[0].id }]
        });
        if (response.data.hotel.is_multiple) {
          this.setState({
            room: response.data.hotel.rooms[0]
          });
          this.setMultiplePrice();

          // per room
          // const multiples = response.data.hotel.rooms[0].rates.map(item => {
          //   const roomId = response.data.hotel.rooms[0].id;
          //   const rate = item.fix_price;
          //   return {
          //     date: item.rateDate,
          //     // rooms: [{ roomId, rate }]
          //     qty: 1
          //   };
          // });

          // multiple room
          const multiples = response.data.hotel.rooms[0].rates.map(item => {
            const roomId = response.data.hotel.rooms[0].id;
            const rate = item.fix_price;
            return {
              date: item.rateDate,
              // rooms: [{ roomId, rate }]
              rooms: [
                { roomId, rate }
                // { roomId, rate }
              ]
            };
          });
          this.setState({
            multiples,
            roomSelected: response.data.hotel.rooms[0].id
          });
        }
        setTimeout(() => {
          this.setState({
            rangeArrival: nowtoarrive,
            guestName: this.props.app.user.name,
            guestNoTelp: this.props.app.user.phone,
            area:
              this.props.app.user.code === undefined
                ? '+62'
                : this.props.app.user.code,
            guestEmail: this.props.app.user.email,
            // arrival:
            //   arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1],
            // departure:
            //   departureDate[2] +
            //   '-' +
            //   departureDate[0] +
            //   '-' +
            //   departureDate[1],
            arrival: Helper.dateFormat(arrival),
            departure: Helper.dateFormat(departure),
            duration: durationInap,
            guest: checkGuest
          });
          if (this.props.app.toggleMenu === true) {
            this.props.toggleModal('toggleMenu');
          }
        }, 1000);
        const hotels = response.data.hotel;
        // this.setState({ hotel: hotels });
        const subtotalPrice = hotels.Price * durationInap;
        const totalasli = response.data.hotel.totalMurni;
        this.setState({
          loading: false,
          subtotal: subtotalPrice,
          total: totalasli,
          totalMurni: totalasli
        });
        // setTimeout(() => {
        this.getPromoByPropertyId(paramsString.villas);
        // }, 1000);
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
      });
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (this.props.app.user !== '') {
        const userId = this.props.app.user.id;
        // console.log('userId', userId)
        ApiCall.getCustomerPoint(userId)
          .then(res => {
            // console.log(res.data.data.remaining_point);
            this.setState({
              point: res.data.data.remaining_point
            });
          })
          .catch(err => {
            console.log(err);
          });
        // console.log('user', this.props.app.user);
        this.setState({
          promoLogin: 0
        });
        // this.getPromoReferralForReferrer();
      }
      // this.findPromoNusalink();
    }, 1000);
  }

  toggleModalPromoCode() {
    this.setState(state => ({
      isPopupPromoCode: !state.isPopupPromoCode
    }));
  }

  async checkReferralCode(e) {
    e.preventDefault();
    const referral_code = this.state.referral_code;
    const t = this.props.t;
    if (referral_code) {
      try {
        // const response =
        await ApiCall.checkReferralCode(referral_code, this.props.app.user.id);
        // const { data } = response;
        toast.success(t['Referral Code'] + ' ' + t['found']);
        this.setState(state => {
          return {
            // promo_referral_for_referrer_percent:state.promo.referral.guest
            // promo_referral_for_referrer_nominal;
            promo_referral_percent: state.promo.referral.guest
          };
        });
      } catch (error) {
        toast.error(t['Referral Code'] + ' ' + t['not found']);
        this.setState({
          promo_referral_percent: 0
        });
      }
    } else {
      toast.error(t['Referral Code'] + ' ' + t['required']);
    }
  }

  findPromoNusalink() {
    ApiCall.findPromoNusalink()
      .then(res => {
        this.setState({
          promoNusalinkPercent: res.data.data.promoNusalink
        });
        if (this.state.hotel !== null && this.state.hotel.is_multiple) {
          this.setMultiplePrice();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeInputArea = event => {
    const value = event.target.value;
    const name = event.target.name;
    if (value === 0) {
      alert('Tidak Boleh Nol Pertama');
    } else {
      this.setState({ [name]: value });
    }
  };

  handleInputCheck = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === 'extraOther') {
      this.setState({
        [name]: value
      });
      if (target.checked) {
        this.setState({
          inputExtraOther: true
        });
      } else {
        this.setState({
          inputExtraOther: false
        });
      }
    }
    if (target.checked) {
      this.setState({
        [name]: value
      });
    } else {
      this.setState({
        [name]: ''
      });
    }
  };

  handleINputChangGuest = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleINputChangExtra = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const panjangCharacter = value.length;
    this.setState({
      [name]: value,
      length: panjangCharacter
    });
  };

  handleINputChangUser = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleInputChangeVoucher = e => {
    const target = e.target;
    const value = target.value;
    this.setState({ kodeVoucher: value });
  };

  pay = e => {
    if (this.state.notAvailable) {
      return;
    }
    const { t } = this.props;
    e.preventDefault();
    if (this.state.optionPay === '1' && this.state.hour_arrival === '') {
      alert(t.arrivalHoursToProperty + ' is required');
      return;
    }
    this.setState({ loading: true });
    const userId = this.props.app.user.id;
    let lang = '';
    if (this.props.app.lang === '' || this.props.app.lang === 'id') {
      lang = 1;
    } else {
      lang = 2;
    }
    let _debt = 0;
    if (this.state.payatvil) {
      _debt =
        parseInt(this.state.total, 10) -
        parseInt(this.state.hotel.PriceMalam, 10);
    }
    const state = this.state;
    ApiCall.paymentGateway(state, lang, userId, _debt)
      .then(response => {
        // console.log(response.data.redirect_url);
        // window.onload = function () {
        this.setState({ loading: false });
        // };
        // if (Number(this.state.optionPay) === 1) {
        //   window.location =
        //     "/?order_id=2903488912&status_code=201&transaction_status=pending";
        // }
        // console.log(response.data);

        if (response.data.reload) {
          alert(response.data.message);
          window.location.reload();
        } else {
          const { redirect_url } = response.data.data.midtrans.response_from_midtrans
          if (
            redirect_url.includes('http://') ||
            redirect_url.includes('https://') ||
            redirect_url.substr(0, 11) === '/?order_id='
          ) {
            window.location = redirect_url;
          } else {
            if (
              this.state.userEmail === 'nusalinkxuob@email.com' ||
              this.state.userEmail === 'nusalinkxuob2@email.com' ||
              this.state.guestEmail === 'nusalinkxuob@email.com' ||
              this.state.guestEmail === 'nusalinkxuob2@email.com'
            )
              toast.error(redirect_url);
            else
              toast.error('There is an error, please contact customer service');
          }
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response) {
          toast.info(error.response);
        } else {
          toast.info(error.response);
        }
        if (error.response.data.status === 'error')
          if (error.response.data.message) {
            const { contact_cs } = error.response.data;
            const msg = (
              <div>
                <div>
                  {error.response.data.message}
                </div>
                <br />
                {
                  contact_cs && (
                    <div style={{ display: 'flex' }}>
                      <div
                        className="wa-cs"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          window.open('https://wa.me/628121855557');
                        }}
                      >
                        {/* <i className="fab fa-whatsapp" /> Customer Service */}
                        <img
                          src={require('../../assets/others/wa-putih.png')}
                          alt="WA Customer Service Nusalink"
                        />
                        CS 1
                      </div>

                      <div
                        className="wa-cs"
                        onClick={() => {
                          window.open('https://wa.me/628111518557');
                        }}
                      >
                        {/* <i className="fab fa-whatsapp" /> Customer Service */}
                        <img
                          src={require('../../assets/others/wa-putih.png')}
                          alt="WA Customer Service 2 Nusalink"
                        />
                        CS 2
                      </div>
                    </div>
                  )
                }
              </div>
            )

            toast.error(msg);
            if (
              state.email === 'nusalinkxuob@email.com' ||
              state.email === 'nusalinkxuob2@email.com'
            ) {
              window.location.href = '/detail-villas?villas=63';
            }
          }
        if (error.response.status === 422) {
          const errors = error.response.data.errors;
          const keys = Object.keys(errors);
          let msg = (
            <div>
              {keys.map(key => {
                return (
                  <div>
                    {errors[key]['msg']}
                    <br />
                  </div>
                );
              })}
            </div>
          );
          toast.error(msg);
        }
      });
  };

  specialRequest = () => {
    this.setState({
      requestspecial: !this.state.requestspecial,
      panah: !this.state.panah,
      extra: ''
    });
  };

  checkVoucher = () => {
    ApiCall.getVoucher(this.state.kodeVoucher, this.state.hotel.id)
      .then(response => {
        const msg = response.data;
        const { t } = this.props;
        if (msg.message === 'found') {
          this.props.setMessage(t.promoNotFound);
          this.props.toggleModal('popupmessage');
          this.setState({
            promo_coupon_percent: 0,
            promo_coupon_value: 0,
            valueVoucher: 0,
            total: this.state.totalMurni,
            promovalactived: false
          });
        } else if (msg.message === 'exp') {
          this.props.setMessage(t.promoExpire);
          this.props.toggleModal('popupmessage');
          this.setState({
            promo_coupon_percent: 0,
            promo_coupon_value: 0,
            valueVoucher: 0,
            total: this.state.totalMurni,
            promovalactived: false
          });
        } else if (msg.message === 'unused') {
          this.props.setMessage(t.promoUse);
          this.props.toggleModal('popupmessage');
          this.setState({
            promo_coupon_percent: 0,
            promo_coupon_value: 0,
            valueVoucher: 0,
            total: this.state.totalMurni,
            promovalactived: false
          });
        } else {
          this.setState(prevState => {
            return {
              promo_coupon_percent: msg.voucher.value,
              promo_coupon_value: msg.voucher.nominal,
              promovalactived: true
            };
          });
        }
        this.setTotal();
      })
      .catch(error => {
        this.props.setMessage(this.props.t.nointernet);
        this.props.toggleModal('popupmessage');
        this.setState({ promovalactived: false });
      });
  };

  toggleModal = name => {
    this.setState({ [name]: !this.state[name] });
  };

  activedPromo = e => {
    if (this.state.promoactived) {
      this.setState({ promovalactived: false });
    }
    this.setState(prevState => ({
      promoactived: !prevState.promoactived,
      valueVoucher: 0,
      kodeVoucher: '',
      promo_coupon_percent: prevState.promoactived
        ? 0
        : prevState.promo_coupon_percent
    }));
    this.setTotal();
  };

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  guestIsUser = () => {
    if (this.state.isCheck === false) {
      this.setState({
        isCheck: true,
        guestName: this.state.userName,
        guestIdNumber: this.state.userIdNumber,
        guestNoTelp: this.state.userNoTelp,
        guestEmail: this.state.userEmail,
        guestAddress: this.state.userAddress
      });
    } else {
      this.setState({
        isCheck: false,
        guestName: '',
        guestIdNumber: '',
        guestNoTelp: '',
        guestEmail: '',
        guestAddress: ''
      });
    }
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle('show');
  };

  changeCodePhone = (status, value, countryData, number, id) => {
    this.setState({ area: value });
  };

  showMethodPayment = () => {
    this.props.toggleModal('methodPayments');
  };

  setMultiplePrice() {
    setTimeout(() => {
      let total = 0;
      let notAvailable = false;
      for (let a = 0; a < this.state.items.length; a++) {
        const itemX = this.state.items[a];
        const room = this.state.rooms.find(item => {
          return Number(item.id) === Number(itemX.room_id);
        });
        for (let i = 0; i < room.rates.length; i++) {
          if (room.rates[i]['unit'] > 0) total += room.rates[i]['fix_price'];
          else notAvailable = true;
        }
      }
      // is promo login
      let promoLogin = 0;
      let promoLoginPercent = 0;
      if (this.props.app.user !== '' && this.props.app.promoLogin > 0) {
        promoLoginPercent = this.props.app.promoLogin;
        promoLogin = Math.floor((total / 100) * this.props.app.promoLogin);
        total -= promoLogin;
      }
      let promoNusalink = 0;
      if (this.state.promoNusalinkPercent > 0) {
        promoNusalink = Math.floor(
          (total / 100) * this.state.promoNusalinkPercent
        );
        total -= promoNusalink;
      }

      this.setState({
        totalMurni: total + promoLogin + promoNusalink,
        total,
        promoLogin,
        promoLoginPercent,
        notAvailable,
        promoNusalink
      });
    }, 500);
  }

  eventClickOptionPay = e => {
    const target = e.target;
    const value = target.value;
    if (value === '1') {
      this.setState({
        payfull: false,
        payatvil: true,
        payupf: false,
        paykredivo: false,
      });
    } else if (value === '0') {
      this.setState({
        payfull: true,
        payatvil: false,
        payupf: false,
        paykredivo: false,
      });
    } else if (value === '2') {
      this.setState({
        payfull: false,
        payatvil: false,
        payupf: true,
        paykredivo: false,
      });
    }
    else if (value === '3') {
      this.setState({
        payfull: false,
        payatvil: false,
        payupf: false,
        paykredivo: true,
      });
    }
    this.setState({ optionPay: value });
  };

  render() {
    const { t, app } = this.props;
    // console.log(app.lang);
    const isEn = app === 'en';
    const {
      loading,
      // params,
      hotel,
      // subtotal,
      total,
      valueVoucher,
      promoactived,
      requestspecial,
      // area,
      panah,
      promovalactived,
      kodeVoucher,
      guest,
      duration,
      payfull,
      payatvil,
      paykredivo,
      // payupf,
      // totalMurni
      // rangeArrival
    } = this.state;
    // console.log("payAtvilla", hotel.payAtVilla);

    const isLoggedIn = !!this.props.app.user

    let coupon = null;
    if (valueVoucher > 0) {
      coupon = (
        <tr className={promovalactived ? 'promo-active' : 'promo-nonactive'}>
          <td>{t.coupon}</td>
          <td colSpan="2" className="has-text-right">
            - {t.currency} {Numeral(valueVoucher).format('0,0')}
          </td>
        </tr>
      );
    }

    const referralForGuest = this.state.promo_referral_nominal > 0 && (
      <tr>
        <td>{t['Referral Code']}</td>
        <td colSpan="2" className="has-text-right">
          - {t.currency} {rupiah(this.state.promo_referral_nominal)}
        </td>
      </tr>
    );

    const referralForReferrer = this.state.promo_referral_for_referrer_nominal >
      0 && (
      <tr>
        <td>
          {t['My Referral Obtained']} (
          {this.state.promo_referral_for_referrer_percent}%)
        </td>
        <td colSpan="2" className="has-text-right">
          - {t.currency}{' '}
          {rupiah(this.state.promo_referral_for_referrer_nominal)}
        </td>
      </tr>
    );

    const referralForReferrerInput = this.state.promoReferralForReferrers
      .length > 0 &&
      this.state.hotel.is_referral === 1 && (
        <tr>
          <td colSpan="3">
            {t['My Referral Obtained']}
            <br />

            {this.state.promoReferralForReferrers.map((item, index) => {
              return (
                <div key={item.id}>
                  <input
                    value={item.id}
                    onChange={e => {
                      const id = e.target.value;
                      const isChecked = e.target.checked;
                      // console.log(e.target.checked);
                      this.setState(state => {
                        const promoReferralForReferrers = state.promoReferralForReferrers.map(
                          item => {
                            if (Number(item.id) === Number(id)) {
                              if (isChecked) {
                                item.is_used = 1;
                              } else {
                                item.is_used = 0;
                              }
                            }
                            return item;
                          }
                        );
                        return {
                          promoReferralForReferrers
                        };
                      });
                    }}
                    type="checkbox"
                  />{' '}
                  Referral {index + 1}
                  <br />
                </div>
              );
            })}
          </td>
        </tr>
      );

    return (
      <section className="section" onClick={this.closeHeader}>
        <Loader loading={loading} />
        <form onSubmit={e => this.pay(e, this.props.app.lang)} action="">
          <div className="container">
            <div className="columns">
              <div className="column is-8">
                <div className="box-reservation">
                  <div className="columns is-mobile is-multiline">
                    <div className="column is-12">
                      <h5 className="title is-5">{t.information}</h5>
                      <hr style={{ marginBottom: -4 }} />
                    </div>
                    <div className="column is-12 is-narrow">
                      <div className="columns is-mobile is-multiline">
                        <div className="column is-narrow is-input">
                          <div className="field">
                            <label className="label">{t.guestName}</label>
                            <div className="control">
                              <input
                                className="input input-reservation"
                                type="text"
                                name="guestName"
                                onChange={this.handleINputChangGuest}
                                value={this.state.guestName || ''}
                                required
                              />
                            </div>
                            <p className="label-down">{t.noteGuestName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-narrow is-input">
                      <label className="label">{t.phone}</label>
                      <div className="field has-addons">
                        <div className="control">
                          <IntlTelInput
                            defaultCountry="id"
                            value={this.state.area}
                            style={{ width: '100%', backgroundColor: 'red' }}
                            onPhoneNumberChange={this.changeCodePhone}
                          />
                        </div>
                        <div className="field-label labelCode">
                          <div className="labelCodePhone">
                            {this.state.area}
                          </div>
                        </div>
                        <div className="control" style={{ width: '100%' }}>
                          <input
                            className="input input-reservation2"
                            type="number"
                            name="guestNoTelp"
                            onChange={this.handleINputChangGuest}
                            value={this.state.guestNoTelp || ''}
                            required
                          />
                        </div>
                      </div>
                      <p className="label-down" style={{ marginTop: -10 }}>
                        {t.notePhone}
                      </p>
                    </div>
                    <div className="column is-narrow is-input">
                      <div className="field">
                        <label className="label">Email</label>
                        <div className="control">
                          <input
                            className="input input-reservation2"
                            type="email"
                            name="guestEmail"
                            onChange={this.handleINputChangGuest}
                            value={this.state.guestEmail || ''}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column is-12">
                      <div
                        className="box-request"
                        style={{ cursor: 'default' }}
                      >
                        <label className="title-request">
                          {t.optionSpecialRequest}{' '}
                        </label>
                        <img
                          alt=""
                          className={
                            panah
                              ? 'panah-request-nonaktif'
                              : 'panah-request-aktif'
                          }
                          src="/icon/panah-bawah.png"
                          width="18"
                          height="18"
                          onClick={this.specialRequest}
                        />
                        <img
                          alt=""
                          className={
                            panah
                              ? 'panah-request-aktif'
                              : 'panah-request-nonaktif'
                          }
                          src="/icon/panahatas.png"
                          width="18"
                          height="18"
                          onClick={this.specialRequest}
                        />
                        <p
                          className="label-down"
                          align="justify"
                          style={{ cursor: 'text' }}
                        >
                          {t.noteSpecialRequest}
                        </p>
                      </div>
                    </div>
                    <div className={'column is-12'}>
                      <div className="field">
                        <div className="control">
                          <textarea
                            className={
                              requestspecial
                                ? ' textarea request-active'
                                : ' textarea request-nonactive'
                            }
                            name="extra"
                            maxLength={670}
                            onChange={this.handleINputChangExtra}
                            type="text"
                          />
                          <label
                            className={
                              requestspecial
                                ? ' label-down request-active'
                                : ' label-down request-nonactive'
                            }
                          >
                            {t.max} {this.state.length} {t.character}{' '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="box-reservation mt-3"
                  style={{ marginTop: '20px' }}
                >
                  <h5 className="title is-5">Rooms</h5>
                  <div className="columns is-multiline long">
                    <RoomList
                      setRoom={room => {
                        if (this.state.hotel.is_multiple) {
                          return;
                        }
                        // console.log('roomRates', room.rates);
                        let total = 0;
                        for (let i = 0; i < room.rates.length; i++) {
                          if (room.rates[i]['unit'] > 0)
                            total += room.rates[i]['fix_price'];
                        }
                        // console.log('total', total);
                        // is promo login
                        let promoLogin = 0;
                        let promoLoginPercent = 0;
                        if (
                          this.props.app.user !== '' &&
                          this.props.app.promoLogin > 0
                        ) {
                          promoLoginPercent = this.props.app.promoLogin;
                          promoLogin = Math.floor(
                            (total / 100) * this.props.app.promoLogin
                          );
                          // total -= promoLogin;
                        }
                        let promoNusalink = 0;
                        if (this.state.promoNusalinkPercent > 0) {
                          promoNusalink = Math.floor(
                            (total / 100) * this.state.promoNusalinkPercent
                          );
                          // total -= promoNusalink;
                        }
                        total -= this.state.promoPrice;
                        const notAvailable = room.rates.some(item => {
                          return item.unit === 0;
                        });
                        this.setState({
                          // totalMurni: total + promoLogin + promoNusalink,
                          totalMurni: total + this.state.promoPrice,
                          total,
                          room,
                          promoLogin,
                          promoLoginPercent,
                          notAvailable,
                          promoNusalink
                        });
                      }}
                      hotel={hotel}
                      rooms={this.state.rooms}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="box-reservation-detail">
                  <h5 className="title is-5">{t.detail}</h5>
                  <hr />
                  <Star star={hotel.star} />
                  <label className="label" style={{ marginBottom: 10 }}>
                    {hotel.name}
                  </label>
                  <div className="columns is-mobile is-multiline">
                    <div className="column is-12">
                      {this.state.hotel.is_multiple === false && (
                        <table
                          className="detail-reser"
                          style={{ width: '100%' }}
                        >
                          <tbody>
                            <tr>
                              <td>{'Room'}</td>
                              <td className="has-text-right">
                                {this.state.room.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      {this.state.hotel.is_multiple === false && (
                        <hr style={{ marginTop: 10, marginBottom: 20 }} />
                      )}

                      <table className="detail-reser" style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td>{t.guestName}</td>
                            <td className="has-text-right">
                              {this.state.guestName}
                            </td>
                          </tr>
                          <tr>
                            <td>{t.phone}</td>
                            <td className="has-text-right">
                              {this.state.guestNoTelp === undefined ||
                              this.state.guestNoTelp === ''
                                ? ''
                                : this.state.area + '' + this.state.guestNoTelp}
                            </td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td className="has-text-right">
                              {this.state.guestEmail}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr style={{ marginTop: 10, marginBottom: 20 }} />
                      <table style={{ width: '100%' }} className="detail-reser">
                        <tbody>
                          <tr>
                            <td>Check-in</td>
                            <td className="has-text-right">
                              {this.state.arrival === ''
                                ? ''
                                : moment(this.state.arrival).format('D') +
                                  ' ' +
                                  (this.props.app.lang === '' ||
                                  this.props.app.lang === 'id'
                                    ? dateIN[
                                        moment(this.state.arrival).format('M') -
                                          1
                                      ]
                                    : dateEN[
                                        moment(this.state.arrival).format('M') -
                                          1
                                      ]) +
                                  ' ' +
                                  moment(this.state.arrival).format('Y')}
                            </td>
                          </tr>
                          <tr>
                            <td>Check-out </td>
                            <td className="has-text-right">
                              {this.state.departure === ''
                                ? ''
                                : moment(this.state.departure).format('D') +
                                  ' ' +
                                  (this.props.app.lang === '' ||
                                  this.props.app.lang === 'id'
                                    ? dateIN[
                                        moment(this.state.departure).format(
                                          'M'
                                        ) - 1
                                      ]
                                    : dateEN[
                                        moment(this.state.departure).format(
                                          'M'
                                        ) - 1
                                      ]) +
                                  ' ' +
                                  moment(this.state.departure).format('Y')}
                            </td>
                          </tr>
                          <tr>
                            <td>{t.stayDuration}</td>
                            <td className="has-text-right">
                              {duration} {t.night}
                            </td>
                          </tr>
                          <tr>
                            <td>{t.guestReservation}</td>
                            <td className="has-text-right">
                              {guest} {t.people}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {this.state.hotel.is_multiple && (
                        <hr style={{ marginTop: 10, marginBottom: 20 }} />
                      )}
                      {/* {this.state.hotel.is_multiple && (
                        <table
                          style={{ width: '100%' }}
                          className="detail-reser"
                        >
                          <tbody>
                            {this.state.items.map((item, index) => (
                              <tr key={index}>
                                <td colSpan={2}>
                                  <SelectOption
                                    onChange={(e) => {
                                      // console.log(e);
                                      const items = this.state.items;
                                      items[index].room_id = e.target.value;
                                      this.setState({
                                        items,
                                      });
                                      this.setMultiplePrice();
                                    }}
                                    label={'Room ' + (index + 1)}
                                  >
                                    {this.state.rooms.map((room) => (
                                      <option
                                        key={room.id}
                                        value={room.id}
                                        defaultValue={item.room_id}
                                      >
                                        {room.name}
                                      </option>
                                    ))}
                                  </SelectOption>
                                  {index !== 0 && (
                                    <a
                                      style={{ color: 'red' }}
                                      onClick={() => {
                                        const items = this.state.items;
                                        items.splice(index, 1);
                                        this.setState({ items });
                                        this.setMultiplePrice();
                                      }}
                                    >
                                      X
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={2}>
                                <a
                                  style={{ color: COLORS.primary }}
                                  onClick={() => {
                                    const items = this.state.items;
                                    items.push({
                                      room_id: this.state.rooms[0].id,
                                    });
                                    this.setState({
                                      items,
                                    });
                                    this.setMultiplePrice();
                                  }}
                                >
                                  Add More Room
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )} */}
                    </div>
                  </div>
                  <hr style={{ marginTop: -13, marginBottom: 20 }} />
                  {this.state.room.name ? (
                    <div className="columns is-mobile is-multiline">
                      <div className="column is-12">
                        <table
                          className="detail-reser"
                          style={{ width: '100%' }}
                        >
                          <tbody>
                            <tr>
                              <th
                                colSpan="3"
                                className=""
                                style={{ paddingBottom: 0, width: '47%' }}
                              >
                                <div className="d-flex justify-content-start">
                                  {t.usePromoLabel}
                                  <label
                                    onClick={e => this.toggleModalPromoCode()}
                                  >
                                    <i>
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 10,
                                          marginBottom: -5,
                                          cursor: 'pointer'
                                        }}
                                        src="/icon/info.png"
                                        alt="Nusalink"
                                      />
                                    </i>
                                  </label>
                                </div>
                              </th>
                              {/* <td
                                className="has-text-right"
                                style={{ paddingBottom: 0, width: '53%' }}
                              >
                                <label className="switchs">
                                  <input
                                    type="checkbox"
                                    name="sliders"
                                    defaultChecked={promoactived}
                                    onClick={this.activedPromo}
                                  />
                                  <span className="sliders round" />
                                </label>
                              </td> */}
                            </tr>
                            <tr
                              className={
                                promoactived
                                  ? 'promo-active'
                                  : 'promo-nonactive'
                              }
                            >
                              <th colSpan="3" className="list-promo">
                                <div className="columns is-mobile is-multiline">
                                  <div
                                    className="column is-12"
                                    style={{
                                      marginBottom: -15,
                                      marginTop: -25
                                    }}
                                  >
                                    <p className="label-down" align="justify">
                                      {t.notePromo}
                                    </p>
                                  </div>
                                  <div
                                    className="column is-12"
                                    style={{ marginBottom: 15 }}
                                  >
                                    <div className="field">
                                      <div
                                        className="control"
                                        style={{ width: '65%', float: 'left' }}
                                      >
                                        <input
                                          className="input"
                                          type="text"
                                          onChange={e =>
                                            this.handleInputChangeVoucher(e)
                                          }
                                          value={kodeVoucher}
                                        />
                                      </div>
                                      <div
                                        className="control"
                                        style={{
                                          width: '30%',
                                          float: 'left',
                                          marginLeft: '5%'
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btnPromo"
                                          onClick={this.checkVoucher}
                                        >
                                          {t.usePromo}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            </tr>

                            {this.state.hotel.is_referral === 1 && (
                              <tr>
                                <td colSpan="2">
                                  <FormInput
                                    label={t['Referral Code']}
                                    onChange={e => {
                                      this.setState({
                                        referral_code: e.target.value
                                      });
                                      // alert(e.target.value);
                                    }}
                                  />
                                </td>
                                <td>
                                  <div style={{ marginTop: 22, marginLeft: 5 }}>
                                    <PrimaryButton
                                      label={t['Check']}
                                      onClick={e => {
                                        this.checkReferralCode(e);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}

                            {this.state.hotel.is_referral === 1 &&
                              referralForReferrerInput}
                            {this.props.app.user !== '' &&
                              this.state.point > 0 && (
                                <tr>
                                  <td>{t.point}</td>
                                  <td colSpan="2" className="has-text-right">
                                    {rupiah(this.state.point)}
                                  </td>
                                </tr>
                              )}
                            {this.props.app.user !== '' &&
                              this.state.point > 0 && (
                                <tr>
                                  <td>Use point?</td>
                                  <td colSpan="2" className="has-text-right">
                                    <input
                                      value="1"
                                      type="checkbox"
                                      name="sliders"
                                      defaultChecked={this.state.usePoint}
                                      onChange={e => {
                                        this.setState({
                                          usePoint: e.target.checked
                                        });
                                        this.setTotal();
                                      }}
                                    />
                                  </td>
                                </tr>
                              )}
                            {this.props.app.user !== '' &&
                              this.state.promo !== null &&
                              this.state.promo.user !== null && (
                                <tr>
                                  <td colSpan="2">
                                    Promo ref (
                                    {
                                      this.state.promo.user.reff_results
                                        .remaining
                                    }
                                    %)
                                  </td>
                                  <td className="has-text-right">
                                    <input
                                      value="1"
                                      type="checkbox"
                                      name="sliders"
                                      defaultChecked={
                                        this.state.is_using_promo_reff
                                      }
                                      onChange={e => {
                                        this.setState({
                                          is_using_promo_reff: e.target.checked
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              )}
                            {this.state.hotel.is_multiple === false && (
                              <tr>
                                <td colSpan="3">{this.state.room.name}</td>
                              </tr>
                            )}

                            {this.state.hotel.fee_percent !== null && (
                              <tr>
                                <td colSpan="3">
                                  <article className="message is-warning">
                                    <div className="message-body">
                                      {/* {isEn
                                        ? `room rates include ${this.state.hotel.fee_percent}% tax`
                                        : `harga kamar sudah termasuk pajak ${this.state.hotel.fee_percent}%`} */}
                                      {isEn
                                        ? `room rates include tax`
                                        : `harga kamar sudah termasuk pajak`}
                                      {this.props.app.user !== '' &&
                                        this.state.promo !== null &&
                                        this.state.promo.user !== null &&
                                        this.state.promo.user.reff_results
                                          .remaining > 50 && [
                                          <br />,
                                          isEn
                                            ? `Maximum referral promo is 50%`
                                            : 'Maksimal promo referral adalah 50%'
                                        ]}
                                    </div>
                                  </article>
                                </td>
                              </tr>
                            )}

                            {/* rate by date */}
                            {this.state.hotel.is_multiple === false &&
                              this.state.room.rates.map(item => (
                                <tr
                                  style={{
                                    color: item.unit <= 0 ? 'red' : 'black'
                                  }}
                                  key={item.id}
                                >
                                  <td>
                                    {item.rateDate}
                                    {item.unit <= 0 && (
                                      <small>
                                        <br />
                                        {this.props.app.lang === 'en'
                                          ? 'Allotment not available'
                                          : 'Tidak tersedia'}
                                      </small>
                                    )}
                                    {item.unit <= 5 && item.unit > 0 && (
                                      <small style={{ color: 'red' }}>
                                        <br />
                                        {this.props.app.lang === 'en'
                                          ? item.unit + ' remaining'
                                          : item.unit + ' tersisa'}
                                      </small>
                                    )}
                                  </td>
                                  <td colSpan="2" className="has-text-right">
                                    {t.currency}{' '}
                                    {rupiah(
                                      item.unit <= 0 ? 0 : item.fix_price
                                    )}
                                  </td>
                                </tr>
                              ))}

                            {/* rate multiple */}
                            {this.state.hotel.is_multiple && (
                              <MultipleBooking
                                roomSelected={this.state.roomSelected}
                                currency={t.currency}
                                onChange={result => {
                                  const { multiples, subTotal } = result;
                                  const promoPrice =
                                    (this.state.promo.promo * subTotal) / 100;
                                  this.setState({
                                    multiples,
                                    totalMurni: subTotal,
                                    promoPrice,
                                    total: subTotal - promoPrice
                                  });
                                  console.log(result);
                                  // let total = 0;
                                  // for (let i = 0; i < room.rates.length; i++) {
                                  //   if (room.rates[i]['unit'] > 0)
                                  //     total += room.rates[i]['fix_price'];
                                  // }
                                  // // console.log('total', total);
                                  // // is promo login
                                  // let promoLogin = 0;
                                  // let promoLoginPercent = 0;
                                  // if (
                                  //   this.props.app.user !== '' &&
                                  //   this.props.app.promoLogin > 0
                                  // ) {
                                  //   promoLoginPercent = this.props.app
                                  //     .promoLogin;
                                  //   promoLogin = Math.floor(
                                  //     (total / 100) * this.props.app.promoLogin
                                  //   );
                                  //   total -= promoLogin;
                                  // }
                                  // let promoNusalink = 0;
                                  // if (this.state.promoNusalinkPercent > 0) {
                                  //   promoNusalink = Math.floor(
                                  //     (total / 100) *
                                  //       this.state.promoNusalinkPercent
                                  //   );
                                  //   total -= promoNusalink;
                                  // }
                                  // const notAvailable = room.rates.some(item => {
                                  //   return item.unit === 0;
                                  // });
                                  // this.setState({
                                  //   totalMurni:
                                  //     total + promoLogin + promoNusalink,
                                  //   total,
                                  //   room,
                                  //   promoLogin,
                                  //   promoLoginPercent,
                                  //   notAvailable,
                                  //   promoNusalink
                                  // });
                                  // localStorage.setItem(
                                  //   'multiples',
                                  //   JSON.stringify(multiples)
                                  // );
                                }}
                                rooms={this.state.rooms}
                                multiples={this.state.multiples}
                                setRoomSelected={roomSelected => {
                                  this.setState({ roomSelected });
                                }}
                              />
                            )}
                            {/* {this.state.hotel.is_multiple &&
                              this.state.hotel.dates.map((tgl, index) => {
                                return this.state.items.map((itemX, indexX) => {
                                  const rooms = this.state.rooms.map(item => {
                                    if (itemX.room_id === null) return null;
                                    if (
                                      Number(item.id) !== Number(itemX.room_id)
                                    )
                                      return null;
                                    const rate = item.rates.find(rate => {
                                      return tgl === rate.rateDate;
                                    });
                                    const hehe = (
                                      <tr
                                        style={{
                                          color:
                                            rate.unit <= 0 ? 'red' : 'black'
                                        }}
                                        key={rate.id}
                                      >
                                        <td>
                                          {item.name}
                                          {rate.unit <= 0 && (
                                            <small>
                                              <br />
                                              {this.props.app.lang === 'en'
                                                ? 'Allotment not available'
                                                : 'Tidak tersedia'}
                                            </small>
                                          )}
                                          {rate.unit <= 5 && rate.unit > 0 && (
                                            <small style={{ color: 'red' }}>
                                              <br />
                                              {this.props.app.lang === 'en'
                                                ? rate.unit + ' remaining'
                                                : rate.unit + ' tersisa'}
                                            </small>
                                          )}
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="has-text-right"
                                        >
                                          {t.currency}{' '}
                                          {rupiah(
                                            rate.unit <= 0 ? 0 : rate.fix_price
                                          )}
                                        </td>
                                      </tr>
                                    );
                                    return hehe;
                                  });
                                  const tglComp = (
                                    <tr>
                                      <td colSpan="3">{tgl}</td>
                                    </tr>
                                  );
                                  return [indexX === 0 ? tglComp : null, rooms];
                                });
                              })} */}

                            {/* sub total */}
                            <tr>
                              <td colSpan="3">
                                <hr style={{ margin: 0 }} />
                              </td>
                            </tr>
                            <tr>
                              <td>Sub Total</td>
                              <td colSpan="2" className="has-text-right">
                                {t.currency}{' '}
                                {Numeral(this.state.subTotal).format('0,0')}
                              </td>
                            </tr>

                            {/* use point */}
                            {this.props.app.user !== '' && this.state.usePoint && (
                              <tr>
                                <td>{t.point}</td>
                                <td colSpan="2" className="has-text-right">
                                  - {t.currency} {rupiah(this.state.pointUsed)}
                                </td>
                              </tr>
                            )}

                            {/* promo login */}
                            {/* {this.props.app.user !== '' &&
                              this.props.app.promoLogin > 0 && (
                                <tr>
                                  <td>{t.promoLogin}</td>
                                  <td colSpan="2" className="has-text-right">
                                    - {t.currency}{' '}
                                    {rupiah(this.state.promoLogin)}
                                  </td>
                                </tr>
                              )} */}

                            {/* promo nusalink */}
                            {/* {this.state.promoNusalinkPercent > 0 && (
                              <tr>
                                <td>{t.promoNusalink}</td>
                                <td colSpan="2" className="has-text-right">
                                  - {t.currency}{' '}
                                  {rupiah(this.state.promoNusalink)}
                                </td>
                              </tr>
                            )} */}

                            {this.state.promoPrice > 0 && (
                              <tr>
                                <td>Promo ({this.state.promo.promo}%)</td>
                                <td colSpan="2" className="has-text-right">
                                  - {t.currency} {rupiah(this.state.promoPrice)}
                                </td>
                              </tr>
                            )}

                            {coupon}
                            {referralForGuest}
                            {referralForReferrer}
                            <tr>
                              <td>Kode Unik</td>
                              <td colSpan="2" className="has-text-right">
                                {t.currency} {rupiah(this.state.unique_code)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* total */}
                      <div
                        className="column is-12"
                        style={{ paddingTop: 0, marginTop: -20 }}
                      >
                        <hr style={{ marginTop: 5 }} />
                        <table
                          style={{
                            width: '100%',
                            marginTop: -15,
                            marginBottom: -15
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ fontSize: 14, fontWeight: 'bold' }}>
                                Total
                              </td>
                              <td
                                className="has-text-right"
                                style={{ fontSize: 14, fontWeight: 'bold' }}
                              >
                                {t.currency}{' '}
                                {Numeral(this.state.total).format('0,0')}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <hr />
                      </div>
                      <div
                        className="column is-12"
                        style={{ marginTop: -25, marginBottom: -10 }}
                      >
                        <label className="labelOptionPayment">
                          {t.optPayments}
                          <label onClick={e => this.showMethodPayment()}>
                            <i>
                              <img
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginLeft: 10,
                                  marginBottom: -5,
                                  cursor: 'pointer'
                                }}
                                src="/icon/info.png"
                                alt="Nusalink"
                              />
                            </i>
                          </label>
                        </label>
                      </div>
                      <div className="column is-12">
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td width="45%">
                                <label className="containerpay">
                                  <input
                                    type="radio"
                                    checked={payfull}
                                    name="radio"
                                    className="optionPay"
                                    value="0"
                                    onChange={e => this.eventClickOptionPay(e)}
                                  />
                                  <span className="checkmarkpay" />{' '}
                                  <label>{t.payinfull}</label>
                                </label>
                              </td>
                              <td width="55%">
                                <label className="labelPrice">
                                  {t.currency} {Numeral(total).format('0,0')}
                                </label>
                              </td>
                            </tr>

                            {this.state.showPayAtProperty &&
                              this.state.hotel.payAtVilla === 1 && (
                                <tr>
                                  <td>
                                    <label className="containerpay">
                                      <input
                                        type="radio"
                                        name="radio"
                                        checked={payatvil}
                                        className="optionPay"
                                        value="1"
                                        onChange={e =>
                                          this.eventClickOptionPay(e)
                                        }
                                      />
                                      <span className="checkmarkpay" />{' '}
                                      <label>{t.payatvilla}</label>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="labelPrice">
                                      {t.currency}{' '}
                                      {Numeral(parseInt(total, 10)).format(
                                        '0,0'
                                      )}
                                    </label>
                                  </td>
                                </tr>
                              )}

                            {this.state.hotel.is_multiple &&
                              this.state.showPayDp && (
                                <tr>
                                  <td>
                                    <label className="containerpay">
                                      <input
                                        type="radio"
                                        name="radio"
                                        checked={this.state.payupf}
                                        className="optionPay"
                                        value="2"
                                        onChange={e =>
                                          this.eventClickOptionPay(e)
                                        }
                                      />
                                      <span className="checkmarkpay" />{' '}
                                      <label>
                                        DP (
                                        {this.state.hotel.dp_multiple_booking}
                                        %)
                                      </label>
                                    </label>
                                  </td>
                                  <td>
                                    <label className="labelPrice">
                                      {t.currency}{' '}
                                      {Numeral(
                                        parseInt(this.state.dp, 10)
                                      ).format('0,0')}
                                    </label>
                                  </td>
                                </tr>
                              )}

                            <tr>
                              <td width="45%">
                                <label className="containerpay">
                                  <input
                                    type="radio"
                                    checked={paykredivo}
                                    name="radio"
                                    className="optionPay"
                                    value="3"
                                    onChange={e => this.eventClickOptionPay(e)}
                                    disabled={!isLoggedIn}
                                  />
                                  <span className="checkmarkpay" />{' '}
                                  <label
                                    style={{
                                      opacity: !isLoggedIn ? '0.5' : '1'
                                    }}
                                  >
                                    Kredivo
                                  </label>
                                  {!isLoggedIn && (
                                    <div
                                      style={{
                                        color: 'red',
                                        marginLeft: 10
                                      }}
                                    >
                                      Login to use Kredivo
                                    </div>
                                  )}
                                </label>
                              </td>
                            </tr>

                            {this.state.optionPay === '1' && (
                              <tr>
                                <td colSpan="2">
                                  <span style={{ fontSize: 14 }}>
                                    {t.arrivalHoursToProperty}
                                  </span>
                                  <div className="select is-fullwidth">
                                    <select
                                      onChange={e => {
                                        this.setState({
                                          hour_arrival: e.target.value
                                        });
                                      }}
                                      className="select-kota"
                                    >
                                      {this.state.hours.map(item => (
                                        <option
                                          key={item}
                                          defaultValue={this.state.hour_arrival}
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="column is-12">
                        <button
                          onClick={e => {
                            e.preventDefault();
                            this.pay(e);
                          }}
                          disabled={this.state.notAvailable}
                          style={{
                            backgroundColor: this.state.notAvailable
                              ? '#ccc'
                              : '#f16726'
                          }}
                          className="btnCheckout"
                          type="submit"
                        >
                          {/* {this.state.notAvailable
                            ? this.props.app.lang === 'en'
                              ? 'Keep booking'
                              : 'Tetap pesan'
                            : t.reserv} */}
                          {this.state.notAvailable
                            ? this.props.app.lang === 'en'
                              ? 'Cannot booking'
                              : 'Tidak bisa memesan'
                            : t.reserv}
                        </button>
                        {this.state.notAvailable && (
                          <small style={{ color: 'red' }}>
                            {this.props.app.lang === 'en'
                              ? 'Some rooms are not available on certain dates'
                              : 'Ada kamar yang tidak tersedia pada tanggal tertentu'}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="notification is-danger">
                      {this.props.app.lang === 'en'
                        ? 'Choose room first'
                        : 'Pilih tipe kamar dulu'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* modal method payments */}
        <div
          className={
            'modal ' + (this.props.app.methodPayments ? 'is-active' : '')
          }
        >
          <div
            className="modal-background"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
          <div
            className="btnClose"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
          <div className="modal-content" style={{ width: 400 }}>
            <div className="boxOptionPay">
              <h4 style={{ color: 'black', marginTop: -10 }}>
                <b>{t.optPayments}</b>
              </h4>
              <br />
              <label style={{ color: 'black' }} className="labelDesOptionPay">
                <b>{t.payinfull}</b>
              </label>
              <p align="justify" className="deskripPayReservation">
                {t.payinfulldes}
              </p>
              <hr />
              <label style={{ color: 'black' }} className="labelDesOptionPay">
                <b>{t.payatvilla}</b>
              </label>
              <p align="justify" className="deskripPayReservation">
                {t.payatvilladesk}
              </p>
              <br />
              <br />
              <p
                align="justify"
                className="deskripPayReservation"
                style={{ marginTop: -20 }}
              >
                {t.payatvilladesknote}
              </p>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
        </div>

        <div
          className={'modal ' + (this.state.isPopupPromoCode && 'is-active')}
          style={{ paddingTop: 100 }}
        >
          <div
            className="modal-background "
            onClick={e => this.toggleModalPromoCode()}
          />
          <div
            className="btnClose btnCloseLogin"
            onClick={e => this.toggleModalPromoCode()}
          />
          <div className="modal-content" style={{ width: 395 }}>
            <div className="box box-register">
              <strong>
                {this.props.app.lang === 'id' ? 'Kode Promo' : 'Promo Code'}
              </strong>
              <br />
              {this.props.app.lang === 'id'
                ? 'Kode promo hanya bisa digunakan di aplikasi mobile. Silakan anda mengunduh terlebih dahulu dari play store atau pun app store'
                : 'The promo code can only be used in the mobile application. Please download first from the play store or app store'}
              <div className="pt-3 d-flex justify-content-center">
                <img
                  onClick={() => {
                    window.open(PLAYSTORE_LINK);
                  }}
                  style={{ maxWidth: 150, cursor: 'pointer' }}
                  src={require('./../../assets/others/get-it-on-google-play.png')}
                  alt="Get It On Google Play"
                />
                <img
                  onClick={() => {
                    window.open(APPSTORE_LINK);
                  }}
                  style={{ maxHeight: 51, cursor: 'pointer' }}
                  src={require('./../../assets/others/appstore3.png')}
                  alt="Download on AppStore"
                />
              </div>
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.toggleModalPromoCode()}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  setUser,
  setMessage,
  toggleModal
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(ReservationForm));
