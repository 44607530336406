import React, { Component } from 'react';
// import { toast } from 'react-toastify';
import moment from 'moment';
// import Numeral from 'numeral';
import { connect } from 'react-redux';
// import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
import { translatable } from 'react-multilingual';
// import { BASE_LINK } from '../../constants'
// import queryString from '../../utils/queryString';
// import Loader from '../../components/Loader/Loader';
import ApiCall from '../../services/ApiCall';
import { setUser, setMessage, toggleModal } from '../../redux/actions';
// import RoomList from './ReservationForm/RoomList';
import {
  // dateEN, dateIN,
  HOUR_ARRIVALS
} from './../../constants';
// import { rupiah } from './../../utils/helper';
import Card from '../../components/others/Card';
import FormInput from '../../components/form/FormInput';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import NewRoomList from '../../components/NewRoomList/NewRoomList';
import DetailOrder from '../../components/DetailOrder/DetailOrder';

export class StayMonthlyReservationForm extends Component {
  state = {
    area: '+62',
    checkIn: '',
    checkOut: '',
    guestCount: 1,
    lamaMenyewa: 1,
    propertyId: null,
    hours: HOUR_ARRIVALS,
    hour_arrival: '14:00',
    rooms: [],
    name: this.props.app.user.name || '',
    userIdNumber: this.props.app.user.IdNumber || '',
    phoneNumber: this.props.app.user.phone || '',
    email: this.props.app.user.email || '',
    userAddress: this.props.app.user.address || '',
    specialRequest: '',
    guestName: '',
    guestIdNumber: '',
    guestNoTelp: '',
    guestEmail: '',
    guestAddress: '',
    extraBed: '',
    extraBreakfast: '',
    hotel: {},
    extraOther: '',
    inputExtraOther: false,
    loading: false,
    isCheck: false,
    params: [],
    kodeVoucher: '',
    valueVoucher: 0,
    subtotal: 0,
    total: 0,
    lang: 'id',
    arrival: '',
    departure: '',
    language: '',
    promoactived: false,
    promovalactived: false,
    requestspecial: false,
    extra: '',
    panah: false,
    user_Id: '',
    length: '0',
    duration: '',
    guest: '',
    optionPayment: '0',
    debt: 0,
    payfull: true,
    payatvil: false,
    payupf: false,
    rangeArrival: 0,
    totalMurni: 0,
    room: {},
    optionPay: 0,
    point: 0,
    usePoint: false,
    pointUsed: 0,
    promoLogin: 0,
    promoLoginPercent: 0,
    notAvailable: false,
    property: null,
    roomIndex: null
  };

  constructor(props) {
    super(props);

    this.specialRequest = this.specialRequest.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.restoreFromStorage();
    this.getData();
    this.setUserLogin();
  }

  restoreFromStorage() {
    const stayMonthly = JSON.parse(localStorage.getItem('stayMonthly'));
    const checkout = moment(stayMonthly.checkIn)
      .add(
        stayMonthly.lamaMenyewa *
          (this.props.isWeekly ? 7 : this.props.isYearly ? 365 : 30),
        'd'
      )
      .format('YYYY-MM-DD');
    stayMonthly.checkOut = checkout;
    this.setState(stayMonthly);
  }

  getData() {
    setTimeout(() => {
      let type = 'monthly';
      if (this.props.isYearly) {
        type = 'yearly';
      } else if (this.props.isWeekly) {
        type = 'weekly';
      }
      ApiCall.getPropertyDetailStayMonthly(this.state.propertyId, true, type)
        .then(res => {
          this.setState({
            property: res.data.data
          });
          console.log(res.data.data);
        })
        .catch(err => {
          console.log(err);
        });
    }, 500);
  }

  specialRequest() {
    this.setState({
      requestspecial: !this.state.requestspecial
    });
  }

  handleForm(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  setRoom(roomIndex) {
    console.log('roomIndex', roomIndex);
    this.setState({ roomIndex });
  }

  setUserLogin() {
    setTimeout(() => {
      this.setState({
        name: this.props.app.user.name || '',
        phoneNumber: this.props.app.user.phone || '',
        email: this.props.app.user.email || ''
      });
    }, 1000);
  }

  render() {
    const { t } = this.props;
    const isEn = this.props.app.lang === 'en';
    const {
      // loading,
      // // params,
      // hotel,
      // // subtotal,
      // total,
      // valueVoucher,
      // promoactived,
      requestspecial,
      // // area,
      // panah,
      // promovalactived,
      // kodeVoucher,
      // guest,
      // duration,
      // payfull,
      // payatvil,
      // // payupf,
      // totalMurni,
      // // rangeArrival
      // name,
      // area,
      // phoneNumber,
      // email,
      // specialRequest,
      property
    } = this.state;

    if (property === null) return <div></div>;

    // if (this.props.isYearly) {
    //   return (
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-md-12">Coming soon</div>
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <section className="section" onClick={this.closeHeader}>
        <form onSubmit={e => this.pay(e, this.props.app.lang)} action="">
          <div className="container">
            <div className="columns">
              <div className="column is-8">
                <Card title={t.information}>
                  <div className="column is-12 is-narrow">
                    <div className="columns">
                      <div className="column is-6">
                        <FormInput
                          name="name"
                          value={this.state.name || ''}
                          onChange={this.handleForm}
                          label={t.name}
                          hint={t.noteGuestName}
                          defaultValue={this.props.app.user.name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-narrow is-input">
                    <PhoneNumberInput
                      phoneNumber={this.state.phoneNumber || ''}
                      area={this.state.area || '+62'}
                      onChange={(e, area) => {
                        this.setState({
                          area
                        });
                      }}
                      onChange2={e => {
                        this.setState({
                          phoneNumber: e.target.value
                        });
                      }}
                    />
                  </div>
                  <div className="column is-narrow is-input">
                    <FormInput
                      name="email"
                      value={this.state.email || ''}
                      onChange={this.handleForm}
                      label="Email"
                      defaultValue={this.props.app.user.email}
                    />
                  </div>
                  <div className="column is-12">
                    <div className="box-request" style={{ cursor: 'default' }}>
                      <label className="title-request">
                        {t.optionSpecialRequest}{' '}
                      </label>
                      <img
                        style={{ cursor: 'pointer' }}
                        alt="Anak Panah"
                        src={
                          !this.state.requestspecial
                            ? '/icon/panah-bawah.png'
                            : '/icon/panahatas.png'
                        }
                        width="18"
                        height="18"
                        onClick={this.specialRequest}
                      />
                      <p
                        className="label-down"
                        align="justify"
                        style={{ cursor: 'text' }}
                      >
                        {t.noteSpecialRequest}
                      </p>
                    </div>
                  </div>
                  <div className={'column is-12'}>
                    <div className="field">
                      <div className="control">
                        <textarea
                          className={
                            requestspecial
                              ? ' textarea request-active'
                              : ' textarea request-nonactive'
                          }
                          name="specialRequest"
                          maxLength={670}
                          onChange={this.handleForm}
                          type="text"
                        />
                        <label
                          className={
                            requestspecial
                              ? ' label-down request-active'
                              : ' label-down request-nonactive'
                          }
                        >
                          {t.max} {this.state.length} {t.character}{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </Card>

                <div style={{ marginTop: 50 }} />
                <Card
                  isDesktop
                  title={isEn ? 'Choose Room Type' : 'Pilih Tipe Kamar'}
                >
                  <NewRoomList
                    isWeekly={this.props.isWeekly}
                    isYearly={this.props.isYearly}
                    onClick={index => {
                      this.setRoom(index);
                    }}
                    property={property}
                  />
                </Card>
              </div>
              <div className="column is-4">
                <DetailOrder
                  isWeekly={this.props.isWeekly}
                  isYearly={this.props.isYearly}
                  detail={this.state}
                />
              </div>
            </div>
          </div>
        </form>
        {/* modal method payments */}
        <div
          className={
            'modal ' + (this.props.app.methodPayments ? 'is-active' : '')
          }
        >
          <div
            className="modal-background"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
          <div
            className="btnClose"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
          <div className="modal-content" style={{ width: 400 }}>
            <div className="boxOptionPay">
              <h4 style={{ color: 'black', marginTop: -10 }}>
                <b>{t.optPayments}</b>
              </h4>
              <br />
              <label style={{ color: 'black' }} className="labelDesOptionPay">
                <b>{t.payinfull}</b>
              </label>
              <p align="justify" className="deskripPayReservation">
                {t.payinfulldes}
              </p>
              <hr />
              <label style={{ color: 'black' }} className="labelDesOptionPay">
                <b>{t.payatvilla}</b>
              </label>
              <p align="justify" className="deskripPayReservation">
                {t.payatvilladesk}
              </p>
              <br />
              <br />
              <p
                align="justify"
                className="deskripPayReservation"
                style={{ marginTop: -20 }}
              >
                {t.payatvilladesknote}
              </p>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={() => this.props.toggleModal('methodPayments')}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {
  setUser,
  setMessage,
  toggleModal
});
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(StayMonthlyReservationForm));
