import React, { Component } from 'react';
import StayMonthlyReservationForm from './StayMonthlyReservationForm';

class StayWeeklyReservationForm extends Component {
  state = {};

  render() {
    return <StayMonthlyReservationForm isWeekly history={this.props.history} />;
  }
}

export default StayWeeklyReservationForm;
