import React, { useEffect, useState } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import PrimaryButton from '../../components/form/PrimaryButton';
import Loader from '../../components/Loader/Loader';
import NotFound from '../../components/others/NotFound';
import PageTitle from '../../components/others/PageTitle';
import ApiCall from '../../services/ApiCall';
import Container from '../layouts/Container';

const GiveAwayDetailPage = ({ match, t, history }) => {
  const [giveaway, setGiveaway] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      ApiCall.findGiveAway(id)
        .then(res => {
          // console.log(res.data);
          if (res.data.status === 'success') setGiveaway(res.data.data);
        })
        .catch(err => {
          console.log('page not found');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Container>
      <PageTitle title={'Give Away'} />
      {!isLoading && giveaway === null && <NotFound />}
      <Loader loading={isLoading} />
      {!isLoading && giveaway !== null && (
        <div>
          <div className="has-text-centered">
            <img
              onClick={() => {
                window.open(giveaway.image_url);
              }}
              style={{ maxWidth: 600, cursor: 'pointer' }}
              src={giveaway.image_url}
              alt={'Give Away Promo ' + giveaway.id}
            />
            {/* <h3>Code: {voucher.code}</h3> */}
          </div>
          <div className="has-text-centered">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-6">
                <div className="has-text-centered" style={{ marginTop: 20 }}>
                  {/* <h3>{t.term}</h3> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: giveaway.term_and_conditions
                    }}
                  />
                  {giveaway.btn_link !== null && giveaway.btn_text !== null && (
                    <PrimaryButton
                      label={giveaway.btn_text}
                      onClick={() => {
                        window.location.href = giveaway.btn_link;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(GiveAwayDetailPage);
