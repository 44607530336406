import React, { Component } from 'react';

class PropertyTags extends Component {
  render() {
    let tags = null;
    if (this.props.tag_villa) {
      tags = this.props.tag_villa.map((item) => {
        return (
          <div
            key={item}
            style={{
              display: 'inline-block',
              backgroundColor: 'rgb(241, 103, 38)',
              color: 'white',
              padding: 5,
              fontSize: 12,
              borderRadius: 15,
              cursor: 'default',
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            {item}
          </div>
        );
      });
    }
    return tags;
  }
}

export default PropertyTags;
