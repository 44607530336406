import React, { Component } from 'react';

class CityAndCategory extends Component {
  render() {
    const { city, categoryName } = this.props;
    return (
      <div className="kolomkota">
        <label className="labelCityVilla">{String(city).toUpperCase()}</label>
        <br />
        <label className="labelTypeVilla180913">{categoryName}</label>
      </div>
    );
  }
}

export default CityAndCategory;
