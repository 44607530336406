import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import { toast } from 'react-toastify';
import moment from 'moment';
import Numeral from 'numeral';

import { logout, toggleModal, showModal, setUser } from '../redux/actions';
import queryString from '../utils/queryString';
import ApiCall from '../services/ApiCall';
import Loader from '../components/Loader/Loader';
import Sidebar from './MyBooking/Sidebar';
import { dateEN, dateIN } from '../constants';
// import LabelPrimary from '../components/others/LabelPrimary';
import './DetailBooking.css';
import Axios from 'axios';

function Row({ col1, col2 }) {
  return (
    <tr>
      <td style={{ fontSize: 12 }}>{col1}</td>
      <td style={{ fontSize: 12 }}>
        <b style={{ float: 'right' }}>{col2}</b>
      </td>
    </tr>
  );
}

class DetailBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      loading: false,
      lang: 'id',
      statusRegister: '',
      status: '',
      checkIn: '',
      checkOut: '',
      night: '',
      phone: '',
      code: '',
      price: '',
      promo: '',
      nip: '',
      nopesanan: '',
      villaname: '',
      guest: '',
      subtotal: 0,
      round: '',
      total: 0,
      specialRequest: '',
      statusPay: '',
      debt: 0,
      upfront: 0,
      totalMurni: '',
      room: null,
      statusText: '',
      transactionDetails: [],
      point: 0,
      promoLogin: 0,
      promoNusalink: 0,
      isMonthly: false,
      monthlyData: null,
      detail: {}
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (this.props.app.user === '') {
        window.location.href = '/';
      } else {
        this.setState({ loading: true });
        const paramString = queryString.parse(this.props.location.search);
        if (paramString.isMonthly) {
          this.setState({
            isMonthly: true
          });
        }
        ApiCall.getDetailBooking(
          paramString.id,
          this.props.app.user.id,
          paramString.isMonthly
        )
          .then(response => {
            this.setState({
              monthlyData: this.state.isMonthly ? response.data.data : null,
              name: response.data.data.guestName || response.data.data.name,
              email: response.data.data.email,
              checkIn:
                response.data.data.checkIn || response.data.data.check_in,
              checkOut:
                response.data.data.checkOut || response.data.data.check_out,
              night: response.data.data.night || response.data.data.duration,
              nip: response.data.data.nip,
              code: response.data.data.code || response.data.data.country_code,
              phone: response.data.data.phone,
              price: response.data.data.price,
              promo: response.data.data.promo,
              status: response.data.data.status,
              nopesanan:
                response.data.data.noPesanan || response.data.data.order_id,
              villaname:
                response.data.data.villaName ||
                response.data.data.property_name,
              guest:
                response.data.data.totalGuest || response.data.data.total_guest,
              round: response.data.data.round,
              specialRequest:
                response.data.data.specialRequest ||
                response.data.data.special_request,
              loading: false,
              statusPay: response.data.data.statusPay,
              totalMurni: response.data.data.totalMurni,
              debt: response.data.data.debt,
              room: response.data.data.room,
              statusText: response.data.data.statusText,
              paymentStatus: response.data.data.paymentStatus,
              transactionDetails: response.data.data.transactionDetails,
              point: response.data.data.point,
              promoLogin: response.data.data.promoLogin,
              promoNusalink: response.data.data.promoNusalink,
              detail: response.data.data
            });
            const promoprice = parseInt(response.data.data.promo, 10);
            const totalprice = parseInt(response.data.data.totalMurni, 10);
            const fixprice = totalprice - promoprice;
            this.setState({ subtotal: totalprice, total: fixprice });
            const upfrontfee = parseInt(response.data.data.dp, 10);
            this.setState({ upfront: upfrontfee });
          })
          .catch(error => {
            if (error.response) {
              toast.error(error.response.data.message);
            } else {
              toast.error(this.props.t.nointernet);
            }
            this.setState({ loading: false });
          });
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
    }, 1000);
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle(index);
  };

  logout = () => {
    this.props.logout();
    const pathname = window.location.pathname;
    setTimeout(() => {
      if (pathname === '/profile' || pathname === '/profile/edit') {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }, 500);
  };

  eventDownloadVoucher = () => {
    ApiCall.getDownloadPdf(this.state.nopesanan)
      .then(res => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const dataPdf = atob(res.data.base64);
        const byteNumbers = new Array(dataPdf.length);
        for (let i = 0; i < dataPdf.length; i++) {
          byteNumbers[i] = dataPdf.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = res.data.pdfName;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch(err => {
        console.log(err, 'Error');
      });
  };

  render() {
    // console.log(this.state, 'woy')
    const payment_method = this.state.detail.payment_method

    const isKredivo = payment_method && payment_method.startsWith('kredivo:')
    
    const { t } = this.props;
    let transactionDetails = Object.keys(this.state.transactionDetails).map(
      bookingDate => {
        const details = this.state.transactionDetails[bookingDate];
        return [
          <tr key={bookingDate}>
            <td>{bookingDate}</td>
          </tr>,
          <tr key={bookingDate + 'details'}>
            <td colSpan="2">
              <div style={{ marginLeft: 10 }}>
                <table width="100%" className="detail-reser2">
                  <tbody>
                    {details.map((detail, index) => (
                      <tr key={index + bookingDate}>
                        <td>{detail.room_name}</td>
                        <td>{detail.qty + ' ' + t.room}</td>
                        <td colSpan="2" className="has-text-right">
                          {t.currency}{' '}
                          {detail.is_available === 1 ? detail.price_rp : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        ];
      }
    );

    const paymentOption = (
      <tr>
        <td style={{ paddingTop: -5 }}>{t.paymentoption}</td>
        {this.state.isMonthly ? (
          <td colSpan="2" className="has-text-right">
            <b>{this.state.detail.payment_option || 'Transfer'}</b>
          </td>
        ) : (
          <td colSpan="2" className="has-text-right">
            <b>{this.state.detail.payment_option}</b>
          </td>
        )}
      </tr>
    );

    if (this.state.transactionDetails.length === 0) {
      transactionDetails = (
        <tr>
          {this.state.monthlyData !== null && (
            <td>
              x {this.state.night}{' '}
              {this.state.monthlyData.reservation_type === 'monthly'
                ? t.month
                : t.week}
            </td>
          )}
          {this.state.monthlyData === null && (
            <td>
              x {this.state.night} {t.night}
            </td>
          )}
          <td colSpan="2" className="has-text-right">
            {t.currency}{' '}
            {this.state.monthlyData === null
              ? Numeral(this.state.totalMurni + this.state.promo).format('0,0')
              : Numeral(this.state.monthlyData.sub_total).format('0,0')}
          </td>
        </tr>
      );
    }

    const subTotal = (
      <tr>
        <td>Sub Total</td>
        <td colSpan="2" className="has-text-right">
          {t.currency}{' '}
          {Numeral(
            this.state.detail.subTotal || this.state.detail.sub_total || 0
          ).format('0,0')}
        </td>
      </tr>
    );

    const point = this.state.point > 0 && (
      <tr>
        <td>{t.point}</td>
        <td colSpan="2" className="has-text-right">
          - {t.currency} {Numeral(this.state.point).format('0,0')}
        </td>
      </tr>
    );
    // const promoLogin = this.state.promoLogin > 0 && (
    //   <tr>
    //     <td>{t.promoLogin}</td>
    //     <td colSpan="2" className="has-text-right">
    //       - {t.currency} {Numeral(this.state.promoLogin).format('0,0')}
    //     </td>
    //   </tr>
    // );
    // const promoNusalink = this.state.promoNusalink > 0 && (
    //   <tr>
    //     <td>{t.promoNusalink}</td>
    //     <td colSpan="2" className="has-text-right">
    //       - {t.currency} {Numeral(this.state.promoNusalink).format('0,0')}
    //     </td>
    //   </tr>
    // );
    const promo = this.state.detail.promo_percent > 0 && (
      <tr>
        <td colSpan="3" className="has-text-centered ">
          <h3 className="has-text-weight-bold is-size-6">
            Promo: {Numeral(this.state.detail.promo_percent).format('0,0')}%
          </h3>
        </td>
      </tr>
    );

    const coupon = this.state.detail.codeVoucher && (
      <tr>
        <td colSpan="3" className="has-text-centered ">
          <h3 className="has-text-weight-bold is-size-6">
            {t.coupon}: {this.state.detail.codeVoucher}
          </h3>
        </td>
      </tr>
    );

    const dp = this.state.monthlyData !== null &&
      this.state.monthlyData.dp > 0 && (
        <tr>
          <td>DP ({this.state.monthlyData.dp_percent}%)</td>
          <td colSpan="2" className="has-text-right">
            {t.currency}{' '}
            {Numeral(parseInt(this.state.monthlyData.dp, 10)).format('0,0')}
          </td>
        </tr>
      );

    return (
      <section className="section mobileprof2" onClick={this.closeHeader}>
        <Loader loading={this.state.loading} />
        <div className="container bookpage">
          <div className="columns">
            <div className="column is-4 myaccountbox">
              <Sidebar />
            </div>
            <div className="column is-8 accountsettingbox">
              <div className="columns is-multiline">
                <div className="column is-12">
                  <div className="columns is-mobile">
                    <div className="column is-9">
                      <h3 style={{ fontSize: 22 }}>
                        <b>{this.state.villaname}</b>
                      </h3>
                      <p style={{ fontSize: 12 }}>
                        {t.orderid} {this.state.nopesanan}{' '}
                      </p>
                    </div>
                    <div className="column is-3">
                      {this.state.status === 1 ? (
                        <center>
                          <button
                            onClick={e => this.eventDownloadVoucher(e)}
                            className="button is-primary is-pulled-right bg-primary downloadbutton"
                          >
                            <b>{t.download}</b>
                          </button>
                        </center>
                      ) : (
                        ''
                      )}
                      {this.state.status === 2 && this.state.statusPay === 1 ? (
                        <center>
                          <button
                            onClick={e => this.eventDownloadVoucher(e)}
                            className="button is-primary is-pulled-right bg-primary downloadbutton"
                          >
                            <b>{t.download}</b>
                          </button>
                        </center>
                      ) : (
                        ''
                      )}
                      {this.state.status === 7 ? (
                        <center>
                          <button
                            onClick={e => this.eventDownloadVoucher(e)}
                            className="button is-primary is-pulled-right bg-primary downloadbutton"
                          >
                            <b>{t.download}</b>
                          </button>
                        </center>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <hr style={{ marginTop: -5, marginBottom: -5 }} />
                </div>
                <div align="center" className="column is-12">
                  {this.state.status === 0 ? (
                    this.state.statusText === 'canceled' ? (
                      <div>
                        <div
                          className="columns circles is-mobile"
                          style={{ marginBottom: -6 }}
                        >
                          <div className="column is-12">
                            <figure className="image icon">
                              <img
                                src="/icon/14.3. cancel.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                        </div>
                        <p
                          style={{
                            marginTop: 3,
                            fontSize: 11,
                            color: '#808080'
                          }}
                        >
                          <b>{t.bookcancel}</b>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div
                          className="columns circles is-mobile"
                          style={{ marginBottom: -6 }}
                        >
                          <div className="column is-12">
                            <figure className="image icon">
                              <img
                                src="/icon/14.3. cancel.png"
                                alt="Nusalink"
                              />
                            </figure>
                          </div>
                        </div>
                        <p style={{ fontSize: 12 }}>Status</p>
                        <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                          <b>{'Pesanan waiting payment'}</b>
                        </p>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  {(this.state.status === 3 || this.state.status === 6) && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6 }}
                      >
                        <div className="column is-12">
                          <figure className="image icon">
                            <img src="/icon/14.3. cancel.png" alt="Nusalink" />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>{t.bookcancel}</b>
                      </p>
                    </div>
                  )}

                  {this.state.paymentStatus === 'expired' && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6 }}
                      >
                        <div className="column is-12">
                          <figure className="image icon">
                            <img
                              src="/icon/15.1. Expired Pembayaran.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>{t.bookexpire}</b>
                      </p>
                    </div>
                  )}

                  {this.state.paymentStatus === 'failed' && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6 }}
                      >
                        <div className="column is-12">
                          <figure className="image icon">
                            <img
                              src="/icon/15.1. Expired Pembayaran.png"
                              alt="Failed payment nusalink"
                            />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>
                          {this.props.app.lang === 'id' ? 'Gagal' : 'Failed'}
                        </b>
                      </p>
                    </div>
                  )}

                  {(this.state.status === 1 || this.state.status === 7) && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6, marginLeft: '-4%' }}
                      >
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/11.3. Pembayaran Pending.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div
                          className="column is-4"
                          style={{ paddingLeft: 13.5 }}
                        >
                          <figure className="image icon">
                            <img
                              src="/icon/12.3. Pembayaran Selesai.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/13.4. check out.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>{t.paid}</b>
                      </p>
                    </div>
                  )}
                  {this.state.status === 2 && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6, marginLeft: '-4%' }}
                      >
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/11.3. Pembayaran Pending.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div
                          className="column is-4"
                          style={{ paddingLeft: 13.5 }}
                        >
                          <figure className="image icon">
                            <img
                              src="/icon/12.4. Pembayaran Selesai.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/13.4. check out.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>{t.pending}</b>
                      </p>

                      {isKredivo && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <a
                            href={this.state.detail.payment_deeplink_url}
                            className="button bg-primary"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Continue Payment
                          </a>

                          {/* <button className="button is-danger" onClick={() => {
                            Axios.post('/payment-method/kredivo/cancel', {
                              transaction_id: this.state.detail.transaction_id,
                              email: this.state.detail.email
                            })
                          }}>
                            Cancel Payment
                          </button> */}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.status === 4 && (
                    <div>
                      <div
                        className="columns circles is-mobile"
                        style={{ marginBottom: -6, marginLeft: '-4%' }}
                      >
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/11.3. Pembayaran Pending.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div
                          className="column is-4"
                          style={{ paddingLeft: 13.5 }}
                        >
                          <figure className="image icon">
                            <img
                              src="/icon/12.3. Pembayaran Selesai.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                        <div className="column is-4">
                          <figure className="image icon">
                            <img
                              src="/icon/13.3. check out.png"
                              alt="Nusalink"
                            />
                          </figure>
                        </div>
                      </div>
                      <p style={{ fontSize: 12 }}>Status</p>
                      <p style={{ fontSize: 18, color: '#3b3b3b' }}>
                        <b>{t.checkedout}</b>
                      </p>
                    </div>
                  )}
                </div>
                <div className="column is-12">
                  <p style={{ fontSize: 16, marginBottom: -15 }}>
                    <b>{t.gdetail}</b>
                  </p>
                  <hr />
                  <table width="100%" className="detail-reser2">
                    <tbody>
                      <tr>
                        <td style={{ fontSize: 12 }}>{t.guestName}</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>{this.state.name}</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>{t.phone}</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.code}
                            {this.state.phone}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>Email</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>{this.state.email}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column is-12">
                  <p style={{ fontSize: 16, marginBottom: -15 }}>
                    <b>{t.detail}</b>
                  </p>
                  <hr />
                  <table className="detail-reser2 is-fullwidth">
                    <tbody>
                      <tr>
                        <td width="40%" style={{ fontSize: 12 }}>
                          Check-in
                        </td>
                        <td width="55%" style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.checkIn === ''
                              ? ''
                              : moment(this.state.checkIn).format('D') +
                                ' ' +
                                (this.props.app.lang === '' ||
                                this.props.app.lang === 'id'
                                  ? dateIN[
                                      moment(this.state.checkIn).format('M') - 1
                                    ]
                                  : dateEN[
                                      moment(this.state.checkIn).format('M') - 1
                                    ]) +
                                ' ' +
                                moment(this.state.checkIn).format('Y')}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>Check-out</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.checkOut === ''
                              ? ''
                              : moment(this.state.checkOut).format('D') +
                                ' ' +
                                (this.props.app.lang === '' ||
                                this.props.app.lang === 'id'
                                  ? dateIN[
                                      moment(this.state.checkOut).format('M') -
                                        1
                                    ]
                                  : dateEN[
                                      moment(this.state.checkOut).format('M') -
                                        1
                                    ]) +
                                ' ' +
                                moment(this.state.checkOut).format('Y')}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>{t.stayDuration}</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.night}{' '}
                            {this.state.monthlyData === null
                              ? t.night
                              : this.state.monthlyData.reservation_type ===
                                'monthly'
                              ? t.month
                              : t.week}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>{t.totalguest}</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.guest} {t.people}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 12 }}>{t.specreq}</td>
                        <td style={{ fontSize: 12 }}>
                          <b style={{ float: 'right' }}>
                            {this.state.specialRequest || '-'}
                          </b>
                        </td>
                      </tr>
                      {this.state.detail.hour_arrival && (
                        <Row
                          col1={t.hourArrival}
                          col2={this.state.detail.hour_arrival}
                        />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="column is-12" style={{ marginTop: 3 }}>
                  <p style={{ fontSize: 16, marginBottom: -15 }}>
                    <b>{t.pdetail}</b>
                  </p>
                  <hr />
                  <table
                    style={{ width: '100%', marginTop: -5 }}
                    className="detail-reser2"
                  >
                    <tbody>
                      {transactionDetails}
                      <tr>
                        <td colSpan="2">
                          <hr style={{ margin: 0 }} />
                        </td>
                      </tr>
                      {subTotal}
                      {this.state.detail !== null &&
                        this.state.detail.unique_code && (
                          <tr>
                            <td>Unique Code</td>
                            <td colSpan="2" className="has-text-right">
                              Rp{' '}
                              {Numeral(this.state.detail.unique_code).format(
                                '0,0'
                              )}
                            </td>
                          </tr>
                        )}
                      {dp}
                      {this.state.detail !== null && this.state.detail.promo2 && (
                        <tr>
                          <td>Promo</td>
                          <td colSpan="2" className="has-text-right">
                            - Rp{' '}
                            {Numeral(this.state.detail.promo2).format('0,0')}
                          </td>
                        </tr>
                      )}
                      {point}

                      {this.state.detail !== null &&
                        this.state.detail.promo_name && (
                          <tr>
                            <td>{this.state.detail.promo_name}</td>
                            <td colSpan="2" className="has-text-right">
                              - Rp{' '}
                              {Numeral(this.state.detail.promo_idr).format(
                                '0,0'
                              )}
                            </td>
                          </tr>
                        )}
                      <tr>
                        <td
                          style={{ fontSize: 14, paddingTop: 20, width: 100 }}
                        >
                          <b>Total</b>
                        </td>
                        <td
                          className="has-text-right"
                          style={{ fontSize: 14, paddingTop: 20 }}
                        >
                          <b>
                            {t.currency}{' '}
                            {this.state.monthlyData === null
                              ? Numeral(this.state.detail.total).format('0,0')
                              : Numeral(this.state.monthlyData.total).format(
                                  '0,0'
                                )}
                          </b>
                        </td>
                      </tr>
                      {promo}
                      {coupon}
                    </tbody>
                  </table>
                </div>
                <div className="column is-12" style={{ marginTop: 3 }}>
                  <p style={{ fontSize: 16, marginBottom: -15 }}>
                    <b>{t.paymentdetail}</b>
                  </p>
                  <hr />
                  <table className="detail-reser2" style={{ marginTop: -5 }}>
                    <tbody>
                      {paymentOption}

                      <tr>
                        <td>{t.upfront}</td>
                        {/* {this.state.statusPay === 0 && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency}{' '}
                            {Numeral(
                              this.state.monthlyData === null
                                ? this.state.totalMurni
                                : this.state.monthlyData.dp
                            ).format('0,0')}
                          </td>
                        )}
                        {this.state.statusPay === 2 && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency}{' '}
                            {Numeral(
                              this.state.monthlyData === null
                                ? this.state.detail.dp
                                : this.state.monthlyData.dp
                            ).format('0,0')}
                          </td>
                        )}
                        {this.state.statusPay === 1 && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency} {Numeral(0).format('0,0')}
                          </td>
                        )} */}
                        {this.state.detail.payment_option === 'transfer' && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency}
                            {Numeral(this.state.detail.total).format('0,0')}
                          </td>
                        )}

                        {this.state.monthlyData !== null && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency}{' '}
                            {Numeral(
                              this.state.detail.payment_option ===
                                'Transfer Full'
                                ? this.state.monthlyData.total
                                : this.state.monthlyData.dp -
                                    this.state.detail.promo_idr +
                                    this.state.detail.unique_code
                            ).format('0,0')}
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td style={{ fontSize: 14 }}>{t.arrivalfee}</td>
                        {this.state.statusPay === 0 && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency} {Numeral(0).format('0,0')}
                          </td>
                        )}
                        {this.state.statusPay === 1 && (
                          <td
                            className="has-text-right"
                            style={{ fontSize: 14 }}
                          >
                            {t.currency}{' '}
                            {Numeral(this.state.totalMurni).format('0,0')}
                          </td>
                        )}
                        {this.state.statusPay === 2 && (
                          <td
                            className="has-text-right"
                            style={{ fontSize: 14 }}
                          >
                            {t.currency}{' '}
                            {Numeral(
                              this.state.detail.totalMurni -
                                this.state.detail.dp
                            ).format('0,0')}
                          </td>
                        )}
                        {this.state.monthlyData !== null && (
                          <td colSpan="2" className="has-text-right">
                            {t.currency}{' '}
                            {Numeral(
                              this.state.detail.payment_option ===
                                'Transfer Full'
                                ? 0
                                : this.state.monthlyData.sub_total -
                                    this.state.monthlyData.dp
                            ).format('0,0')}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(DetailBooking));


