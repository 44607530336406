import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translatable } from 'react-multilingual'
import { toggleModal } from '../../redux/actions'

class Term extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
		setTimeout(() => {
			if (this.props.app.toggleMenu === true) {
				this.props.toggleModal('toggleMenu')
			}
		}, 1000)
	}

	closeHeader = () => {
		if (this.props.app.toggleMenu === true) {
			this.props.toggleModal('toggleMenu')
		}
	}

	render() {
		return (
			<section className="section" onClick={this.closeHeader}>
				<div className="container">
					<div className="content" style={{textAlign: 'justify'}}>
							<h1>Syarat & Ketentuan</h1>
							<hr />
							<p>
								Nusalink.asia dimiliki dan dioperasikan oleh PT Samala Kayana Jayasri (selanjutnya disebut Perusahaan), berdagang sebagai Nusalink.asia, dengan kantor utamanya berlokasi di Komp. Perkantoran Aldiron Hero Bhakti Blok D 8A, Jakarta Barat
							</p>
							<p>
								Nusalink.asia bertindak sebagai agen untuk pemilik dan pengelola vila, apartemen, suite, dan resor. Perjanjian antara Perusahaan dan orang yang membuat pemesanan (selanjutnya disebut Pelanggan atau Anda) hanya berlaku setelah pemesanan diterima oleh Perusahaan bersama dengan pembayaran yang sesuai, dan pemesanan telah dikonfirmasi kepada Pelanggan oleh Perusahaan.
							</p>
							<label style={{fontSize: 22}}><b>1. Perjanjian</b></label><br />
							<p align="justify">
								Dengan mencentang kotak “Saya setuju” pada pemesanan situs web Nusalink.asia, Pelanggan menerima ketentuan ini dan mengonfirmasi bahwa ia berwenang untuk melakukannya atas nama semua orang yang disebutkan dalam formulir pemesanan sehingga membentuk kontrak antara Perusahaan dan pelanggan; yang pada gilirannya membentuk kontrak antara pemilik atau manajer properti vila yang Anda pilih dan Pelanggan. Syarat dan ketentuan pemesanan ini harus dibaca bersama dengan kebijakan properti individu.
							</p>
							<label style={{fontSize: 22}}><b>2. Harga</b></label><br />
							<p align="justify">
								Harga diiklankan dalam IDR per properti per malam. Tarif dikutip memungkinkan untuk penghunian maksimum villa seperti yang ditunjukkan pada informasi yang disediakan di situs web. Jumlah tamu yang menginap di properti tidak boleh melebihi jumlah maksimum tamu yang dipublikasikan atau disepakati dalam konfirmasi pemesanan.
							</p>
							<label style={{fontSize: 22}}><b>3. Jaminan Harga</b></label><br />
							<p align="justify">
								Nusalink.asia menjamin harga akomodasi Anda tidak akan berubah setelah Anda melakukan pemesanan dan kami telah mengeluarkan faktur konfirmasi. Penawaran ini tidak berlaku untuk kesalahan penulisan atau kesalahan harga.
							</p>
							<label style={{fontSize: 22}}><b>4. Pemesanan</b></label><br />
							<p align="justify">
								Pertanyaan diadakan untuk maksimal 48 jam dan untuk mengamankan pemesanan kami memerlukan konfirmasi dan deposit email tertulis. Kami akan mengirim faktur melalui email. Syarat standarnya adalah, kami mengambil deposit 50% ketika Anda memesan akomodasi Anda, dan sisanya dibayarkan 60 hari sebelum kedatangan Anda.
							</p>
							<label style={{fontSize: 22}}><b>5. Ekstra</b></label><br />
							<p align="justify">
								Di mana tambahan seperti Extra bed, sofa yang diperlukan beberapa properti mungkin memiliki biaya tambahan. Namun jika properti tidak memiliki barang seperti itu, pelanggan akan diberitahu tentang hal itu pada saat pemesanan dan mungkin diperlukan untuk menyewa peralatan tambahan. Item tambahan tergantung pada ketersediaan dan harus diminta pada saat pemesanan.
							</p>
							<p><b>5.1 Permintaan Permintaan</b></p>
							<p align="justify">
								Khusus (misalnya check-in awal atau check-out lebih lambat) tidak dijamin, namun kami akan selalu melakukan yang terbaik untuk mematuhi hal-hal ini asalkan dilakukan dengan baik sebelum keberangkatan Anda. Biaya tambahan mungkin berlaku dan akan dibebankan langsung oleh properti pada saat kedatangan atau keberangkatan Anda.
							</p>
							<p><b>5.2 Acara</b></p>
							<p align="justify">
								Villa harus diberitahu jika Pelanggan berencana untuk mengadakan pesta makan malam atau fungsi khusus melebihi jumlah maksimum yang disarankan tamu selama mereka tinggal dan sebelum memesan vila. Untuk fungsi yang lebih besar, biaya tambahan mungkin berlaku seperti biaya izin khusus dan penambahan staf tambahan.
							</p>
							<p><b>5.3 Penyewaan Mobil</b></p>
							<p align="justify">
								Staf kantor kami akan dengan senang hati mengatur untuk menyediakan penyewaan mobil untuk tamu. Untuk periode musim yang tinggi sebaiknya Anda memesan mobil Anda jauh-jauh hari sebelumnya.
							</p>
							<p><b>5.4 Transfer Bandara Transfer</b></p>
							<p align="justify">
								Bandara kembali dimasukkan untuk sebagian besar villa dengan menginap minimal 4 malam untuk tamu yang datang / berangkat dengan penerbangan yang sama. Beberapa vila tidak termasuk transfer bandara dan biaya tambahan berlaku untuk layanan ini. Silakan periksa inklusi di profil villa.
							</p>
							<label style={{fontSize: 22}}><b>6. Ketentuan Pembayaran</b></label><br />
							<p align="justify">
								Deposit sebesar 50% dari harga akomodasi Anda harus dibayar pada saat pemesanan. Saldo akomodasi Anda harus dibayar setidaknya 60 hari sebelum kedatangan. Jika pemesanan berlangsung kurang dari 60 hari sebelum kedatangan, jumlah penuh akan segera dibayarkan untuk menjamin pemesanan. Jika saldo tidak dibayarkan 60 hari sebelum kedatangan Anda, kami berhak untuk menahan deposit, membatalkan pemesanan Anda dan menerapkan biaya pembatalan kami sebagaimana diatur dalam Klausul 7 di bawah ini.
							</p>
							<p><b>6.1 Metode Pembayaran</b></p>
							<p align="justify">
								Anda dapat membayar online melalui kartu kredit (semua kartu utama diterima) atau mengatur transfer bank dalam mata uang pilihan Anda (AUD / EUR / GBP / JPY / SGD / USD). Anda bertanggung jawab atas semua biaya bank. Deposit 50% diperlukan untuk menjamin pemesanan dan sisanya harus dibayar 60 hari sebelum kedatangan. Atau Anda dapat membayar jumlah penuh pada saat pemesanan untuk membatasi transaksi.
							</p>
							<label style={{fontSize: 22}}><b>7. Perubahan atau Pembatalan yang Dibuat oleh Pelanggan</b></label><br />
							<p align="justify">
								Pelanggan harus memberi tahu perubahan atau pembatalan yang dibuat untuk pemesanan kepada Perusahaan secara tertulis. Kami akan melakukan yang terbaik untuk membantu jika memungkinkan dengan perubahan yang wajar yang dibuat oleh Pelanggan, tetapi mereka mungkin tidak dapat dilakukan. Biaya administrasi sebesar USD $ 50 per pemesanan untuk setiap perubahan akan berlaku sebagai tambahan biaya yang dikenakan oleh pemasok kami sebagai akibat dari perincian perincian. Biaya tersebut akan diberitahukan kepada Pelanggan. Jika Pelanggan telah meminta perubahan pada tanggal liburan kurang dari 60 hari sebelum kedatangan, pemesanan liburan akan dianggap dibatalkan dan biaya pembatalan yang disebut di bawah ini akan berlaku.
								Perubahan apa pun terhadap jumlah orang di dalam kelompok Anda harus diberitahukan kepada kami sesegera mungkin dan jika berlaku, setiap pembayaran yang Anda perlukan untuk orang tambahan harus dilakukan sepenuhnya jika perubahan tersebut diminta kurang dari 60 hari sebelum kedatangan.
								Jika Anda membatalkan pemesanan yang dikonfirmasi, sanksi berikut biasanya akan berlaku:
								<ul>
								<li>50% dari jumlah total sewa akan hangus jika pembatalan dilakukan lebih dari 60 hari sebelum kedatangan</li>
								<li>Hingga 100% dari jumlah total sewa akan hangus jika pembatalan dilakukan antara 1 dan 59 hari sebelum kedatangan</li>
								</ul>
								Jumlah denda yang berlaku akan dikurangi dari jumlah setoran Anda, dan Perusahaan akan mengembalikan saldo yang tersisa kepada Anda.
							</p>
							<label style={{fontSize: 22}}><b>8. Perubahan atau Pembatalan yang dibuat oleh Perusahaan</b></label><br />
							<p><b>8.1 Perubahan Minor</b></p>
							<p align="justify">
								Sebagian besar perubahan atau perubahan pada pengaturan liburan yang dikonfirmasi adalah kecil, misalnya penarikan fasilitas di akomodasi Anda; perubahan yang dilakukan oleh pemilik atau pengelola properti tetapi tidak disarankan kepada kami; kesalahan tipografi dalam deskripsi properti. Kami akan selalu melakukan yang terbaik untuk memberi tahu Anda tentang perubahan tersebut.
							</p>
							<p align="justify">
								Kami selalu memberi tahu Anda tentang perubahan apa pun yang mungkin telah terjadi sejak deskripsi ditulis / dicentang, atau sejak Anda melakukan pemesanan, segera setelah kami mendapat informasi pembatalan di Villa. Liburan direncanakan jauh hari sebelumnya, dan mungkin ada saatnya ketika pemilik properti kami mengubah atau layanan penarikan sementara atau fasilitas yang diiklankan, tanpa peringatan sebelumnya kepada kami.
							</p>
							<p align="justify">
								Harap dicatat dalam kasus pengembalian uang ditawarkan dari villa atau Manajer Villa kami tidak bertanggung jawab atas biaya bank untuk mentransfer pengembalian dana kepada Anda.
							</p>
							<p><b>8.2 Perubahan Utama</b></p>
							<p align="justify">
								Meskipun tidak mungkin, kami berhak untuk mengganti akomodasi yang berbeda dengan yang dipesan oleh Pelanggan dengan standar yang serupa atau setara. Apabila terjadi perubahan besar, Pelanggan memiliki pilihan untuk menerima akomodasi alternatif yang ditawarkan oleh Perusahaan (untuk menghindari keraguan di mana akomodasi alternatif memiliki harga yang lebih rendah daripada jumlah yang telah dibayarkan oleh Perusahaan kepada Perusahaan akan mengembalikan selisihnya dan jika alternatifnya adalah harga yang lebih tinggi daripada yang dibayarkan oleh Pelanggan, maka Perusahaan berhak untuk membebankan kepada Pelanggan perbedaan antara jumlah tersebut dan harga yang meningkat) atau Pelanggan memiliki pilihan untuk membatalkan sewa mereka dan Perusahaan akan menggunakan upaya terbaik untuk segera mengembalikan semua uang yang dibayarkan.
							</p>
							<p align="justify">
								Kami telah mengambil semua langkah yang wajar untuk memastikan bahwa setiap perusahaan atau agen yang digunakan di luar negeri memiliki reputasi baik. Namun kami tidak memiliki kontrol langsung atas organisasi atau karyawannya dan oleh karena itu tidak dapat bertanggung jawab atas mereka. Dalam kejadian yang tidak mungkin bahwa keadaan di luar kendali kami mengharuskan pembatalan penyewaan Anda, kami akan menggunakan upaya terbaik kami untuk segera mengembalikan semua uang yang dibayarkan dan Perusahaan tidak akan bertanggung jawab atas kompensasi tambahan.
							</p>
							<label style={{fontSize: 22}}><b>9. Akomodasi Anda</b></label><br />
							<p><b>9.1 Hunian</b></p>
							<p align="justify">
								Akomodasi Anda disediakan untuk penggunaan eksklusif orang-orang yang disebutkan di faktur konfirmasi dan tidak ada orang lain yang dapat menggunakan properti. Tarif dikutip memungkinkan untuk penghunian maksimum villa seperti yang ditunjukkan pada informasi yang disediakan di situs web. Jumlah tamu yang menginap di properti tidak boleh melebihi jumlah maksimum tamu yang dipublikasikan atau disetujui.
							</p>
							<p><b>9.2 Deposit Keamanan Villa</b></p>
							<p align="justify">
								Pemesanan tertentu terutama di properti paling bergengsi dan di mana pemilik properti menuntut, dapat dikenakan deposit antara USD $ 250 - $ 1.000 atau lebih, "Deposit Keamanan" sebagai pengganti biaya apapun sehubungan dengan telepon, pembersihan atau kerusakan spesialis tambahan untuk perabotan dan peralatan bernilai tinggi. Deposit, tunduk pada peristiwa yang tercantum dalam klausul ini akan dikembalikan dan dibayarkan kembali kepada Anda dalam waktu 14 hari dari tanggal keberangkatan Anda dari akomodasi.
								Kewajiban Anda untuk properti melampaui "Deposit Keamanan" yang diambil untuk akomodasi Anda. Kerusakan apa pun yang disebabkan ke properti harus segera dilaporkan ke pengelola villa lokal dan biaya kerusakan akan dikurangkan dari deposit rumah Anda. Jika Anda bepergian dengan anak-anak, harap berhati-hati agar mereka tidak menggunakan krayon dan cat pada bantal dan sofa. Untuk noda kain yang tidak dapat dipindahkan, vila akan membebankan biaya penggantian kepada tamu.
								Pelanggan bertanggung jawab untuk meninggalkan properti dalam keadaan baik dan dalam kondisi bersih. Pelanggan selanjutnya melakukan pembayaran untuk segala kerusakan atau kerugian yang terjadi selama pendudukan. Pemilik berhak untuk menarik kembali properti jika Pelanggan atau anggota partai telah menyebabkan kerusakan yang berlebihan.
							</p>
							<p><b>9.3 Layanan di Akomodasi</b></p>
							<p><b>9.3.1 Telepon dan Internet</b></p>
							<p align="justify">
								Jika properti Anda dilengkapi dengan telepon rumah, panggilan telepon masuk Anda gratis, namun semua panggilan telepon keluar dibebankan sesuai penggunaan dan akan dikenakan biaya, yang akan ditagih untuk pembayaran segera. Kualitas internet dapat bervariasi dari vila ke vila dan di beberapa yang tidak di lokasi pusat mungkin mengalami penundaan.
							</p>
							<p><b>9.3.2 Utilitas</b></p>
							<p align="justify">
								Baik Perusahaan maupun pemilik properti tidak akan bertanggung jawab atas kegagalan sementara atau gangguan terhadap penyediaan baik utilitas (gas, pemanas, listrik, telepon) atau Internet yang disebabkan oleh peristiwa yang berada di luar kendali kami, atau seharusnya perangkat Anda tidak kompatibel dengan sistem Internet.
							</p>
							<p><b>9.3.3 TV</b></p>
							<p align="justify">
								Satelit Di mana televisi satelit kami tidak dapat menjamin saluran yang tepat yang ditawarkan karena layanan ini berubah dari waktu ke waktu, dan Kementerian Villas mungkin tidak diberitahu tentang perubahan tersebut. Jika Anda meminta kami untuk mengklarifikasi layanan mana yang disediakan di properti tertentu, biasanya kami dapat mengetahuinya. Baik Perusahaan maupun pengelola properti tidak dapat bertanggung jawab atas hilangnya penyediaan layanan ini dalam hal masalah teknis atau jika layanan ditarik oleh penyedia karena alasan apa pun.
							</p>
							<p><b>9.3.4 Pemeliharaan Area Luar</b></p>
							<p align="justify">
								Kolam renang pribadi pelanggan dan area taman di mana disebutkan, serta properti itu sendiri akan dibersihkan dan dipelihara secara teratur selama masa tinggal pelanggan oleh staf yang memiliki akses ke properti.
							</p>
							<p><b>9.3.5 Layanan Pembantu</b></p>
							<p align="justify">
								Dalam kebanyakan kasus, vila tidak termasuk binatu pribadi. Namun ini dapat diatur dengan biaya tambahan.
							</p>
							<p><b>9.4 Masalah Keamanan</b></p>
							<p><b>9.4.1 Barang-Barang Anda </b></p>
							<p align="justify">
								Semua bagasi dan barang-barang pribadi yang disimpan di properti disimpan pada risiko Pelanggan. Anda disarankan untuk mengamankan properti dan barang-barang pribadi di brankas keamanan (jika tersedia) ketika Anda tidak di rumah dan di malam hari.
							</p>
							<p><b>9.4.2 Kolam Renang </b></p>
							<p align="justify">
								Banyak dari properti kami menyediakan kolam renang pribadi atau bersama. Kolam renang vila digunakan sepenuhnya oleh Pelanggan, kelompok Pelanggan, atau tamu-tamunya dengan risiko mereka sendiri. Karena kecelakaan terjadi, kami menyarankan Anda untuk selalu berhati-hati saat menggunakan kolam. Kami lebih lanjut menyarankan bahwa baik Anda maupun anggota partai Anda tidak boleh masuk ke kolam renang, atau menggunakan kolam renang saat berada di bawah pengaruh alkohol. Harap jauh sebagian besar villa tidak dilengkapi dengan pagar kolam renang untuk anak-anak. Anak-anak di area kolam renang harus diawasi setiap saat. Sebuah pagar kolam dapat diatur dengan biaya tambahan
								Pelanggan dengan ini melepaskan Pemilik dan agennya termasuk Manajer Villa, dari tuntutan tuntutan, hutang, kontrak, pengeluaran, penyebab tindakan, tuntutan hukum, kerusakan dan kewajiban, dalam bentuk apa pun sehubungan dengan cedera pribadi saat menggunakan kolam vila.
							</p>
							<p><b>9.5 Keluhan </b></p>
							<p align="justify">
								Kami berkomitmen untuk menawarkan Anda akomodasi liburan terbaik yang tersedia. Namun, jika Pelanggan memiliki alasan untuk mengajukan keluhan saat sedang berlibur, Pelanggan harus menghubungi perwakilan lokal Perusahaan atau agen yang nama dan rincian kontaknya ditetapkan pada formulir konfirmasi. Jika Pelanggan tidak dapat menghubungi perwakilan lokal atau tidak puas dengan resolusi untuk masalah apa pun yang diberikan oleh perwakilan atau agen lokal, Pelanggan disarankan untuk menghubungi Perusahaan yang nomor kontaknya tercantum pada Konfirmasi Faktur, dan kami akan menggunakan usaha yang beralasan untuk memperbaiki masalah kecuali di mana keadaan berada di luar kendali kami yang wajar.
							</p>
							<p><b>9.6 Kedatangan dan Keberangkatan dari Akomodasi Anda </b></p>
							<p align="justify">
								Pada hari pertama Anda, properti Anda siap untuk kedatangan Anda pada suatu waktu antara jam 2 siang dan 3 sore (tergantung pada properti) atau nanti dengan pengaturan. Beri tahu kami perkiraan waktu kedatangan Anda, sehingga kami dapat merencanakan untuk meminimalkan penundaan untuk check-in Anda. Pada hari keberangkatan Anda, waktu checkout properti Anda adalah 11am / 12pm (lihat faktur) kecuali jika diatur lain dengan agen lokal atau manajer rumah kami. Kami selalu berusaha mengatur waktu check-in dan check-out agar sesuai dengan jadwal penerbangan pelanggan kami tetapi tidak dapat menjamin waktu.
								Jika anggota partai Anda berada di luar jam 11 pagi / 12 malam (lihat faktur) pada hari keberangkatan tanpa persetujuan secara tertulis dari Perusahaan, kami berhak untuk menagih Pelanggan sehubungan dengan hari tambahan yang dihabiskan di akomodasi, waktu pembersihan tambahan yang dilakukan oleh staf kebersihan setempat, dan kerugian apa pun yang ditimbulkan kepada Perusahaan sebagai akibat gangguan yang dilakukan kepada pembuat liburan lain yang menggunakan akomodasi jika bukan karena terlambatnya pesta Anda.
							</p>
							<p align="justify">
								Ketika Anda tiba di villa, staf vila akan mengambil fotokopi KTP/KITAS, paspor setiap tamu tidak lama setelah tiba di vila.
							</p>
							<p align="justify">
								Berdasarkan undang-undang di sebagian besar negara, semua tamu harus terdaftar di kantor polisi setempat dalam waktu 24 jam setelah kedatangan di tempat tujuan. Formulir pendaftaran harus diisi & ditandatangani oleh setiap tamu dan surat-surat ini kemudian akan dibawa ke kantor pendaftaran desa terdekat.
							</p>
							<p><b>9.6.1 Petunjuk Arah dan Peta</b></p>
							<p align="justify">
								Kami memberikan petunjuk yang jelas mudah diikuti dengan memberikan lokasi yang tepat dari vila pilihan Anda. Ketika menyewa vila melalui Ministry of Villas, kami menyertakan transfer bandara kembali untuk vila tertentu, atau Anda dapat menyewa mobil dari bandara jika Anda menginginkannya. Jika Anda ingin kami memberi Anda melalui petunjuk sebelum Anda pergi, kami akan sangat senang melakukannya.
							</p>
							<p><b>9.6.2 Bantuan Luar Negeri</b></p>
							<p align="justify">
								Kami menghormati privasi dan independensi Anda, dan akan memberi Anda nomor kontak lokal untuk staf kami di tujuan pilihan Anda, jika Anda memerlukan bantuan atau informasi.
							</p>
							<p><b>9.7 Pengumpulan dan Pengembalian Kunci</b></p>
							<p align="justify">
								Pengambilan dan pengembalian kunci akomodasi selalu menjadi tanggung jawab Pelanggan. Kunci harus dikumpulkan dari manajer villa lokal kecuali dinyatakan sebaliknya pada saat pemesanan. Pada akhir liburan, adalah tanggung jawab Pelanggan untuk mengembalikan kunci ke tempat yang sama ketika dikumpulkan.
							</p>
							<p><b>9.8 Masalah Umum</b></p>
							<p align="justify">
								Kami berhak untuk mengakhiri liburan Anda jika perilaku Anda atau anggota pihak Anda sedemikian rupa sehingga dapat menyebabkan marabahaya, kerusakan, bahaya atau gangguan kepada klien, karyawan, properti, atau pihak ketiga. Jika Anda atau anggota dari pihak Anda dilarang bepergian karena menurut pendapat siapa pun yang berwenang Anda, Anda atau anggota partai Anda tampaknya tidak layak atau mungkin menyebabkan ketidaknyamanan atau gangguan kepada pihak lain, kami tidak akan memiliki tanggung jawab lebih lanjut untuk selesaikan pengaturan sewa Anda. Kami tidak akan bertanggung jawab atas pengembalian uang, kompensasi, atau biaya tambahan apa pun yang Anda keluarkan. Kami tidak dapat menerima tanggung jawab apa pun atas perilaku orang lain yang menginap di akomodasi kami.
							</p>
							<p><b>9.9 Pekerjaan Bangunan</b></p>
							<p align="justify">
								Di sebagian besar wilayah, di semua negara, ada pekerjaan bangunan baru yang sedang berlangsung. Kami mengambil langkah-langkah untuk memantau ini dan memberi tahu Anda jika ada pekerjaan bangunan mempengaruhi vila Anda, villa estate atau resor namun ini tidak selalu dapat dijamin. Haruskah kita mempertimbangkan bahwa plot atau plot bangunan tetangga akan sangat mempengaruhi properti Anda dengan baik polusi suara atau debu atau keduanya, maka kita akan menggunakan upaya terbaik untuk menemukan properti alternatif untuk Anda di bawah persyaratan kondisi pemesanan kami sebagaimana tercantum dalam Bagian 8 .
								Di mana pekerjaan jalan atau pekerjaan umum terjadi dalam waktu singkat atau tanpa pemberitahuan, dan yang berada di luar kendali kami, kami tidak dapat bertanggung jawab atas ketidaknyamanan yang Anda alami.
							</p>
							<p><b>9.10 Foto</b></p>
							<p align="justify">
								Foto di situs web kami biasanya diperbarui dan memberi kesan umum tentang properti atau resor individual. Namun, Perusahaan tidak bertanggung jawab atas pandangan atau benda apa pun seperti furnitur dll. Yang muncul dalam gambar, tetapi mungkin telah dihapus atau diubah, atau untuk perubahan apa pun terhadap aspek tampilan sejak gambar diambil, atau deskripsi dikompilasi .
							</p>
							<label style={{fontSize: 22}}><b>10. Force Majeure</b></label><br />
							<p align="justify">
								Kami tidak dapat menerima pertanggungjawaban dalam situasi apa pun di mana kinerja dan / atau kinerja yang cepat dari kewajiban kami dicegah sebagai akibat dari perang, kerusuhan, perselisihan sipil, kegiatan teroris, bencana alam, perselisihan industrial, kebakaran, ledakan nuklir atau kondisi cuaca buruk.
							</p>
							<label style={{fontSize: 22}}><b>11. Persyaratan Pribadi</b></label><br />
							<p><b>11.1 Paspor & Visa</b></p>
							<p align="justify">
								Pelanggan dari negara yang disetujui harus memiliki paspor, yang berlaku paling sedikit 6 bulan sejak tanggal kedatangan dan kartu embarkasi / disembarkasi lengkap yang mereka terima dari maskapai penerbangan mereka. Warga negara-negara tertentu ini akan dapat mengajukan permohonan VoA (visa turis) berlaku selama 30 hari setelah kedatangan melalui udara di Bali, Jakarta dan beberapa bandara internasional lainnya atau dengan kapal di sejumlah pelabuhan laut Indonesia. Visa 30-hari dibayar pada saat kedatangan dan dapat diperpanjang selama 30 hari lagi. Sadarilah bahwa petugas Imigrasi menghitung periode 30 hari sebagai berikut: hari kedatangan Anda dihitung sebagai hari pertama Anda, dan Anda harus meninggalkan negara pada tanggal 30. atau ke-60. hari!
							</p>
							<p><b>11.2 Asuransi</b></p>
							<p align="justify">
								Kami sangat menyarankan agar Anda mengambil asuransi komprehensif untuk melindungi Anda dan semua orang yang menemani Anda selama waktu kunjungan Anda terhadap penyakit, cedera, kematian, kehilangan bagasi dan barang-barang pribadi, pembatalan dan kemungkinan perjalanan lainnya. Kami juga sangat menyarankan asuransi kesehatan yang mencakup evakuasi medis untuk keadaan darurat. Pemilik tidak bertanggung jawab atas kerugian atau kerusakan properti Pelanggan.
							</p>
							<p><b>11.3 Kesehatan Pribadi</b></p>
							<p align="justify">
								Tolong beritahu kami pada saat pemesanan tentang kondisi medis atau kelemahan dari setiap anggota partai Anda yang mungkin relevan dengan liburan Anda. Kami memiliki kontak dengan fasilitas medis dan perusahaan di luar negeri yang dapat menawarkan layanan yang dapat membantu dan membuat perjalanan Anda menjadi lebih nyaman.
							</p>
							<label style={{fontSize: 22}}><b>12. Kesepakatan Ketidakpatuhan</b></label><br />
							<p align="justify">
								Pelanggan menjamin Pemilik bahwa setiap penyewa yang melanggar salah satu ketentuan Perjanjian ini harus segera ditolak hunian dan akan memperbaiki kerusakan atau biaya lain yang disebabkan oleh Pelanggan dan / atau tamu Pelanggan.
							</p>
							<label style={{fontSize: 22}}><b>13. Penggunaan yang Sah</b></label><br />
							<p align="justify">
								Pelanggan dan / atau tamu mereka tidak akan mengganggu, mengganggu, membahayakan, atau ketidaknyamanan tetangga, atau menggunakan tempat untuk tujuan yang melanggar hukum, atau melanggar undang-undang atau peraturan, atau melakukan pemborosan atau gangguan di atau tentang tempat. Setiap perilaku yang tidak pantas atau merusak dapat mengakibatkan pengusiran seluruh kelompok Pelanggan tanpa pengembalian uang.
							</p>
							<label style={{fontSize: 22}}><b>14. Tanpa-Pengecualian</b></label><br />
							<p align="justify">
								Baik Pemilik maupun Pelanggan melepaskan haknya untuk memberlakukan pelanggaran apa pun terhadap Perjanjian ini, pelepasan hak itu akan dianggap sementara dan bukan pengabaian yang berkelanjutan atas pelanggaran yang terjadi di kemudian hari. Meskipun Pemilik mungkin tahu ketika menerima sewa bahwa Pelanggan melanggar satu atau lebih dari ketentuan Perjanjian ini, Pemilik dalam menerima sewa sama sekali tidak mengesampingkan hak mereka untuk menegakkan pelanggaran tersebut. Baik Pemilik maupun Pelanggan tidak akan melepaskan hak mereka untuk memberlakukan pelanggaran apa pun kecuali mereka menyetujui pengabaian secara tertulis.
							</p>
							<label style={{fontSize: 22}}><b>15. Referensi dalam Wording</b></label><br />
							<p align="justify">
								Referensi-referensi plural yang dibuat untuk para pihak yang terlibat dalam Perjanjian ini mungkin juga tunggal, dan referensi tunggal mungkin bersifat jamak. Referensi ini juga berlaku untuk pewaris Pemilik, dan Penyewa, pelaksana, administrator, atau penerus, tergantung pada situasinya.
							</p>
							<label style={{fontSize: 22}}><b>16. Pembebasan & Pembebasan dari Kewajiban</b></label><br />
							<p align="justify">
								Pelanggan dengan ini membebaskan dan melepaskan, mengganti rugi, membebaskan, dan selamanya membebaskan Perusahaan beserta agen dan karyawannya serta agen, staf, dan karyawannya masing-masing dari setiap dan semua klaim, tuntutan, utang, kontrak, biaya, penyebab tindakan, tuntutan hukum, kerusakan, dan kewajiban, dari setiap jenis dan sifat, termasuk klaim Perusahaan atau kelalaian karyawan mereka, baik diketahui atau tidak diketahui, dalam hukum atau ekuitas, bahwa Pelanggan pernah atau mungkin timbul dari atau terkait dengan partisipasi dalam salah satu peristiwa atau kegiatan, dan untuk durasi tinggal di properti yang disebutkan. Perusahaan dan agennya tidak bertanggung jawab atas kehilangan, kerusakan properti, kepada / Pelanggan atau kelompok atau tamu. Pelanggan menanggung risiko apa pun, dan bertanggung jawab penuh serta membebaskan segala klaim cedera pribadi, kematian atau kerusakan pada properti pribadi yang terkait dengan properti yang disebutkan termasuk tetapi tidak terbatas pada bahaya buatan yang terkait dengan properti seperti kolam mandi yang licin, bak, fasilitas kolam renang dan bahaya alam seperti lubang, parit, pohon tumbang, cabang, es , salju, dan atau ketidakberesan lainnya di medan dan menggunakan medan termasuk kegiatan berjalan, jogging, bersepeda atau yang terkait. Kontak utama pelanggan harus setidaknya delapan belas (18) tahun.
							</p>
					</div>
				</div>
			</section>
		)
	}
}

const mapStateToProps = (state) => {
	const { app } = state
	return { app }
}

const mapTranslationsToProps = ({t}) => ({t})

const connector = connect(mapStateToProps, { toggleModal })
const translator = translatable(mapTranslationsToProps)

export default translator(connector(Term))
