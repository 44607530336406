import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import { toast } from 'react-toastify';
// import FacebookLogin from "react-facebook-login";
import TiSocialGoogleCircular from 'react-icons/lib/ti/social-google-plus-circular';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
// import ReactTelInput from 'react-telephone-input'
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/rrui.css'
// import 'react-phone-number-input/style.css'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Public/Home';
import HotelList from './pages/Public/HotelList';
import HotelAll from './pages/Public/HotelAll';
import HotelDetail from './pages/Public/HotelDetail';
import ReservationForm from './pages/Public/ReservationForm';
import Term from './pages/Public/Term';
import Privacy from './pages/Public/Privacy';
import AboutUs from './pages/Public/AboutUs';
import DetailBooking from './pages/DetailBooking';
import Profile from './pages/Profile';
import ProfileEdit from './pages/ProfileEdit';
import ChangePassword from './pages/ChangePassword';
import MyBooking from './pages/MyBooking';
import ApiCall from './services/ApiCall';
import Auth from './services/Auth';
import { toggleModal, showModal, setUser, setMessage } from './redux/actions';
import {
  KEY_API_GOOGLE
  // KEY_API_FACEBOOK,
  // BASE_URL_ADM
} from './constants';
import './style/styles.css';
import './style/fonts.css';
import ReservationCheck from './pages/Public/ReservationCheck';
import HowToReserve from './pages/Public/HowToReserve';
import HowToReserveModal from './pages/layouts/HowToReserveModal';
import 'animate.css';
import Events from './pages/Public/Events';
import Promo from './components/Promo/Promo';
import PromoPage from './pages/Public/Promo';
import StayMonthly from './pages/Public/StayMonthly';
import StayMonthlyDetail from './pages/Public/StayMonthlyDetail';
import StayMonthlyReservationForm from './pages/Public/StayMonthlyReservationForm';
import PropertyListByType from './pages/Public/PropertyListByType';
import StayWeekly from './pages/Public/StayWeekly';
import StayWeeklyDetail from './pages/Public/StayWeeklyDetail';
import StayYearlyDetail from './pages/Public/StayYearlyDetail';
import StayWeeklyReservationForm from './pages/Public/StayWeeklyReservationForm';
import Review from './pages/Public/Review';
import TouristAttraction from './pages/Public/TouristAttraction';
import CreditsPage from './pages/Public/TopUp/Credits/CreditsPage';
import GiveAwayPage from './pages/Public/GiveAwayPage';
import GiveAwayDetailPage from './pages/Public/GiveAwayDetailPage';
import TouristAttractionAll from './pages/Public/TouristAttractionAll';
import VoucherRamadhan from './pages/Public/VoucherRamadhan';
import StayYearly from './pages/Public/StayYearly';
import StayYearlyReservationForm from './pages/Public/StayYearlyReservationForm';
import RestaurantPage from './pages/Public/RestaurantPage';
import RestaurantDetailPage from './pages/Public/RestaurantDetailPage';
import StringHelper from './utils/StringHelper';
import RestaurantPerTypePage from './pages/Public/RestaurantPerTypePage';
import { GoogleLogin } from '@react-oauth/google'
import DeleteAccount from './pages/Public/delete-account';

// import MapHotelDetail from "./pages/Public/MapHotelDetail";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      konfirpass: '',
      name: '',
      idNumber: 0,
      phone: '',
      city: '',
      area: '+62',
      address: '',
      notification: false,
      loading: false,
      activationCode: '',
      activationCodeInput: false,
      repeatPassword: '',
      token: '',
      lang: 'en',
      codePhone: '',
      isGoogleLoginHide: false,
      logining: false
    };
  }

  componentDidMount() {
    // console.log(window.navigator.userAgent);
    const userAgentAndroid =
      'Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/86.0.4240.77 Mobile/15E148 Safari/604.1';
    const isSameWithAndroidApp =
      userAgentAndroid === window.navigator.userAgent;
    // console.log('isSameWithAndroidApp', isSameWithAndroidApp);
    if (isSameWithAndroidApp) {
      this.setState({
        isGoogleLoginHide: isSameWithAndroidApp
      });
    }
  }

  onChangeInputArea = event => {
    const value = event.target.value;
    const name = event.target.name;
    if (value === 0) {
      alert('Tidak Boleh Nol Pertama');
    } else {
      this.setState({ [name]: value });
    }
  };

  onChangeInput = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  };

  cobareturn = e => {
    console.log(e, 'response phone');
  };

  changeLang = langMaps => {
    this.setState({
      lang: langMaps
    });
    this.props.changeLocale(langMaps);
    this.props.setLang(langMaps);
  };

  register = e => {
    this.setState({ loading: true });

    e.preventDefault();

    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.area + '-' + this.state.phone,
      password: this.state.password
    };
    const isEn = this.props.lang === 'en';
    if (StringHelper.isEmpty(this.state.name)) {
      toast.error(isEn ? 'Name is required' : 'Nama wajib diisi');
      return;
    }
    if (StringHelper.isEmpty(this.state.email)) {
      toast.error(isEn ? 'Email is required' : 'Email wajib diisi');
      return;
    }
    if (StringHelper.isEmpty(this.state.phone)) {
      toast.error(isEn ? 'Phone is required' : 'No Telp wajib diisi');
      return;
    }
    if (StringHelper.isEmpty(this.state.password)) {
      toast.error(isEn ? 'Password is required' : 'Password wajib diisi');
      return;
    }
    if (this.state.password.length < 5) {
      toast.error(isEn ? 'Password min 5 char' : 'Password minimal 5 karakter');
      return;
    }
    if (this.state.phone.length < 10) {
      toast.error(isEn ? 'Phone min 10 char' : 'No telp minimal 10 karakter');
      return;
    }

    // to do: validation
    if (this.state.password !== this.state.konfirpass) {
      this.props.setMessage(this.props.t.notwrong);
      this.props.toggleModal('popupmessage');
    } else {
      Auth.register(data)
        .then(res => {
          this.props.setMessage(this.props.t.registrasi);
          this.props.toggleModal('popupmessage');
          this.props.showModal('modalLogin');
          this.setState({
            loading: false,
            name: '',
            idNumber: 0,
            email: '',
            phone: '',
            address: '',
            password: '',
            konfirpass: ''
          });
        })
        .catch(err => {
          console.log(err, 'response register');
          const { t } = this.props;
          if (err.response) {
            if (err.response.data.status === 1) {
              this.props.setMessage(
                t.register_valid1 + ' facebook ' + t.register_valid2
              );
              this.props.toggleModal('popupmessage');
            } else if (err.response.data.status === 2) {
              this.props.setMessage(
                t.register_valid1 + ' google ' + t.register_valid2
              );
              this.props.toggleModal('popupmessage');
            } else {
              this.props.setMessage(t.acchasbeen);
              this.props.toggleModal('popupmessage');
            }
          } else {
            this.props.setMessage('Terjadi Kesalahan');
            this.props.toggleModal('popupmessage');
          }
          this.setState({ loading: false });
        });
    }
  };

  responseFacebook = (response, stt) => {
    if (stt === 'login') {
      this.props.toggleModal('modalLogin');
    } else {
      this.props.toggleModal('modalRegister');
    }
    if (response.userID) {
      const data = {
        name: response.name,
        email: response.email,
        statusRegister: 1
      };
      ApiCall.loginSocial(data)
        .then(resp => {
          this.props.setUser(resp.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  responseGoogle = (credentialRes, stt) => {
    const [,payloadBase64] = credentialRes.credential.split('.');
    const googleUser = JSON.parse(atob(payloadBase64));

    console.log('googleUser', googleUser, stt);
    if (stt === 'login') {
      this.props.toggleModal('modalLogin');
    } else {
      this.props.toggleModal('modalRegister');
    }

    const data = {
      name: googleUser.name,
      email: googleUser.email,
      statusRegister: 2
    };
    ApiCall.loginSocial(data)
      .then(res => {
        this.props.setUser(res.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  login = e => {
    this.setState({ loading: true });

    e.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password,
      konfirpass: '',
      repeatPassword: '',
      activationCode: this.state.activationCode
    };

    // to do: validation

    Auth.login(data)
      .then(res => {
        const { t } = this.props;
        if (res.data.status === 1) {
          this.props.setMessage(
            t.login_valid1 +
              ' ' +
              this.state.email +
              ' ' +
              t.login_valid2 +
              ' facebook ' +
              t.login_valid3
          );
          this.props.toggleModal('popupmessage');
        } else if (res.data.status === 2) {
          this.props.setMessage(
            t.login_valid1 +
              ' ' +
              this.state.email +
              ' ' +
              t.login_valid2 +
              ' google ' +
              t.login_valid3
          );
          this.props.toggleModal('popupmessage');
        } else {
          this.props.toggleModal('modalLogin');
          this.props.setUser(res.data.data);
          this.setState({
            email: '',
            password: '',
            loading: false,
            activationCodeInput: false,
            konfirpass: ''
          });
          if (localStorage.getItem('redirect')) {
            this.props.history.push(localStorage.getItem('redirect'));
            localStorage.removeItem('redirect');
          } else {
            window.location.reload();
          }
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            this.props.setMessage(this.props.t.incpassword);
            this.props.toggleModal('popupmessage');
          } else if (err.response.status === 402) {
            this.props.setMessage(this.props.t.inverifikasi);
            this.props.toggleModal('popupmessage');
            // this.setState({activationCodeInput: true})
            this.setState({ activationCodeInput: false });
          }
        } else {
          this.props.setMessage(this.props.t.nointernet);
          this.props.toggleModal('popupmessage');
        }
      });
  };

  closeModalRegister = () => {
    this.setState({
      password: '',
      email: '',
      konfirpass: '',
      repeatPassword: '',
      token: '',
      name: '',
      phone: '',
      area: '+62'
    });
    this.props.toggleModal('modalRegister');
  };
  closeModalLogin = () => {
    localStorage.removeItem('redirect');
    this.setState({
      password: '',
      email: '',
      konfirpass: '',
      repeatPassword: '',
      token: ''
    });
    this.props.toggleModal('modalLogin');
  };

  closeModalReset = () => {
    this.setState({
      password: '',
      email: '',
      konfirpass: '',
      repeatPassword: '',
      token: ''
    });
    this.props.toggleModal('modalResetPassword');
  };

  closeModalForgot = () => {
    this.setState({
      email: ''
    });
    this.props.toggleModal('modalForgotPassword');
  };

  closPopUpMIdtrans = () => {
    this.props.toggleModal('popupmidtrans');
    this.props.history.push('/');
  };

  forgotPassword = e => {
    this.setState({ loading: true });

    e.preventDefault();

    const data = {
      email: this.state.email
    };

    Auth.forgotPassword(data)
      .then(res => {
        this.props.showModal('modalResetPassword');
        this.setState({
          email: '',
          loading: false,
          password: '',
          konfirpass: ''
        });
      })
      .catch(err => {
        const { t } = this.props;
        if (err.response) {
          this.props.setMessage(t.emailNotRegister);
          this.props.toggleModal('popupmessage');
        } else {
          toast.error(this.props.t.nointernet);
        }
        this.setState({
          loading: false,
          email: '',
          password: '',
          konfirpass: ''
        });
      });
  };

  showForgotPassword = () => {
    this.setState({ email: '' });
    this.props.showModal('modalForgotPassword');
  };

  resetPassword = e => {
    e.preventDefault();
    this.setState({ loading: true });
    const { t } = this.props;
    const data = {
      password: this.state.password,
      token: this.state.token
    };
    if (this.state.password !== this.state.repeatPassword) {
      this.props.setMessage(t.notwrong);
      this.props.toggleModal('popupmessage');
      this.setState({ loading: false });
    } else {
      Auth.resetPassword(data)
        .then(res => {
          this.props.setMessage(t.passwordOke);
          this.props.toggleModal('popupmessage');
          this.props.setUser(res.data.user);
          this.props.showModal();
          this.setState({
            loading: false,
            email: '',
            password: '',
            konfirpass: ''
          });
        })
        .catch(err => {
          if (err.response) {
            this.props.setMessage(this.props.t.incorrectToken);
            this.props.toggleModal('popupmessage');
          } else {
            this.props.setMessage(this.props.t.nointernet);
            this.props.toggleModal('popupmessage');
          }
          this.setState({
            loading: false,
            email: '',
            konfirpass: ''
          });
        });
    }
  };
  changeCodePhone = (status, value, countryData, number, id) => {
    this.setState({ area: value });
  };

  render() {
    const {
      email,
      password,
      activationCode,
      name,
      phone,
      loading,
      activationCodeInput,
      repeatPassword,
      token,
      konfirpass
    } = this.state;
    const { t } = this.props;
    let RegisterSocial = null;
    if (!this.state.isGoogleLoginHide) {
      RegisterSocial = (
        <div>
          <div className="columns is-mobile">
            <div className="column">
              <div className="">
                <center>
                  <label className="labelatau">{t.or}</label>
                </center>
                <hr className="garislogin" />
              </div>
            </div>
          </div>
          <div className="columns is-mobile" style={{ marginTop: -40 }}>
            {/* <div className="column has-text-right">
                  <FacebookLogin
                    appId={KEY_API_FACEBOOK}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={(e) => this.responseFacebook(e, "register")}
                    cssClass="btnLOginFb"
                    textButton=""
                    style={{ float: "right" }}
                  />
                </div> */}
            <div className="column has-text-centered">
              <GoogleLogin
                // uxMode="redirect"
                onSuccess={e => this.responseGoogle(e, 'register')}
                className="btnLOginGoogle"
                buttonText=""
                icon={<TiSocialGoogleCircular />}
              />
            </div>
          </div>
        </div>
      );
    }
    const LoginSocial = (
      <div>
        <div className="columns is-mobile">
          <div className="column">
            <div className="">
              <center>
                <label className="labelatau">{t.or}</label>
              </center>
              <hr className="garislogin" />
            </div>
          </div>
        </div>
        <div className="columns is-mobile" style={{ marginTop: -40 }}>
          {/* <div className="column has-text-centered">
                  <FacebookLogin
                    appId={KEY_API_FACEBOOK}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={(e) => this.responseFacebook(e, "login")}
                    cssClass="btnLOginFb"
                    textButton=""
                  />
                </div> */}
          <div className="column has-text-centered">
            <GoogleLogin
              // uxMode="redirect"
              onSuccess={e => this.responseGoogle(e, 'login')}
              className="btnLOginGoogle"
              buttonText=""
              icon={<TiSocialGoogleCircular />}
            />
          </div>
        </div>
      </div>
    );
    return (
      <div className="App">
        <Header />

        <HowToReserveModal />

        <Switch>
          <Route
            path="/restaurants/detail/:productId"
            component={RestaurantDetailPage}
          />
          <Route
            path="/restaurants/type/:restaurantId/:typeId"
            component={RestaurantPerTypePage}
          />
          <Route path="/restaurants/:slug" component={RestaurantPage} />
          <Route path="/voucher-event/:eventType" component={VoucherRamadhan} />
          <Route path="/top-up/credits" component={CreditsPage} />
          <Route path="/review" component={Review} />
          <Route path="/detail-villas" component={HotelDetail} />
          <Route path="/events" component={Events} />
          <Route path="/detail-booking" component={DetailBooking} />
          <Route path="/villas" component={HotelList} />
          <Route path="/hotelall" component={HotelAll} />
          <Route path="/reservation-form" component={ReservationForm} />
          <Route path="/terms-and-conditions" component={Term} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/delete-account" component={DeleteAccount} />
          <Route path="/about" component={AboutUs} />
          <Route path="/profile/edit" component={ProfileEdit} />
          <Route path="/profile" component={Profile} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/my-booking" component={MyBooking} />
          <Route path="/reservation-check" component={ReservationCheck} />
          <Route path="/how-to-reserve" component={HowToReserve} />
          <Route
            path="/stay-monthly/checkout/reservation-form"
            component={StayMonthlyReservationForm}
          />
          <Route
            path="/stay-weekly/checkout/reservation-form"
            component={StayWeeklyReservationForm}
          />
          <Route
            path="/stay-yearly/checkout/reservation-form"
            component={StayYearlyReservationForm}
          />
          <Route
            path="/stay-monthly/:propertyId"
            component={StayMonthlyDetail}
          />
          <Route path="/stay-yearly/:propertyId" component={StayYearlyDetail} />
          <Route path="/stay-weekly/:propertyId" component={StayWeeklyDetail} />
          <Route path="/stay-monthly" component={StayMonthly} />
          <Route path="/stay-yearly" component={StayYearly} />
          <Route path="/stay-weekly" component={StayWeekly} />
          <Route
            path="/properties/by-type/:propertyType"
            component={PropertyListByType}
          />
          <Route path="/promo/:promoId" component={PromoPage} />
          <Route path="/give-away/all" component={GiveAwayPage} />
          <Route path="/give-away-detail/:id" component={GiveAwayDetailPage} />
          <Route
            path="/tourist-attractions/:id"
            component={TouristAttraction}
          />
          <Route
            path="/tourist-attractions-all"
            component={TouristAttractionAll}
          />
          <Route path="/" component={Home} />
        </Switch>

        {
          // modal login
        }
        <div
          className={'modal ' + (this.props.app.modalLogin ? 'is-active' : '')}
          style={{ paddingTop: 100 }}
        >
          <div
            className="modal-background "
            onClick={e => this.closeModalLogin(e)}
          />
          <div
            className="btnClose btnCloseLogin"
            onClick={e => this.closeModalLogin(e)}
          />
          <div className="modal-content" style={{ width: 395 }}>
            <div className="box box-login">
              <form onSubmit={this.login}>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          Email
                        </label>
                      </div>
                      <div className="control">
                        <input
                          className="input input-login"
                          style={{ height: 40 }}
                          name="email"
                          autoComplete="username"
                          type="email"
                          onChange={this.onChangeInput}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                          required
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile" style={{ marginTop: -20 }}>
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          {t.passlogin}
                        </label>
                      </div>
                      <div className="control">
                        <input
                          autoComplete="current-password"
                          className="input input-login"
                          style={{ height: 40 }}
                          name="password"
                          type="password"
                          onChange={this.onChangeInput}
                          required
                          value={password}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile" style={{ marginTop: -20 }}>
                  <div className="column">
                    <div
                      className={
                        'field ' + (activationCodeInput || 'is-hidden')
                      }
                    >
                      <div className="control">
                        <input
                          className="input input-login"
                          name="activationCode"
                          type="text"
                          onChange={this.onChangeInput}
                          value={activationCode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="columns is-mobile has-text-right"
                  style={{ marginTop: -45 }}
                >
                  <div className="column">
                    <a
                      style={{ fontSize: 12 }}
                      className="link-forgot"
                      onClick={() => this.showForgotPassword()}
                    >
                      {t.forgotPassword}
                    </a>
                  </div>
                </div>
                <div className="columns is-mobile" style={{ marginTop: -30 }}>
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <center>
                          <button
                            type="submit"
                            className={
                              'btnLOgin ' + (loading ? 'is-loading' : '')
                            }
                          >
                            {t.login}
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile" style={{ marginTop: -30 }}>
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <center>
                          <button
                            onClick={e => {
                              e.preventDefault();
                              this.props.toggleModal('modalRegister');
                            }}
                            type="submit"
                            className={
                              'btnLOgin ' + (loading ? 'is-loading' : '')
                            }
                            style={{
                              backgroundColor: '#eee',
                              color: '#f16726'
                            }}
                          >
                            {t.register}
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              {this.state.isGoogleLoginHide ? null : LoginSocial}
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.closeModalLogin(e)}
          />
        </div>

        {
          // modal Register
        }
        <div
          className={
            'modal ' + (this.props.app.modalRegister ? 'is-active' : '')
          }
          style={{ paddingTop: 100 }}
        >
          <div
            className="modal-background"
            onClick={e => this.closeModalRegister(e)}
          />
          <div className="btnClose" onClick={e => this.closeModalRegister(e)} />
          <div className="modal-content" style={{ width: 465 }}>
            <div className="box box-register">
              <form onSubmit={this.register}>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          {t.nameregister}
                        </label>
                      </div>
                      <div className="control inputRegister">
                        <input
                          autoComplete="username"
                          className="input input-register"
                          type="text"
                          name="name"
                          onChange={this.onChangeInput}
                          required
                          value={name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label phonenumber">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          {t.nomorreggister}
                        </label>
                      </div>
                      <div className="field has-addons boxPhoneRegister">
                        <div className="control">
                          <IntlTelInput
                            defaultCountry="id"
                            value={this.state.area}
                            style={{ width: '100%', backgroundColor: 'red' }}
                            onPhoneNumberChange={this.changeCodePhone}
                          />

                          {/*
													<PhoneInput

												  value="+12223333333"
												  onChange={(value) => this.setState({area: value})}/>
												<span className="select">
													<select name="area" ref="city" onChange={this.onChangeInputArea} value={this.state.area}>
														<option value="+62">+62</option>
														<option value="+1">+1</option>
														<option value="+33">+33</option>
														<option value="+44">+44</option>
														<option value="+49">+49</option>
														<option value="+65">+65</option>
														<option value="+81">+81</option>
														<option value="+82">+82</option>
														<option value="+86">+86</option>
													</select>
												</span>
												*/}
                        </div>
                        <div className="field-label labelCode">
                          <div className="labelCodePhone">
                            {this.state.area}
                          </div>
                        </div>
                        <div className="control phoneCode">
                          <input
                            className="input phone-register"
                            type="number"
                            name="phone"
                            onChange={this.onChangeInput}
                            required
                            value={phone}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          Email
                        </label>
                      </div>
                      <div className="control inputRegister">
                        <input
                          autoComplete="username"
                          className="input input-register"
                          type="email"
                          name="email"
                          onChange={this.onChangeInput}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                          required
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          {t.passregister}
                        </label>
                      </div>
                      <div className="control inputRegister">
                        <input
                          autoComplete="new-password"
                          className="input input-register"
                          type="password"
                          name="password"
                          onChange={this.onChangeInput}
                          required
                          value={password}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label
                          className="labellogin"
                          style={{ fontWeight: 100 }}
                        >
                          {t.konpassregister}
                        </label>
                      </div>
                      <div className="control inputRegister">
                        <input
                          className="input input-register"
                          autoComplete="new-password"
                          type="password"
                          name="konfirpass"
                          onChange={this.onChangeInput}
                          required
                          value={konfirpass}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div
                    className="column"
                    style={{ marginBottom: -20, marginTop: 5 }}
                  >
                    <div className="field">
                      <div className="control">
                        <center>
                          <p style={{ fontSize: 12 }}>
                            {t.register1}
                            <Link
                              to="/terms-and-conditions"
                              onClick={() =>
                                this.props.toggleModal('modalRegister')
                              }
                              className="linkto"
                            >
                              {t.term}
                            </Link>
                            {t.register2 === ' and ' ? <br /> : ''}
                            {t.register2}
                            <Link
                              to="/privacy-policy"
                              onClick={() =>
                                this.props.toggleModal('modalRegister')
                              }
                              className="linkto"
                            >
                              {t.privacyp}
                            </Link>
                            {t.register3}
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <center>
                          <button
                            type="submit"
                            className={
                              'btnLOgin ' + (loading ? 'is-loading' : '')
                            }
                          >
                            {t.register}
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {RegisterSocial}
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.closeModalRegister(e)}
          />
        </div>

        {
          // modal Forgot Password
        }
        <div
          className={
            'modal ' + (this.props.app.modalForgotPassword ? 'is-active' : '')
          }
        >
          <div
            className="modal-background"
            onClick={e => this.closeModalForgot(e)}
          />
          <div className="btnClose" onClick={e => this.closeModalForgot(e)} />
          <div className="modal-content" style={{ width: 600 }}>
            <div className="box">
              <p style={{ fontSize: 24 }} className="title has-text-centered">
                {t.sendMail}
              </p>
              <p className="has-text-centered" style={{ marginTop: -10 }}>
                {t.desEmail}
              </p>
              <br />
              <form onSubmit={this.forgotPassword}>
                <div className="columns is-mobile">
                  <div align="center" className="column">
                    <div className="field is-horizontal">
                      <div className="field-label" style={{ marginTop: 5 }}>
                        Email
                      </div>
                      <div className="control">
                        <input
                          align="center"
                          className="input input-conf"
                          type="email"
                          name="email"
                          onChange={this.onChangeInput}
                          value={email}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <center>
                      <button
                        className="btnLOgin"
                        style={{ width: 150 }}
                        type="submit"
                      >
                        {t.sendMailPassword}
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.closeModalForgot(e)}
          />
        </div>

        {
          // modal Reset Password
        }
        <div
          className={
            'modal ' + (this.props.app.modalResetPassword ? 'is-active' : '')
          }
        >
          <div
            className="modal-background"
            onClick={e => this.closeModalReset(e)}
          />
          <div className="btnClose" onClick={e => this.closeModalReset(e)} />
          <div className="modal-content" style={{ width: 600 }}>
            <div className="box" style={{ paddingTop: 40, paddingBottom: 20 }}>
              <center>
                <h4 className="title is-4 has-text-centered">{t.resetpass}</h4>
                <p className="reset-center">{t.des_reset_pass}</p>
                <br />
              </center>
              <form onSubmit={this.resetPassword} align="center">
                <div className="field is-horizontal">
                  <div className="field-label" style={{ marginTop: 5 }}>
                    {t.newPass}
                  </div>
                  <div className="control">
                    <input
                      autoComplete="new-password"
                      className="input input-reset"
                      align="center"
                      type="password"
                      name="password"
                      onChange={this.onChangeInput}
                      value={password}
                      required
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label" style={{ marginTop: 5 }}>
                    {t.confrPass}
                  </div>
                  <div className="control">
                    <input
                      autoComplete="new-password"
                      className="input input-reset"
                      type="password"
                      align="center"
                      name="repeatPassword"
                      onChange={this.onChangeInput}
                      value={repeatPassword}
                      required
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label" style={{ marginTop: 5 }}>
                    {t.tokenrese}
                  </div>
                  <div className="control">
                    <input
                      className="input input-reset"
                      type="text"
                      align="center"
                      name="token"
                      onChange={this.onChangeInput}
                      value={token}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <center>
                      <button
                        type="submit"
                        className={'btnReset ' + (loading ? 'is-loading' : '')}
                      >
                        {t.resetpassbtn}
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.closeModalReset(e)}
          />
        </div>

        {
          // modal pop up
        }
        <div
          className={
            'modal ' + (this.props.app.popupmessage ? 'is-active' : '')
          }
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-content" style={{ width: 400 }}>
            <div className="box">
              <center>
                <p style={{ marginTop: 10 }}>{this.props.app.message}</p>
              </center>
              <br />
              <div className="field">
                <div className="control">
                  <center>
                    <button
                      className="btnLOgin"
                      style={{ width: 150 }}
                      onClick={() => this.props.toggleModal('popupmessage')}
                    >
                      Close
                    </button>
                  </center>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        {
          // modal midtrans
        }
        <div
          className={
            'modal ' + (this.props.app.popupmidtrans ? 'is-active' : '')
          }
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-content" style={{ width: 400 }}>
            <div className="box">
              <br />
              <center>
                <img
                  src="/img/logo nusalink 3.png"
                  alt="Nusalink"
                  width="200"
                  height="200"
                />
              </center>
              <br />
              <p align="center" style={{ fontSize: 12 }}>
                {this.props.app.messagemid1} {this.props.app.messagemid2}
              </p>
              <br />
              <div className="field">
                <div className="control">
                  <center>
                    <button
                      className="btnLOgin"
                      style={{ width: 150 }}
                      onClick={e => this.closPopUpMIdtrans(e)}
                    >
                      Close
                    </button>
                  </center>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        {
          // modal promo
        }

        <Promo />

        <Footer history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;

  return { app };
};
const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  toggleModal,
  showModal,
  setUser,
  setMessage
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(App));
