import { http } from '../ApiCall';

const CREDIT_ALL = 'credit/providers/all';
const CREDIT_TRANSACTION = 'credit/transactions';

const CreditApi = {
  async getAll() {
    return await http.get(CREDIT_ALL);
  },

  async postTransaction(data) {
    return await http.post(CREDIT_TRANSACTION, data);
  }
};

export default CreditApi;
