import React from 'react';
import { IconHotel, IconKost } from '../../assets/images/icons/properties';

export default function KosEksklusif({ history }) {
  const toHotel = () => {
    history.push('/properties/by-type/konvensional');
  };

  const toKost = () => {
    history.push('/stay-monthly');
  };

  return (
    <section
      style={{
        backgroundImage: `url('/images/home_bg_2.png')`
      }}
    >
      <div style={{ paddingTop: 30, paddingBottom: 30 }} className="container">
        <div
          style={{
            borderRadius: 20,
            border: '2px solid #E3E3E3',
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: 'white'
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 28,
              textAlign: 'center'
            }}
          >
            {/* Mau Kos Eksklusif? */}
            Lagi Cari Apa Nih?
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            {/* <div
              onClick={() => {
                history.push('/stay-weekly');
              }}
              style={{
                cursor: 'pointer',
                backgroundColor: '#F3732C',
                padding: 10,
                borderRadius: 10,
                color: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold'
              }}
            >
              Mingguan
            </div>
            <div style={{ width: 10 }} />
            <div
              onClick={() => {
                history.push('/stay-monthly');
              }}
              style={{
                cursor: 'pointer',
                backgroundColor: '#F3732C',
                padding: 10,
                borderRadius: 10,
                color: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold'
              }}
            >
              Bulanan
            </div>
            <div style={{ width: 10 }} />
            <div
              onClick={() => {
                history.push('/stay-yearly');
              }}
              style={{
                cursor: 'pointer',
                backgroundColor: '#F3732C',
                padding: 10,
                borderRadius: 10,
                color: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold'
              }}
            >
              Tahunan
            </div> */}

            <img
              onClick={toHotel}
              style={{ width: 150, cursor: 'pointer' }}
              src={IconHotel}
              alt="Hotel"
            />
            <div style={{ width: 30 }} />
            <img
              onClick={toKost}
              style={{ width: 150, cursor: 'pointer' }}
              src={IconKost}
              alt="Kost"
            />
          </div>
          <div
            className="fs-24px fs-18px-mobile"
            style={{ color: '#949494', textAlign: 'center' }}
          >
            Semuanya dijamin pasti mudah bersama Nusalink Asia!
          </div>
        </div>
      </div>
    </section>
  );
}
