// import { toast } from 'react-toastify'

// import ApiCall from '../../services/ApiCall'
import {
  TOGGLE_MODAL,
  SHOW_MODAL,
  SET_USER,
  USER_LOGOUT,
  SET_LANG,
  SET_MAPS,
  SET_MESSAGE,
  SET_PAGES,
  SET_MESSAGE_MID,
  SET_BOX_FILTER_DETAIL,
} from './types';

export const toggleModal = (name) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_MODAL,
      payload: { name },
    });
  };
};

export const showModal = (name) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: { name },
    });
  };
};

export const setUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      payload: { data },
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: USER_LOGOUT,
    });
  };
};

export const setLang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LANG,
      payload: { data },
    });
  };
};

export const setMaps = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_MAPS,
      payload: { data },
    });
  };
};

export const setMessage = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE,
      payload: { data },
    });
  };
};

export const setMessageMId = (label, data) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MID,
      name: label,
      payload: { data },
    });
  };
};

export const setPages = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAGES,
      payload: { data },
    });
  };
};

export const setBoxFilterDetail = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_BOX_FILTER_DETAIL,
      payload: { value },
    });
  };
};

export const setPromoLogin = (promoLogin) => {
  // console.log('promoLogin', promoLogin)
  return (dispatch) => {
    dispatch({
      type: 'SET_PROMO_LOGIN',
      payload: { promoLogin },
    });
  };
};
