import React from 'react';

export default function PropertyTypeIcon() {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73914 14.838V1.71006C9.73914 1.55868 9.67697 1.4135 9.56631 1.30646C9.45565 1.19942 9.30557 1.13928 9.14907 1.13928H2.06833C1.91184 1.13928 1.76175 1.19942 1.6511 1.30646C1.54044 1.4135 1.47827 1.55868 1.47827 1.71006V14.838"
        stroke="#F3722B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5217 14.838V6.58207C15.5217 6.4361 15.4608 6.2961 15.3524 6.19288C15.2439 6.08967 15.0968 6.03168 14.9435 6.03168H9.73914"
        stroke="#F3722B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95651 5.05319H6.43477"
        stroke="#F3722B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78259 8.96716H7.26085"
        stroke="#F3722B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95651 12.881H6.43477"
        stroke="#F3722B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2174 11.9026H13.0435"
        stroke="#F3722B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2174 8.96716H13.0435"
        stroke="#F3722B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
