import React from 'react';

export default function GuestIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 18.125C12.7661 18.125 15.3125 15.4877 15.3125 12.2344C15.3125 8.98107 12.7661 6.34375 9.625 6.34375C6.48388 6.34375 3.9375 8.98107 3.9375 12.2344C3.9375 15.4877 6.48388 18.125 9.625 18.125Z"
        stroke="#F3722B"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7253 6.56318C16.5076 6.3349 17.328 6.2829 18.1313 6.41067C18.9347 6.53845 19.7022 6.84303 20.3823 7.30391C21.0624 7.76478 21.6392 8.37125 22.0739 9.08246C22.5087 9.79368 22.7912 10.5931 22.9025 11.4269C23.0138 12.2608 22.9513 13.1096 22.7191 13.9163C22.487 14.723 22.0907 15.4688 21.5568 16.1035C21.023 16.7382 20.364 17.247 19.6243 17.5957C18.8846 17.9444 18.0813 18.1249 17.2686 18.125"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.74951 22.3613C2.63778 21.0527 3.81702 19.9847 5.18766 19.2473C6.5583 18.51 8.08011 18.1251 9.6246 18.125C11.1691 18.1249 12.6909 18.5098 14.0616 19.247C15.4323 19.9842 16.6116 21.0522 17.5 22.3607"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2688 18.125C18.8134 18.1238 20.3356 18.5082 21.7064 19.2455C23.0772 19.9828 24.2563 21.0514 25.1438 22.3607"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
