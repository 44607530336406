import React, { useEffect, useState } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import Loader from '../../components/Loader/Loader';
import NotFound from '../../components/others/NotFound';
import PageTitle from '../../components/others/PageTitle';
import ApiCall from '../../services/ApiCall';
import Container from '../layouts/Container';

const GiveAwayPage = ({ match, t, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [giveaway, setGiveaway] = useState([]);

  useEffect(() => {
    ApiCall.getGiveAway(null)
      .then(res => {
        if (res.data.status === 'success') setGiveaway(res.data.data);
      })
      .catch(err => {
        console.log('page not found');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container>
      <PageTitle title="Give Away" />
      {!isLoading && giveaway.length === 0 && <NotFound />}
      <Loader loading={isLoading} />
      {giveaway.length > 0 && (
        <div className="columns is-multiline">
          {giveaway.map((item, index) => (
            <div key={index} className="column is-3">
              <img
                onClick={() => {
                  const link = '/give-away-detail/' + item.id;
                  // window.location.href = link;
                  history.push(link);
                }}
                style={{ cursor: 'pointer' }}
                src={item.image_url}
                alt={item.code}
                width="500"
                height="500"
              />
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(GiveAwayPage);
