import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';

import { toast } from 'react-toastify';
import BtnMoreInfo from '../../components/Buttons/BtnMoreInfo';
import NusaCafe2Icon from '../../components/Icons/NusaCafe2Icon';
import NusaCafe3Icon from '../../components/Icons/NusaCafe3Icon';
import NusaMinang2Icon from '../../components/Icons/NusaMinang2Icon';
import NusaMinang3Icon from '../../components/Icons/NusaMinang3Icon';
import Loader from '../../components/Loader/Loader';
import MenuItem from '../../components/Restaurant/MenuItem';
import { EXAMPLE_IMAGES, GRADIENT_BG } from '../../constants';
import ApiCall from '../../services/ApiCall';
import ComingSoonPage from './ComingSoonPage';
import ExampleImage from './../../assets/example.png';
import ModalImages from '../../components/Modals/ModalImages';
import { moneyFormat } from '../../utils/helper';
import { connect } from 'react-redux';

export class RestaurantDetailPage extends Component {
  state = {
    recomendedProducts: [],
    detail: null,
    loading: true,
    isModalImages: false,
    outlet: null,
    marketplace: null,
    marketplaces: [],
    images: []
  };

  constructor(props) {
    super(props);

    this.goToDetail = this.goToDetail.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getData();
    }
  }

  getData() {
    this.setState({
      loading: true
    });
    const { productId } = this.props.match.params;
    ApiCall.getRestaurantProductDetail(productId)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            detail: response.data.data.detail,
            recomendedProducts: response.data.data.recomended_products,
            images: response.data.data.detail.images.map((item, index) => ({
              src: item.image,
              alt: response.data.data.detail.name + ' ' + (index + 1)
            }))
          });
        }
      })
      .catch(error => {})
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  goToDetail(e, propertyId) {
    // console.log(e, propertyId);
    let url = '/stay-monthly/' + propertyId;
    if (this.props.isWeekly) {
      url = '/stay-weekly/' + propertyId;
    } else if (this.props.isYearly) {
      url = '/stay-yearly/' + propertyId;
    }
    this.props.history.push(url);
    // window.location = url;
  }

  render() {
    // return <ComingSoonPage />;
    const { detail } = this.state;
    const { t } = this.props;
    const { lang } = this.props.app;
    const isEn = lang === 'en';
    const slug = this.props.match.params.slug;
    if (this.state.loading) {
      return <Loader key="loader" loading={this.state.loading} />;
    }

    if (detail === null) return <div />;

    return (
      <div className="container py-3">
        <div className="row">
          <div className="col-md-3">
            <img
              src={detail.images[0].image || ExampleImage}
              alt="alt"
              style={{ borderRadius: 20 }}
              className="mb-2 cursor-pointer"
              onClick={() => {
                this.setState({ isModalImages: true });
              }}
            />
            <div className="row">
              {detail.images.map(item => (
                <div className="col-md-3" key={item.id}>
                  <img
                    onClick={() => {
                      this.setState({ isModalImages: true });
                    }}
                    src={item.image || ExampleImage}
                    alt="alt"
                    style={{ borderRadius: 15 }}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-9">
            <div className="ml-4">
              <div
                className="d-flex justify-content-between pb-4 mb-3"
                style={{ borderBottom: '1px solid #E3E3E3' }}
              >
                <h1 style={{ fontSize: 30, fontWeight: 'bold' }}>
                  {detail.name || `Nasi Ijo Sama Telur`}
                </h1>
                <div
                  style={{ color: '#FFBA33', fontSize: 24, fontWeight: 'bold' }}
                >
                  {moneyFormat(
                    (detail.promo_price > 0
                      ? detail.promo_price
                      : detail.price) || 32000,
                    lang,
                    true
                  )}
                </div>
              </div>
              <div>
                <div className="mb-3">
                  <strong>{isEn ? 'Description' : 'Deskripsi'}</strong>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        detail.description ||
                        `Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries`
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <strong>{isEn ? 'Choose Outlet' : 'Pilih Outlet'}</strong>
                    </div>
                    <div>
                      {detail.outlets.map(item => (
                        <div
                          key={item.outlet_name}
                          style={
                            this.state.outlet === item.outlet_name
                              ? {
                                  backgroundColor: '#FFBA33',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  borderRadius: 20,
                                  color: 'white',
                                  cursor: 'pointer',
                                  display: 'inline'
                                }
                              : {
                                  backgroundColor: 'rgb(196, 196, 196, 0.45)',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  borderRadius: 20,
                                  color: '#797979',
                                  cursor: 'pointer',
                                  display: 'inline'
                                }
                          }
                          className="me-2"
                          onClick={() => {
                            this.setState({
                              outlet: item.outlet_name,
                              marketplaces: item.marketplaces
                            });
                          }}
                        >
                          {item.outlet_name}
                        </div>
                      ))}
                    </div>
                  </div>
                  {this.state.marketplaces.length > 0 && (
                    <div className="col-md-6">
                      <div className="mb-2">
                        <strong>
                          {isEn ? 'Choose Marketplace' : 'Pilih Marketplace'}
                        </strong>
                      </div>
                      <div>
                        {this.state.marketplaces.map(item => (
                          <div
                            key={item.id}
                            style={
                              this.state.marketplace !== null &&
                              this.state.marketplace.id === item.id
                                ? {
                                    backgroundColor: '#FFBA33',
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    borderRadius: 20,
                                    color: 'white',
                                    cursor: 'pointer',
                                    display: 'inline'
                                  }
                                : {
                                    backgroundColor: 'rgb(196, 196, 196, 0.45)',
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    borderRadius: 20,
                                    color: '#797979',
                                    cursor: 'pointer',
                                    display: 'inline'
                                  }
                            }
                            onClick={() => {
                              this.setState({
                                marketplace: item
                              });
                            }}
                            className="me-2"
                          >
                            {item.marketplace_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="my-5">
                <a
                  className="px-5 py-3"
                  style={{
                    color: 'white',
                    backgroundColor:
                      this.state.marketplace === null ||
                      this.state.outlet === null
                        ? 'rgba(196, 196, 196, 0.45)'
                        : '#F3722B',
                    borderRadius: 10
                  }}
                  onClick={() => {
                    if (this.state.outlet === null) {
                      toast.warn(
                        isEn
                          ? 'Choose outlet first'
                          : 'Pilih outlet terlebih dahulu'
                      );
                      return;
                    }
                    if (this.state.marketplace === null) {
                      toast.warn(
                        isEn
                          ? 'Choose marketplace first'
                          : 'Pilih marketplace terlebih dahulu'
                      );
                      return;
                    }
                    // alert(this.state.marketplace.deep_link);
                    window.location.href = this.state.marketplace.deep_link;
                  }}
                >
                  {isEn ? 'Add To Order' : `Tambahkan Pesanan`}
                </a>
              </div>
            </div>
          </div>
        </div>

        {this.state.recomendedProducts.length > 0 && (
          <div className="my-4">
            <h2 className="is-size-4 font-bold mb-2">
              <strong>{isEn ? 'Recomended Menu' : 'Rekomendasi Menu'}</strong>
            </h2>

            <div className="columns">
              {this.state.recomendedProducts.map((item, index) => (
                <div className="column is-3" key={item.id}>
                  <MenuItem
                    variant="recomended"
                    menu={item}
                    history={this.props.history}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {/* modal images */}
        <ModalImages
          images={this.state.images || EXAMPLE_IMAGES}
          isShowed={this.state.isModalImages}
          onClose={() => {
            this.setState({
              isModalImages: false
            });
          }}
        />
      </div>
    );
    return [
      <section
        key="header"
        style={{
          background: GRADIENT_BG,
          position: 'relative'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundImage: `url('/images/city4.png')`,
            backgroundSize: '40% 100%',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat'
          }}
        />

        <div className="container py-3">
          <div className="py-3 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              {this.props.match.params.slug === 'nusa-minang' ? (
                <NusaMinang2Icon />
              ) : (
                <NusaCafe2Icon />
              )}
              <div className="px-3 text-white" style={{ fontSize: 20 }}>
                |
              </div>
              <h1
                className=""
                style={{
                  fontSize: 36,
                  color: 'white'
                }}
              >
                {this.props.match.params.slug === 'nusa-minang' ? (
                  <span>
                    Nusa<strong className="text-white">Minang</strong>
                  </span>
                ) : (
                  <span>
                    Nusa<strong className="text-white">Cafe</strong>
                  </span>
                )}
              </h1>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className="d-flex align-items-center"
              onClick={() => {
                this.props.history.push(
                  slug === 'nusa-minang'
                    ? '/restaurants/nusa-cafe'
                    : '/restaurants/nusa-minang'
                );
              }}
            >
              {slug === 'nusa-minang' ? (
                <div className="d-flex align-items-center flex-column ">
                  <NusaCafe3Icon />
                  <div className="text-white">
                    <strong className="text-white">Nusa Cafe</strong>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center flex-column ">
                  <NusaMinang3Icon />
                  <div className="text-white">
                    <strong className="text-white">Nusa Minang</strong>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>,
      // <section
      //   key="body"
      //   className="section2 inner box-list"
      //   style={{ backgroundColor: '#f8f8f8' }}
      // >
      //   <div className="container">
      //     <div className="columns is-multiline long">
      //       <div className="column is-12">
      //         <h2 className="is-size-4 font-bold mb-2">
      //           <strong>
      //             {isEn ? 'Interesting Promotions' : 'Promo Menarik'}
      //           </strong>
      //         </h2>
      //         <div
      //           className={promoMenus.length > 0 ? 'mb-5' : ''}
      //           style={{
      //             backgroundColor: '#C4C4C4',
      //             height: '250px',
      //             width: '100%',
      //             borderRadius: 20
      //           }}
      //         ></div>
      //       </div>
      //       {promoMenus.length > 0 &&
      //         promoMenus.slice(0, 3).map((item, index) => (
      //           <div className="column is-4" key={item.id}>
      //             <MenuItem menu={item} history={this.props.history} />
      //           </div>
      //         ))}
      //       {!promoMenus && (
      //         <div className="column is-12">
      //           <br />
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       )}
      //     </div>
      //   </div>
      // </section>,
      // <section
      //   key="body-type"
      //   className="section2 inner box-list"
      //   style={{ backgroundColor: '#f8f8f8' }}
      // >
      //   {menusWithType.map(item => {
      //     return (
      //       <div className="container mb-4" key={item.id}>
      //         <div className="columns is-multiline long">
      //           <div className="column is-12">
      //             <div className="d-flex justify-content-between align-items-center">
      //               <h2 className="is-size-4 font-bold mb-2">
      //                 <strong>
      //                   {isEn ? item.product_type : item.product_type}
      //                 </strong>
      //               </h2>
      //               {item.products.length > 0 && (
      //                 <BtnMoreInfo
      //                   onClick={() => {
      //                     toast.info('Coming soon');
      //                   }}
      //                 />
      //               )}
      //             </div>
      //           </div>
      //           {item.products.length > 0 &&
      //             item.products.slice(0, 3).map((item, index) => (
      //               <div className="column is-4" key={item.id}>
      //                 <MenuItem
      //                   variant="simple"
      //                   menu={item}
      //                   history={this.props.history}
      //                 />
      //               </div>
      //             ))}
      //           {!this.state.loading && item.products.length === 0 && (
      //             <div className="column is-12">
      //               <div className="has-text-centered">
      //                 <img
      //                   src={require('./../../assets/backgrounds/data_not_found.png')}
      //                   alt="Info Not Available"
      //                   width="500"
      //                   height="500"
      //                 />
      //               </div>
      //               <br />
      //               <div className="d-flex justify-content-center align-items-center">
      //                 <h4 style={{ color: '#B0B0B0', fontSize: 28 }}>
      //                   {isEn ? 'Info not available' : 'Info tidak tersedia'}
      //                 </h4>
      //               </div>
      //             </div>
      //           )}
      //           {this.state.loading && (
      //             <Loader key="loader" loading={this.state.loading} />
      //           )}
      //         </div>
      //       </div>
      //     );
      //   })}
      // </section>
      ,
    ];
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {});

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default connector(translator(RestaurantDetailPage));
