import React, { Component } from 'react';
import StayMonthly from './StayMonthly';

class StayYearly extends Component {
  render() {
    return <StayMonthly history={this.props.history} isYearly />;
  }
}

export default StayYearly;
