import React, { Component } from 'react';
import Container from '../layouts/Container';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';

class HowToReserve extends Component {
  render() {
    const { t } = this.props;
    const lang = this.props.app.lang;
    const isEn = lang === 'en';
    return (
      <Container>
        <h2>{t.howToReserve}</h2>
        {isEn ? (
          <ul>
            <li>
              Select city, enter Check-in and Check-out date, input number of
              guests (max 2 people)
            </li>
            <li>Click "explore"</li>
            <li>Select an available property</li>
            <li>Next, click "select"</li>
            <li>
              Input your data in the form of name, active telephone number and
              active email address to receive email from us
            </li>
            <li>
              Add additional requests if there are additional services you want
              (if any)
            </li>
            <li>Select the type of room you want</li>
            <li>
              Input additional coupon code if you have discount coupon (if any)
            </li>
            <li>Select a payment option</li>
            <li>Select a bank</li>
            <li>Select the payment method</li>
            <li>Click "view account number" to see the purpose of transfer</li>
            <li>Click "save instructions" to save the payment method</li>
            <li>
              Click "Please complete payment" to return to the original page
            </li>
            <li>Complete your payment to get the e-ticket within 3 hours</li>
            <li>
              Check your e-mail to see book reviews, how to make payments, and
              e-tickets when the payment has been completed
            </li>
            <li>
              To find out the status of your booking, please click "check
              reservation" in the start menu
            </li>
            <li>Enter your order id and the email that you entered earlier</li>
            <li>
              And if the e-ticket has been obtained, please come to the
              destination property and show your e-ticket to our Front Office so
              you can stay overnight
            </li>
            <li>Happy to place orders</li>
          </ul>
        ) : (
          <ul>
            <li>
              Pilih kota, masukkan tanggal Check-in dan Check-out, input jumlah
              tamu (maks 2 orang)
            </li>
            <li>Klik “explore”</li>
            <li>Pilih property yang tersedia</li>
            <li>Selanjutnya, klik “pilih”</li>
            <li>
              Inputkan data anda berupa nama, nomor telepon aktif, dan alamat
              email aktif untuk menerima email dari kami
            </li>
            <li>
              Tambahkan permintaan tambahan apabila ada tambahan service yang
              anda inginkan (jika ada)
            </li>
            <li>Pilih tipe kamar yang diinginkan</li>
            <li>
              Inputkan kode kupon tambahan jika memiliki kupon potongan harga
              (jika ada)
            </li>
            <li>Pilih opsi pembayaran</li>
            <li>Pilih bank</li>
            <li>Pilih cara pembayaran</li>
            <li>Klik “lihat nomor rekening” untuk melihat tujuan transfer</li>
            <li>Klik “simpan instruksi” untuk menyimpan cara pembayaran</li>
            <li>
              Klik “mohon selesaikan pembayaran” untuk kembali kehalaman awal
            </li>
            <li>
              Selesaikan pembayaran anda untuk mendapatkan e-tiket dalam rentang
              waktu 3 jam
            </li>
            <li>
              Cek email anda untuk melihat review bookingan, cara melakukan
              pembayaran, dan e-tiket apabila pembayaran telah selesai dilakukan
            </li>
            <li>
              untuk mengetahui status bookingan anda, silahkan klik “cek
              reservasi” di menu awal
            </li>
            <li>
              masukkan order id anda serta email yang anda inputkan sebelumnya
            </li>
            <li>
              Dan apabila e-tiket sudah didapatkan, silahkan datang ke property
              tujuan dan tunjukkan e-tiket anda kepada Front Office kami agar
              bisa menginap
            </li>
            <li>Selamat melakukan pesanan</li>
          </ul>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {});
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(HowToReserve));
