import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
// import moment from "moment";
import ApiCall from '../../services/ApiCall';
import ApiMaps from '../../services/ApiMaps';
import Loader from '../../components/Loader/Loader';
import ImageGaleryVillas from '../../components/Slider/ImageGaleryVillas';
import MapsVillas from '../../components/MapsVilla/MapsVillas';
import Fasilitas from '../../components/Fasilitas/Fasilitas';
import Rules from '../../components/Rules/Rules';
import BoxBooking from '../../components/BoxBooking/BoxBooking';
// import BoxBookingKosong from '../../components/BoxBooking/BoxBookingKosong';
import Accessibility from '../../components/Accessibility/Accessibility';
import Spoken from '../../components/Spoken/Spoken';
import Similar from '../../components/Similar/Similar';
import queryString from '../../utils/queryString';
import PinPointIcon from '../../components/Icons/PinPointIcon';
import SelectGuest from '../../components/form/SelectGuest';
import InputCheckIn from '../../components/form/InputCheckIn';
import InputCheckOut from '../../components/form/InputCheckOut';
import BedIcon from '../../components/Icons/BedIcon';
import BathRoomIcon from '../../components/Icons/BathRoomIcon';
import BedRoomIcon from '../../components/Icons/BedRoomIcon';
import {
  BASE_URL_ADM,
  PublicFacilities,
  PublicAttraction,
  IS_SHOW_MAP
  // COLORS,
} from '../../constants';
import {
  toggleModal,
  showModal,
  setBoxFilterDetail
} from '../../redux/actions';
import '../../style/detailVilla.css';
import LabelPrimary from '../../components/others/LabelPrimary';
import PropertyTags from '../../components/PropertyTags/PropertyTags';
import HotelReview from '../../components/HotelDetail/HotelReview';
import Star from '../../components/HotelDetail/Star';
import PrimaryButton from '../../components/form/PrimaryButton';
import MapIcon from '../../components/Icons/MapIcon';
import Helper, { getToday, moneyFormat } from '../../utils/helper';
import GuestIcon from '../../components/Icons/GuestIcon';
import moment from 'moment';

class HotelDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'id',
      loading: true,
      rooms: [],
      params: '',
      duration: '',
      arrival: '',
      guest: '',
      departure: '',
      hotels: {
        VillaLocations: []
      },
      fiturs: [],
      fitursothers: [],
      viewMore: false,
      imgHotel: [],
      imgHotels: [],
      imgView: [],
      imgTampil: [],
      imgRoom: [],
      imgBackup: [],
      imgRespon: [],
      lat: 0,
      long: 0,
      masuk: '',
      keluar: '',
      city: '',
      substrid: '',
      substren: '',
      idn: '',
      eng: '',
      click: false,
      cover: '',
      clickAv: false,
      bookModal: false,
      scrollPosition: this.getWindowScrollTop(),
      scrollActive: false,
      priceTotal: '',
      hotelPrice: '',
      viewMoreModal: false,
      dateTanggal: '',
      totalMurni: '',
      bathroom: [],
      bedroom: [],
      exterior: [],
      dining: [],
      indoor: [],
      kitchen: [],
      lain: [],
      layout: [],
      living: [],
      lobi: [],
      outdoor: [],
      swimming: [],
      marker: [],
      markerFacilities: [],
      markerAttraction: [],
      booking: false,
      dataAvailable: [],
      zero: false,
      viilaRules: [],
      villaAkses: [],
      villaSpoken: [],
      konfigurasi: {},
      similar: [],
      villaFilter: false,
      reviewsHehe: null,
      propertyId: null,
      checkIn: null,
      checkOut: null
    };
    this.handleInterval = this.handleInterval.bind(this);
    this.handleRequestAnimationFrame = this.handleRequestAnimationFrame.bind(
      this
    );
  }

  componentDidMount() {
    const INTERVAL = 20;
    let CHECK_IN = localStorage.getItem('CHECK_IN') || Helper.getToday();
    const duration2 = moment(localStorage.getItem('CHECK_IN')).diff(
      moment.now(),
      'd'
    );
    if (duration2 < 0 || !CHECK_IN) {
      CHECK_IN = Helper.getToday();
    }
    // alert(CHECK_IN);
    let CHECK_OUT = localStorage.getItem('CHECK_OUT') || Helper.getTomorrow();
    const duration3 = moment(CHECK_OUT).diff(moment(CHECK_IN), 'd');
    if (duration3 <= 0) {
      CHECK_OUT = Helper.getTomorrow();
    }
    this.setState({
      checkIn: CHECK_IN,
      checkOut: CHECK_OUT,
      loading: true
    });
    localStorage.setItem('CHECK_IN', CHECK_IN);
    localStorage.setItem('CHECK_OUT', CHECK_OUT);
    this.intervalID = setInterval(this.handleInterval, INTERVAL);

    const paramsString = queryString.parse(this.props.location.search);
    if (paramsString.villas === undefined || paramsString.villas === '') {
      setTimeout(() => {
        this.props.history.push('/');
      }, 1000);
    } else {
      this.dataVillaNotFilter(paramsString);
      this.setState({
        propertyId: paramsString.villas
      });
    }
    this.setState({
      loading: false
    });
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.getScrollBox.bind(this));
    window.addEventListener('resize', this.getScrollBox.bind(this));

    window.onload = () => {
      const paramsString = queryString.parse(this.props.location.search);
      if (
        paramsString.arrival !== undefined ||
        paramsString.departure !== undefined
      ) {
        this.props.setBoxFilterDetail(true);
      } else {
        this.props.setBoxFilterDetail(false);
      }
      // window.onpopstate = (event, history) => {
      //   window.location.reload();
      // };
      //  window.onpopstate = (event, history) => {

      // window.location.reload()
      // }
    };
    this.getScrollBox();
  }

  componentDidUpdate(e, b) {
    const paramsString = queryString.parse(e.history.location.search);
    if (paramsString.duration !== undefined) {
      // this.setDuration(paramsString.duration);
    }
    // if (paramsString.arrival !== undefined || paramsString.departure !== undefined) {
    //         this.props.setBoxFilterDetail(true)
    //      } else {
    //         this.props.setBoxFilterDetail(false)
    //     }
  }

  getWindowScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  setDuration(_dur) {
    // this.state.duration = _dur;
    this.setState({
      duration: _dur
    });
  }
  getNearBy(_location, _lat, _lang) {
    if (IS_SHOW_MAP) {
      const arrData = [];
      if (_location && _location.length > 0) {
        for (let i = 0; i < _location.length; i++) {
          const _data = {
            search: _location[i],
            lat: _lat,
            lang: _lang
          };
          ApiMaps.getNearByMapsOneData(_data, res => {
            if (res) {
              let result =
                res.results !== undefined ? res.results[0] : undefined;
              if (result !== undefined) {
                const name = result.name;
                const id = result.place_id;
                const __lat = result.geometry.location.lat;
                const __lng = result.geometry.location.lng;
                const _street = this.getDistanceFromLatLonInKm(
                  _lat,
                  _lang,
                  __lat,
                  __lng
                ).toFixed(3);
                const _jarak = Math.floor(_street * 1000);
                const _arrData = {
                  index: id,
                  label: name,
                  lat: __lat,
                  lng: __lng,
                  street: _street,
                  jarak: _jarak,
                  lok: _location[i]
                };
                arrData.push(_arrData);
              }
            }
          });
        }
      }
      return arrData;
    }
    return [];
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const _lat1 = this.deg2rad(lat1);
    const _lat2 = this.deg2rad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(_lat1) *
        Math.cos(_lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  getScrollBox() {
    if (document.getElementById('boxBooking')) {
      try {
        const box = document
          .getElementById('boxBooking')
          .getBoundingClientRect();
        if (box.bottom < 780) {
          const boks = document.getElementById('BoxDetailBooking');
          boks.style.position = 'sticky';
          // boks.style.width = (box.width - 30) + 'px'
        } else if (box.bottom > 480) {
          // const boks = document.getElementById('BoxDetailBooking');
          // boks.style.position = 'fixed';
          // boks.style.width = (box.width - 30) + 'px'
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  deg2rad(deg) {
    return (deg * Math.PI) / 180;
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  handleInterval() {
    cancelAnimationFrame(this.requestID);
    this.requestID = requestAnimationFrame(this.handleRequestAnimationFrame);
  }

  handleRequestAnimationFrame() {
    const { scrollPosition } = this.state;
    const newScrollPosition = this.getWindowScrollTop();
    if (newScrollPosition !== scrollPosition) {
      this.setState({
        scrollPosition: newScrollPosition
      });
    }
  }

  dataVillaNotFilter = paramsString => {
    this.setState({ params: paramsString, zero: true });

    ApiCall.getDateAvailabelVilla(paramsString.villas)
      .then(response => {
        this.setState({
          dataAvailable: response.data.data
        });
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Koneksi internet bermasalah');
        }
        this.setState({ loading: false });
      });
    ApiCall.getRooms(paramsString)
      .then(response => {
        // let checkGuest = "";
        // if (paramsString.guest === "" || paramsString.guest === "0") {
        //   checkGuest = 1;
        // } else if (
        //   Math.floor(paramsString.guest) >
        //   Math.floor(response.data.hotel.capacity)
        // ) {
        //   checkGuest = Math.floor(response.data.hotel.capacity);
        // } else {
        //   checkGuest = paramsString.guest;
        // }
        const _markerFacilities = this.getNearBy(
          PublicFacilities,
          response.data.hotel.lat,
          response.data.hotel.long
        );
        const _markerAttraction = this.getNearBy(
          PublicAttraction,
          response.data.hotel.lat,
          response.data.hotel.long
        );
        this.setState({
          markerFacilities: _markerFacilities,
          markerAttraction: _markerAttraction,
          hotels: response.data.hotel,
          hotelPrice: response.data.hotel.Price,
          city: response.data.hotel.Cities,
          fiturs: response.data.hotel.Fiturs,
          konfigurasi: response.data.hotel.konfigurasi,
          fitursothers: response.data.hotel.FitursOther,
          departure: paramsString.departure,
          arrival: paramsString.arrival,
          totalMurni: response.data.hotel.totalMurni,
          imgHotel: response.data.hotel.images.split(';'),
          imgTampil: response.data.hotel.images.split(';'),
          imgBackup: response.data.hotel.images.split(';'),
          imgHotels: response.data.hotel.images.split(';'),
          imgRespon: response.data.hotel.images.split(';'),
          bathroom: response.data.hotel.images2.bathroom,
          bedroom: response.data.hotel.images2.bedroom,
          exterior: response.data.hotel.images2.exterior,
          dining: response.data.hotel.images2.dining,
          indoor: response.data.hotel.images2.indoor,
          kitchen: response.data.hotel.images2.kitchen,
          lain: response.data.hotel.images2.lain_lain,
          layout: response.data.hotel.images2.layout,
          living: response.data.hotel.images2.living,
          lobi: response.data.hotel.images2.lobi,
          outdoor: response.data.hotel.images2.outdoor,
          swimming: response.data.hotel.images2.swimming,
          guest: '',
          villaAkses: response.data.hotel.villa_aksesibilitas,
          villaSpoken: response.data.hotel.spoken,
          keluar: '',
          loading: false,
          startDate: null,
          endDate: null,
          booking: false,
          villaRules: response.data.hotel.villa_rules
        });
        ApiCall.getSimilarVilla(this.state.hotels.id).then(responseData => {
          // console.log(responseData.data.data, "woy 34");
          this.setState({
            similar: responseData.data.data
          });
        });
        const gambar = this.state.imgHotel;
        const img = [];
        if (gambar.length > 3) {
          img.push(gambar[0]);
          img.push(gambar[1]);
          img.push(gambar[2]);
          this.setState({
            imgHotel: img
          });
        } else {
          this.setState({ imgHotel: gambar });
        }
        const respon = this.state.imgHotel;
        const picture = [];
        if (respon.length > 2) {
          picture.push(respon[0]);
          picture.push(respon[1]);
          this.setState({ imgRespon: picture });
        } else {
          this.setState({ imgRespon: respon });
        }
        setTimeout(() => {
          const strid = response.data.hotel.description_id;
          const resid = strid.substring(0, 310);
          const stren = response.data.hotel.description_en;
          const resen = stren.substring(0, 310);
          this.setState({
            substrid: resid,
            substren: resen,
            idn: response.data.hotel.description_id,
            eng: response.data.hotel.description_en
          });
          if (this.props.app.toggleMenu === true) {
            this.props.toggleModal('toggleMenu');
          }
        }, 1000);
        setTimeout(() => {
          this.setState({
            lat: this.state.hotels.lat,
            long: this.state.hotels.long
          });
        }, 1000);
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Koneksi internet bermasalah');
        }
        this.setState({ loading: false });
      });
  };

  viewMore = (e, actions, img) => {
    if (img !== '') {
      const images = this.state.imgBackup;
      const dtImages = [];
      for (let i = 0; i < images.length; i++) {
        const arrIamges = {
          original: BASE_URL_ADM + '/images/hotel/' + images[i],
          thumbnail: BASE_URL_ADM + '/images/hotel/' + images[i]
        };
        dtImages.push(arrIamges);
      }
      this.setState({ imgView: dtImages });
    } else {
      this.setState({});
    }

    if (actions === 'show') {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
  };

  viewMoreClosed = (e, actions) => {
    if (actions === 'show') {
      this.setState({ viewMore: true });
    } else {
      this.setState({ viewMore: false });
    }
  };

  bookModal = (e, action) => {
    if (action === 'show') {
      this.setState({
        bookModal: true
      });
    } else {
      this.setState({
        bookModal: false
      });
    }
  };
  bookModalClosed = (e, action) => {
    if (action === 'show') {
      this.setState({
        bookModal: true
      });
    } else {
      this.setState({
        bookModal: false
      });
    }
  };

  klik = () => {
    this.setState({
      click: !this.state.click
    });
  };

  viewMoreClosedModal = (e, actions) => {
    if (actions === 'show') {
      this.setState({ viewMoreModal: true });
    } else {
      this.setState({ viewMoreModal: false });
    }
  };

  klikImage = (e, gambar) => {
    const gambarTampilan = this.state.imgHotel;
    const img = [];
    if (gambar === gambarTampilan[0]) {
      img.push(gambarTampilan[1]);
      img.push(gambarTampilan[2]);
      img.push(gambarTampilan[0]);
    } else if (gambar === gambarTampilan[1]) {
      img.push(gambarTampilan[2]);
      img.push(gambarTampilan[0]);
      img.push(gambarTampilan[1]);
    } else if (gambar === gambarTampilan[2]) {
      img.push(gambarTampilan[0]);
      img.push(gambarTampilan[1]);
      img.push(gambarTampilan[2]);
    }
    this.setState({
      imgHotels: [gambar]
    });
    setTimeout(() => {
      this.setState({
        imgHotels: img
      });
    }, 3000);
  };

  handleScroll = () => {
    const scrollPositionY = +window.scrollY;
    return this.setState({ scrollPositionY });
  };
  showpopup = (e, index) => {
    const popup = document.getElementById(index);
    popup.classList.toggle('show');
    popup.classList.toggle('lihat');
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  reservation = (e, id, minStay) => {
    const { t } = this.props;
    if (minStay > this.state.duration) {
      toast.info(t.minstayf + ' ' + minStay + ' ' + t.minstayb);
    } else {
      this.props.history.push(
        `/reservation-form?guest=${this.state.guest}&arrival=${this.state.params.arrival}&departure=${this.state.params.departure}&duration=${this.state.duration}&villas=${this.state.params.villas}`
      );
    }
    this.setState({
      loading: false
    });
  };

  render() {
    const { t } = this.props;
    const { loading, hotels, marker, konfigurasi } = this.state;
    const settingGalery = {
      data: this.state.imgHotel,
      dataChild: this.state.imgHotel,
      dataAll: this.state.imgTampil,
      bathroom: this.state.bathroom,
      bedroom: this.state.bedroom,
      exterior: this.state.exterior,
      dining: this.state.dining,
      indoor: this.state.indoor,
      kitchen: this.state.kitchen,
      lain: this.state.lain,
      layout: this.state.layout,
      living: this.state.living,
      lobi: this.state.lobi,
      outdoor: this.state.outdoor,
      swimming: this.state.swimming,
      dataMore: this.state.imgHotel[0]
    };
    // console.log(this.state.hotels.id);
    const PropsBoxBooking = {
      history: this.props.history,
      onResize: this.getScrollBox(),
      star: this.state.hotels.star,
      propertyId: this.state.hotels.id,
      property: this.state.hotels
    };
    // const PropsBoxBookingZero = {
    //   hotels: this.state.hotels,
    //   city: this.state.city,
    //   priceTotal: this.state.priceTotal,
    //   guest: this.state.guest,
    //   history: this.props.history,
    //   villas: this.state.params.villas,
    //   arrival: this.state.startDate,
    //   departure: this.state.endDate,
    //   duration: this.state.duration,
    //   dateTanggal: this.state.dateTanggal,
    //   totalMurni: this.state.totalMurni,
    //   masuk: this.state.params.arrival,
    //   keluar: this.state.params.departure,
    //   dataAvailable: this.state.dataAvailable,
    //   star: this.state.hotels.star
    // };
    const PropsFasilitas = {
      basic: this.state.fiturs,
      others: this.state.fitursothers
    };

    const setMaps = {
      nearbyFacilities: this.state.markerFacilities,
      nearbyAtraction: this.state.markerAttraction
    };
    const PropsvillaRules = {
      villaRules: this.state.villaRules
    };
    const propsvillaAkses = {
      villaAkses: this.state.villaAkses
    };
    const propsvillaSpoken = {
      villaSpoken: this.state.villaSpoken
    };
    const propsSimilar = {
      hotels: this.state.hotels,
      city: this.state.city,
      priceTotal: this.state.priceTotal,
      guest: this.state.guest,
      history: this.props.history,
      villas: this.state.params.villas,
      arrival: this.state.startDate,
      departure: this.state.endDate,
      duration: this.state.duration,
      dateTanggal: this.state.dateTanggal,
      totalMurni: this.state.totalMurni,
      masuk: this.state.params.arrival,
      keluar: this.state.params.departure,
      dataAvailable: this.state.dataAvailable,
      similarP: this.state.similar
    };
    const tags = <PropertyTags tag_villa={hotels.tag_villa} />;
    return (
      <section
        className="section"
        style={{ paddingBottom: 0, marginBottom: -50 }}
        onClick={this.closeHeader}
      >
        <Loader loading={loading} />
        <div className="container">
          <div
            style={{ backgroundColor: '#F9F9F9', fontSize: 18 }}
            className="p-md-5 p-3 mb-3 mb-md-5"
          >
            <div className="columns">
              <div className="column is-7">
                <div style={{ color: '#797979' }} className="mb-2">
                  {hotels.category_name}
                </div>
                <h1
                  style={{ color: '#333333', fontSize: 36, fontWeight: 'bold' }}
                >
                  {hotels.name}
                </h1>
                <div>
                  <Star star={hotels.star} />
                </div>
                <div className="">
                  <div style={{ color: '#797979', fontSize: 14 }}>
                    <PinPointIcon />
                    &nbsp;&nbsp;&nbsp;
                    {hotels.address}
                  </div>
                  <br />
                  <a
                    onClick={() => {
                      window.event.preventDefault();
                      window.open(
                        'https://www.google.com/maps/search/?api=1&query=' +
                          hotels.lat +
                          ',' +
                          hotels.long
                      );
                    }}
                    className="button bg-primary text-white  mt-2 mt-md-0"
                    style={{ fontSize: 12, borderRadius: 10, border: 'unset' }}
                  >
                    <MapIcon /> &nbsp;&nbsp;
                    {this.props.app.lang === 'id' ? 'Buka peta' : 'Open map'}
                  </a>
                </div>
              </div>
              <div className="column is-5">
                <div
                  style={{ border: '1px solid #C4C4C4', borderRadius: 10 }}
                  className="p-4 d-none d-md-block"
                >
                  <SelectGuest
                    onChange={e => {}}
                    defaultValue={localStorage.getItem('GUEST_COUNT') || 1}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <InputCheckIn
                        value={this.state.checkIn}
                        onChange={e => {
                          if (moment(e.target.value).isBefore(getToday())) {
                            toast.error('Check In is invalid');
                          } else if (
                            moment(e.target.value).isAfter(this.state.checkOut)
                          ) {
                            // alert(1);
                            this.setState({
                              checkIn: e.target.value,
                              checkOut: ''
                            });
                          } else this.setState({ checkIn: e.target.value });
                        }}
                        defaultValue={this.state.checkIn}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputCheckOut
                        value={this.state.checkOut}
                        onChange={e => {
                          if (
                            moment(e.target.value).isBefore(getToday()) ||
                            moment(e.target.value).isSameOrBefore(
                              this.state.checkIn
                            )
                          ) {
                            toast.error('Check Out is invalid');
                          } else this.setState({ checkOut: e.target.value });
                        }}
                        defaultValue={this.state.checkOut}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="columns is-mobile is-multiline">
            <div className="column is-12 is-mobile name-responsive">
              <label className="labelTypeVilla180913">
                {hotels.category_name}
              </label>
              <Star star={hotels.star} />
              <b>
                <p style={{ marginTop: 2 }}>{hotels.name}</p>
              </b>
            </div>
            <div className="column is-12 is-mobile name-responsive">
              <div className="columns is-multiline is-mobile kontenDescDetail180914">
                <div className="column is-12">
                  <p className="labelAddressHeaderVilla180913">
                    {hotels.address}
                  </p>
                </div>
                <div className="column is-12">
                  {hotels.payAtVilla && (
                    <LabelPrimary rounded fontSize={12} text={t.payAtVilla} />
                  )}
                </div>
              </div>
            </div>
          </div>
           */}
          <div className="columns boxKOnfigurasi">
            <div className="column is-5 galery">
              <div className="columns is-multiline is-mobile">
                <div className="column is-12" style={{ paddingTop: 5 }}>
                  <ImageGaleryVillas
                    hotel={this.state.hotels}
                    availability={this.state.dataAvailable}
                    {...settingGalery}
                  />
                </div>
              </div>
            </div>
            <div
              className="column is-7"
              style={{
                marginTop: 12,
                zIndex: 1,
                marginBottom: 21
              }}
              id="boxBooking"
            >
              {/* {this.props.app.filterDetail ? (
                <BoxBooking {...PropsBoxBooking} />
              ) : (
                <BoxBookingKosong {...PropsBoxBookingZero} />
              )} */}
              <BoxBooking {...PropsBoxBooking} />

              <div
                className="mt-5 mb-3"
                style={{
                  color: '#333333',
                  fontSize: 15,
                  fontWeight: 'bold'
                }}
              >
                {this.props.app.lang === 'id' ? 'Deskripsi' : 'Description'}
              </div>
              <div
                style={{
                  color: '#656565',
                  borderBottom: '1px solid #C4C4C4',
                  paddingBottom: 30,
                  marginBottom: 30
                }}
              >
                {this.props.app.lang === 'id'
                  ? hotels.description_id
                  : hotels.description_en}
              </div>
              <div
                style={{
                  paddingBottom: 30
                }}
              >
                {hotels !== null && (
                  <div className="row">
                    <div className="col-6" style={{ color: '#333333' }}>
                      <div className="d-flex justify-content-start align-items-center mb-4">
                        <GuestIcon /> &nbsp;&nbsp;&nbsp;
                        {hotels.capacity}&nbsp;
                        {this.props.app.lang === 'id'
                          ? 'tamu (max)'
                          : 'guest (max)'}
                      </div>
                    </div>
                    <div className="col-6" style={{ color: '#333333' }}>
                      <div className="d-flex justify-content-start align-items-center mb-4">
                        <BedIcon /> &nbsp;&nbsp;&nbsp;
                        {konfigurasi.bed}&nbsp;
                        {this.props.app.lang === 'id' ? 'ranjang' : 'bed'}
                      </div>
                    </div>
                    <div className="col-6" style={{ color: '#333333' }}>
                      <div className="d-flex justify-content-start align-items-center mb-4">
                        <BedRoomIcon /> &nbsp;&nbsp;&nbsp;
                        {konfigurasi.bedroom}&nbsp;
                        {this.props.app.lang === 'id'
                          ? 'kamar tidur'
                          : 'bedroom'}
                      </div>
                    </div>
                    <div className="col-6" style={{ color: '#333333' }}>
                      <div className="d-flex justify-content-start align-items-center mb-4">
                        &nbsp;&nbsp;
                        <BathRoomIcon /> &nbsp;&nbsp;&nbsp;
                        {konfigurasi.bathroom}&nbsp;
                        {this.props.app.lang === 'id'
                          ? 'kamar mandi'
                          : 'bathroom'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <div className="columns is-multiline is-mobile">
                <div className="column is-12">
                  <hr style={{ marginTop: -5 }} />
                </div>
                <div
                  className="column is-12 labelFasilitas"
                  style={{ marginTop: -27 }}
                >
                  <b style={{ fontSize: 18 }}>{t.facilities}</b>
                </div>
                <div className="column is-12">
                  <Fasilitas {...PropsFasilitas} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b>
                    <p className="font-title">{t.location}</p>
                  </b>
                  <h6 className="font-label">{hotels.address}</h6>
                  {hotels.tag_villa && (
                    <h6 className="font-label" style={{ color: '#3A6A6D' }}>
                      {tags}
                    </h6>
                  )}
                </div>
                {IS_SHOW_MAP && (
                  <div className="column is-12 mapJarak">
                    {this.state.lat !== 0 ? (
                      <MapsVillas
                        {...setMaps}
                        lat={parseFloat(this.state.lat)}
                        long={parseFloat(this.state.long)}
                        marker={marker}
                        isMarkerShown
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}
                <div className="column is-12">
                  <hr style={{ marginTop: 0 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.rules}</b>
                </div>
                <div className="column is-12">
                  <Rules {...PropsvillaRules} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.spokenlang}</b>
                </div>
                <div className="column is-9 is-multiline">
                  <Spoken {...propsvillaSpoken} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                <div className="column is-12" style={{ marginTop: -27 }}>
                  <b style={{ fontSize: 18 }}>{t.accessibilitas}</b>
                </div>
                <div className="column is-12">
                  <Accessibility {...propsvillaAkses} />
                </div>
                <div className="column is-12">
                  <hr style={{ marginTop: -12 }} />
                </div>
                {this.state.propertyId !== null && (
                  <HotelReview t={t} propertyId={this.state.propertyId} />
                )}
                {this.state.similar.length === 0 ? (
                  ''
                ) : (
                  <div className="column is-12">
                    <hr style={{ marginTop: -12 }} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.similar.length === 0 ? (
            ''
          ) : (
            <div className="columns is-multiline">
              <div
                className="column is-12 desktopsTeks"
                style={{ marginLeft: 4 }}
              >
                <b className="tablet" style={{ fontSize: 18 }}>
                  {' '}
                  {t.similar}{' '}
                  {this.state.hotels.VillaLocations.length
                    ? this.state.hotels.VillaLocations[0]['nama_kota']
                    : ''}
                </b>
              </div>
              <div className="column is-12 mobiles" style={{ marginTop: -82 }}>
                <b style={{ fontSize: 18 }}> {t.similar}</b>
              </div>
              <div className="column is-12 boxSlide" style={{ marginTop: -40 }}>
                <Similar {...propsSimilar} />
              </div>
            </div>
          )}

          {/*modal booking */}
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={e => this.viewMoreClosed(e, 'hidden')}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {
  toggleModal,
  showModal,
  setBoxFilterDetail
});
const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(HotelDetail));
