import React from 'react';

function PrimaryButton({ onClick, label, disabled }) {
  return (
    <button
      onClick={onClick}
      className="button bg-primary text-white"
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export default PrimaryButton;
