import React from 'react';
import { IconTopUpTicket } from '../../assets/images/icons/topups';

function TicketIcon({ width = 40, height = 40, withBg = true }) {
  return <img src={IconTopUpTicket} />;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13 11V21"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 18.7215C7.99998 18.5936 8.04285 18.4695 8.12135 18.3704C8.19985 18.2713 8.30914 18.2032 8.43071 18.1778C8.91717 18.0752 9.35438 17.8025 9.66836 17.4057C9.98235 17.0088 10.1538 16.5123 10.1538 16C10.1538 15.4877 9.98235 14.9912 9.66836 14.5943C9.35438 14.1975 8.91717 13.9248 8.43071 13.8222C8.30914 13.7968 8.19985 13.7287 8.12135 13.6296C8.04285 13.5305 7.99998 13.4064 8 13.2785V11.5556C8 11.4082 8.05673 11.2669 8.15771 11.1627C8.25869 11.0585 8.39565 11 8.53846 11H21.4615C21.6043 11 21.7413 11.0585 21.8423 11.1627C21.9433 11.2669 22 11.4082 22 11.5556V13.2785C22 13.4064 21.9572 13.5305 21.8787 13.6296C21.8002 13.7287 21.6909 13.7968 21.5693 13.8222C21.0828 13.9248 20.6456 14.1975 20.3316 14.5944C20.0177 14.9912 19.8462 15.4877 19.8462 16C19.8462 16.5123 20.0177 17.0088 20.3316 17.4057C20.6456 17.8025 21.0828 18.0752 21.5693 18.1778C21.6909 18.2032 21.8002 18.2713 21.8787 18.3704C21.9572 18.4695 22 18.5936 22 18.7215V20.4444C22 20.5918 21.9433 20.7331 21.8423 20.8373C21.7413 20.9415 21.6043 21 21.4615 21H8.53846C8.39565 21 8.25869 20.9415 8.15771 20.8373C8.05673 20.7331 8 20.5918 8 20.4444V18.7215Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {withBg && (
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="15"
            y1="5.71429"
            x2="15"
            y2="41.4286"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F77E3C" />
            <stop offset="1" stopColor="#FF9B64" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
}

export default TicketIcon;
