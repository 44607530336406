import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';

import { logout, showModal, toggleModal, setUser } from '../redux/actions';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      name: '',
      email: '',
      data: {},
      loading: false,
      lang: 'id',
      statusRegister: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (this.props.app.user === '') {
        window.location.href = '/';
      } else {
        console.log(this.props.app.user);
        this.setState({
          data: this.props.app.user
        });
      }
      if (this.props.app.toggleMenu === true) {
        this.props.toggleModal('toggleMenu');
      }
    }, 1000);
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  changeLang = lang => {
    this.setState({ lang });
    this.props.changeLocale(lang);
  };

  logout = () => {
    this.props.logout();
    const pathname = window.location.pathname;
    setTimeout(() => {
      if (pathname === '/profile' || pathname === '/profile/edit') {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }, 500);
  };

  render() {
    const { t, app } = this.props;

    return (
      <section className="section mobileprof" onClick={this.closeHeader}>
        <div className="container profpage">
          <div className="columns">
            <div className="column is-4 myaccountbox">
              <h3 className="title" style={{ fontSize: 18 }}>
                {t.myaccount}
              </h3>
              <hr style={{ marginTop: -10 }} />
              <Link
                to="/profile"
                className="column is-12 activated boxes"
                style={{
                  height: 30,
                  marginTop: -10,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.accountset}</p>
              </Link>
              <Link
                to="/my-booking"
                className="column boxes unactive is-12"
                style={{
                  height: 30,
                  marginTop: 0,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.mybook}</p>
              </Link>
              <Link
                to="/"
                className="column boxes unactive is-12"
                style={{
                  height: 30,
                  marginTop: 0,
                  paddingTop: 3,
                  paddingBottom: 5,
                  marginLeft: -31
                }}
                onClick={this.logout}
              >
                <p style={{ fontSize: 14, color: 'black' }}>{t.logout}</p>
              </Link>
            </div>
            <div className="column is-8 accountsettingbox">
              <h3 className="title" style={{ fontSize: 18 }}>
                {t.accountset}
              </h3>
              <hr style={{ marginTop: -10 }} />
              <div
                className="column is-12"
                style={{ marginTop: -20, paddingLeft: 0, paddingRight: 0 }}
              >
                <b style={{ fontSize: 14 }}>{t.name}</b> <br />
                <p style={{ fontSize: 14 }}>{app.user.name}</p>
              </div>
              <br />
              <div
                className="columns is-mobile is-multiline"
                style={{ marginTop: -25 }}
              >
                <div className="column is-6 is-narrow mobilecolumn">
                  <b style={{ fontSize: 14 }}>{t.phone}</b> <br />
                  <p style={{ fontSize: 14 }}>
                    {app.user.code}
                    {app.user.phone}
                  </p>
                </div>
                <div className="column is-6 is-narrow mobilecolumn">
                  <b style={{ fontSize: 14 }}>Email</b> <br />
                  <p style={{ fontSize: 14 }}>{app.user.email}</p>
                </div>
              </div>
              <div className="columns is-mobile is-multiline">
                <div className="column is-6  is-narrow mobilecolumn">
                  <b style={{ fontSize: 14 }}>{t['Referral Code']}</b> <br />
                  <p style={{ fontSize: 14 }}>{app.user.referral_code}</p>
                </div>
                <div className="column is-6  is-narrow mobilecolumn">
                  <b style={{ fontSize: 14 }}>Bagikan</b>
                  <br />
                  <div className="d-flex align-items-center">
                    <img
                      onClick={() => {
                        window.open(
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                            window.origin +
                            '?reff=' +
                            app.user.referral_code
                        );
                      }}
                      style={{
                        maxWidth: 30,
                        marginRight: 10,
                        cursor: 'pointer'
                      }}
                      src={require('./../../src/assets/icons/fb.png')}
                      alt="Facebook Share"
                    />
                    <img
                      onClick={() => {
                        window.open(
                          'https://twitter.com/intent/tweet?url=' +
                            window.origin +
                            '?reff=' +
                            app.user.referral_code
                        );
                      }}
                      style={{ maxWidth: 30, cursor: 'pointer' }}
                      src={require('./../../src/assets/icons/twitter.png')}
                      alt="Twitter Share"
                    />
                  </div>
                </div>
              </div>
              <hr style={{ marginTop: 0 }} className="linemobile" />
              {app.user.name ? (
                <div className="column is-12 buttons" style={{ marginTop: -8 }}>
                  {app.user.statusRegister === 0 ? (
                    <Link
                      to="/change-password"
                      className="changepass submitbutton bg-primary has-text-centered"
                    >
                      <b>{t.changePassword}</b>
                    </Link>
                  ) : (
                    ''
                  )}
                  <Link
                    to="/profile/edit"
                    className="editprof submitbutton bg-primary has-text-centered"
                  >
                    <b>{t.editProfile}</b>
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, {
  logout,
  toggleModal,
  showModal,
  setUser
});
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Profile));
