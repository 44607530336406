import {
  TOGGLE_MODAL,
  SHOW_MODAL,
  SET_USER,
  USER_LOGOUT,
  SET_LANG,
  SET_MAPS,
  SET_MESSAGE,
  SET_PAGES,
  SET_MESSAGE_MID,
  SET_BOX_FILTER_DETAIL,
} from '../actions/types';

const INITIAL_STATE = {
  modalLogin: false,
  modalBooking: false,
  modalRegister: false,
  modalForgotPassword: false,
  modalResetPassword: false,
  modalPopDates: false,
  modalGuest: false,
  popupmessage: false,
  popupmidtrans: false,
  toggleMenu: false,
  messagemid1: '',
  messagemid2: '',
  methodPayments: false,
  modalVoucher: false,
  lebelPromo: false,
  modalBoxBooking: false,
  filterDetail: false,
  user: '',
  lang: 'en',
  maps: '',
  message: '',
  pages: 0,
  promoLogin: 7943984398,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...state, [action.payload.name]: !state[action.payload.name] };
    case SHOW_MODAL:
      return {
        ...state,
        modalLogin: false,
        modalBooking: false,
        modalRegister: false,
        modalForgotPassword: false,
        modalResetPassword: false,
        modalPopDates: false,
        modalGuest: false,
        [action.payload.name]: true,
      };
    case SET_BOX_FILTER_DETAIL:
      return { ...state, filterDetail: action.payload.value };
    case SET_MESSAGE_MID:
      return { ...state, [action.name]: action.payload.data };
    case SET_PAGES:
      return { ...state, pages: action.payload.data };
    case SET_MESSAGE:
      return { ...state, message: action.payload.data };
    case SET_MAPS:
      return { ...state, maps: action.payload.data };
    case SET_LANG:
      return { ...state, lang: action.payload.data };
    case SET_USER:
      return { ...state, user: action.payload.data };
    case USER_LOGOUT:
      return { ...state, user: '' };
    case 'SET_PROMO_LOGIN':
      // console.log('payload', action.payload.promoLogin)
      return { ...state, promoLogin: action.payload.promoLogin };
    default:
      return state;
  }
};
