import axios from 'axios';
import { BASE_URL } from '../constants';

axios.defaults.baseURL = BASE_URL + '/v1';
axios.defaults.headers.common['App-Key'] = 'nusanlinkfor1ndonesi4';
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

export const http = axios;

function paymentGateway(state, bahasa, userId, _debt) {
  const {
    optionPay,
    hour_arrival,
    pointUsed,
    items,
    rooms
  } = state;
  const multiple_rates = [];
  if (items !== null) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const room = rooms.find(room => {
        return Number(room.id) === Number(item.room_id);
      });
      const rates = room.rates;
      multiple_rates.push({
        room_id: room.id,
        rates
      });
    }
  }
  let payment_option = 'transfer';
  if (Number(optionPay) === 1) {
    payment_option = 'pay_at_property';
  } else if (Number(optionPay) === 2) {
    payment_option = 'transfer_dp';
  }
  // console.log(multiple_rates);
  // return;

  let payment_method
  if (Number(optionPay) === 3) {
    payment_method = 'kredivo:3_months';
  }

  const isMultiple = state.multiples.length > 0

  let formData = isMultiple ? {
    arrival: state.arrival,
    departure: state.departure,
    name: state.guestName,
    guest: state.guest,
    phone: state.area + '-' + state.guestNoTelp,
    email: state.guestEmail,
    propertyId: state.params.villas,
    details: state.multiples.map((multiple) => {
      const mapRoomIdIndex = {}
      return {
        date: multiple.date,
        rooms: multiple.rooms.reduce((acc, room, index) => {
          const curRoomIdIndex = mapRoomIdIndex[room.roomId]
          if(curRoomIdIndex !== undefined) {
            acc[curRoomIdIndex].qty += 1
            return acc
          }

          mapRoomIdIndex[room.roomId] = index
          acc.push({
            room_id: room.roomId,
            qty: 1
          })

          return acc
        }, [])
      }
    }),
    lang: bahasa,
    voucherCode: state.kodeVoucher,
    nip: '',
    address: '',
    specialRequest: state.extra,
    payment_option,
    referral_code: state.promo_referral_nominal > 0 ? state.referral_code : '',
    hour_arrival,
    point: pointUsed,
    referral_obtaineds: state.promoReferralForReferrers,
    user_Id: userId,
    order_from: 'web',
    payment_method,
    unique_code: state.unique_code
  } :  {
    arrival: state.arrival,
    departure: state.departure,
    name: state.guestName,
    guest: state.guest,
    phone: state.area + '-' + state.guestNoTelp,
    email: state.guestEmail,
    propertyId: state.params.villas,
    roomId: state.room.id,
    lang: bahasa,
    voucherCode: state.kodeVoucher,
    nip: '',
    address: '',
    specialRequest: state.extra,
    payment_option,
    referral_code: state.promo_referral_nominal > 0 ? state.referral_code : '',
    hour_arrival,
    point: pointUsed,
    referral_obtaineds: state.promoReferralForReferrers,
    user_Id: userId,
    order_from: 'web',
    payment_method,
    unique_code: state.unique_code
  }

  return isMultiple ? axios.post('/reservations/multiple', formData) : axios.post('/reservations/single', formData);
}

async function getHotels(params, pages) {
  const arrivalParams = params.arrival === undefined ? '-' : params.arrival;
  const arrivalDate = arrivalParams.split('-');
  const departureParams =
    params.departure === undefined ? '-' : params.departure;
  const departureDate = departureParams.split('-');
  const masuk = arrivalDate[2] + '-' + arrivalDate[0] + '-' + arrivalDate[1];
  const keluar =
    departureDate[2] + '-' + departureDate[0] + '-' + departureDate[1];
  const guest =
    params.guest === '' || params.guest === '0' || params.guest === undefined
      ? 1
      : params.guest;
  return axios.get(
    `/hotels?guest=${guest}&arrival=${masuk}&departure=${keluar}&page=${pages}&city=${params.city}&propertyName=${params.propertyName}`
  );
}

function getRooms(params) {
  return axios.get(
    `/hotels/${params.villas}?guest=${params.guest}&arrival=${params.arrival}&departure=${params.departure}&duration=${params.duration}`
  );
}

function getDetailRooms(params) {
  return axios.get(`/rooms/${params.id_rooms}`);
}

function getVoucher(kodeVoucher, hotelId) {
  return axios.get(`/voucher/${kodeVoucher}/${hotelId}`);
}

function getUserDetail(email) {
  return axios.get('/users-by-email/' + email);
}

function putUser(state) {
  const data = {
    name: state.name,
    idNumber: state.idNumber,
    email: state.email,
    phone: state.telp,
    address: state.address,
    password: state.password,
    id: state.id
  };
  return axios.put('/users-by-email/' + data.id, data);
}
function getCity() {
  return axios.get('/list-cities');
}
function loginSocial(data) {
  return axios.post('/register-social', data);
}

function resetPassProfile(data) {
  return axios.post('/users-change-password', data);
}

function getBooking(userid) {
  return axios.get('list-mybooking?user_Id=' + userid);
}

function getDetailBooking(idreservation, userId, isMonthly) {
  // console.log('isMonthly', isMonthly);
  if (isMonthly)
    return axios.get(
      '/detail-mybooking?reservationId=' +
        idreservation +
        '&userId=' +
        userId +
        '&isMonthly=true'
    );
  return axios.get(
    '/detail-mybooking?reservationId=' + idreservation + '&userId=' + userId
  );
}

function getDownloadPdf(orderId) {
  return axios.get('/download?order_id=' + orderId);
}

function getPromo() {
  return axios.get('/voucher-home');
}
function getHotelHome(isRandom) {
  let params = {};
  if (isRandom)
    params = {
      isRandom: 1
    };
  return axios.get('/hotels/home-villa', { params });
}
function getHotelAll(pages, queryString = null) {
  if (queryString)
    return axios.get(`/hotels/all-villa?page=${pages}&${queryString}`);
  return axios.get(`/hotels/all-villa?page=${pages}`);
}
function getDateAvailabelVilla(VillaId) {
  return axios.get(`/hotels/available?HotelId=${VillaId}`);
}

function checkMinNight(params) {
  return axios.get(
    `/hotels/${params.villasId}?guest=${params.guest}&arrival=${params.arrival}&departure=${params.departure}&duration=${params.duration}`
  );
}

function getHtelsTags(isRandom = false) {
  let params = {};
  if (isRandom)
    params = {
      isRandom: 1
    };
  return axios.get('/hotels/tag-villa?tag=Our+Pick', { params });
}
function getSimilarVilla(VillaId) {
  return axios.get(`villas/similar/${VillaId}`);
}
function getEventHome(isAll = true) {
  return axios.get('/villas/event-home?all=' + isAll);
}

const ApiCall = {
  async findByOrderId(orderId, query = '') {
    return await axios.get('reservations/check-by-order-id/' + orderId + query);
  },

  async checkPlnNumber(plnNumber) {
    return await axios.get('top-up/pln/check-pln-number/' + plnNumber);
  },

  getGiveAway(limit = 5) {
    if (limit === null) return axios.get('give-away/all');
    return axios.get('give-away/limit/5');
  },
  getHotels,
  getRooms,
  getCity,
  paymentGateway,
  getDetailRooms,
  getVoucher,
  getUserDetail,
  putUser,
  loginSocial,
  resetPassProfile,
  getBooking,
  getDetailBooking,
  getDownloadPdf,
  getPromo,
  getHotelHome,
  getHotelAll,
  getDateAvailabelVilla,
  checkMinNight,
  getHtelsTags,
  getSimilarVilla,
  getEventHome,
  getHotelCities() {
    return axios.get('/hotel/cities');
  },
  getPropertyName() {
    return axios.get('/properties/name-and-id');
  },
  getCustomerPoint(userId) {
    return axios.get('/customers/point?userId=' + userId);
  },
  async getPromoLogin() {
    return await axios.get('/promo-login');
  },
  async findPromoEvent() {
    return await axios.get('/voucher-event');
  },
  async claimPromoEvent(userId, email) {
    return await axios.get(`/voucher-claim/random/${userId}/${email}`);
  },

  // stay monthly
  getPropertyStayMonthly(type = 'monthly') {
    if (type === 'weekly')
      return axios.get('/properties/stay-monthly?isWeekly=true');
    if (type === 'yearly')
      return axios.get('/properties/stay-monthly?isYearly=true');
    return axios.get('/properties/stay-monthly');
  },
  getLongStayProperties(type = 'monthly') {
    return axios.get('/properties/long-stay/' + type);
  },
  getPropertyDetailStayMonthly(propertyId, isReservation = false, type) {
    // if (isReservation && type === 'weekly') {
    //   return axios.get(
    //     '/properties/stay-monthly/' +
    //       propertyId +
    //       '?isWeekly=true&isReservationForm=true'
    //   );
    // }
    if (isReservation) {
      return axios.get(
        '/properties/stay-monthly/' +
          propertyId +
          '?type=' +
          type +
          '&isReservationForm=true'
      );
    }
    return axios.get(
      '/properties/stay-monthly/' + propertyId + '?type=' + type
    );
    // if (isReservation) {
    //   return axios.get(
    //     '/properties/stay-monthly/' + propertyId + '?isReservationForm=true'
    //   );
    // }
    // return axios.get('/properties/stay-monthly/' + propertyId);
  },
  getPropertyByType(type) {
    return axios.get('/properties/by-type/' + type + '?limit=200');
  },
  postReservationStayMonthly(data) {
    return axios.post('/stay-monthly/reservation', data);
  },

  // get booking list (month)
  getBookingMonthList(email) {
    return axios.get('/stay-monthly/my-booking-list/' + email);
  },

  // review
  postReview(data) {
    return axios.post('/review', data);
  },
  findReview(email, order_id, reservation_type) {
    return axios.get(
      '/review?email=' +
        email +
        '&order_id=' +
        order_id +
        '&reservation_type=' +
        reservation_type
    );
  },
  getReviewByPropertyId(propertyId) {
    return axios.get('/review/' + propertyId);
  },
  findPromoNusalink() {
    return axios.get('/promo/nusalink');
  },
  getAllPromo(roomId, isLogin) {
    return axios.get('/promo/all?roomId=' + roomId + '&isLogin=' + isLogin);
  },
  getPromoByPropertyId(propertyId, isLogin, userId) {
    userId = userId ? '&userId=' + userId : '';
    const result = axios.get(
      '/promo/by-property/' + propertyId + '?isLogin=' + isLogin + userId
    );
    return result;
  },
  findVoucherActive(id) {
    return axios.get('/voucher/active/' + id);
  },
  findGiveAway(id) {
    return axios.get('/give-away/' + id);
  },
  checkReferralCode(referral_code, userId) {
    return axios.get('/promo/referral/check/' + referral_code + '/' + userId);
  },
  getPromoReferralForReferrer(userId) {
    return axios.get('/promo/referral/for-referrer/' + userId);
  },
  getTouristAttractions(all = false, isRandom = false) {
    if (all)
      return axios.get('/tourist-attractions/all', {
        params: {
          isRandom: isRandom ? 1 : 0
        }
      });
    return axios.get('/tourist-attractions', {
      params: {
        isRandom: isRandom ? 1 : 0
      }
    });
  },
  findTouristAttractions(id) {
    return axios.get('/tourist-attractions/' + id);
  },
  getPropertyByTouristAttractionId(id) {
    return axios.get('/properties/by-tourist-attraction/' + id);
  },

  findPopupPromo() {
    return axios.get('/promo/popup');
  },

  getVoucherHomeAll() {
    return axios.get('/voucher/all');
  },
  getOtherVouchers() {
    return axios.get('/voucher/others');
  },

  // restaurants
  getRestaurantContent(id) {
    return axios.get('/restaurants/content/' + id);
  },
  getRestaurantProductsByType(restoId, typeId) {
    return axios.get(`/restaurants/products/${restoId}/${typeId}`);
  },
  getRestaurantProductDetail(productId) {
    return axios.get(`/restaurants/content/detail/${productId}`);
  }
};

export default ApiCall;
