import React, { Component } from 'react';

class Row extends Component {
  render() {
    return (
      <tr style={{ fontWeight: this.props.bold ? 'bold' : 'normal' }}>
        <td>{this.props.text[0]}</td>
        <td colSpan="2" className="has-text-right" style={{ fontSize: 14 }}>
          {this.props.text[1]}
        </td>
      </tr>
    );
  }
}

export default Row;
