import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translatable } from 'react-multilingual';
import './Footer.css';
import { toggleModal } from '../../redux/actions';
import ApiCall from '../../services/ApiCall';
import StringHelper from '../../utils/StringHelper';
import FacebookIcon from '../Icons/FacebookIcon';
import TwitterIcon from '../Icons/TwitterIcon';
import InstagramIcon from '../Icons/InstagramIcon';
import { APPSTORE_LINK, PLAYSTORE_LINK } from '../../constants';
import TiktokIcon from '../Icons/TiktokIcon'
import SnackVideoIcon from '../Icons/SnackVideoIcon'

class Footer extends Component {
  constructor(props) {
    super(props);

    this.isLoading = React.createRef(false);

    this.state = {
      lang: 'id',
      toggleMenu: false,
      promo: null
    };
  }
  async componentDidMount() {
    try {
      const response = await ApiCall.findPromoEvent();
      if (response.data.status === 'success') {
        this.setState({
          promo: response.data.data
        });
      }
    } catch (error) {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.app !== this.props.app) {
      if (StringHelper.isFilled(this.props.app.user)) {
        // console.log(this.props.app);
      }
    }
  }

  closeHeader = () => {
    if (this.props.app.toggleMenu === true) {
      this.props.toggleModal('toggleMenu');
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div onClick={this.closeHeader}>
        <div className="footer">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-3 footer-about mobilefooter">
                <b>Nusalink</b>
                <ul className="titlefooter">
                  <li style={{ marginTop: 10 }}>
                    <Link to="/about">{t.about}</Link>
                  </li>
                  <li style={{ marginTop: 10 }}>
                    <Link to="/terms-and-conditions">{t.term}</Link>
                  </li>
                  <li style={{ marginTop: 10 }}>
                    <Link to="/privacy-policy">{t.privacyp}</Link>
                  </li>
                </ul>
              </div>
              <div className="column is-3 mobilefooter">
                <b>{t.customer}</b>
                <ul className="titlefooter">
                  {/* <li style={{ marginTop: 10 }}>
                    <a href="tel:021-22563798">(021) 22563798</a>
                  </li>
                  <li style={{ marginTop: 10 }}>
                    <a href="tel:+628 121 855 557">+628 121 855 557</a>
                  </li> */}
                  <li style={{ marginTop: 10 }}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:cs@nusalink.asia"
                    >
                      cs@nusalink.asia
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column is-3 mobilefooter">
                <b style={{ fontSize: 18 }}>{t.paymentMethod}</b>
                <div
                  className="titlefooter"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/shopeepay.png')}
                      alt="Shopee Pay"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/akulaku.png')}
                      alt="Aku Laku"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/gopay.png')}
                      alt="Gopay"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/ovo.png')}
                      alt="OVO"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/kredivo.png')}
                      alt="Kredivo"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/dana.png')}
                      alt="Dana"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/bri.png')}
                      alt="BRI"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/bni.png')}
                      alt="BNI"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/mandiri.png')}
                      alt="Bank Mandiri"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/bca.png')}
                      alt="BCA"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/permata.png')}
                      alt="Bank Permata"
                    />
                  </div>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <img
                      style={{ width: '100%', maxWidth: '80px' }}
                      src={require('./../../assets/banks/new/alfagroup.png')}
                      alt="Alfa Group"
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3 mobilefooter">
                <b style={{ fontSize: 18 }}>{t.folow}</b>
                <div className="columns titlefooter">
                  <div className="column is-12">
                    <a
                      className=""
                      style={{ marginRight: 10 }}
                      href="https://www.facebook.com/profile.php?id=100086213813088"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      className=""
                      style={{ marginRight: 10 }}
                      href="https://www.twitter.com/nusalinkasia"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      className=""
                      href="https://www.instagram.com/nusalinkasia"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ marginRight: 10 }}
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      className=""
                      href="https://www.tiktok.com/@yuktravel"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ marginRight: 10 }}
                    >
                      <TiktokIcon />
                    </a>
                    <a
                      className=""
                      href="https://sck.io/u/@Nusalink.asia"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <SnackVideoIcon />
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-12">
                <img
                  onClick={() => {
                    window.open(PLAYSTORE_LINK);
                  }}
                  style={{ maxWidth: 150, cursor: 'pointer' }}
                  src={require('./../../assets/others/get-it-on-google-play.png')}
                  alt="Get It On Google Play"
                />
                <img
                  onClick={() => {
                    window.open(APPSTORE_LINK);
                  }}
                  style={{ maxHeight: 51, cursor: 'pointer' }}
                  src={require('./../../assets/others/appstore3.png')}
                  alt="Download on AppStore"
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.promo !== null && (
          <div
            style={{
              position: 'fixed',
              bottom: 50,
              right: 20,
              visibility:
                window.location.pathname ===
                '/voucher-event/' + this.state.promo.id
                  ? 'hidden'
                  : 'visible'
            }}
            onClick={() => {
              if (StringHelper.isFilled(this.props.app.user)) {
                const url = '/voucher-event/' + this.state.promo.id;
                this.props.history.push(url);
              } else {
                toast.error('Anda harus masuk terlebih dahulu');
                // alert('Anda harus masuk terlebih dahulu');
              }
              // window.location.href = url;
            }}
          >
            <img
              style={{ width: 150, cursor: 'pointer' }}
              src={this.state.promo.icon_url}
              alt={'Event ' + this.state.promo.event_type}
            />
          </div>
        )}

        <div className="wa-cs-container">
          <div
            className="wa-cs"
            style={{ marginRight: 10 }}
            onClick={() => {
              window.open('https://wa.me/628121855557');
            }}
          >
            {/* <i className="fab fa-whatsapp" /> Customer Service */}
            <img
              src={require('./../../assets/others/wa-putih.png')}
              alt="WA Customer Service Nusalink"
            />
            CS 1
          </div>

          <div
            className="wa-cs"
            onClick={() => {
              window.open('https://wa.me/628111518557');
            }}
          >
            {/* <i className="fab fa-whatsapp" /> Customer Service */}
            <img
              src={require('./../../assets/others/wa-putih.png')}
              alt="WA Customer Service 2 Nusalink"
            />
            CS 2
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { app } = state;

  return { app };
};

const mapTranslationsToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, { toggleModal });
const translator = translatable(mapTranslationsToProps);

export default translator(connector(Footer));
