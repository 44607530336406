import React from 'react';
import { connect } from 'react-redux';
import { COLOR_PRIMARY } from '../../constants';
import { moneyFormat } from '../../utils/helper';
import ExampleImage from './../../assets/example2.jpg';

function MenuItem({ variant = 'promo', history, menu, app }) {
  function onClick(e) {
    history.push('/restaurants/detail/' + menu.id);
  }

  // console.log(app);
  // const isEn = app.lang === 'en';
  // console.log(menu.images);

  return (
    <div
      className="box boxheight"
      style={{
        padding: 0,
        borderRadius: 4,
        paddingBottom: 10
      }}
    >
      <div className="columns" style={{ marginBottom: -15 }}>
        <div className="column is-12" style={{ paddingTop: 0 }}>
          <div className="slide-item">
            <figure image="true" is-4by3="true">
              <img
                className="images-galery imgVillasList"
                src={
                  typeof menu.images !== 'undefined' && menu.images.length > 0
                    ? menu.images[0].image || ExampleImage
                    : ExampleImage
                }
                style={{ marginBottom: 0, objectFit: 'cover' }}
                alt=""
                onClick={onClick}
              />
            </figure>
          </div>
        </div>
      </div>
      <div className="columns kolomkotanama" onClick={onClick}>
        {variant === 'promo' ? (
          <div className="column is-12">
            <h5 style={{ color: '#494949', fontWeight: 'bold' }}>
              {menu.name || 'Nama Menu'}
            </h5>
            <div style={{ color: '#797979', fontSize: 12 }}>
              {menu.short_desc || 'Deskripsi pendek'}
            </div>
            <div style={{ color: '#656565', fontSize: 12 }}>
              {moneyFormat(
                menu.price || menu.promo_price || 5000,
                app.lang,
                true
              )}
            </div>
            <div
              style={{
                color: COLOR_PRIMARY,
                fontWeight: 'bold',
                fontSize: 24,
                paddingBottom: 6
              }}
            >
              {moneyFormat(menu.promo_price || 1000, app.lang, true)}
            </div>
          </div>
        ) : variant === 'recomended' ? (
          <div className="column is-12">
            <div className="d-flex justify-content-between align-items-center py-2">
              <h5 style={{ color: '#494949', fontWeight: 'bold' }}>
                {menu.name || 'Paket Makan Kenyang!'}
              </h5>
            </div>
            <div
              style={{
                color: '#FFBA33',
                fontWeight: 'bold',
                fontSize: 20,
                paddingBottom: 6
              }}
            >
              {moneyFormat(
                (menu.promo_price > 0 ? menu.promo_price : menu.price) || 30000,
                app.lang,
                true
              )}
            </div>
          </div>
        ) : (
          <div className="column is-12">
            <div className="d-flex justify-content-between align-items-center py-2">
              <h5 style={{ color: '#494949', fontWeight: 'bold' }}>
                {menu.name || 'Nama Menu'}
              </h5>
              <div
                style={{
                  color: COLOR_PRIMARY,
                  fontWeight: 'bold',
                  fontSize: 20,
                  paddingBottom: 6
                }}
              >
                {moneyFormat(
                  (menu.promo_price > 0 ? menu.promo_price : menu.price) ||
                    30000,
                  app.lang,
                  true
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { app } = state;
  return { app };
};

const connector = connect(mapStateToProps, {});

export default connector(MenuItem);
