import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';
import Loader from '../../components/Loader/Loader';
import PropertyItem from '../../components/PropertyItem/PropertyItem';
import { BASE_URL_ADM } from '../../constants';
import ApiCall from '../../services/ApiCall';

export class StayMonthly extends Component {
  state = {
    properties: [],
    loading: true
  };

  constructor(props) {
    super(props);

    this.goToDetail = this.goToDetail.bind(this);
  }

  componentDidMount() {
    let type = 'monthly';
    if (this.props.isWeekly) {
      type = 'weekly';
    } else if (this.props.isYearly) {
      type = 'yearly';
    }
    // if (type === 'yearly') {
    ApiCall.getLongStayProperties(type)
      .then(res => {
        this.setState({
          properties: res.data.data
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
    // } else {
    //   ApiCall.getPropertyStayMonthly(type)
    //     .then(res => {
    //       this.setState({
    //         properties: res.data.data
    //       });
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.setState({
    //         loading: false
    //       });
    //     });
    // }
  }

  goToDetail(e, propertyId) {
    // console.log(e, propertyId);
    let url = '/stay-monthly/' + propertyId;
    if (this.props.isWeekly) {
      url = '/stay-weekly/' + propertyId;
    } else if (this.props.isYearly) {
      url = '/stay-yearly/' + propertyId;
    }
    this.props.history.push(url);
    // window.location = url;
  }

  render() {
    const { properties } = this.state;
    const { t } = this.props;
    return [
      <section
        key="header"
        style={{
          backgroundColor: '#F3722B',
          backgroundImage: `url('/images/home_bg.png')`,
          backgroundSize: '40% 100%',
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container py-3">
          <div className="py-5">
            <h1 style={{ fontSize: 36, color: 'white', fontWeight: 'bold' }}>
              {this.props.isWeekly
                ? t.stayWeekly
                : this.props.isYearly
                ? t.stayYearly
                : t.stayMonthly}
            </h1>
            <h2 style={{ color: 'white' }}>
              Temukan berbagai penginapan menarik untuk menemani liburan
              panjangmu!
            </h2>
          </div>
        </div>
      </section>,
      <section
        key="body"
        className="section2 inner box-list"
        style={{ backgroundColor: '#f8f8f8' }}
      >
        <div className="container">
          <div className="columns is-multiline long">
            <div className="column is-12">
              <h1 className="is-size-4">
                {/* {this.props.isWeekly
                  ? t.stayWeekly
                  : this.props.isYearly
                  ? t.stayYearly
                  : t.stayMonthly} */}
                Popular Hotel
              </h1>
              <hr />
            </div>
            {properties.length > 0 &&
              properties.map((item, index) => (
                <PropertyItem
                  per={
                    this.props.isWeekly
                      ? 'week'
                      : this.props.isYearly
                      ? 'year'
                      : 'month'
                  }
                  onClick={this.goToDetail}
                  property={item}
                  key={item.id + item.name}
                />
              ))}
            {!properties && (
              <div className="column is-12">
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
            {!this.state.loading && properties.length === 0 && (
              <div className="column is-12">
                <div className="has-text-centered">
                  <img
                    src={`${BASE_URL_ADM}/images/Not_Found.png`}
                    alt=""
                    width="500"
                    height="500"
                  />
                </div>
                <br />
                <center>
                  <h4>{t.hotelNotFound}</h4>
                </center>
              </div>
            )}
            {this.state.loading && (
              <Loader key="loader" loading={this.state.loading} />
            )}
          </div>
        </div>
      </section>
    ];
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);

export default translator(StayMonthly);
