import React, { Component } from 'react';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Numeral from 'numeral';
import queryString from '../../utils/queryString';
import { BASE_URL_ADM } from '../../constants.js';
import { setBoxFilterDetail } from '../../redux/actions';

import './similar.css';
import StartFrom from '../../pages/layouts/StartFrom';
import PayAtProperty from '../others/PayAtProperty';

class Similar extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      klikAccess: false,
      similar: [],
      loading: true,
      guest: '',
      awal: '',
      klikSimilar: false,
      duration: ''
    };
  }

  componentWillMount() {
    // const paramsString = queryString.parse(this.props.history.location.search)
    // if (paramsString.guest !== undefined){
    // 	console.log(paramsString.guest, 'PARAMS')
    // 	 this.props.setBoxFilterDetail(true)
    // }
    // else{
    // 		this.props.setBoxFilterDetail(false)
    // }
    // window.onpopstate =  (event, history) => {
    // window.location.reload()
    // }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    const paramsString = queryString.parse(this.props.history.location.search);
    window.scrollTo(0, 0);
    this.setState({
      duration: paramsString.duration
    });
  }

  // componentDidUpdate() {
  // 	this.state.loading = false
  //
  // }

  gotodetail = (e, hotelid, action) => {
    window.location = `/detail-villas?villas=${hotelid}`;
    // return;
    // window.scrollTo(0, 0);
    // // window.location.reload();
    // this.props.setBoxFilterDetail(false);
    // this.props.history.push(`/detail-villas?villas=${hotelid}`);
  };

  klikAccess = (e, action) => {
    if (action === 'show') {
      this.setState({
        klikAccess: true
      });
    } else {
      this.setState({
        klikAccess: false
      });
    }
  };

  toTitleCase = str => {
    let _string = str.replace('KOTA', '');
    _string = _string.replace('KABUPATEN', '');
    return _string.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  render() {
    const { t } = this.props;
    // console.log(this.props.app, "BOX");
    const settingsResponsive = {
      dots: false,
      infinite: false,
      speed: 500,
      className: 'center',
      centerPadding: '30px',
      centerMode: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        }
      ]
    };

    const settingImage = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: false,
      swipe: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      initialSlide: 0
    };
    return (
      <div>
        <div className="column is-12 boxSimilar desktops">
          <Slider {...settings}>
            {this.props.similarP &&
              this.props.similarP !== '' &&
              this.props.similarP.map((items, index) => (
                <div className="column is-12" key={index.toString()}>
                  <div
                    className="boxheight boxShadow box"
                    style={{ padding: 0, borderRadius: 4, paddingBottom: 10 }}
                  >
                    <div className="columns ">
                      <div
                        className="column is-12 gambarAja"
                        style={{ paddingTop: 0, marginTop: 12 }}
                      >
                        {items.payAtVilla === 1 && <PayAtProperty />}
                        <Slider {...settingImage}>
                          <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[0].images}`}
                            onClick={e => this.gotodetail(e, items.id, 'show')}
                          />
                          {/* <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[1].images}`}
                            onClick={e => this.gotodetail(e, items.id, 'show')}
                          />
                          <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[2].images}`}
                            onClick={e => this.gotodetail(e, items.id, 'show')}
                          /> */}
                        </Slider>
                      </div>
                    </div>
                    <div className="columns kolomkotanama">
                      <div className="column is-12">
                        <div
                          className="columns is-mobile"
                          style={{ marginBottom: -5 }}
                        >
                          <div className="column is-12 boxKonten180913">
                            <label
                              onClick={e => this.gotodetail(e, items.id)}
                              className="labelTypeVilla180913"
                            >
                              {items.category_name}
                            </label>
                            <br />
                            <div
                              onClick={e => this.gotodetail(e, items.id)}
                              className="labelCityName180913"
                            >
                              <img
                                src="/icon/point-location.png"
                                alt="Nusalink"
                                className="iconLocationVilla180913"
                              />
                              <label className="labelAddress180914">
                                {items.VillaLocations.length > 0
                                  ? this.toTitleCase(
                                      items.VillaLocations[0].nama_kota
                                    ) +
                                    ', ' +
                                    this.toTitleCase(
                                      items.VillaLocations[0].nama_provinsi
                                    )
                                  : ''}
                              </label>
                            </div>
                          </div>
                        </div>
                        <b
                          className="kolomnama labelNameVilla180914"
                          onClick={e => this.gotodetail(e, items.id)}
                        >
                          {/*(items.name).length > 27 ? (items.name).substring(0, 22) + '...' : items.name*/}
                          {items.name}
                        </b>
                      </div>
                    </div>
                    <div
                      className="columns"
                      onClick={e => this.gotodetail(e, items.id)}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <div className="column is-12 kolomharga">
                        <span style={{ fontSize: 14, color: 'black' }}>
                          <p>
                            <label>
                              <StartFrom />
                              {t.currency}{' '}
                              {Numeral(Math.floor(items.low_price)).format(
                                '0,0'
                              )}
                              {t.pernight}
                            </label>
                            {items.round === 1 ? (
                              <span
                                className="icon has-text-info popup"
                                style={{ marginLeft: 10, marginBottom: -5 }}
                                onClick={e => this.showpopup(e, index)}
                              >
                                <i>
                                  <figure className="image is-24x24">
                                    <img src="/icon/info.png" alt="Nusalink" />
                                  </figure>
                                </i>
                                <span className="popuptext" id={index}>
                                  <p style={{ marginLeft: 5, marginRight: 5 }}>
                                    {t.average}
                                  </p>
                                </span>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
        {/* mobile */}

        <div className="container is-fullhd boxSimilar mobiles">
          <Slider {...settingsResponsive}>
            {this.props.similarP &&
              this.props.similarP !== '' &&
              this.props.similarP.map((items, index) => (
                <div
                  className="column is-12 similarRespon"
                  key={index.toString()}
                >
                  <div
                    className="box boxheight"
                    style={{ padding: 0, borderRadius: 4, marginTop: 45 }}
                  >
                    <div className="columns">
                      <div
                        className="column is-12 gambarAja"
                        style={{ marginTop: -10 }}
                      >
                        {items.payAtVilla === 1 && <PayAtProperty />}
                        <Slider {...settingImage}>
                          <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[0].images}`}
                          />
                          {/* <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[1].images}`}
                          />
                          <img
                            alt=""
                            className="gambarSimilar"
                            src={`${BASE_URL_ADM}/images/hotel/${items.imagesvillas[2].images}`}
                          /> */}
                        </Slider>
                      </div>
                    </div>
                    <div className="columns kolomkotanama">
                      <div className="column is-12">
                        <div
                          className="columns is-mobile"
                          style={{ marginBottom: -5 }}
                        >
                          <div className="column is-12 boxKonten180913">
                            <label
                              onClick={e => this.gotodetail(e, items.id)}
                              className="labelTypeVilla180913"
                            >
                              {items.category_name}
                            </label>
                            <br />
                            <div
                              onClick={e => this.gotodetail(e, items.id)}
                              className="labelCityName180913"
                            >
                              <img
                                src="/icon/point-location.png"
                                alt="Nusalink"
                                className="iconLocationVilla180913"
                              />
                              <label className="labelAddress180914">
                                {items.VillaLocations.length > 0
                                  ? this.toTitleCase(
                                      items.VillaLocations[0].nama_kota
                                    ) +
                                    ', ' +
                                    this.toTitleCase(
                                      items.VillaLocations[0].nama_provinsi
                                    )
                                  : ''}
                              </label>
                            </div>
                          </div>
                        </div>
                        <b
                          className="kolomnama labelNameVilla180914"
                          onClick={e => this.gotodetail(e, items.id)}
                        >
                          {/*(items.name).length > 27 ? (items.name).substring(0, 22) + '...' : items.name*/}
                          {items.name}
                        </b>
                      </div>
                    </div>
                    <div
                      className="columns"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <div className="column is-12 kolomharga">
                        <span style={{ fontSize: 14, color: 'black' }}>
                          <p>
                            <label>
                              <StartFrom />
                              {t.currency}{' '}
                              {Numeral(Math.floor(items.low_price)).format(
                                '0,0'
                              )}
                              {t.pernight}
                            </label>
                            {items.round === 1 ? (
                              <span
                                className="icon has-text-info popup"
                                style={{ marginLeft: 10, marginBottom: -5 }}
                                onClick={e => this.showpopup(e, index)}
                              >
                                <i>
                                  <figure className="image is-24x24">
                                    <img src="/icon/info.png" alt="Nusalink" />
                                  </figure>
                                </i>
                                <span className="popuptext" id={index}>
                                  <p style={{ marginLeft: 5, marginRight: 5 }}>
                                    {t.average}
                                  </p>
                                </span>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(mapStateToProps, { setBoxFilterDetail });

const translator = translatable(translateToProps);
export default translator(connector(Similar));
