import React from 'react';
import { BASE_URL_ADM } from '../../constants';

const NotFound = () => {
  return (
    <div className="column is-12">
      <div className="has-text-centered">
        <img
          src={`${BASE_URL_ADM}/images/Not_Found.png`}
          alt="Not Found"
          width="500"
          height="500"
        />
      </div>
      {/* <br />
      <center><h4>{t.hotelNotFound}</h4></center> */}
    </div>
  );
};

export default NotFound;
