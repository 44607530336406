import React from 'react';
import { IconTopUpPLN } from '../../assets/images/icons/topups';

function PLN({ width = 40, height = 40 }) {
  return <img src={IconTopUpPLN} />;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="8" fill="url(#paint0_linear)" />
      <path
        d="M13.25 21.1246L14.125 16.7496L10.625 15.4371L16.75 8.87457L15.875 13.2496L19.375 14.5621L13.25 21.1246Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15"
          y1="5.71429"
          x2="15"
          y2="41.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F77E3C" />
          <stop offset="1" stopColor="#FF9B64" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PLN;
