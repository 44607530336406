import React, { Component } from 'react';
import SelectOption from '../../components/form/SelectOption';
import TopUpApi from '../../services/api/TopUpApi';

class MyTopUpList extends Component {
  state = {
    transaction_type: 'credits',
    all_transactions: null,
    active_transactions: [],
    isLoaded: false
  };

  async getData() {
    try {
      if (this.props.isLoaded === false) {
        const response = await TopUpApi.getMyTransactions(this.props.userId);
        if (response.data.status === 'success') {
          this.setState((state) => {
            const data = {
              active_transactions: response.data.data[state.transaction_type],
              all_transactions: response.data.data,
              isLoaded: true
            };
            this.props.onComplete && this.props.onComplete(data);
            return data
          });

        }
      }
      // console.log(response.data);
    } catch (error) {
      this.setState({
        isLoaded: true
      })
      console.log(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.getData();
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(this.props.data);
    } else if (this.props.userId) this.getData();
  }

  renderView = () => {
    if(this.state.isLoaded === false) {
      return <div>Loading...</div>
    }

    if(this.state.active_transactions.length === 0) {
      return <div>Data not found</div>
    }

    return (
      <div
        className="table-container"
        style={{ overflowX: 'auto', maxHeight: 650, overflowY: 'auto' }}
      >
        <table className="table is-bordered is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>Order Number</th>
            <th>Buy At</th>
            <th>Product Name</th>
            {(this.state.transaction_type === 'credits' ||
              this.state.transaction_type === 'data_packages' ||
              this.state.transaction_type === 'ewallets') && (
              <th>Phone Number</th>
            )}
            {(this.state.transaction_type === 'credits' ||
              this.state.transaction_type === 'data_packages') && (
              <th>Provider</th>
            )}
            {this.state.transaction_type === 'pln_token' && [
              <th>PLN Number</th>,
              <th>Token</th>,
              <th>Admin Fee</th>
            ]}
            <th>Status</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          {this.state.active_transactions.map(item => (
            <tr key={item.id}>
              <td>{item.ref_id}</td>
              <td>{item.date}</td>
              <td>{item.product_name}</td>
              {(this.state.transaction_type === 'credits' ||
                this.state.transaction_type === 'data_packages' ||
                this.state.transaction_type === 'ewallets') && (
                <td>{item.phone_number}</td>
              )}
              {(this.state.transaction_type === 'credits' ||
                this.state.transaction_type === 'data_packages') && (
                <td>{item.provider}</td>
              )}
              {this.state.transaction_type === 'pln_token' && [
                <td>{item.pln_number}</td>,
                <td>{item.pln_token}</td>,
                <td>{item.admin_fee}</td>
              ]}
              <td>{item.status}</td>
              <td>{item.total}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )
  }


  render() {


    return (
      <div className="column is-8">
        <SelectOption
          label={'Pilih Jenis Transaksi'}
          onChange={e => {
            const transaction_type = e.target.value;
            if(this.state.all_transactions === null) {
              return this.setState({ transaction_type });
            }

            const active_transactions = this.state.all_transactions[
              transaction_type
              ];
            this.setState({ active_transactions, transaction_type });
          }}
        >
          <option value="credits">Pulsa</option>
          <option value="data_packages">Paket Data</option>
          <option value="pln_token">Token PLN</option>
          <option value="ewallets">E-Wallet</option>
          <option value="movie_tickets">Movie Ticket</option>
          <option value="emoneys">E-Money</option>
          <option value="voucher_games">Voucher Games</option>
        </SelectOption>
        {this.renderView()}
      </div>
    );
  }
}

export default MyTopUpList;
