import axios from 'axios';
import { BASE_URL } from '../constants'

axios.defaults.baseURL = BASE_URL + '/v1'
axios.defaults.headers.common['App-Key'] = 'nusanlinkfor1ndonesi4'

function register(data) {
	return axios.post('/register', data)
}

function login(data) {
	return axios.post('/login', data)
}

function forgotPassword(data) {
	return axios.post('forgot-password', data)
}

function resetPassword(data) {
	return axios.post('reset-password', data)
}

const Auth = {
	register,
	login,
	forgotPassword,
	resetPassword
}

export default Auth
