import ExampleImage from './assets/example.png';

const url = {
  production: 'https://api.nusalink.asia',
  staging: 'https://staging2-api.nusalink.asia',
  development: 'http://localhost:1100'
  // development: 'https://api.nusalink.asia'
};

const urladmin = {
  production: 'https://internal.nusalink.asia',
  staging: 'https://internal.nusalink.asia',
  // development: 'http://nusantech-nusalink-admin.test',
  development: 'https://internal.nusalink.asia'
};

const apisosialgoogle = {
  // production:
  //   "266570232098-3ie8isrofe2npmu3scgcue8deprr0bjd.apps.googleusercontent.com",
  // staging:
  //   "266570232098-3ie8isrofe2npmu3scgcue8deprr0bjd.apps.googleusercontent.com",
  // development:
  //   "266570232098-3ie8isrofe2npmu3scgcue8deprr0bjd.apps.googleusercontent.com",
  production:
    '27489163277-a8drvob8pbcqtktldm1q5b1soqgnieuj.apps.googleusercontent.com',
  staging:
    '27489163277-a8drvob8pbcqtktldm1q5b1soqgnieuj.apps.googleusercontent.com',
  development:
    '27489163277-a8drvob8pbcqtktldm1q5b1soqgnieuj.apps.googleusercontent.com'
  // google

  // Client ID
  // 266570232098-3ie8isrofe2npmu3scgcue8deprr0bjd.apps.googleusercontent.com
  // Client secret
  // bJiuFmpup90WB3JCyHt6n1sS
  // old
  //production: '886016046971-s8l3socrr3vfla3kq05r3gbk6fhvnuf9.apps.googleusercontent.com',
  // development: '640716872602-m0u2b2huvqklumiv310cles9jrddvg48.apps.googleusercontent.com'
  //293418631242094
};

const apisosialfacebook = {
  // production: '174160430048883',
  // staging: '2237228983170873',
  // development: '2237228983170873'
  production: '582888135739058',
  staging: '582888135739058',
  development: '582888135739058'
  //   [17:46, 9/3/2020] Bang Niko (Nusantech): app id : 582888135739058
  // [17:47, 9/3/2020] Bang Niko (Nusantech): client token : 66388789accbf244262c734abe38e86b
  // app secret : 5806325348fb3c4f9c59a0188caabc11
};

const urllink = {
  production: 'https://www.nusalink.asia/',
  staging: 'https://staging2.nusalink.asia/',
  development: 'https://dev.nusalink.asia/'
};

const ENV = process.env.REACT_APP_BUILD_ENV || 'production';
const API_ENV = process.env.REACT_APP_BUILD_ENV || 'production';
// console.log('react env', ENV);
// console.log('api react env', API_ENV);

export const BASE_URL = url[API_ENV];
export const BASE_LINK = urllink[ENV];
export const BASE_URL_ADM = urladmin[ENV];
export const KEY_API_GOOGLE =
  '107092146368-eoh0cdqrs5ejqffakk2n37o9un1tht3u.apps.googleusercontent.com' ||
  apisosialgoogle[ENV];
// p5Soyyxf6VZRzMTauBKmVZvf;
export const KEY_API_FACEBOOK =
  '582888135739058' || process.env.FACEBOOK_APP_ID || apisosialfacebook[ENV];
// APP ID 582888135739058
// app secret 5806325348fb3c4f9c59a0188caabc11
let google_map_api_key = 'AIzaSyCiSlJuVQhfAy-4Uob4erlm-oA1mgkhJ30'; // punya anam
// if (ENV === "production")
google_map_api_key = 'AIzaSyBjoZuT4-s9y0oxuBR93sPKnZGTFir1qmg'; // aslinya
export const GOOGLE_MAP_API_KEY = google_map_api_key;
export const PublicFacilities = [
  'airport',
  'atm',
  'bank',
  'bus_station',
  'car_rental',
  'church',
  'city_hall',
  'courthouse',
  'dentist',
  'doctor',
  'embassy',
  'fire_station',
  'florist',
  'gym',
  'hindu_temple',
  'hospital',
  'laundry',
  'local_government_office',
  'meal_delivery',
  'mosque',
  'pharmacy',
  'police',
  'post_office',
  'subway_station',
  'taxi_stand',
  'train_station',
  'veterinary_care'
];
export const PublicAttraction = [
  'amusement_park',
  'aquarium',
  'art_gallery',
  'bar',
  'bowling_alley',
  'cafe',
  'campground',
  'casino',
  'department_store',
  'movie_theater',
  'museum',
  'night_club',
  'park',
  'restaurant',
  'shopping_mall',
  'spa',
  'stadium',
  'supermarket',
  'zoo'
];

export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  initialSlide: 0
};

export const dateIN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Agu',
  'Sep',
  'Okt',
  'Nop',
  'Des'
];

export const dateEN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const HOUR_ARRIVALS = [
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
];

export const COLORS = {
  primary: 'rgb(241, 103, 38)',
  grey: '#cfcfcf'
};

// export const IS_SHOW_MAP = process.env.REACT_APP_BUILD_ENV === 'production';
export const IS_SHOW_MAP = false;

export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  initialSlide: 0
};

export const stayMonthlyArray = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export const PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=asia.nusalink';
export const APPSTORE_LINK = `https://apps.apple.com/id/app/nusalink/id1565166175?l=id`;

export const GRADIENT_BG = `linear-gradient(90deg, rgba(243,114,43,1) 0%, rgba(255,135,68,1) 100%)`;
export const COLOR_PRIMARY = '#FA5900';

export const EXAMPLE_IMAGES = [
  {
    src: ExampleImage,
    alt: 'Image 1'
  },
  {
    src: ExampleImage,
    alt: 'Image 2'
  },
  {
    src: ExampleImage,
    alt: 'Image 3'
  },
  {
    src: ExampleImage,
    alt: 'Image 4'
  }
];

export const IS_MOBILE = window.innerWidth <= 425;
