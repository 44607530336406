import React, { Component } from 'react';
import { COLORS } from '../../constants';

export class LabelPrimary extends Component {
  render() {
    const onClick = this.props.onClick;
    return (
      <span
        onClick={onClick}
        style={{
          backgroundColor: COLORS.primary,
          color: 'white',
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: this.props.fontSize || 16,
          borderRadius: this.props.rounded ? 15 : 5,
          ...this.props.style,
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        {this.props.text}
      </span>
    );
  }
}

export default LabelPrimary;
