import React, { Component } from 'react';

class FormRadio extends Component {
  render() {
    return (
      <label className="containerpay">
        <input
          type="radio"
          checked={this.props.checked}
          name="radio"
          className="optionPay"
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <span className="checkmarkpay" /> <label>{this.props.label}</label>
      </label>
    );
  }
}

export default FormRadio;
