import React from 'react';
import './orange-btn.css';

function OrangeBtn({ onClick, label }) {
  return (
    <a onClick={onClick}>
      <div className="orange-btn">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75Z"
            stroke="white"
            strokeMiterlimit="10"
          />
          <path
            d="M9.19286 9.19328L11.1682 4.82385L6.80639 6.80674L5.01651 10.9831L9.19286 9.19328Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div style={{ color: 'white', marginLeft: 12 }}>{label}</div>
      </div>
    </a>
  );
}

export default OrangeBtn;
