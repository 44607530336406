import React from 'react';

function NusaCafe2Icon({ width = 40, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79004 1.28564V4.44752"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2842 1.28564V4.44752"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5269 1.28564V4.44752"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.80029 24.9995H24.5158"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42089 24.9997C6.44488 24.1317 4.7707 22.7393 3.59714 20.9878C2.42357 19.2363 1.79994 17.1993 1.80029 15.1187V9.19043H24.5158V15.1187C24.5161 17.1993 23.8925 19.2363 22.7189 20.9878C21.5453 22.7393 19.8711 24.1318 17.8951 24.9997"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0162 9.19043C26.2737 9.19043 27.4797 9.63459 28.3689 10.4252C29.2581 11.2158 29.7577 12.2881 29.7577 13.4062V14.4602C29.7577 15.5783 29.2581 16.6506 28.3689 17.4412C27.4797 18.2318 26.2737 18.676 25.0162 18.676H24.5156"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NusaCafe2Icon;
