import React, { Component } from 'react';
import StayMonthlyDetail from './StayMonthlyDetail';

class StayWeeklyDetail extends Component {
  render() {
    return (
      <StayMonthlyDetail
        history={this.props.history}
        propertyId={this.props.match.params.propertyId}
        isWeekly
      />
    );
  }
}

export default StayWeeklyDetail;
