import React from 'react';

export default function BathRoomIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 12.4688C16.25 6.5625 10 1.96875 10 1.96875C10 1.96875 3.75 6.5625 3.75 12.4688C3.75 14.2092 4.40848 15.8784 5.58058 17.1091C6.75269 18.3398 8.3424 19.0312 10 19.0312C11.6576 19.0312 13.2473 18.3398 14.4194 17.1091C15.5915 15.8784 16.25 14.2092 16.25 12.4688Z"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 12.4688C13.75 13.513 13.3549 14.5146 12.6517 15.253C11.9484 15.9914 10.9946 16.4062 10 16.4062"
        stroke="#F3722B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
