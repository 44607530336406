import React, { Component } from "react";
import { translatable } from "react-multilingual";
import { connect } from "react-redux";
// import { BASE_URL_ADM } from '../../constants'

import "./Rules.css";

class Rules extends Component {
  constructor(props, content) {
    super(props, content);

    this.state = {
      klikRules: false,
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  klikRules = (e, action) => {
    if (action === "show") {
      this.setState({
        klikRules: true,
      });
    } else {
      this.setState({
        klikRules: false,
      });
    }
  };
  render() {
    const { t, villaRules } = this.props;
    return (
      <div>
        {villaRules === undefined || villaRules === null ? (
          ""
        ) : (
          <div className="columns is-multiline">
            <div className="column is-12 is-multiline boxRules">
              <div className="iconRules">
                {/*<img src='/icon/checkinrules.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                <label>Check-in</label>
              </div>
              <div className="labelRules">
                {villaRules.checkin_second !== "" &&
                villaRules.checkin_second !== null ? (
                  <label>
                    {t.rulesBetween} {villaRules.checkin_first} {t.rulesAnd}{" "}
                    {villaRules.checkin_second}
                  </label>
                ) : (
                  <label>
                    {t.rulesAfter} {villaRules.checkin_first}
                  </label>
                )}
              </div>
            </div>
            <div className="column is-12 is-multiline boxRules">
              <div className="iconRules">
                {/*<img src='/icon/checkoutrules.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                <label>Check-out</label>
              </div>
              <div className="labelRules">
                {villaRules.checkout_first !== "" &&
                villaRules.checkout_first !== null ? (
                  <label>
                    {t.rulesBetween} {villaRules.checkout_first} {t.rulesAnd}{" "}
                    {villaRules.checkout_second}
                  </label>
                ) : (
                  <label>
                    {t.rulesBefore} {villaRules.checkout_second}
                  </label>
                )}
              </div>
            </div>
            <div className="column is-12 is-multiline boxRules">
              <div className="iconRules">
                {/*<img src='/icon/cancelnotif.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                <label>{t.rulesCancellaton}</label>
              </div>
              <div className="labelRules">
                {villaRules.day_cancel !== null ? (
                  <label>
                    {villaRules.day_cancel} {t.rulesCancel}
                  </label>
                ) : (
                  <label>{t.rulesNotCancellaton}</label>
                )}
              </div>
            </div>
            <div className="column is-12 is-multiline boxRules">
              <div className="iconRules">
                {/*<img src='/icon/refund.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                <label>Refund</label>
              </div>
              <div className="labelRules">
                {villaRules.refund_persent !== null ? (
                  <label>{villaRules.refund_persent}%</label>
                ) : (
                  <label>{t.rulesNoRefund}</label>
                )}
              </div>
            </div>
            {Math.floor(villaRules.no_smoking) === 1 ? (
              <div className="column is-12 is-multiline boxRules">
                <div className="iconRules">
                  {/*<img src='/icon/smoking.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                  <label>{t.rulesSmoking}</label>
                </div>
                <div className="labelRules">
                  <label>{t.rulesNotAllowed}</label>
                </div>
              </div>
            ) : (
              ""
            )}
            {Math.floor(villaRules.no_pet) === 1 ? (
              <div className="column is-12 is-multiline boxRules">
                <div className="iconRules">
                  {/*<img src='/icon/pets.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                  <label>{t.rulesPets}</label>
                </div>
                <div className="labelRules">
                  <label>{t.rulesNotAllowed}</label>
                </div>
              </div>
            ) : (
              ""
            )}
            {Math.floor(villaRules.no_party) === 1 ? (
              <div className="column is-12 is-multiline boxRules">
                <div className="iconRules">
                  {/*<img src='/icon/parties.png' width="20" alt="" style={{marginBottom: -5}} />*/}
                  <label>{t.rulesParties}</label>
                </div>
                <div className="labelRules">
                  <label>{t.rulesNotAllowed}</label>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="column is-12 is-multiline">
              {villaRules.other_rules !== "" ? (
                <a
                  className="read-more"
                  onClick={(e) => this.klikRules(e, "show")}
                >
                  {" "}
                  {t.showallrules} >{" "}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        <div className={"modal " + (this.state.klikRules ? "is-active" : "")}>
          <div
            className="modal-background"
            style={{ background: "white", paddingTop: 90 }}
            onClick={(e) => this.klikRules(e, "hidden")}
          />
          <div
            className="btnClose"
            onClick={(e) => this.klikRules(e, "hidden")}
          />
          <div
            className="modal-content boxModalRules"
            style={{
              width: 500,
              background: "white",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <div className="box-ruless">
              <div className="columns is-multiline">
                <div className="column is-12 is-multiline rulesDetail">
                  <label className="headerModalRules">{t.rules}</label>
                </div>
                <div className="column is-12 is-multiline">
                  {villaRules === undefined || villaRules === null ? (
                    ""
                  ) : (
                    <div className="columns is-multiline">
                      <div className="column is-12 is-multiline boxRulesRes">
                        <div className="iconRulesRes">
                          <img
                            src="/icon/checkinrules.png"
                            width="20"
                            alt=""
                            style={{ marginBottom: -5 }}
                          />
                          <label>Check-in</label>
                        </div>
                        <div className="labelRulesRes">
                          {villaRules.checkin_second !== "" &&
                          villaRules.checkin_second !== null ? (
                            <label>
                              {t.rulesBetween} {villaRules.checkin_first}{" "}
                              {t.rulesAnd} {villaRules.checkin_second}
                            </label>
                          ) : (
                            <label>
                              {t.rulesAfter} {villaRules.checkin_first}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="column is-12 is-multiline boxRulesRes">
                        <div className="iconRulesRes">
                          <img
                            src="/icon/checkoutrules.png"
                            width="20"
                            alt=""
                            style={{ marginBottom: -5 }}
                          />
                          <label>Check-out</label>
                        </div>
                        <div className="labelRulesRes">
                          {villaRules.checkout_first !== "" &&
                          villaRules.checkout_first !== null ? (
                            <label>
                              {t.rulesBetween} {villaRules.checkout_first}{" "}
                              {t.rulesAnd} {villaRules.checkout_second}
                            </label>
                          ) : (
                            <label>
                              {t.rulesBefore} {villaRules.checkout_second}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="column is-12 is-multiline boxRulesRes">
                        <div className="iconRulesRes">
                          <img
                            src="/icon/cancelnotif.png"
                            width="20"
                            alt=""
                            style={{ marginBottom: -5 }}
                          />
                          <label>{t.rulesCancellaton}</label>
                        </div>
                        <div className="labelRulesRes">
                          {villaRules.day_cancel !== null ? (
                            <label>
                              {villaRules.day_cancel} {t.rulesCancel}
                            </label>
                          ) : (
                            <label>{t.rulesNotCancellaton}</label>
                          )}
                        </div>
                      </div>
                      <div className="column is-12 is-multiline boxRulesRes">
                        <div className="iconRulesRes">
                          <img
                            src="/icon/refund.png"
                            width="20"
                            alt=""
                            style={{ marginBottom: -5 }}
                          />
                          <label>Refund</label>
                        </div>
                        <div className="labelRulesRes">
                          {villaRules.refund_persent !== null ? (
                            <label>{villaRules.refund_persent}%</label>
                          ) : (
                            <label>{t.rulesNoRefund}</label>
                          )}
                        </div>
                      </div>
                      {Math.floor(villaRules.no_smoking) === 1 ? (
                        <div className="column is-12 is-multiline boxRulesRes">
                          <div className="iconRulesRes">
                            <img
                              src="/icon/smoking.png"
                              width="20"
                              alt=""
                              style={{ marginBottom: -5 }}
                            />
                            <label>{t.rulesSmoking}</label>
                          </div>
                          <div className="labelRulesRes">
                            <label>{t.rulesNotAllowed}</label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {Math.floor(villaRules.no_pet) === 1 ? (
                        <div className="column is-12 is-multiline boxRulesRes">
                          <div className="iconRulesRes">
                            <img
                              src="/icon/pets.png"
                              width="20"
                              alt=""
                              style={{ marginBottom: -5 }}
                            />
                            <label>{t.rulesPets}</label>
                          </div>
                          <div className="labelRulesRes">
                            <label>{t.rulesNotAllowed}</label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {Math.floor(villaRules.no_party) === 1 ? (
                        <div className="column is-12 is-multiline boxRulesRes">
                          <div className="iconRulesRes">
                            <img
                              src="/icon/parties.png"
                              width="20"
                              alt=""
                              style={{ marginBottom: -5 }}
                            />
                            <label>{t.rulesParties}</label>
                          </div>
                          <div className="labelRulesRes">
                            <label>{t.rulesNotAllowed}</label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div className="column is-12 is-multiline desRules">
                  {villaRules === undefined || villaRules === null ? (
                    ""
                  ) : (
                    <div>
                      {villaRules.other_rules !== "" ? (
                        <p
                          style={{ fontSize: 12 }}
                          align="justify"
                          dangerouslySetInnerHTML={{
                            __html: String(villaRules.other_rules).replace(
                              /\n/g,
                              "<br>"
                            ),
                          }}
                        ></p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <br />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={(e) => this.klikRules(e, "hidden")}
          />
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  const { app } = state;
  return { app };
};
const translateToProps = ({ t }) => ({ t });

const connector = connect(StateToProps, {});
const translator = translatable(translateToProps);
export default translator(connector(Rules));
