import React from 'react';
import { IconTopUpPulsa } from '../../assets/images/icons/topups';

function Pulsa({ width = 40, height = 40 }) {
  return <img src={IconTopUpPulsa} />;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="8" fill="url(#paint0_linear)" />
      <path
        d="M16.5314 12.5147C15.2056 12.0598 13.7654 12.0631 12.4417 12.524C12.3454 12.5584 12.2598 12.6172 12.1932 12.6947C12.1266 12.7722 12.0814 12.8656 12.0619 12.9659L11.6241 15.156C11.6048 15.2528 11.5616 15.3433 11.4984 15.4193C11.4352 15.4952 11.3541 15.5542 11.2624 15.5909L7.75094 16.9955C7.63197 17.0435 7.50062 17.0516 7.37666 17.0184C7.25271 16.9853 7.14288 16.9128 7.06371 16.8119C6.53347 16.1262 6.27131 15.2708 6.32634 14.4058C6.38136 13.5408 6.74979 12.7256 7.36264 12.1126C9.25559 10.2197 11.823 9.15625 14.5 9.15625C17.177 9.15625 19.7444 10.2197 21.6374 12.1126C22.2502 12.7256 22.6186 13.5408 22.6737 14.4058C22.7287 15.2708 22.4665 16.1262 21.9363 16.8119C21.8571 16.9128 21.7473 16.9853 21.6233 17.0184C21.4994 17.0516 21.368 17.0435 21.2491 16.9955L17.7345 15.5896C17.6436 15.5533 17.5631 15.495 17.5002 15.42C17.4372 15.345 17.3938 15.2556 17.3738 15.1597L16.9129 12.9526C16.8926 12.8529 16.8468 12.7601 16.7799 12.6833C16.7131 12.6066 16.6275 12.5485 16.5314 12.5147Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96875 19.8438H21.0312"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15"
          y1="5.71429"
          x2="15"
          y2="41.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F77E3C" />
          <stop offset="1" stopColor="#FF9B64" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Pulsa;
