import React from 'react';
import './FormInput.css';

function FormInput({
  label,
  placeholder,
  name,
  onChange,
  type,
  defaultValue,
  hint,
  value
}) {
  return (
    <div className="field">
      {label && <label className="labels">{label}</label>}

      <div className="control">
        <input
          placeholder={placeholder}
          style={{
            paddingBottom: 5,
            paddingTop: 6,
            height: 'auto',
            backgroundColor: '#F0F0F0',
            borderRadius: 8,
            boxShadow: 'unset',
            WebkitBoxShadow: 'unset',
            border: 'unset',
            lineHeight: 1.3
          }}
          name={name}
          onChange={e => onChange(e)}
          className="input"
          type={type || 'text'}
          defaultValue={defaultValue}
          value={value}
        />
        {hint && <p className="label-down">{hint}</p>}
      </div>
    </div>
  );
}

export default FormInput;
