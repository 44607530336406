import React from 'react';

export default function MapIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89459 11.4325L1.68408 12.4266V3.47948L5.89459 2.48535"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1053 13.2551L5.89478 11.2969V2.48535L10.1053 4.44348V13.2551Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1052 4.30791L14.3157 3.31378V12.2609L10.1052 13.2551"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
