import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { translatable } from 'react-multilingual'
import { Link, Redirect } from 'react-router-dom'
import { logout, toggleModal, setUser, setMessage } from '../redux/actions'
import ApiCall from '../services/ApiCall'
import Auth from '../services/Auth'

class ChangePassword extends Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			password: props.app.user.password,
			name: '',
			email: '',
			idUser: '',
			data: {},
			loading: false,
			lang: 'id',
			log: '',
			direct: false,
			code: '',
			phone: '',
			statusRegister: '',
			oldpassword: '',
			newpassword: '',
			confirmpassword: ''
		}
	}

	componentWillMount() {
		setTimeout(() => {
			this.setState({data: this.props.app.user})
		}, 1000)
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		setTimeout(() => {
			if (this.props.app.user === '' || this.props.app.user.name === '' || this.props.app.user.name === undefined) {
				window.location.href = '/'
			}
			if (this.props.app.toggleMenu === true) {
				this.props.toggleModal('toggleMenu')
			}
		}, 1000)
	}

	closeHeader = () => {
		if (this.props.app.toggleMenu === true) {
			this.props.toggleModal('toggleMenu')
		}
	}
	
	changeLang = (lang) => {
		this.setState({lang})
		this.props.changeLocale(lang)
	}


	handleInputChange = (event) => {
		const target = event.target
		const value = target.value
		const name = target.name
		this.setState({[name]: value})
	}

	changeSetPassword = (e) => {
		e.preventDefault()
		this.setState({loading: true})
		const cekData = {
			email: this.state.data.email,
			password: this.state.oldpassword
		}
		Auth.login(cekData)
		.then(res => {
			if (this.state.newpassword !== this.state.confirmpassword) {
				this.props.setMessage(this.props.t.notwrong)
				this.props.toggleModal('popupmessage')
				this.setState({loading: false})
			} else {
				const data = { 
					password: this.state.newpassword,
					id: this.state.data.id
				}
				ApiCall.resetPassProfile(data)
				.then(response => {
					this.setState({loading: false})
					this.props.setMessage(this.props.t.passwordOke)
					this.props.toggleModal('popupmessage')
					this.setState({direct: true})
				})
				.catch(error => {
					if (error.response) {
						toast.error(error.response.data.message)
					}
					else {
						toast.error(this.props.t.nointernet)
					}
					this.setState({loading: false})
				})
				this.setState({loading: false})
			}
		})
		.catch(err => {
			this.props.setMessage(this.props.t.incorrectpassword)
			this.props.toggleModal('popupmessage')
		})
	}

	logout = () => {
		this.props.logout()
		const pathname = window.location.pathname
		setTimeout(() => {
			if (pathname === '/profile' || pathname === '/profile/edit') {
				window.location.href = '/'
			} else {
				window.location.reload()
			}
		}, 500)
	}

	render() {
		const { t } = this.props
		
		if (this.state.direct) {
			return (
			<Redirect to={{pathname: '/profile/' + this.props.app.user.name, state: { from: this.props.location }}} />
			)
		}
		return (
			<section className="section mobileprof" onClick={this.closeHeader}>
				<div className="container profpage">
					<div className="columns">
						<div className="column is-4 myaccountbox">
							<h3 className="title" style={{fontSize: 18}}>{t.myaccount}</h3>
							<hr style={{marginTop: -10}} />
							<Link to='/profile' className="column is-12 activated boxes" style={{height: 30, marginTop: -10, paddingTop: 3, paddingBottom: 5, marginLeft: -31}}>
								<p style={{fontSize: 14, color: 'black'}}>{t.accountset}</p>
							</Link>
							<Link to='/my-booking' className="column boxes unactive is-12" style={{height: 30, marginTop: 0, paddingTop: 3, paddingBottom: 5, marginLeft: -31}}>
								<p style={{fontSize: 14, color: 'black'}}>{t.mybook}</p>
							</Link>
							<Link to='/' className="column boxes unactive is-12" style={{height: 30, marginTop: 0, paddingTop: 3, paddingBottom: 5, marginLeft: -31}} onClick={this.logout}>
								<p style={{fontSize: 14, color: 'black'}}>{t.logout}</p>
							</Link>
						</div>
						<div className="column is-8 accountsettingbox">
							<form onSubmit={this.changeSetPassword}>
								<h3 className="title" style={{fontSize: 18}}>{t.accountset}</h3>
								<hr style={{marginTop: -10}} />
								<div className="column is-6" style={{marginTop: -20, paddingLeft: 0, paddingRight: 0}}>
									<b style={{fontSize: 14}}>{t.oldpass}</b> 
									<br />
									<input type="password" name="oldpassword" className="input inputbox" style={{fontSize: 14, marginTop: 5}} onChange={this.handleInputChange} required="true" />
								</div>
								<br />
								<div className="columns is-mobile is-multiline" style={{marginTop: -25}}>
									<div className="column is-6 is-narrow newpass">
										<b style={{fontSize: 14}}>{t.newPassword}</b> <br />
										<input type="password" name="newpassword" className="input" style={{fontSize: 14, marginTop: 5}} onChange={this.handleInputChange} required="true" />
									</div>
									<div className="column is-6 is-narrow mobilecolumn conpass">
										<b style={{fontSize: 14}}>{t.confirmpass}</b> <br />
										<input type="password" name="confirmpassword" className="input" style={{fontSize: 14, marginTop: 5}} onChange={this.handleInputChange} required="true" />
									</div>
								</div>
								<hr className="linemobile" />
								<div className="column is-12 buttons" style={{marginTop: -8}}>
									<button type="submit" className="button is-primary bg-primary savebutton" ><b>{t.save}</b></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

const mapStateToProps = (state) => {
	const { app } = state
	return { app }
}

const mapTranslationsToProps = ({t}) => ({t})

const connector = connect(mapStateToProps, { logout, setUser, setMessage, toggleModal })
const translator = translatable(mapTranslationsToProps)

export default translator(connector(ChangePassword))
