import React, { Component } from 'react';
import translatable from 'react-multilingual/dist/translatable';

class PayAtVilla extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="kolomkota">
        <label
          style={{ color: 'white' }}
          className="has-text-centered is-pulled-right payvilla"
        >
          <p style={{ marginLeft: 18 }}>{t.payAtVilla}</p>
          <figure
            className="cottagepic"
            style={{ marginLeft: 16, height: 12, width: 12 }}
          >
            <img src="/icon/cottage.png" alt="Nusalink" />
          </figure>
        </label>
      </div>
    );
  }
}

const mapTranslationsToProps = ({ t }) => ({ t });
const translator = translatable(mapTranslationsToProps);
export default translator(PayAtVilla);
